import cookie from "cookie_js";
import fetchProcessor from "./fetchProcessor";
import processError from "./processError";
import user from "../User";

const loadDataFromApi = (
  url,
  method,
  data,
  callback,
  errorCallback,
  supressErrorNotification = false,
  raw = false
) => {
  if (fetchProcessor[method?method.toLowerCase():"GET"]) {
    const options = {
      url:`${raw?url:"/api/data/"+url}`,
      data,
      callback,
      token: user.getUserDetails()?user.getUserDetails().token:null,
      xsrfToken: cookie.get("XSRF-TOKEN"),
      errorCallback: error => {
        processError(errorCallback, error, supressErrorNotification);
      }
    };
    fetchProcessor[method.toLowerCase()](options);
  } else {
    console.warn("api", "Error: Unsupported Method");
  }
};

export default loadDataFromApi;
