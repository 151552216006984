import React, { useEffect } from "react";
import { observable, toJS } from "mobx";

import FormLoaderStyle from "./FormLoaderStyle";
import Noop from "../Core/Noop";
import checkAnyHiddenOnAddAvailable from "./actions/checkAnyHiddenOnAddAvailable";
import componentRegistry from "./componentRegistry";
import computeLocked from "./actions/computeLocked";
import computeReadOnly from "./actions/computeReadOnly";
import computeReadOnlyAdd from "./actions/computeReadOnlyAdd";
import { guid } from "../../utilities";
import { useParsley } from "./actions/setupParsley";
import { withTheme } from "@emotion/react";

//import { computeField } from "./FieldProcessor";

const FormLoader = (props) => {
  useParsley({ element: props.FormId, data: props.EntryDetails });
  useEffect(() => {
    // if (props.NoForm !== true) {
    /*setupParsley(props.FormId, () => {
        const d = toJS(props.EntryDetails);
        console.log(d);
        return props.EntryDetails;
      });*/
    // }
    // return () => {
    //  removeParsley(props.FormId);
    //  };
  }, []);

  const defineItem = (field) => {
    let CurrentComponent = componentRegistry.Components[field.type]
      ? componentRegistry.Components[field.type]
      : componentRegistry.Custom[field.type]
      ? componentRegistry.Custom[field.type]
      : Noop;

    if (field.hideOn) {
      if (field.hideOn.add === true && props.Adding) {
        CurrentComponent = componentRegistry.Components["spacer"];
      }
      if (field.hideOn.edit === true && props.Editing) {
        CurrentComponent = componentRegistry.Components["spacer"];
      }
    }

   

    return (
      <CurrentComponent
        key={guid()}
        noadd={computeReadOnlyAdd(field, props.EntryDetails)}
        FormId={props.FormId}
        adding={props.Adding}
        stack={props.stack}
        onLaunchDialog={props.onLaunchDialog}
        onLaunchReport={props.onLaunchReport}
        AfterSave={props.AfterSave}
        ReadOnly={computeReadOnly(
          field,
          props.ParentData,
          props.EntryDetails,
          props.ReadOnly,
          props.Adding
        )}
        HostFormId={props.FormId}
        Editing={props.Editing}
        Locked={computeLocked(
          field,
          props.ParentData,
          props.EntryDetails,
          props.Locked,
          props.Adding
        )}
        EntryDetails={props.EntryDetails}
        ParentData={props.ParentData ? props.ParentData : props.EntryDetails}
        onSelection={props.onSelection}
        onDone={props.onDone}
        Config={field}
        datasource={props.datasource}
        ReloadParent={props.ReloadParent}
      />
    );
  };

  const drawForm = (
    <div className={props.noAnimation ? "form-holder" : "form-holder"}>
      <div className="card-holder">
        {props.FormDefinition.formDefinition.rows.map((row) => {
          return checkAnyHiddenOnAddAvailable(row.columns, props.Adding) ? (
            <div key={guid()+"card"} className="card">
              <div className="row">
                {row.columns.map((column) => {
                  return (column.hideOnAdd === true ||
                    column.hideOnAdd === "true") &&
                    props.Adding ? (
                    <Noop key={guid()+"field"} />
                  ) : (
                    <div key={guid()+"field"} className={`col-md`}>
                      <h2
                        style={{
                          visibility:
                            column.title !== "\n" ? "visible" : "hidden",
                          display:
                            column.title === "" || !column.title
                              ? "none"
                              : "block",
                        }}
                      >
                        {column.title.replace("\n", "None")}
                      </h2>

                      {column.fields.map((field) => (
                        <div className="itemcontainer">
                          {Array.isArray(field) ? (
                            <div className="subfield">
                              <div className="container">
                                <div className="row">
                                  {field.map((subfield) => {
                                    return (
                                      <div
                                        className={
                                          subfield.width
                                            ? `subitem col-md-${subfield.width}`
                                            : "subitem col-md"
                                        }
                                      >
                                        {defineItem(subfield)}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          ) : (
                            defineItem(field)
                          )}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <Noop key={guid()+"noop"} />
          );
        })}
      </div>
    </div>
  );

  return (
    <FormLoaderStyle>
      {props.NoForm ? (
        <>{drawForm}</>
      ) : (
        <form id={props.FormId} autoComplete="off">
          {drawForm}
        </form>
      )}
    </FormLoaderStyle>
  );
};

export default withTheme(FormLoader);
