import { user } from '../../../../utilities';
const lowerCaseArray = (array) => {
  const result = [];
  array.forEach((item) => {
    result.push(item.SecurityRole.trim().toLowerCase());
  });
  return result;
};

const checkPermissions = (button, props) => {
  const claims = lowerCaseArray(user.getUserDetails().Claims);
  if (button && button.roles) {
    let result = false;
    button.roles.forEach((permission) => {
      permission.role.forEach((r) => {
        if (r.trim().toLowerCase() === 'default') {
          result = permission.value;
        }
        claims.forEach((claim)=>{
          if(claim === r.trim().toLowerCase())
          {
            result = permission.value;
          }
        })
      });
    });

    return result;
  }

  if (button.requiresAllowAdd) {
    if (props.FormDefinition && props.FormDefinition.allowAdd) {
      let result = false;
      props.FormDefinition.allowAdd.forEach((permission) => {
        permission.role.forEach((r) => {
          if (r.trim().toLowerCase() === 'default') {
            result = permission.value;
          }
          claims.forEach((claim)=>{
            if(claim === r.trim().toLowerCase())
            {
              result = permission.value;
            }
          })
        });
      });

      return result;
    }
  }

  if (button.requiresAllowEdit) {
    if (props.FormDefinition && props.FormDefinition.allowEdit) {
      let result = false;
      props.FormDefinition.allowEdit.forEach((permission) => {
        permission.role.forEach((r) => {
          if (r.trim().toLowerCase() === 'default') {
            result = permission.value;
          }
          claims.forEach((claim)=>{
            if(claim === r.trim().toLowerCase())
            {
              result = permission.value;
            }
          })
        });
      });

      return result;
    }
  }

  if (button.requiresAllowView) {
    if (props.FormDefinition && props.FormDefinition.allowView) {
      let result = false;
      props.FormDefinition.allowView.forEach((permission) => {
        permission.role.forEach((r) => {
          if (r.trim().toLowerCase() === 'default') {
            result = permission.value;
          }
          claims.forEach((claim)=>{
            if(claim === r.trim().toLowerCase())
            {
              result = permission.value;
            }
          })
        });
      });

      return result;
    }    
  }

  if (button.requiresAllowAudit) {
    if (props.FormDefinition && props.FormDefinition.allowAudit) {
      let result = false;
      props.FormDefinition.allowAudit.forEach((permission) => {
        permission.role.forEach((r) => {
          if (r.trim().toLowerCase() === 'default') {
            result = permission.value;
          }
          claims.forEach((claim)=>{
            if(claim === r.trim().toLowerCase())
            {
              result = permission.value;
            }
          })
        });
      });

      return result;
    }
  }

  if (button.requiresAllowPrint) {
    if (props.FormDefinition && props.FormDefinition.allowPrint) {
      let result = false;
      props.FormDefinition.allowPrint.forEach((permission) => {
        permission.role.forEach((r) => {
          if (r.trim().toLowerCase() === 'default') {
            result = permission.value;
          }
          claims.forEach((claim)=>{
            if(claim === r.trim().toLowerCase())
            {
              result = permission.value;
            }
          })
          
        });
      });

      return result;
    }
  }

  return true;
};

export default checkPermissions;
