import checkPermissions from './computePermissions';
//import computeDefaultButtons from './computeDefaultButtons';
//import computeActionProcessor from './computeActionProcessor';
import computeShown from './computeShown';
import dependentShow from './dependentShow';
import buttons from './buttons';
const computeFormDefinitionButtons = (props) => {

  //capture all buttons here and cycle all buttons will be wrapped in 1 class


  const allButtons = [...buttons(props)];

  if(props.AdditionalButtons)
  {
    props.AdditionalButtons.forEach(button => {
      let found=false;
      allButtons.forEach((normal,index)=>{
        if(button.text=== normal.text)
        {
          allButtons[index]=button;
          found =true;
        }
      });
      if(!found)
      {
        allButtons.push(button);
      }
    })
  }


  const returnFinalButtons = [];

  allButtons.forEach(button=>{
    if(checkPermissions(button,props))
    {
      if(computeShown(button,props))
      {
        if(dependentShow(button,props))
        {
          returnFinalButtons.push(button);
        }
      }
    }
  });
  return returnFinalButtons;
  //inject action on button cycle all

  


  /*if (props.formDefinition) {
    const formDefinition = props.formDefinition;
    buttons = [...computeDefaultButtons(props)];
    if (formDefinition.customActions) {
      formDefinition.customActions.forEach(customItem => {
        if (checkPermissions(customItem.roles)) {
          buttons.push({
            ...customItem
          });
        }
      });
    }
  }

  if (props.AdditionalButtons) {
    props.AdditionalButtons.forEach(customItem => {
      if (checkPermissions(customItem.roles)) {
        buttons.push({
          ...customItem
        });
      }
    });
  }

  const finalButtons = [];
  buttons.forEach(button => {
    if (computeShown(button, props)) {
      const finalButton = button;
      finalButton.action = computeActionProcessor(button, props);
      finalButtons.push(finalButton);
    }
  });

  const returnFinalButtons = [];

  finalButtons.forEach(button => {
    if (customDependentShow(button, props)) {
      const finalButton = button;
      finalButton.action = computeActionProcessor(button, props);
      returnFinalButtons.push(finalButton);
    }
  });*/



 // return returnFinalButtons;
};

export default computeFormDefinitionButtons;
