import styled from '@emotion/styled';

const LoaderStyle = styled.div`
  .loader-background:before,
  .loader-background:after {
    content: '';
    height: 60vh;
    width: 100%;
    position: fixed;
    left: 0;
    z-index: 10000;
  }

  .loader-background:before {
    top: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    background: ${(props) => props.theme.accent};
  }
  .loader-background:after {
    bottom: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    background: ${(props) => props.theme.accent};
  }

  .loader-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;

    display: none;
  }

  .loader-background-init {
    display: inherit;
  }

  .loader-background-init:before {
    top: 51%;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    background: ${(props) => props.theme.accent};
  }
  .loader-background-init:after {
    bottom: 51%;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    background: ${(props) => props.theme.accent};
  }

  .loader-transitioner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50000;
    display: none;
  }

  .loader-center {
    -webkit-transform: scale(0);
    transform: scale(0);
    position: absolute;
    left: 50%;
    top: 50%;
    background: ${(props) => props.theme.accent};
    width: 230px;
    height: 230px;
    border-radius: 115px;
    border: 1px solid ${(props) => props.theme.borderExtraLight};
    margin-top: -115px;
    margin-left: -115px;
    z-index: 60000;
  }
  .loading-text {
    font-family: 'Work Sans', sans-serif;
    position: absolute;
    display: block;
    top: 50%;
    width: 100%;
    left: 0;
    text-align: center;
    color: #fff;
    font-size: 12px;
    margin-top: -6px;
  }

  .line-left {
    background: #fff;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    margin-top: -1px;
    opacity: 0.1;
  }

  .line-right {
    background: #fff;
    opacity: 0.1;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0%;
    margin-top: -1px;
  }

  .svg-calLoader {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -115px;
    width: 230px;
    height: 230px;
    transform-origin: 115px 115px;
    animation: 2s linear infinite loader-spin;
  }

  .cal-loader__plane {
    fill: ${(props) => props.theme.backgroundDark};
  }

  .cal-loader__path {
    stroke: ${(props) => props.theme.backgroundLight};
    opacity: 0.2;
    animation: 2s ease-in-out infinite loader-path;
  }

  @keyframes loader-spin {
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader-path {
    0% {
      stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
    50% {
      stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
    }
    100% {
      stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
  }

  .before,
  .after {
    content: '';
    width: 100%;
    position: fixed;
    left: 0;
    z-index: 10000;
    background: ${(props) => props.theme.accent};
    height: 50%;
  }

  .before {
    top: 0;
  }
  .after {
    bottom: 0;
  }

  .leftline,
  .rightline {
    z-index: 10001;
    position: fixed;
    height: 1px;
    top: 50%;
    opacity: 0.1;
    background: ${(props) => props.theme.backgroundDark};
  }

  .leftline {
    left: 0;
    width: 50%;
  }

  .rightline {
    right: 0;
    width: 50%;
  }

  .logoholder {
    position: fixed;
    z-index: 10002;
    left: 50%;
    top: 50%;
    margin-left: -115px;
    margin-top: -115px;
    width: 230px;
    height: 230px;
    background: ${(props) => props.theme.accent};
    border: 1px solid ${(props) => props.theme.borderLight};
    border-radius: 50%;
  }

  .logotext {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -40px;
    width: 60px;
    height: 80px;
    font-size: 11px;
    font-weight: 600;
    padding-top: 30px;
    color: ${(props) => props.theme.borderMedium};
    background: ${(props) => props.theme.accent};
    dispaly: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .svg-calLoader {
    position: absolute;
    width: 230px;
    height: 230px;
    transform-origin: 115px 115px;
    animation: 2s linear infinite loader-spin;
  }

  .cal-loader__plane {
    fill: ${(props) => props.theme.backgroundDark};
  }

  .cal-loader__path {
    stroke: ${(props) => props.theme.backgroundLight};
    opacity: 0.2;
    animation: 2s ease-in-out infinite loader-path;
  }

  @keyframes loader-spin {
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader-path {
    0% {
      stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
    50% {
      stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
    }
    100% {
      stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
  }

  .hidden {
    display: none;
  }
`;

export default LoaderStyle;
