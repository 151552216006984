import loadDataFromApi from '../../../../utilities/API';
import { toJS } from 'mobx';

const authenticate = (props, entryDetails) => {
  return new Promise((resolve, reject) => {
    const dataSend = toJS(entryDetails);

    if (props.Config.authenticate) {
      loadDataFromApi(
        props.Config.authenticate.url,
        props.Config.authenticate.method,
        dataSend,
        (response) => {
          if (response && response.Code === '200') {
            resolve(response[props.Config.authenticate.identity].Token);
          }
          else
          {
            reject(null);  
          }

        },
        () => {
          reject(null);
        },
        props.Config.authenticate.suppressErrors,
        props.Config.authenticate.isRaw
      );
    }
  });
};

export default authenticate;
