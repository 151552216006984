export default {
  track: "CargoPaymentDetail",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true,
    },
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false,
    },
    {          
      role: ["Cargo Administrator", "Accounting Administrator", "Accounting Member"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false,
    },
  ],
  caption: "Cargo Payment Detail",
  editSearchSelector: {},
  functionactions: {
    createnew: {},
    update: {
      functionname: "UpdateCargoPaymentDetail",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Payment Detail Information Updated",
      alertText: "The Cargo Payment Detail Has Been Updated"
    },
    after: {},
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Cargo Payment Detail Information",
            fields: [
              {
                key: "InvoiceNumber",
                type: "text",
                label: "Invoice Number",
                placeholder: "Invoice Number",
                readOnly: true
              }, {
                key: "AmountDue",
                type: "number",
                format: "$0,0.00",
                label: "Amount Due",
                placeholder: "Amount Due",
                readOnly: true
              },
              {
                key: "PaymentAmount",
                type: "number",
                format: "$0,0.00",
                label: "Payment Amount",
                placeholder: "Payment Amount",
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 0,
                  "data-parsley-max": 1000000
                }
              },
              {
                key: "Reference",
                type: "text",
                label: "Reference",
                placeholder: "Reference",
                validation: {
                  "data-parsley-maxlength": 255
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                type: "spacer"
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                type: "spacer"
              }
            ]
          }
        ]
      }
    ]
  }
};
