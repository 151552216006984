import Grid from "../../../Core/Grid/";
import React from "react";
import { withTheme } from "@emotion/react";

const DataGrid = (props) => {
 
  return (
    <Grid
      Config={props.Config}
      stack={props.stack}
      ParentData={props.ParentData}
      FormId={props.FormId}
      noadd={props.noadd}
      adding={props.adding}
      onLaunchDialog={props.onLaunchDialog}
      onLaunchReport={props.onLaunchReport}
      AfterSave={props.AfterSave}
      ReadOnly={props.ReadOnly}
      HostFormId={props.HostFormId}
      Editing={props.Editing}
      Locked={props.Locked}
      EntryDetails={props.EntryDetails}
      onSelection={props.onSelection}
      onDone={props.onDone}
      datasource={props.datasource}
      ReloadParent={props.ReloadParent}
    ></Grid>
  );
};
export default withTheme(DataGrid);
