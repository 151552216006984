
import CargoSpecialCustomerRateList from "../cargospecialcustomerrate/cargospecialcustomerratelist";
import CargoSpecialCustomerSearch from "./cargospecialcustomersearch";
export default {
  formtype: "dataEntry",
  customSearch: {
    searchForm: { ...CargoSpecialCustomerSearch },
    width: 800,
    align: 500,
    height: 800,
    id: "cargospecialcustomersearch"
  },
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator", "Accounting Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator", "Accounting Administrator"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Special Customer Details",
  editSearchSelector: {
    resource: "GetCargoSpecialCustomerList",
    resourceId: ["CargoSpecialCustomerId"],
    resourceType:"GET",
    resourceIdentity: "CargoSpecialCustomers",
    resourceValue: "CustomerName",
    resourceSelectedType: "POST",
    resourceSelected: "GetCargoSpecialCustomer",
    resourceSelectedIdentity: "CargoSpecialCustomer",
    resourceDisplayFields: [],
    after: []
  },
  functionactions: {
    createnew: {
      functionname: "AddCargoSpecialCustomer",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Customer Information Added",
      alertText: "The Cargo Customer Has Been Added"
    },
    update: {
      functionname: "UpdateCargoSpecialCustomer",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Customer Information Updated",
      alertText: "The Cargo Customer Has Been Updated"
    },
    after: {
      functionname: "GetCargoSpecialCustomerList",
      functiontype: "GET"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Special Cargo Customer Information",
            fields: [
              {
                key: "CustomerName",
                type: "text",
                label: "Customer Name",
                placeholder: "Customer Name",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 50
                }
              },           
              {
                key: "AgencyId",
                type: "select",
                defaultValue: "",
                label: "Agency",
                placeholder: "Agency",
                readOnly: false,
                optionsResource: {
                  resource: "GetAgencyList",
                  resourceType: "GET",
                  resourceId: ["AgencyId"],
                  resourceIdentity: "Agencies",
                  resourceValue: "AgencyName"
                },
                validation: {
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 5,
                }
              },
            ]
          },
          {
            title: "\n",
            fields: [              
              {
                key: "CustomerActive",
                type: "checkbox",
                label: "Active",
                placeholder: "Active",
                readOnly: false
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "tabs",
                readOnlyOnEdit: true,
                tabs: [
                  {
                    caption: "Rates",
                    definition: { ...CargoSpecialCustomerRateList }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
