export default {
  duplicates: [],
  access: [],
  track: "Company",
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    },
    {
      role: [""],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    },
    {
      role: ["Company Administration Administrator"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Company Administration Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Company Details",
  editSearchSelector: {
    resource: "GetCompanyList",
    resourceId: ["CompanyRecordNumber"],
    resourceMethod:"GET",
    resourceIdentity: "Companies",
    resourceValue: "CompanyRecordNumber",
    resourceSelectedType: "POST",
    resourceSelected: "getCompany",
    resourceSelectedIdentity: "Company",
    resourceDisplayFields: [],
    after: []
  },
  model: {
    modelid: "getCompany",
    autoload: false
  },
  functionactions: {
    createnew: {
      functionname: "addCompany",
      functiontype: "POST",
      validateData: true,
      validationFailedTitle: "",
      validationFailedText: "",
      alertOnComplete: true,
      alertTitle: "Company Information Added",
      alertText: "The Company Has Been Added"
    },
    update: {
      functionname: "updateCompany",
      functiontype: "POST",
      validateData: true,
      validationFailedTitle: "",
      validationFailedText: "",
      alertOnComplete: true,
      alertTitle: "Company Information Updated",
      alertText: "The Company Has Been Updated"
    },
    after: {
      functionname: "getCompanyList",
      functiontype: "POST"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Company Information",
            fields: [
              {
                key: "CompanyName",
                type: "text",
                label: "Company Name",
                placeholder: "Company Name",
                readOnly: true,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 100
                }
              },
              {
                key: "CompanyAddress1",
                type: "text",
                label: "Address 1",
                placeholder: "Address 1",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 100
                }
              },
              {
                key: "CompanyAddress2",
                type: "text",
                label: "Address 2",
                placeholder: "Address 2",
                readOnly: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 100
                }
              },
              {
                key: "CompanyCity",
                type: "text",
                label: "City",
                placeholder: "City",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 100
                }
              },
              {
                key: "CompanyState",
                type: "text",
                label: "State",
                placeholder: "State",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 2
                }
              },
              {
                key: "CompanyZip",
                type: "text",
                label: "Zip Code",
                placeholder: "Zip Code",
                readOnly: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 10
                }
              },             
              {
                key: "CountryCode",
                type: "select",
                defaultValue: "",
                label: "Country",
                placeholder: "Country",
                readOnly: false,
                optionsResource: {
                  resource: "GetCountryList",
                  resourceType: "GET",
                  resourceId: ["CountryCode"],
                  resourceIdentity: "Countries",
                  resourceValue: "CountryName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 2,
                }
              }
            ]
          },
          {
            title: "Contact Information",
            fields: [
              {
                key: "TelephoneNumber1",
                type: "text",
                label: "Telephone Number 1",
                placeholder: "Telephone Number 1",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 12
                }
              },
              {
                key: "TelephoneNumber2",
                type: "text",
                label: "Telephone Number 2",
                placeholder: "Telephone Number 2",
                readOnly: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 12
                }
              },
              {
                key: "FaxNumber",
                type: "text",
                label: "Fax Number",
                placeholder: "Fax Number",
                readOnly: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 12
                }
              },
              {
                key: "EmailAddress",
                type: "text",
                label: "Email Address",
                placeholder: "Email Address",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "WebSiteAddress",
                type: "text",
                label: "Web Site Address",
                placeholder: "Web Site Address",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "",
                type: "spacer"
              },
              {
                key: "",
                type: "spacer"
              }
            ]
          }
        ]
      }
    ]
  }
};
