

import Colors from "../../../constants/Colors";
export default {
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],

  
  printreport: "",
  caption: "Void Airwaybill",
  editSearchSelector: {
    resource: "GetAirwaybillList",
    resourceType: "GET",
    resourceId: ["AirwaybillNumber"],
    resourceIdentity: "Airwaybills",
    resourceValue: "AirwaybillNumber",
    resourceSelected: "GetAirwaybill",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "Airwaybill",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "VoidAirwaybill",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true
    }
  },
  addConfirmation: {
    title: 'Confirm',
    text: 'Please confirm voiding of airwaybill'
  },
  formDefinition: {
    rows: [
      {
        columns: [          
          {
            title: "Void Air Waybill",
            fields: [
              {
                key: "AirwaybillNote",
                type: "textarea",
                label: "Reason For Voiding",
                placeholder: "Reason for Voiding",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 1024
                }
              }
            ]
          },
          {
            title: "",
            fields: [
              {
                type: "spacer"
              }
            ]
          },
          {
            title: "",
            fields: [
              {
                type: "spacer"
              }
            ]
          }
        ]
      }
    ]
  }
};
