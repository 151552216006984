import React, { PureComponent } from "react";
import FormLoader from "../../FormRenderer";
import definition from "../../../definitions/forms/help/helpsearch";
import { observable, toJS } from "mobx";
import { loadData, guid } from "../../../utilities";
import $ from "jquery";
import Noop from "../../Core/Noop";
import { Observer } from "mobx-react";
import HelpStyle from "./HelpStyle";
export default class extends PureComponent {
  constructor(props) {
    super(props);
    if (!props.Data.SearchDetails) {
      props.Data.SearchDetails = observable({
        MajorCategory: "",
        MinorCategory: "",
        DocumentationTopic: "",
        topics: [],
        selectedTopic: "",
        searched: false,
        currentTopic: "",
        topicShown: null
      });
    }
    /*this.state = {
   
    };*/
  }
  findTopics() {
    
    loadData(
      "getuserdocumentationlist",
      "post",
      toJS(this.props.Data.SearchDetails),
      response => {
        /*this.setState({
          ...this.state,
          topics: response["UserDocumentations"]
            ? response["UserDocumentations"]
            : [],
          searched: true
        });*/
        this.props.Data.SearchDetails.topics = response["UserDocumentations"]
          ? response["UserDocumentations"]
          : [];
        this.props.Data.searched = true;
      },
      () => {}
    );
  }
  showHelp(item) {
    loadData(
      "getuserdocumentation",
      "post",
      item,
      response => {
        //this.setState({
        //  ...this.state,
        // selectedTopic: response["UserDocumentations"].DocumentationContent
        // });
        this.props.Data.SearchDetails.topicShown = guid();
        $("#helpcontainer").css("width","600px");
        this.props.Data.SearchDetails.currentTopic = `<h4>${response["UserDocumentation"].DocumentationTopic}</h4><br/>${response["UserDocumentation"].DocumentationContent}`;
      },
      () => {}
    );
  }
  hideHelp(){
    //$("#helpcontainer").removeClass("slideInRight");
    //$("#helpcontainer").addClass("slideOutRight");
    $("#helpcontainer").hide();
  }
  render() {
    return (
      <HelpStyle id="helpcontainer">
        <div className="container-sidebar">
         
            <FormLoader
              noAnimation={true}
              Adding={true}
              ReadOnly={false}
              EntryDetails={this.props.Data.SearchDetails}
              FormDefinition={definition}
            />
         

          <a href="#/" onClick={(e)=>{e.preventDefault(); this.findTopics();}} className="btn findTopic">
            <span>
              <i className="mdi mdi-magnify"></i>Find Topic
            </span>
          </a>
          <Observer>
            {() => (
              <div id={this.props.Data.SearchDetails.topicShown}                
                className={`help ${this.props.Data.SearchDetails.topicShown}`}
              >
                {this.props.Data.SearchDetails.topics.length > 0 ? (
                  <h6>{this.props.Data.SearchDetails.topics.length} results:</h6>
                ) : this.props.Data.searched ? (
                  <h6>No topics matched your search criteria.</h6>
                ) : (
                  <Noop />
                )}
                <ul>
                  {this.props.Data.SearchDetails.topics.map(item => {
                    return (
                      <li key={guid()}>
                        <a href="#/" onClick={(e)=>{e.preventDefault();this.showHelp(item);}}>
                          {item.DocumentationTopic}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </Observer>
        </div>

        <div className="help-contents">
          <Observer>
            {() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.Data.SearchDetails.currentTopic
                }}
              ></div>
            )}
          </Observer>
        </div>

        <div className="logo">
         
          <table>
            <tbody>
              <tr>
                <td><h4 style={{fontWeight:600}}>Help</h4></td>
                <td style={{textAlign:"right"}}> <a href="#/" onClick={(e)=>{e.preventDefault();this.hideHelp()}}>
                      <i className="icon-cross" />
                    </a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </HelpStyle>
    );
  }
}
