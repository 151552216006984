import React from 'react';
import NotificationStyle from './NotificationStyle';

const NotificationItem = (props)=> {
  
    return (
      <NotificationStyle>
      <div className={props.type}>
        <table className="item">
          <tbody>
            <tr>
              <td>
                <i className={props.icon}></i>
              </td>
              <td>
                <h4>{props.title}</h4>
                {props.message.toString()}
              </td>
              <td>
                <button onClick={props.close}>
                <i className="mdi mdi-close"></i>
                </button>
              </td>
            </tr>
         
          </tbody>
        </table>
        </div>
      </NotificationStyle>
    );
  }


export default NotificationItem;