export default {
  track: "CargoPayment",
  formtype: "dataEntry",
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                key: "CargoPaymentRecordNumber",
                type: "text",
                label: "Receipt Number",
                placeholder: "Receipt Number",
                readOnly: false
              },
              {
                key: "AgencyId",
                type: "select",
                label: "Agency",
                placeholder: "Agency",
                readOnly: false,
                optionsResource: {
                  resource: "GetAgencyList",
                  resourceType: "GET",
                  resourceId: ["AgencyId"],
                  resourceIdentity: "Agencies",
                  resourceValue: "AgencyName"
                }
              }
            ]
          },
          {
            title: '\n',
            fields: [
              {
                key: "BeginDate",
                type: "date",
                label: "Begin Date",
                placeholder: "Begin Date",
                readOnly: false
              },
              {
                key: "EndDate",
                type: "date",
                label: "End Date",
                placeholder: "End Date",
                readOnly: false
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "",
            hideOnAdd: "true",
            fields: [
              {
                type: "searchtable",
                id:"CargoPayment",
                data: {
                  getResource: {
                    id: "GetCargoPaymentListForSearch",
                    resourceType: "POST",
                    resourceIdentity: "CargoPayments"
                  }
                },
                fields: [
                  {
                    key: "AgencyName",
                    type: "text",
                    label: "Agency"
                  },
                  {
                    key: "CargoPaymentRecordNumber",
                    type: "text",
                    label: "Receipt No."
                  },
                  {
                    key: "PaymentDate",
                    type: "date",
                    label: "Payment Date"
                  },
                  {
                    key: "PaymentAmount",
                    type: "number",
                    label: "Amount",
                    format: "c2",
                  },
                  {
                    key: "PaymentStatus",
                    type: "text",
                    label: "Status"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
