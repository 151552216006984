
import * as $ from 'jquery';


const Notification = {
  info(message) {
    $(window).trigger('snack-bar', {
      title: 'Information',
      message: message,
      icon: 'mdi mdi-information-outline',
      type: 'information',
    });
  },
  success(message) {
    $(window).trigger('snack-bar', {
      title: 'Success',
      message: message,
      icon: 'mdi mdi-check',
      type: 'success',
    });
  },
  error(message, source, callback) {
    $(window).trigger('snack-bar', {
      title: 'Error',
      message: message,
      source: source,
      callback: callback,
      icon: 'mdi mdi-alert-circle-outline',
      type: 'error',
    });
  },
  warning(message) {
    $(window).trigger('snack-bar', {
      title: 'Alert',
      message: message,
      icon: 'mdi mdi-alert-outline',
      type: 'warning',
    });
  },
};

export default Notification;
