import React, { useEffect } from 'react';
import { ThemeProvider, withTheme } from '@emotion/react';
import { guid, user } from '../../../utilities';
import { observable, toJS } from 'mobx';

import ActionBar from '../../Core/ActionBar';
import FormLoader from '../../FormRenderer';
import Loader from './Loader';
import NothingSelected from './NothingSelected';
import { Observer } from 'mobx-react';
import ReactDOM from 'react-dom';
import SearchComponent from '../SearchComponent';
import { loadData } from '../../../utilities';
import themeStore from '../../../stores/themeStore';

const TabPane = (props) => {
  const details = observable({
    nothingSelected: true,
    selectedItem: {},
    loading: false,
    selectedObservable: {},
    original: {},
    editing: false,
    adding: false,
    refreshing: false,
    updatekey: guid(),
    hideSearch:false,
  });
  useEffect(() => {
    if (props.preSelected) {
      onSelected(props.preSelected, false);
    }

    if(props.FormDefiniton.calculateForm == true)
    {
        //details.adding = true;
        //details.editing = false;
        //details.selectedObservable = data;
    }
    /* $(refs.formgrid).parsley({
      errorsContainer: (el) => {
        return el.$element.closest('.form-group');
      },
    });*/
    autoLoad();
  }, []);
  let preventAutoSearch = false;
 
  const stack = observable([]);
  const FormId = guid();
  let lastResult = null;
  

  const autoLoad = () => {
    if (!checkPermissions(props.FormDefiniton.allowSearch)) {
      loadData(
        props.FormDefiniton.editSearchSelector.resource,
        props.FormDefiniton.editSearchSelector.resourceMethod,
        props.FormDefiniton.editSearchSelector.resourceParameters,
        (response) => {
          const single =
            response[
              props.FormDefiniton.editSearchSelector.resourceIdentity
            ][0];

          lastResult = single;

          loadData(
            props.FormDefiniton.editSearchSelector.resourceSelected,
            props.FormDefiniton.editSearchSelector.resourceSelectedType,
            single,
            (singleResponse) => {
              var auto = observable({
                ...singleResponse[
                  props.FormDefiniton.editSearchSelector
                    .resourceSelectedIdentity
                ],
              });
              details.selectedObservable = auto;
              details.nothingSelected = false;
              details.original =
                singleResponse[
                  props.FormDefiniton.editSearchSelector.resourceSelectedIdentity
                ];
            }
          );
        }
      );
    }
  };

  const reloadParent = (newSelected) => {
    preventAutoSearch = true;
    details.refreshing = true;

    setTimeout(() => {
      details.refreshing = false;

      if (newSelected) {
        lastResult = newSelected;
        onSelected(lastResult, true);
      } else {
        onSelected(lastResult, true);
      }
    }, 100);
  };

  const onSelected = (result, delayRefresh) => {
    lastResult = result;
    details.selectedItem = result;
    details.updatekey = guid();
    if (delayRefresh !== true) {
      details.loading = true;
      //details.selectedObservable = result;
      details.nothingSelected = true;
    }

    loadData(
      props.FormDefiniton.editSearchSelector.resourceSelected,
      props.FormDefiniton.editSearchSelector.resourceSelectedType,
      result,
      (response) => {
        const ogResponse = {
          ...response[
            props.FormDefiniton.editSearchSelector.resourceSelectedIdentity
          ],
        };

        details.nothingSelected = false;
        details.original = { ...ogResponse };
        details.selectedObservable = { ...ogResponse };
        details.loading = false;
      },
      () => {}
    );
  };

  const checkPermissions = (permissionObject) => {
    let retval = false;
    if (
      permissionObject === undefined ||
      permissionObject === null ||
      permissionObject[0] === undefined ||
      permissionObject[0] === null
    ) {
      return false;
    }

    if (permissionObject.length <= 1) {
      return permissionObject[0].value;
    }
    const p = user.getUserDetails().Claims;

    p.forEach((claim) => {
      permissionObject.forEach((permission) => {
        permission.role.forEach((r) => {
          if (
            r.trim().toLowerCase() === claim.SecurityRole.trim().toLowerCase()
          ) {
            retval = permission.value;
          }
        });
      });
    });
    return retval;
  };

  const isEmpty = (map) => {
    if (map instanceof Array) {
      if (map.length === 0) {
        return true;
      }
    }
    for (var key in map) {
      if (map.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  const onStateChanged = (currentState, data) => {
    // let newState = { ...state };
    switch (currentState) {
      case 'Normal':
        details.adding = false;
        details.editing = false;
        if (data && !isEmpty(data)) {
          details.selectedObservable = data;
        }
        break;
      case 'Calculate':
          details.adding = true;
          details.editing = false;
          break;
      case 'Add':
        details.adding = true;
        details.editing = false;
        details.selectedObservable = data;
        break;
      case 'Edit':
        details.editing = true;
        details.adding = false;
        //details.selectedObservable = data;
        break;
      case 'View':
        details.editing = false;
        details.adding = false;
        //details.selectedObservable = data;
        break;
      case 'Cancel':
        details.adding = false;
        details.editing = false;
        details.selectedObservable = { ...toJS(details).original };
        break;
      default:
        details.adding = false;
        details.editing = false;
        break;
    }
  };

  return (
    <div className="tab-pane">
      <Observer>
        {() => (
          <>
            {stack.length > 0 ? (
              <div className="dialog-holder-stack">
                {stack.map((dialog) => (
                  <div key={dialog.id}>{dialog.component}</div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </Observer>

      <div className="action-bar">
        <Observer>
          {() => (
            <ActionBar
              FormDefinition={props.FormDefiniton}
              AdditionalButtons={
                props.FormDefiniton ? props.FormDefiniton.customActions : []
              }
              nothingSelected={details.nothingSelected}
              readOnly={!details.adding && !details.editing}
              onStateChanged={onStateChanged}
              FormId={FormId}
              saveAndNew={props.FormDefiniton.saveAndNew}
              stack={stack}
              Locked={false}
              refreshAfterSave={reloadParent}
              isAdding={details.adding}
              isEditing={details.editing}
              isDialog={false}
              EntryDetails={details.selectedObservable}
              ParentData={details.selectedObservable}
            />
          )}
        </Observer>
        <Observer>
          {() => (
            <div
              className="searchHolder"
              style={{
                display:
                !checkPermissions(props.FormDefiniton.allowSearch) || ((details.adding || details.editing) &&
                  
                  !details.refreshing)
                    ? 'none'
                    : 'inherit',
              }}
            >
              
            {!props.FormDefiniton.hideSearch && 
              <SearchComponent
                autoSearch={
                  preventAutoSearch === false && props.FormDefiniton.autoSearch
                    ? props.FormDefiniton.autoSearch
                    : false
                }
                FormDefiniton={props.FormDefiniton}
                customDefinition={props.FormDefiniton.customSearch}
                resource={
                  props.FormDefiniton.editSearchSelector
                    ? props.FormDefiniton.editSearchSelector.resource
                    : ''
                }
                resourceParameters={{}}
                resourceIdentity={
                  props.FormDefiniton.editSearchSelector.resourceIdentity
                }
                resourceMethod={
                  props.FormDefiniton.editSearchSelector
                    ? props.FormDefiniton.editSearchSelector.resourceType
                    : ''
                }
                display={
                  props.FormDefiniton.editSearchSelector
                    ? props.FormDefiniton.editSearchSelector.resourceValue
                    : ''
                }
                value={
                  props.FormDefiniton.editSearchSelector
                    ? props.FormDefiniton.editSearchSelector.resourceId
                    : ''
                }
                onSelected={onSelected}
                resourceDisplayFields={
                  props.FormDefiniton.editSearchSelector
                    ? props.FormDefiniton.editSearchSelector
                        .resourceDisplayFields
                    : ''
                }
              />
            }
            
            </div>
          )}
        </Observer>
      </div>

      <Observer>
        {() => (
          <div className="from-holder">
            {details.nothingSelected && !details.adding ? (
              details.loading ? (
                <Loader key={guid()} />
              ) : (
                <NothingSelected
                  header={props.FormDefiniton.caption}
                  description={props.FormDefiniton.description}
                  key={guid()}
                />
              )
            ) : (
              <FormLoader
                FormId={FormId}
                stack={stack}
                Adding={details.adding}
                ReadOnly={!details.adding && !details.editing}
                refreshAfterSave={reloadParent}
                Editing={details.editing}
                EntryDetails={details.selectedObservable}
                FormDefinition={props.FormDefiniton}
                ReloadParent={reloadParent}
                ParentData={details.selectedObservable}
              />
            )}
          </div>
        )}
      </Observer>
    </div>
  );
};

/*
 
export 

*/
export default withTheme(TabPane);
