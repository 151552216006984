import DeliverAirwaybillPackage from "./deliverairwaybillpackage";
export default {
  formtype: "dataEntry",
  saveAndNew:false,
  hideSearch:true,
  allowSearch: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: [""],
      value: false,
    },
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: [""],
      value: false,
    },
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false,
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false,
    },
  ],
  caption: "Receive Package",
  editSearchSelector: {
    resource: "GetCompanyList",
    resourceId: ["CompanyRecordNumber"],
    resourceMethod:"GET",
    resourceIdentity: "Companies",
    resourceValue: "CompanyRecordNumber",
    resourceSelectedType: "POST",
    resourceSelected: "getCompany",
    resourceSelectedIdentity: "Company",
    resourceDisplayFields: [],
    after: []
  },
  functionactions: {
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Choose Package for Delivery",
            fields: [              
            {
              key:"AirwaybillPackageNumber",
              type:"barcode",
              startText:"Scan Package",
              endText: "End Scanning of Packages",
              continuousScan: false,
              action:{                
                eventType: 'form',
                dialogOnly: false,
                adding:true,
                definition:{...DeliverAirwaybillPackage},
                action:{
                  functionName:"GetAirwaybillPackage",
                  functionType:"POST",
                  functionResourceIdentity:"AirwaybillPackage",
                },
                roles: [
                  {
                    role: ['Cargo Administrator','Cargo Member'],
                    value: true
                  }        
                ]
              }
            },
            ]
          },
          {
            title: "\n",
            fields: [
            ]
          },
          {
            title: "\n",
            fields: [
              
            ]
          },
          {
            title: "\n",
            fields: [              
            ]
          }
        ]
      }
    ]
  }
};
