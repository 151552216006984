import styled from '@emotion/styled';

const TabContainerStyle = styled.div`
  html,
  html a {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  a {
    text-decoration: none !important;
  }

  button {
    outline: 0 !important;
    background: transparent;
    border: 0 solid transparent;
  }

  .e-radio:checked + label:hover::before {
    border-color: ${(props) => props.theme.accent};
  }

  .e-radio:checked + label::before {
    border-color: ${(props) => props.theme.accent};
  }

  .e-radio:checked + label:hover::after {
    background-color: ${(props) => props.theme.accent};
  }

  .e-radio:checked + label::after {
    background-color: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.accent};
  }

  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input {
    border-color: #dfd9c2 !important;
  }

  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: ${(props) => props.theme.accent};
    border-color: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.backgroundLight};
  }

  .e-btn.e-flat.e-primary,
  .e-css.e-btn.e-flat.e-primary {
    background-color: ${(props) => props.theme.accent} !important;
    border-color: ${(props) => props.theme.accent} !important;
    color: ${(props) => props.theme.backgroundLight} !important;
  }

  .content-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}




  /**/
`;

export default TabContainerStyle;
