import CargoPaymentDetail from "./cargopaymentdetail";
import Colors from '../../../constants/Colors';
export default {
  track: "Cargo Payment Detail List",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role:["Accounting Administrator", "Accounting Member"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  caption: "Cargo Payment Details",
  editSearchSelector: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "editabletable", 
                allowInlineEditing:{
                  parentDependentFields: [
                    {
                      key: 'PaymentStatus',
                      values: ['Processed', 'Voided'],
                      active: false
                    }
                  ],
                },                  
                multiSelect:false,
                customButtons : [
                  {
                    showInEditMode: false,
                    showInAddMode: false,
                    showInLocked: true,
                    requiresSelection: true,
                    showInEditableMode: true,
                    eventType: 'edit',
                    dialogOnly: false,
                    text: 'Edit',
                    icon: 'mdi mdi-pencil',
                    color: Colors.edit,
                    roles: [
                      {
                        role: ["Accounting Administrator", "Accounting Member"],
                        value: true
                      }        
                    ],
                    parentDependentFields: [
                      {
                        key: 'PaymentStatus',
                        values: ['Processed', 'Voided'],
                        active: false
                      }
                    ],
                  }],
                data: {
                  getResource: {
                    resourceType: "POST",
                    id: "GetCargoPaymentDetailList",
                    resourceIdentity: "CargoPaymentDetails"
                  },
                  save:{
                    functionname:"UpdateCargoPaymentDetail",
                    functiontype:"POST",
                  }
                },
                entry: {
                  resource: {
                    resourceType: "POST",
                    id: "GetCargoPaymentDetail",
                    resourceIdentity: "CargoPaymentDetail"
                  },
                  definition: { ...CargoPaymentDetail }
                },
                fields: [
                  {
                    key: "InvoiceNumber",
                    type: "text",
                    label: "Invoice Number",
                    placeholder: "Invoice Number",
                    readOnly: true
                  },
                  {
                    key: "InvoiceDate",
                    type: "date",
                    label: "Invoice Date",
                    placeholder: "Invoice Date",
                    readOnly: true
                  },
                  {
                    key: "AmountDue",
                    type: "number",
                    format: "C2",
                    label: "Amount Due",
                    placeholder: "Amount Due",
                    readOnly: true
                  },
                  {
                    key: "PaymentAmount",
                    type: "number",
                    label: "Payment Amount",
                    placeholder: "Payment Amount",
                    format: "c2",
                    readOnly: false,
                    validation: {
                      "data-parsley-required": true,
                      "data-parsley-min": 0,
                      "data-parsley-max": 1000000,
                      "data-parsley-validcargopaymentamount": true
                    }
                  },
                  {
                    key: "Reference",
                    type: "text",
                    label: "Reference",
                    placeholder: "Reference",
                    readOnly: false,
                    validation: {
                      "data-parsley-maxlength": 255
                    }
                  }
                ]
              }
            ]
          }
        ]
      }     
    ]
  }
};



