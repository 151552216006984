export default {
	duplicates: [],
	access: [],
	track: "AirwaybillLog",
	formtype: "dataEntry",

	allowSearch: [
		{
			role:  [ "default" ],
			value:  true
		}
	],
	allowDelete: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowAudit: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowEdit: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowAdd: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowPrint: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	printreport: "",
	caption:  "Airwaybill Log Details",
	editSearchSelector: {
		resource: "GetAirwaybillLogList",
		resourceType: "GET",
		resourceId: [ "AirwaybillLogRecordNumber" ],
		resourceValue: "AirwaybillLogRecordNumber",
		resourceSelected: "GetAirwaybillLog",
		resourceSelectedType: "POST",
		resourceDisplayFields: [ ]
	},
	functionactions:
	{
		createnew: {
		},
		update: {
		},
		after: {
		}
	},
	formDefinition: {
		rows: [
			{
			columns: [
				{
				title: "Airwaybill Log Information",
				fields: [
					{
						key: "AirwaybillNumber",
						type: "label",
						defaultValue: "",
						label: "Airwaybill Number",
						placeholder: "Airwaybill Number",
						readOnly: true
					},
					{
						key: "PackageNumber",
						type: "number",
						defaultValue: "",
						label: "Package Number",
						placeholder: "Package Number",
						readOnly: true
					},
					{
						key: "ActionTaken",
						type: "text",
						defaultValue: "",
						label: "Action Taken",
						placeholder: "Action Taken",
						readOnly: true
					}
				]
			  },
			  {
				title: '\n',
				fields: [
					{
						key: "UserName",
						type: "text",
						defaultValue: "",
						label: "User Name",
						placeholder: "User Name",
						readOnly: true
					},
					{
						key: "ActionDate",
						type: "date",
						defaultValue: "",
						label: "Action Date",
						placeholder: "Action Date",
						readOnly: true
					},
					{
						key: "ActionTime",
						type: "text",
						defaultValue: "",
						label: "Action Time",
						placeholder: "Action Time",
						readOnly: true
					}
				]
				}
			]
			}
		]
	}
}