import React, { useEffect } from 'react';
import TabContainerStyle from './TabContainerStyle';
import TabPane from './TabPane';
import TabHeader from './TabHeader';
import { observable } from 'mobx';
import { guid, winManager } from '../../../utilities';
import UserSettings from './UserSettings';
import { setupTabs } from './tabSetup';
import dragscroll from 'dragscroll';
import GridForm from '../../GridFormRenderer';
import Welcome from './Welcome';
import { Observer } from 'mobx-react';
import { withTheme } from '@emotion/react';

const Loader = (props) => {
  const tabs = observable({ selectedIndex: -1, tabs: []});
  const ReportComponent = (props)=>{
    return (
      <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        background: props.theme.backgroundDark,
      }}
    >
      <iframe sandbox={"allow-scripts allow-forms allow-same-origin allow-modals allow-popups allow-popups-to-escape-sandbox allow-downloads"}
        className="animate__animated animate__faster animate__fadeIn"
        key={guid()}
        frameBorder="0"
        title={props.menu.resource}
        src={`/reports/?id=${props.menu.resource}`}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          background: props.theme.backgroundDark,
        }}
      />
    </div>
    );
  };
  const Report = withTheme(ReportComponent);

  const createTab = (menu, formDefiniton, data) => {
    const opened = tabs.tabs.findIndex(
      (value) => value.item.resource === menu.resource
    );
    if (opened > -1) {
      tabs.selectedIndex = opened;
      return;
    }
    let tabDefinition = {};
    

    switch (menu.resourcetype) {
      case 'form': {
        tabDefinition = {
          id: guid(),
          item: menu,
          content: (
            <TabPane
              preSelected={data}
              key={guid()}
              FormDefiniton={formDefiniton}
            />
          ),
          formDefinition: formDefiniton,
        };

        break;
      }
      case 'grid': {
        tabDefinition={
          id: guid(),
          item: menu,
          content: (
            <GridForm
              preSelected={data}
              key={guid()}
              FormDefiniton={formDefiniton}
            />
          ),
          formDefinition: formDefiniton,
        };

        break;
      }

      

      case 'report': {
        tabDefinition={
          id: guid(),
          item: menu,
          content: (
           <Report menu={menu}/>
          ),
          formDefinition: formDefiniton,
        };
        break;
      }
      default: {
        tabDefinition={
          id: guid(),
          item: menu,
          content: <div key={guid()} />,
          formDefinition: null,
        };
        break;
      }
    }
    tabs.tabs.push(tabDefinition);
    tabs.selectedIndex = tabs.tabs.length - 1;

    setupTabs();
    dragscroll.reset();
  };
  const closeTab = (index) => {
    tabs.tabs.splice(index, 1);
    

    if (tabs.selectedIndex === index) {
      tabs.selectedIndex = tabs.tabs.length - 1;
    }

    setupTabs();
    dragscroll.reset();
  };

  useEffect(() => {
    if (props.Events) {
      props.Events.OpenTab = (menu, formDefiniton, data) => {
        createTab(menu, formDefiniton, data);
      };
    }

    if (props.AutoTab) {
      createTab(props.AutoTab.menu, props.AutoTab.formDefinition);
    } else {
      setupTabs();
    }
  }, []);

  return (
    <TabContainerStyle>
      <div
        className="tab-header"
        style={{ display: props.AutoTab ? 'none' : 'inherit' }}
      >
        <div className="tab-header-line" />
        <div className="bordertop" />

        <div className="pn-ProductNav_Wrapper">
          <nav id="pnProductNav" className="pn-ProductNav dragscroll">
            <Observer>
              {() => (
                <ul
                  id="pnProductNavContents"
                  className="pn-ProductNav_Contents"
                >
                  {tabs.tabs.map((item, index) => {
                    return (
                      <li
                        key={item.id}
                        className={index === tabs.selectedIndex ? 'active' : ''}
                      >
                        <TabHeader
                          NewWindow={() => {
                            winManager.popTabToWindow(
                              item.item,
                              item.formDefinition
                            );
                          }}
                          Select={() => {
                            tabs.selectedIndex = index;
                          }}
                          Close={() => {
                            closeTab(index);
                          }}
                          Caption={item.item.name}
                        />
                        ,
                        <button
                          onClick={() => {
                            tabs.selectedIndex = index;
                          }}
                        >
                          {item.item.name}
                        </button>
                        <button
                          className="close-icon"
                          onClick={() => {
                            closeTab(index);
                          }}
                        >
                          <i className="icon-circle-with-cross"></i>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </Observer>
          </nav>

          <button
            id="pnAdvancerLeft"
            className="pn-Advancer pn-Advancer_Left"
            type="button"
          >
            <i className="mdi mdi-arrow-left" />
          </button>
          <button
            id="pnAdvancerRight"
            className="pn-Advancer pn-Advancer_Right"
            type="button"
          >
            <i className="mdi mdi-arrow-right" />
          </button>
        </div>
      </div>
      <div
        className="user-account"
        style={{ display: props.AutoTab ? 'none' : 'inherit' }}
      >
        <UserSettings menuSelected={props.menuSelected} />
      </div>
      <div
        className="tab-content"
        id="tab-container-holder"
        style={{
          top: props.AutoTab ? '0px' : '35px',
          left: props.contentLeft,
        }}
      >
        <Observer>
          {() => (
            <>
              {tabs.selectedIndex > -1 ? (
                <>
                  {tabs.tabs.map((item, index) => {
                    return (
                      <div
                        key={item.id}
                        style={{
                          display:
                            tabs.selectedIndex === index ? 'inherit' : 'none',
                        }}
                      >
                        {item.content}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="container-fluid">
                      {props.AutoTab ? <span /> : <Welcome />}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </Observer>
      </div>
    </TabContainerStyle>
  );
};

export default withTheme(Loader);
