const processResponse = async response => {
  if (!response.ok) {
    try {
      const msg = JSON.parse(await response.text());
      return { error: msg.error };
    } catch (ignore) {
      return { error: response.statusText };
    }
  }

  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return { data: await response.json() };
  } else {
    return { data: response.text() };
  }
};
export default processResponse;
