import React from 'react';
import { guid } from '../../../utilities';
import { withTheme } from '@emotion/react';

const Speech = (props) => {

  return (
    <div
      className="submenus recent"
      style={{ left: props.menuCollapsed ? '58px' : '248px' }}
    >
      <div className="submenuholder">
        <div className="submenuholderinner">
          <h6>Are you looking for:</h6> 
          <div className="container-sidebar" style={{ top: '40px' }}>
           
              <ul>
                {props.MenuSystem                  
                  .map((menu) => (
                    <li key={guid()}>
                      <a
                        href="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          props.onItemSelected(menu);
                        }}
                      >
                        {menu.name}
                      </a>
                    </li>
                  ))}
              </ul>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTheme(Speech);
