import React, { useState, useEffect } from "react";
import ActionBarStyle from "./ActionBarStyle";
import computeFormDefinitionButtons from "./actions/computeFormDefinitionButtons";
import computerActionProcessor from "./actions/computeActionProcessor";
import { guid } from "../../../utilities";
import { withTheme } from "@emotion/react";
import ActionButton from "./ActionButton";
import ActionButtonGroup from "./ActionButtonGroup";
import { observable } from "mobx";
import { Observer } from "mobx-react";
const ActionBar = (props) => {
  const [state] = useState(observable({ started: false, disable: false }));
  const buttons = [];
  const innerOnStateChanged = (currentState, data) => {
    props.onStateChanged(currentState, data);
  };
  const onClick = (button) => {
    state.disable = true;
    state.started = true;
    setTimeout(() => {
      computerActionProcessor(button, props, innerOnStateChanged)
        .then(() => {
          state.started = false;
          state.disable = false;
        })
        .catch(() => {
          state.started = false;
          state.disable = false;
        });
    }, 1000);
  };
  const buttonsOriginal = computeFormDefinitionButtons(props);

  buttonsOriginal.forEach((item) => {
    if (item.hasSub) {
      item.subMenus = [];
      buttonsOriginal
        .filter((x) => x.parentId === item.id)
        .forEach((u) => {
          item.subMenus.push(u);
        });

      if (!item.parentId) {
        buttons.push(item);
      }
    } else {
      if (!item.parentId) {
        buttons.push(item);
      }
    }
  });

  useEffect(() => {
    if (props.FormDefinition && props.FormDefinition.calculateForm) {
      state.disable = true;
      state.started = true;
      
        computerActionProcessor(
          {
            showInEditMode: false,
            showInAddMode: false,
            requiresAllowAdd: true,
            requiresAllowEdit: false,
            requiresSelection: false,
            showInLocked: false,
            showInEditableMode: true,
            showInDialog: false,
            eventType: "add",
            text: "Add",
            icon: "mdi mdi-plus",
            color: props.theme.add,
          },
          props,
          innerOnStateChanged
        )
          .then(() => {
            state.started = false;
            state.disable = false;
          })
          .catch(() => {
            state.started = false;
            state.disable = false;
          });
      
    }
  }, []);

  return (
    <ActionBarStyle>
      <Observer>
        {() => (
          <div>
            <div
              className="wrapper-holder"
              style={{ display: state.started ? "inherit" : "none" }}
            >
              <div className="wrapper">
                <div className="block mover"></div>
                <div className="block jumper"></div>
                <div className="block jumper"></div>
                <div className="block jumper"></div>
                <div className="block jumper"></div>
              </div>
            </div>
            {buttons.length > 0 ? (
              <div style={{ display: state.started ? "none" : "inherit" }}>
                {buttons.map((item) => (
                  <span key={guid()}>
                    {item.subMenus ? (
                      <ActionButtonGroup
                        key={guid()}
                        icon={item.icon}
                        text={item.text}
                        color={item.color}
                      >
                        <>
                          {item.subMenus.map((item2) => (
                            <ActionButton
                              key={guid()}
                              color={item2.color}
                              text={item2.text}
                              icon={item2.icon}
                              onClick={() => {
                                if (state.disable !== true) {
                                  onClick(item2);
                                }

                                //console.log(onClick);
                              }}
                            />
                          ))}
                        </>
                      </ActionButtonGroup>
                    ) : (
                      <ActionButton
                        key={guid()}
                        color={item.color}
                        text={item.text}
                        icon={item.icon}
                        onClick={() => {
                          if (state.disable !== true) {
                            onClick(item);
                          }
                          //console.log(onClick);
                        }}
                      />
                    )}
                  </span>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </Observer>
    </ActionBarStyle>
  );
};

export default withTheme(ActionBar);
