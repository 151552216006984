import React from "react";
import { useDropzone } from "react-dropzone";
import { Observer } from "mobx-react";


const FileUploader = ({ onDrop, accept }) => {
    // Initializing useDropzone hooks with options
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept
    });
  
  
    return (   
      <div {...getRootProps()}>
        <input className="dropzone-input" {...getInputProps()} />
        <div className="text-center">
          {isDragActive ? (
            <p className="dropzone-content">Release to drop the files here</p>
          ) : (
            <p className="dropzone-content">
              Drag 'n' drop signature file here, or click to select file.
            </p>
          )}
        </div>
      </div>
    );
  };

export default FileUploader;