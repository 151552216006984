import React, { PureComponent } from 'react';

import BindModel from '../../../Core/BindModel';
import { Observer } from 'mobx-react';
import Select2Style from './Select2Style';
import computeValidation from '../../actions/computeValidation';
import { guid } from '../../../../utilities';
import renderSelect2 from './actions/renderSelect2';
import showTextBox from './actions/showTextBox';
import showToggle from './actions/showToggle';
import toggleSelect from './actions/toggleSelect';
import { withTheme } from '@emotion/react';

class SearchComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.defaultValue = [];
    this.state = {
      loading: true,
      canToggle: false,
      isTextBox: false,
      rows: [],
    };
    this.initialData = [];
    this._renderSelect2 = renderSelect2.bind(this);
    this._toggleSelect = toggleSelect.bind(this);
  }

  componentDidMount() {
    if (showToggle(this.props.Config)) {
      showTextBox(this.props.EntryDetails, this.props.Config).then((result) => {
        this.defaultValue = result.data ? [result.data] : [];
        this.alldata = result.alldata;
        this.setState({
          ...this.state,
          loading: false,
          canToggle: true,
          isTextBox: result.status,
        });
      });
    } else {
      showTextBox(this.props.EntryDetails, this.props.Config).then((result) => {
        this.defaultValue = result.data ? [result.data] : [];
        this.alldata = result.alldata;
        this.setState({
          ...this.state,
          loading: false,
          canToggle: false,
          isTextBox: false,
        });
      });
    }
  }
  renderSelect2() {
    if (!this.props.Config.options) {
      if (!this.state.isTextBox) {
        this._renderSelect2(
          this.defaultValue,
          (value) => {
            this.defaultValue = [value];
          },
          this.alldata
        );
      }
    } else {
      this._renderSelect2(
        this.defaultValue,
        (value) => {
          this.defaultValue = [value];
        },
        this.props.Config.options
      );
    }
  }
  componentDidUpdate() {
    setTimeout(() => {
      this.renderSelect2();
    }, 10);
  }
  
  render() {
    return (
      <Select2Style ref="select2holder">
        {this.state.loading ? (
          <span
            className="form-control"
            style={{
              border: `1px solid ${this.props.theme.backgroundDarker}`,
              display: 'block',
            }}
          >
            Loading...
          </span>
        ) : this.state.canToggle && !this.props.ReadOnly ? (
          <table cellSpacing={0} cellPadding={0} style={{ width: '100%' }}>
            <tbody>
              <tr style={{padding:0,margin:0,borderSpacing:0}}>
                <td style={{padding:0,margin:0,borderSpacing:0}}>
                  {this.state.isTextBox ? (
                    <Observer>
                      {() => (
                        <input
                          className="form-control"
                          readOnly={this.props.ReadOnly}
                          type="text"
                          {...computeValidation(
                            this.props.Config,
                            this.props.FormId
                          )}
                          {...BindModel(
                            this.props.EntryDetails,
                            this.props.Config.key,
                            'value',
                            'onChange'
                          )}
                        />
                      )}
                    </Observer>
                  ) : (
                    <div ref="select2">
                      <select className="sl2" style={{ width: '100%' }}>
                        {this.defaultValue.map((row) => {
                          return (
                            <option key={guid()} value={row ? row.id : ''}>
                              {row ? row.text : ''}
                            </option>
                          );
                        })}
                      </select>
                      <Observer>
                        {() => (
                          <input
                            className="form-control"
                            type="text"
                            style={{ display: 'none' }}
                            {...computeValidation(
                              this.props.Config,
                              this.props.FormId
                            )}
                            {...BindModel(
                              this.props.EntryDetails,
                              this.props.Config.key,
                              'value',
                              'onChange'
                            )}
                          />
                        )}
                      </Observer>
                    </div>
                  )}
                </td>
                <td ref="togglebutton">
                  <button
                    type="button"
                    className={
                      this.state.isTextBox
                        ? 'toggleBtn toggleOn'
                        : ' toggleBtn toggleOff'
                    }
                    onClick={this._toggleSelect}
                  >
                    <i className="mdi mdi-toggle-switch-off-outline" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        ) : this.state.isTextBox ? (
          <Observer>
            {() => (
              <input
                className="form-control"
                readOnly={this.props.ReadOnly}
                type="text"
                {...computeValidation(this.props.Config, this.props.FormId)}
                {...BindModel(
                  this.props.EntryDetails,
                  this.props.Config.key,
                  'value',
                  'onChange'
                )}
              />
            )}
          </Observer>
        ) : (
          <div ref="select2">
            <select className="sl2" style={{ width: '100%' }}>
              {this.defaultValue.map((row) => {
                return (
                  <option key={guid()} value={row ? row.id : ''}>
                    {row ? row.text : ''}
                  </option>
                );
              })}
            </select>
            <Observer>
              {() => (
                <input
                  className="form-control"
                  type="text"
                  style={{ display: 'none' }}
                  {...computeValidation(this.props.Config, this.props.FormId)}
                  {...BindModel(
                    this.props.EntryDetails,
                    this.props.Config.key,
                    'value',
                    'onChange'
                  )}
                />
              )}
            </Observer>
          </div>
        )}
      </Select2Style>
    );
  }
}
export default withTheme(SearchComponent);
