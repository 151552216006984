const buildPostModification = (Config, ParentData) => {
    const overridedata = {};
    if (Config.optionsResource.postmodifications) {
      Config.optionsResource.postmodifications.forEach(item => {
        overridedata[item.addedvalue] = ParentData[item.parentid];
      });
    }
    return overridedata;
  };

  export default buildPostModification;