
import CargoInvoiceSearch from "./cargoinvoicesearch";
import CargoInvoiceItemList from "../cargoinvoiceitem/cargoinvoiceitemlist";
import CargoInvoiceTransactionList from "../cargoinvoicetransaction/cargoinvoicetransactionlist";
import CargoInvoiceAdjustmentList from "../cargoinvoiceadjustment/cargoinvoiceadjustmentlist";
import CargoInvoiceItemFromAdjustmentList from "../cargoinvoiceitemfromadjustment/cargoinvoiceitemfromadjustmentlist";
import Colors from "../../../constants/Colors";
import cargoinvoiceadjustmentlist from "../cargoinvoiceadjustment/cargoinvoiceadjustmentlist";
export default {
  track: "CargoInvoice",
  formtype: "dataEntry",
  customSearch: {
    searchForm: { ...CargoInvoiceSearch },
    width: 800,
    align: 500,
    height: 800,
    id: "cargoInvoiceSearch"
  },
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],

  customActions: [
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-printer",
      text: "Print",
      eventType: "report",
      report: {
        id: "Accounting/CargoInvoice",
        header: "Cargo Invoice"
      },
      color: Colors.print,
      roles: [
        {
          role: ["Cargo Administrator",  "Accounting Administrator", "Accounting Member", "Accounting Viewer"],
          value: true
        }
      ]
    }
  ],
  printreport: "",
  caption: "Cargo Invoice",
  editSearchSelector: {
    resource: "GetCargoInvoiceList",
    resourceType: "GET",
    resourceId: ["CargoInvoices"],
    resourceIdentity: "CargoInvoices",
    resourceValue: "InvoiceNumber",
    resourceSelected: "GetCargoInvoice",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoInvoice",},
  functionactions: {
    createnew: {
    },
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Invoice No. & Agency",
            fields: [ 
              {
                key: "InvoiceNumber",
                type: "text",
                label: "Invoice Number",
                placeholder: "Invoice Number",
                readOnly: true
              },
              {
                key: "AgencyName",
                type: "text",
                label: "Agency Name",
                placeholder: "Agency Name",
                readOnly: true
              },
              {
                key: "AgencyId",
                type: "text",
                label: "Agency Id",
                placeholder: "Agency Id",
                readOnly: true
              }
            ]
          },
          {
            title: "Amounts",
            fields: [
              {
                key: "InvoiceAmount",
                type: "number",
                format: "$0,0.00",
                label: "Invoice Amount",
                placeholder: "Invoice Amount",
                readOnly: true
              },
              {
                key: "OutstandingBalance",
                type: "number",
                format: "$0,0.00",
                label: "Balance",
                placeholder: "Balance",
                readOnly: true
              }
            ]
          },
          {
            title: "Invoice Dates",
            fields: [  
              {
                key: "InvoiceDate",
                type: "date",
                label: "Invoice Date",
                placeholder: "Invoice Date",
                readOnly: true
              },            
              {
                key: "PaymentDueDate",
                type: "date",
                label: "Due Date",
                placeholder: "Due Date",
                readOnly: true
              }
            ]
          },
          {
            title: "Invoice Period",
            fields: [
              {
                key: "EndingInvoicePeriod",
                type: "date",
                label: "Ending Period",
                placeholder: "Ending Period",
                readOnly: true
              },
              {
                key: "CreatedBy",
                type: "text",
                label: "Created By",
                placeholder: "Created By",
                readOnly: true
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "tabs",
                readOnlyOnEdit: true,
                tabs: [
                  {
                    caption: "Items",
                    definition: { ...CargoInvoiceItemList }
                  },
                  {
                    caption: "Items From Adjustment",
                    definition: { ...CargoInvoiceItemFromAdjustmentList }
                  },
                  {
                    caption: "Transactions",
                    definition: { ...CargoInvoiceTransactionList }
                  },
                  {
                    caption: "Adjustments",
                    definition: { ...CargoInvoiceAdjustmentList }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
