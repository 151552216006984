export default {
  formtype: "dataEntry",
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                key: "AdjustmentNumber",
                type: "text",
                label: "Adjustment Number",
                placeholder: "Adjustment Number",
                readOnly: false
              },
              {
                key: "InvoiceNumber",
                type: "text",
                label: "Invoice Number",
                placeholder: "Invoice Number",
                readOnly: false
              }
            ]
          },
          {
            title: '\n',
            fields: [
              {
                key: "BeginDate",
                type: "date",
                label: "Begin Date",
                placeholder: "Begin Date",
                readOnly: false
              },
              {
                key: "EndDate",
                type: "date",
                label: "End Date",
                placeholder: "End Date",
                readOnly: false
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "",
            hideOnAdd: "true",
            fields: [
              {
                type: "searchtable",
                id:"CargoInvoiceAdjustment",
                data: {
                  getResource: {
                    id: "GetCargoInvoiceAdjustmentListForSearch",
                    resourceType: "POST",
                    resourceIdentity: "CargoInvoiceAdjustments"
                  }
                },
                fields: [                  
                  {
                    key: "AdjustmentNumber",
                    type: "text",
                    label: "Adjustment No."
                  },             
                  {
                    key: "InvoiceNumber",
                    type: "text",
                    label: "Invoice No."
                  },
                  {
                    key: "TransactionTypeDescription",
                    type: "text",
                    label: "Adjustment Type"
                  },
                  {
                    key: "AdjustmentDate",
                    type: "date",
                    label: "Adjustment Date"
                  },
                  {
                    key: "AdjustmentAmount",
                    type: "number",
                    label: "Amount",
                    format: "c2",
                  },
                  {
                    key: "AdjustmentStatus",
                    type: "text",
                    label: "Status"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
