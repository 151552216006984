
import CargoInvoiceAdjustmentSearch from "./cargoinvoiceadjustmentsearch";
import CargoInvoiceAdjustmentLogList from "../cargoinvoiceadjustmentlog/cargoinvoiceadjustmentloglist";
import Colors from "../../../constants/Colors";
export default {
  track: "CargoInvoiceAdjustment",
  formtype: "dataEntry",
  customSearch: {
    searchForm: { ...CargoInvoiceAdjustmentSearch },
    width: 800,
    align: 500,
    height: 800,
    id: "cargoInvoiceAdjustmentSearch"
  },
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {          
      role: ["Accounting Administrator", "Accounting Member"],
      value: true
    }
  ],  
  customActions: [
    {
      showInEditMode: false,
      showInAddMode: false,
      showInLocked: false,
      requiresSelection: true,
      showInEditableMode: true,
      eventType: "edit",
      refreshAfterAction: true,
      dialogOnly: false,
      text: "Edit",
      icon: "mdi mdi-pencil",
      refreshAfterAction: true,
      color: Colors.edit,
      dependentFields: [
        {
          key: "AdjustmentStatus",
          values: ["Processed",  "Voided"],
          active: false
        }
      ],
      roles: [
        {
          role: ["Accounting Administrator", "Accounting Member"],
          value: true
        }
      ]
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-chevron-double-right",
      text: "Process Adjustment",
      refreshAfterAction: true,
      eventType: "api",
      color: Colors.process,
      roles: [
        {
          role: ["Accounting Administrator", "Accounting Member"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "AdjustmentStatus",
          values: ["Processed",  "Voided"],
          active: false
        }
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Pleaseconfirm processing of adjustment"
      }, 
      function: {
        functionName: "ProcessCargoInvoiceAdjustment",
        functionType: "POST",
        validateData: true,
        alertOnComplete: true,
        alertText: "The Cargo Invoice Adjustment Has Been Processed"
      }
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-null",
      text: "Void Adjustment",
      eventType: "api",
      refreshAfterAction: true,
      color: Colors.cancel,
      roles: [
        {
          role: ["Accounting Administrator", "Accounting Member"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "AdjustmentStatus",
          values: ["Processed", "Voided"],
          active: false
        }
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm voiding of adjustment"
      }, 
      function: {
        functionName: "VoidCargoInvoiceAdjustment",
        functionType: "POST",
        validateData: true,
        alertOnComplete: true,
        alertText: "The Cargo Invoice Adjustment Has Been Voided"
      }
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Invoice Adjustment",
  editSearchSelector: {
    resource: "GetCargoInvoiceAdjustmentList",
    resourceType: "GET",
    resourceId: ["CargoInvoiceAdjustmentRecordNumber"],
    resourceIdentity: "CargoInvoiceAdjustments",
    resourceValue: "AdjustmentNumber",
    resourceSelected: "GetCargoInvoiceAdjustment",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoInvoiceAdjustment"},
  functionactions: {
    createnew: {
      functionname: "AddCargoInvoiceAdjustment",
      functiontype: "POST",
      resourceIdentity: {
        id: "CargoInvoiceAdjustment",
        mapping: [
          {
            id: "AdjustmentNumber",
            key: "AdjustmentNumber"
          },
          {
            id: "AdjustmentStatus",
            key: "AdjustmentStatus"
          }
        ]
      },
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Invoice Adjustment Created",
      alertText: "Cargo Invoice Adjustment Created"
    },
    update: {
      functionname: "UpdateCargoInvoiceAdjustment",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Invoice Adjustment Information Updated",
      alertText: "The Cargo Invoice Adjustment Has Been Updated"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Agency & Adjustment Date",
            fields: [ 
              {
                key: "InvoiceNumber",
                type: "select",
                label: "Invoice Number",
                placeholder: "Invoice Number",
                readOnly: false,
                readOnlyOnEdit: true,
                optionsResource: {
                  resource: "GetCargoInvoiceList",
                  resourceType: "GET",
                  resourceId: ["InvoiceNumber"],
                  resourceIdentity: "CargoInvoices",
                  resourceValue: "InvoiceNumber"
                },
                details: ["AgencyName"],
                mapping: [
                  {
                    id: "AgencyName",
                    key: "AgencyName"
                  }
                ],
                validation: {
                  "data-parsley-required": true
                }
              },
              {
                key: "AgencyName",
                type: "text",
                label: "Agency",
                placeholder: "Agency",
                readOnly: true,
                validation: {
                  "data-parsley-required": true
                }
              },
              {
                key: "AdjustmentAmount",
                type: "number",
                format: "$0,0.00",
                label: "Amount",
                placeholder: "Amount",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": .01,
                  "data-parsley-max": 1000000,
                }
              }
            ]
          },
          {
            title: "Type and Description",
            fields: [  
              {
                key: "TransactionType",
                type: "select",
                label: "Adjustment Type",
                placeholder: "Adjustment Type",
                readOnly: false,
                readOnlyOnEdit: true,
                optionsResource: {
                  resource: "GetCargoInvoiceAdjustmentTypeList",
                  resourceType: "GET",
                  resourceId: ["TransactionType"],
                  resourceIdentity: "CargoInvoiceTransactionTypes",
                  resourceValue: "TransactionTypeDescription"
                },
                validation: {
                  "data-parsley-required": true
                }
              },
              {
                key: "AdjustmentNotes",
                type: "textarea",
                label: "Notes",
                placeholder: "Notes",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 255
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "AdjustmentNumber",
                type: "text",
                label: "Adjustment Number",
                placeholder: "Adjustment Number",
                readOnly: true
              },
              {
                key: "AdjustmentStatus",
                type: "text",
                label: "Status",
                placeholder: "Status",
                readOnly: true
              },
              {
                key: "AdjustmentDate",
                type: "date",
                defaultValue:"Today",
                label: "Enter Date",
                placeholder: "Enter Date",
                readOnly: true,
                validation: {
                  "data-parsley-required": true
                }
              },
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "tabs",
                readOnlyOnEdit: true,
                tabs: [
                  {
                    caption: "Logs",
                    definition: { ...CargoInvoiceAdjustmentLogList }
                  }
                ]
              }
            ]
          }
        ]
      }    
    ]
  }
};
