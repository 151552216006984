import React from 'react';
import ReactPaginate from 'react-paginate';
import Noop from '../../Core/Noop';
import SearchResultItem from './SearchResultItem';
const SearchResults = (props) => {
  
  return (
    <div className="search-results">
      <div className="arrow-holder">
        <div className="triangle">
          <div className="empty"></div>
        </div>

        <div className="search-results-holder">
          <div className="internal-search-results-holder">
            {props.Data.length === 0 ? (
              <div style={{fontWeight:"600",padding:"20px 10px"}}>
              No records to display.
              </div>
            ) : (
              <>
                <ul>
                  {props.Data.map((result, index) => {
                    return (
                      <SearchResultItem
                        key={`result-${index}`}
                        result={result}
                        onSelected={props.onSelected}
                        index={index}
                        Display={props.Display}
                        ResourceDisplayFields={props.ResourceDisplayFields}
                      />
                    );
                  })}
                </ul>

                {Math.ceil(props.AllData.length / 15) > 1 ? (
                  <div className="pagination-holder">
                    <ReactPaginate
                      previousLabel={''}
                      nextLabel={''}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={Math.ceil(props.AllData.length / 15)}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={3}
                      onPageChange={props.HandlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                      forcePage={props.currentPage}
                    />
                  </div>
                ) : (
                  <Noop />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
