const onCancel = (actionDefinition, props, innerOnStateChanged) => {
  return new Promise((resolve) => {
    if (innerOnStateChanged) {
      innerOnStateChanged("Cancel");
      resolve();
    }
  });
};

export default onCancel;
