import styled from '@emotion/styled';
import chroma from 'chroma-js';
const FormLoaderStyle = styled.div`
  /* Old browsers */
  background: ${(props) => props.theme.backgroundDark};
  .form-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => props.theme.backgroundDark};
    overflow-y: auto;
  }
  .grid-holder {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;

  }
  .form-group {
    min-height: 85px;
  }
  .row {
    margin: 0px;
  }
  .card {
    border: 0px solid transparent;
    border-radius: 0;
    margin: 0 20px;
    padding: 10px 20px 0px;
    border-radius: 3px;
  }
  .card-holder {
    margin: 0 20px 0px 20px;
    border-radius: 3px;
  }

  h2 {
    font-family: ${(props) => props.theme.headerFont};
    font-size: 10pt;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    text-transform: capitalize;
    color: ${(props) => props.theme.accent};
    width: 100%;
    display: block;

    padding-bottom: 3pt;
  }

  label {
    font-family: ${(props) => props.theme.mainFont}!important;
    font-size: 8pt;
    font-weight: 600;
    text-transform: capitalize;
    color: ${(props) => props.theme.textMedium};
  }

  .form-control {
    margin-top: 1px;
    padding: 4px 0.75rem !important;
    font-family: ${(props) => props.theme.mainFont}!important;
    height: auto;
    background: ${(props) => props.theme.backgroundLight};
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    outline: none;
    font-size: 14px;
  }
  .form-control:read-only {
    background: ${(props) => props.theme.backgroundDark}!important;
  }
  .form-control:focus {
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    outline: 0;
    outline-color: initial;
    outline-style: initial;
    outline-width: 0px;
    box-shadow: none;
  }

  .form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(props) => props.theme.placeHolder}!important;
    opacity: 1; /* Firefox */
  }

  .addButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.add2};
    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.add2};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .removeButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: #95000c;
    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: #95000c;
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .actionbar {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        padding: 10px;
        display: inline-block;
      }
    }
  }
  .searchButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.search};
    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.search};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }
  .grid-menu {
  }

  .editButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.edit2};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.edit2};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }
  .printButton {
    margin-right: 20px;
    cursor: pointer;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.print};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.print};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight};
    }
  }

  .requestButton {
    margin-right: 20px;
    cursor: pointer;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.request};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.request};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight};
    }
  }
  .viewButton {
    margin-right: 20px;

    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.view};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.view};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .verifyButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.verify};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.verify};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .processButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.process};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.process};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .removeButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.edit2};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.edit2};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .e-grid {
    border-radius: 3px;
    border: 0px solid #eaeaea;
    font-family: ${(props) => props.theme.mainFont};
  }

  .ql-toolbar.ql-snow {
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    background: ${(props) => props.theme.backgroundDark};
  }

  .ql-container.ql-snow {
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    
  }

  .ql-editor {
    background: ${(props) => props.theme.backgroundLight};
  }
  .ql-disabled {
    .ql-editor {
      background: ${(props) => props.theme.backgroundDark};
    }
  }
  .parsley-errors-list {
    list-style: none;
    margin: 5px 0 0 0;
    padding: 0;
    font-size: 8px;
    font-weight: 600;
    color: #ad0400;
    text-transform: uppercase;
  }

  .form-group {
    margin-bottom: 0px !important;
  }

  .dialog {
    .form-holder {
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }
  }

  .e-grid .e-toolbar {
    border: 0px solid #eaeaea;
  }
  .e-grid {
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    font-family: ${(props) => props.theme.mainFont};
    margin-bottom: 20px;
  }
  .e-pager {
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.backgroundLight};
    font-size: 8pt;
  }
  .e-grid td.e-active {
    border-top:1px solid ${(props) => chroma(props.theme.accent).alpha(0.15).brighten(1).css()};
    background-color: ${(props) => chroma(props.theme.accent).alpha(0.15).brighten(1).css()};
    color: ${(props) => props.theme.accent};
  }
  .e-grid.e-gridhover
    tr[role='row']:not(.e-editedrow):hover
    .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
  .e-grid.e-gridhover
    tr[role='row']:hover
    .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
  .e-grid.e-gridhover
    tr[role='row']:hover
    .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
      border-top:1px solid ${(props) => chroma(props.theme.accent).alpha(0.15).brighten(1).css()};
    background-color: ${(props) => chroma(props.theme.accent).alpha(0.15).brighten(1).css()};
    color: ${(props) => props.theme.accent};
    cursor:pointer;
  }
  .e-row {
    &:nth-of-type(even) {
      background-color: ${(props) =>
        chroma(props.theme.backgroundDark).alpha(0.4).css()};
    }
  }
  .grid-holder-grid{
    padding:10px;
    background-color: ${(props) => props.theme.backgroundLight};
    border:1px solid ${(props) => props.theme.backgroundDarker};
    border-radius:3px;
    margin-top:15px;
    margin-right:10px;
  }

  element.style {
  }
  .e-grid .e-groupheadercell {
    background-color: ${(props) => props.theme.backgroundDark};
    border-color: ${(props) => props.theme.backgroundDarker};
    border-radius: 3px;
    color: ${(props) => props.theme.textMenu};
  }
  .e-pager .e-prevpagedisabled,
  .e-pager .e-prevpage,
  .e-pager .e-nextpage,
  .e-pager .e-nextpagedisabled,
  .e-pager .e-lastpagedisabled,
  .e-pager .e-lastpage,
  .e-pager .e-firstpage,
  .e-pager .e-firstpagedisabled {
    background-color: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.backgroundLight};
  }

  .e-grid .e-groupdroparea {
    background-color: ${(props) => props.theme.accent};
    border-top-color: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.backgroundLight};
  }

  .e-pager .e-numericitem:not(.e-active):not(.e-np):not(.e-pp):hover {
    background-color: ${(props) => props.theme.accent}!important;
  }

  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: ${(props) => props.theme.accent};
    border-color: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.backgroundLight};
  }

  .e-btn.e-flat.e-primary:active,
  .e-btn.e-flat.e-primary.e-active,
  .e-css.e-btn.e-flat.e-primary:active,
  .e-css.e-btn.e-flat.e-primary.e-active {
    background-color: ${(props) => props.theme.accent}!important;
    border-color: ${(props) => props.theme.accent}!important;
    color: ${(props) => props.theme.backgroundLight};
  }

  .e-pager .e-active {
    background-color: ${(props) => props.theme.backgroundDark}!important;
    border: 0px solid transparent !important;
    border-radius: 100px;
    padding: 10px 3px;
    color: ${(props) => props.theme.accent}!important;
    opacity: 1;
  }

  .e-pager .e-pagercontainer {
    background-color: ${(props) => props.theme.accent};
    border-color: ${(props) => props.theme.accent};
  }

  .e-pager .e-numericitem {
    background: ${(props) => props.theme.accent};
    border-right-color: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.backgroundLight};
  }

  .e-toolbar .e-tbar-btn {
    background: ${(props) => props.theme.backgroundDark}!important;
    border-color: ${(props) => props.theme.backgroundDark}!important;
    font-family: ${(props) => props.theme.headerFont};
    font-weight: 700;

    text-transform: uppercase;
  }
  .e-tbar-btn-text {
    font-size: 11px !important;
  }
  .e-toolbar.e-control[class*='e-toolbar'] {
    background: ${(props) => props.theme.backgroundDark};
  }
  .e-headertext {
    font-family: ${(props) => props.theme.headerFont};
    font-weight: 600;
    font-size: 8pt !important;
    text-transform: capitalize;
  }
  .e-grid .e-gridcontent tr.e-row:first-of-type .e-rowcell {
    border-top: 1px solid transparent;
  }
  .e-grid .e-headercontent {
    border: 0px solid transparent;
  }

  .e-hscroll .e-scroll-nav.e-scroll-right-nav {
    border-left: 0px solid #a6a6a6;
  }

  .e-hscroll .e-scroll-nav.e-scroll-left-nav {
    border-left: 0px solid #a6a6a6;
  }

  .e-hscroll .e-scroll-nav {
    background: ${(props) => props.theme.backgroundDark}!important;
    border: 0px solid #000;
  }
  .e-hscroll-content {
    border: 0px solid #000;
  }

  .e-pager {
    border: 0px solid #000;
  }

  .e-grid .e-headercell,
  .e-grid .e-detailheadercell {
    background-color: ${(props) => props.theme.backgroundDark};
    border-color: #eaeaea;
  }

  .e-grid .e-gridheader {
    background-color: ${(props) => props.theme.backgroundDark}!important;
    border-bottom-color: ${(props) => props.theme.backgroundDark}!important;
    border-top-color: ${(props) => props.theme.backgroundDark}!important;
    color: #666;
  }

  .e-toolbar .e-toolbar-items {
    background: ${(props) => props.theme.backgroundDark};
  }
`;

export default FormLoaderStyle;
