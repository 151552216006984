export default {
  formtype: "dataEntry",
  saveAndNew:true,
hideSearch:true,
  allowSearch: [
    {
      role: ["default"],
      value: true,
    },
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: [""],
      value: false,
    },
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: [""],
      value: false,
    },
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: ["Cargo Administrator", "Cargo Member"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false,
    },
  ],
  caption: "Receive Package",
  editSearchSelector: {
    resource: "MockApi",
    resourceType: "GET",
    resourceId: "MockValue",
    resourceIdentity: "MockList",
    resourceValue: "",
    resourceSelected: "MockApi",
    resourceSelectedType: "GET",
    resourceSelectedIdentity: "MockList",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: { 
      functionname: "ReceiveAirwaybillPackage",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Airway Bill Package Received",
      alertText: "The Airway Bill Package Has Been Received"
    },
    update: {
      functionname: "ReceiveAirwaybillPackage",
      functiontype: "POST",     
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Airwaybill Information Updated",
      alertText: "The Airwaybill Has Been Updated"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Current Location",
            fields: [
              {
                key: "CargoStation",
                type: "text",
                defaultValue: "StationPostedName",
                label: "",
                readOnly: true
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "Receive Package",
            fields: [
              {
                key: "AirwaybillPackageNumber",
                type: "select",
                label: "AWB Package Number",
                placeholder: "AWB Package No.",
                readOnly: false,
                optionsResource: {
                  resource: "GetShippedAirwaybillPackageList",
                  resourceType: "GET",
                  resourceId: ["AirwaybillPackageNumber"],
                  resourceIdentity: "AirwaybillPackages",
                  resourceValue: "AirwaybillPackageNumber"
                },
                mapping: [
                  {
                    id: "AirwaybillNumber",
                    key: "AirwaybillNumber"
                  },
                  {
                    id: "PackageNumber",
                    key: "PackageNumber"
                  }
                ],
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 13,
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "AirwaybillNumber",
                type: "text",
                label: "Airwaybill Number",
                placeholder: "Airwybill Number",
                readOnly: true,
                validation: {
                  "data-parsley-required": true
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "PackageNumber",
                type: "text",
                label: "Package Number",
                placeholder: "Package Number",
                readOnly: true,
                validation: {
                  "data-parsley-required": true
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
               
              }
            ]
          }
        ]
      }
    ]
  }
};
