export default {
  track: "changeownpassword",
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    },
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  caption: "Change Own Password",
  editSearchSelector: {
    resource: "GetCompanyList",
    resourceId: ["CompanyRecordNumber"],
    resourceMethod:"GET",
    resourceIdentity: "Companies",
    resourceValue: "CompanyRecordNumber",
    resourceSelectedType: "POST",
    resourceSelected: "getCompany",
    resourceSelectedIdentity: "Company",
    resourceDisplayFields: [],
    after: []
  },
  functionactions: {
    update: {
      functionname: "changeownpassword",
      functiontype: "POST",
      validateData: true,
      validationFailedTitle: "",
      validationFailedText: "",
      alertOnComplete: true,
      alertTitle: "The Password Information Updated",
      alertText: "The Password Has Been Updated"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Change Own Password",
            fields: [
              {
                key: "OldPassCode",
                type: "password",
                label: "Old Password",
                placeholder: "Old Password",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 100
                }
              },
              {
                key: "PassCode",
                type: "password",
                label: "New Password",
                placeholder: "New Password",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 100
                }
              },
              {
                key: "ConfirmPassCode",
                type: "password",
                label: "Confirm Password",
                placeholder: "Confirm Password",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 100,
                  "data-parsley-confirmpasscode":true
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: ""
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: ""                               
              }
            ]
          }
        ]
      }
    ]
  }
};
