export default {
  "formtype": "authentication",
  "caption": "Login",
  "functionactions": {
    "createnew": {
      "functionname": "agents/createAgent",
      "functiontype": "POST"
    },
    "update": {
      "functionname": "agents/updateAgent",
      "functiontype": "POST"
    },
    "after": {
      "functionname": "agents/getClientList",
      "functiontype": "POST"
    }
  },
  "formDefinition": {
    "rows": [
      {
        "columns": [
          {
            "fields": [
              {
                "key": "auth",
                "type": "auth",
                "onComplete": "move next scene",
                "title": "Employment Office Management System"
              }
            ]
          }
        ]
      }
    ]
  }
}