import { toJS } from "mobx";
import { user } from '../../../utilities';

const lowerCaseArray = (array) => {
  const result = [];
  array.forEach((item) => {
    result.push(item.SecurityRole.trim().toLowerCase());
  });
  return result;
};

const computeReadOnly = (field, parentData, data, readOnly, adding) => {
  const entryDetails = toJS(data);
  const parent = toJS(parentData);
  
  if (readOnly === true) {
    return true;
  }

  // check if record is being added and ignore readonly flag is set
  if (adding && field.ignoreReadOnlyOnAddRole) {

    // get the claims for the user
    const claims = lowerCaseArray(user.getUserDetails().Claims);

    // loop through the roles to ignore readonly roles
    for(var x = 0; x < field.ignoreReadOnlyOnAddRole.length; x++){
      
        // utilized the for loop instead of foreach to be able to exit the loop after the value is found
        for(var i = 0; i < claims.length; i++){

          if(claims[i] === field.ignoreReadOnlyOnAddRole[x].trim().toLowerCase()){
            return false;
          }
        }    
      }    
  }


  // check if record is being added and ignore readonly flag is set
  if (!adding && field.ignoreReadOnlyOnEditRole) {

    // get the claims for the user
    const claims = lowerCaseArray(user.getUserDetails().Claims);

    // loop through the roles to ignore readonly roles
    for(var x = 0; x < field.ignoreReadOnlyOnEditRole.length; x++){

        // utilized the for loop instead of foreach to be able to exit the loop after the value is found
        for(var i = 0; i < claims.length; i++){

          if(claims[i] === field.ignoreReadOnlyOnEditRole[x].trim().toLowerCase()){
            return false;
          }
        }    
      }    
  }

  if (field.readOnly === true) {
    return true;
  }

  if (!adding && field.readOnlyOnEdit === true) {
    return true;
  }

  if (field.parentReadOnlyDependentException !== undefined) {
    var rtp = true;

    field.parentReadOnlyDependentException.forEach(item => {
      if (parent[item.id]) {
        if (
          parent[item.id]
            .toString()
            .toLowerCase()
            .trim() ===
          item.value
            .toString()
            .toLowerCase()
            .trim()
        ) {
          rtp = false;
        }
      }
    });

    if (rtp === true) {
      return true;
    }
  }

  if (field.readOnlyDependent !== undefined) {
    let retval = true;
    field.readOnlyDependent.forEach(item => {
      if(entryDetails[item.id] && item.value &&
        entryDetails[item.id]
          .toString()
          .toLowerCase()
          .trim() ===
        item.value
          .toString()
          .toLowerCase()
          .trim()
      ) {
        retval = false;
      } else {
        retval = true;
      }
    });
    return retval;
  }
  return false;
};
export default computeReadOnly;
