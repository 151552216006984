import AirwaybillPackage from "./airwaybillpackage"

export default {
  duplicates: [],
  access: [],
  track: "Airwaybill Package List",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    },
    {
      role: ["Cargo Administrator", "Cargo Member", "Cargo Viewer"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Airwaybill Packages",
  editSearchSelector: {
  },
  model: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "",
            fields: [
              {
                  type: "editabletable", 
                  allowInlineEditing:{
                    parentDependentFields: [
                      {
                        key: 'AirwaybillStatus',
                        values: ['Billed', 'Delivered', 'Shipped', 'Voided'],
                        active: false
                      }
                    ],
                  },                  
                  multiSelect:false,
                  customButtons : [
                    {
                      showInEditMode: false,
                      showInAddMode: false,
                      showInLocked: true,
                      requiresSelection: true,
                      showInEditableMode: true,
                      eventType: 'edit',
                      dialogOnly: false,
                      text: 'Edit',
                      icon: 'mdi mdi-pencil',
                      color: '#FD9048',
                      roles: [
                        {
                          role: ['Cargo Administrator','Cargo Member'],
                          value: true
                        }        
                      ],
                      parentDependentFields: [
                        {
                          key: 'AirwaybillStatus',
                          values: ['Billed', 'Delivered', 'Shipped', 'Voided'],
                          active: false
                        }
                      ],
                    }
                  ],
                  datasource: "GetAirwaybillPackage",
                  key: "AirwaybillNumber",
                  keylink: "AirwaybillNumber",
                  data: {
                    getResource: {
                      resourceType: "POST",
                      id: "GetAirwaybillPackageList",
                      resourceIdentity: "AirwaybillPackages"
                    },
                    save:{
                      functionname:"UpdateAirwaybillPackageDescription",
                      functiontype:"POST",
                    }
                  },
                  entry: {
                    resource: {
                      resourceType: "POST",
                      id: "GetAirwaybillPackage",
                      resourceIdentity: "AirwaybillPackage"
                    },
                    definition: { ...AirwaybillPackage }
                  },
                  fields: [
                    {
                      key: "PackageType",
                      type: "text",
                      label: "Pkg. Type",
                      placeholder: "Pkg. Type",
                      readOnly: true
                    },
                    {
                      key: "PackageDescription",
                      type: "text",
                      label: "Description",
                      placeholder: "Description",
                      readOnly: false
                    },
                    {
                      key: "PackageNumber",
                      type: "text",
                      label: "Pkg. No.",
                      placeholder: "Pkg. No.",
                      readOnly: true
                    },
                    {
                      key: "AirwaybillPackageNumber",
                      type: "text",
                      label: "AWB / Pkg No.",
                      placeholder: "AWB / Pkg No.",
                      readOnly: true
                    },
                    {
                      key: "PackageStatus",
                      type: "text",
                      label: "Status",
                      placeholder: "Status",
                      readOnly: true
                    }
                  ]
                }
            ]
          }
        ]
      }     
    ]
  }
};



