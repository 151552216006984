import React from "react";
import Select2Component from "../Select2Component";
import computeAsterisk from "../../actions/computeAsterisk";

const Select2 = props => (
  <div className="form-group">
    <label>{props.Config.label} {computeAsterisk(props.Config)}</label>
    <Select2Component
      ReadOnly={props.ReadOnly}
      EntryDetails={props.EntryDetails}
      Config={props.Config}
      ParentData={props.EntryDetails}
      FormId={props.FormId}
    />   
  </div>
);
export default Select2;
