import { loadData } from '../../../../utilities';
import { toJS } from 'mobx';
import { observable } from 'mobx';
import onLaunchReport from './onLaunchReport';

const launchReport = (button, props,EntryDetails) => {
  return onLaunchReport(
    {
    id:button.report.id,
    header:button.report.header,
    data:toJS(EntryDetails),
    stack:props.stack
    }
  );
};

const executeReport = (button,props) => {
  return new Promise((resolve,reject)=>{

    if (props.action && props.action.functionName) {
      loadData(
        button.action.functionName,
        button.action.functionType,
        toJS(props.ParentData),
        response => {
          const EntryDetails = observable({
            ...response[button.action.functionResourceIdentity]
          });
          launchReport(button, props,EntryDetails).then(()=>{
            resolve();
          }).catch(()=>{
            reject();
          });
          
        },
        recoverableError => {
         reject();
        }
      );
    }
    else if (button.defaultValues) {
      const newEntry = { ...button.defaultValues };
      if (button.keylink instanceof Array) {
        button.keylink.forEach((item, index) => {
          newEntry[item] = props.EntryDetails[button.key[index]];
        });
        
      } else {
        newEntry[button.keylink] = props.EntryDetails[button.key];
       
      }
  
      launchReport(button, props, observable(newEntry)).then(()=>{
        resolve();
      }).catch(()=>{
        reject();
      });
    
    } else {
      launchReport(button, props, props.EntryDetails).then(()=>{
        resolve();
      }).catch(()=>{
        reject();
      });
      
    }

  });

  
  
};

export default executeReport;
