import * as numeral from "numeral";

import BindModel from "../../../Core/BindModel";
import { Observer } from "mobx-react";
import React from "react";
import computeAsterisk from "../../actions/computeAsterisk";
import computeId from "../../actions/computeId";
import computeValidation from "../../actions/computeValidation";

const NumberTextBox = props => (
  <Observer>
    {() => (
      <div className="form-group">
        <label>{props.Config.label} {computeAsterisk(props.Config)}</label>
        {props.ReadOnly !== true?(

        <input
          id={computeId(props.Config)}
          autoComplete="off"
          type="text"
          
          {...computeValidation(props.Config,props.FormId,{"data-parsley-type":"number"})}
          className="form-control"
          placeholder={props.Config.placeholder}
          {...BindModel(
            props.EntryDetails,
            props.Config.key,
            "value",
            "onChange"
          )}
        />):(

        <span
          className="form-control"
          style={{
            display: "block",
            
          }}
        >
          {props.Config.format?numeral(props.EntryDetails[props.Config.key]).format(props.Config.format):props.EntryDetails[props.Config.key]}
        </span>)}
      </div>
    )}
  </Observer>
);
export default NumberTextBox;