
import styled from "@emotion/styled";

const SignatureStyle = styled.div`

 .btn-outline-secondary {
    outline:none!important;
    background-color: ${(props) => props.theme.backgroundDark};
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    border-radius: 0;
    color:${(props) => props.theme.textMedium};
}
.mb-3, .my-3 {
     margin-bottom: 0 !important; 
}
.error
{
    
      color: #ad0400!important;          
      font-size: 8px!important;
      font-weight: 600!important;
}
.signature-image{
    margin-top:4px;
    border:1px solid ${(props) => props.theme.backgroundDarker};
    padding:5px;
    display:block;
    width:100%;    
    text-align:center;
    img{
        height:80px;
    }
}
`;

export default SignatureStyle;