import AirwaybillLog from "./AirwaybillLog"

export default {
  duplicates: [],
  access: [],
  track: "Airwaybill Log List",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Airwaybill Logs",
  editSearchSelector: {
  },
  model: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "",
            fields: [
              {
                  type: "editabletable", 
                  datasource: "GetAirwaybillLog",
                  key: "AirwaybillNumber",
                  keylink: "AirwaybillNumber",
                  data: {
                    getResource: {
                      resourceType: "POST",
                      id: "GetAirwaybillLogListByAirwaybillNumber",
                      resourceIdentity: "AirwaybillLogs"
                    }
                  },
                  entry: {
                    resource: {
                      resourceType: "POST",
                      id: "GetAirwaybillLog",
                      resourceIdentity: "AirwaybillLog"
                    },
                    definition: { ...AirwaybillLog }
                  },
                  fields: [
                    {
                      key: "PackageNumber",
                      type: "text",
                      label: "Package Number",
                      placeholder: "Pkg. Type",
                      readOnly: true
                    },
                    {
                      key: "ActionTaken",
                      type: "text",
                      label: "Action Taken",
                      placeholder: "Action Taken",
                      readOnly: true
                    },
                    {
                      key: "UserName",
                      type: "text",
                      label: "User",
                      placeholder: "User",
                      readOnly: true
                    },
                    {
                      key: "ActionDate",
                      type: "date",
                      label: "Date",
                      placeholder: "Date",
                      readOnly: true
                    },
                    {
                      key: "ActionTime",
                      type: "text",
                      label: "Time",
                      placeholder: "Time",
                      readOnly: true
                    }
                  ]
                }
            ]
          }
        ]
      }     
    ]
  }
};



