import React, { PureComponent } from "react";
import { observable, toJS } from "mobx";

import $ from "jquery";
import BellIcon from "./BellIcon";
import BindModel from "../../Core/BindModel";
import Notifications from "../../Core/Notification";
import { Observer } from "mobx-react";
import { loadData } from "../../../utilities";
import { user } from "../../../utilities";

export default class UserSettings extends PureComponent {
  constructor(props) {
    super(props);
    this.userCredentials = observable({
      PassCode: "",
      password: "",
      locationActive: false,
      locationOptions: [],
      station: "",
    });

    const listener2 = (event) => {
      if ($(event.target).closest(".profile").length === 0) {
        $(".search-results-profile").hide();
        $(".activities").hide();
      }
    };

    $(window).on("click", listener2);
    $(window).on("touchend", listener2);
  }
  renderIcon(item) {
    switch (item.type) {
      case "new": {
        return <i className="mdi mdi-alert-circle-outline"></i>;
      }
      case "seen": {
        return <i className="mdi mdi-check"></i>;
      }
      default: {
        return <i className="mdi mdi-alert-circle-outline"></i>;
      }
    }
  }
  processSubItems(menuItems, items) {
    items.forEach((item) => {
      if (item.submenu) {
        this.processSubItems(menuItems, item.submenu);
      } else {
        menuItems.push(item);
      }
    });
  }

  selectNotification(item) {
    if (item.resource) {
      const target = this.menuItems.filter(
        (menu) => menu.resource === item.resource
      );
      if (target.length > 0) {
        this.props.menuSelected(target[0], item.resourceData);
      }
    }
    //mark as acknowledged and reload
  }

  acknowledge(id) {
    loadData("acknowledge", "POST", { id: id }, (data) => {
      user.refreshNotifications();
    });
  }

  showPrifile() {
    $(".activities").hide();
    $(".search-results-profile").show();
  }

  hideProfile() {
    $(".search-results-profile").hide();
  }

  showNotifications() {
    user.refreshNotifications();

    if (!this.menuItems) {
      this.menuItems = [];
      user.userDetails.menuSystem.forEach((item) => {
        if (item.submenu) {
          this.processSubItems(this.menuItems, item.submenu);
        } else {
          this.menuItems.push(item);
        }
      });
    }

    $(".search-results-profile").hide();
    $(".activities").show();
  }

  hideNotifications() {
    $(".activities").hide();
  }

  componentDidMount() {
    $(".changepasswordform").parsley({
      errorsContainer: (el) => {
        return el.$element.closest(".form-group");
      },
    });
    //loadData("getactivecargostationlist", "GET", {}, (data) => {
    //  this.userCredentials.locationOptions = data.CargoStations;
    //  this.userCredentials.station = user.userDetails.StationPosted;
    //});
  }

  //changeLocation(event)
  //{
    

    //loadData("changesystemuserstationposted", "POST", {StationPosted:event.target.value}, (data) => {
    //  user.userDetails.StationPosted = event.target.value;  
    //  user.userDetails.StationPostedName = data.SystemUsers.StationPostedName;
      
    //});
    //this.userCredentials.station= event.target.value;  
  //}

  logout() {
    window.location.reload();
  }

  showHelp() {
    $("#helpcontainer").show();
    $(".activities").hide();
    $(".search-results-profile").hide();
    //$("#helpcontainer").removeClass("slideOutRight");
    //$("#helpcontainer").addClass("slideInRight");
  }

  render() {
    return (
      <div className="profile">
        <Observer>
          {() => (
            <BellIcon
              onClick={this.showNotifications.bind(this)}
              active={user.notifications.details.length > 0}
              count={user.notifications.details.length}
            />
          )}
        </Observer>
        <button onClick={this.showPrifile.bind(this)}>
          <i
            style={{ fontSize: "20px" }}
            className="mdi mdi-account-circle-outline"
          />
        </button>

        <button
          onClick={(e) => {
            e.preventDefault();
            this.showHelp();
          }}
          style={{ top: "-4px", position: "relative" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M19 2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h4l3 3 3-3h4c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 16h-4.83l-.59.59L12 20.17l-1.59-1.59-.58-.58H5V4h14v14zm-8-3h2v2h-2zm1-8c1.1 0 2 .9 2 2 0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4S8 6.79 8 9h2c0-1.1.9-2 2-2z" />
          </svg>
        </button>

        <div className="search-results-profile">
          <div className="arrow-holder">
            <div className="triangle">
              <div className="empty" />
            </div>

            <div className="search-results-profile-holder">
              <div className="internal-search-results-profile-holder">
                <ul>
                  <li>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.hideProfile();
                      }}
                    >
                      <i className="icon-cross" />
                    </button>
                  </li>
                  <li>
                    <h6>You are Currently Logged In as:</h6>
                    <span>
                      <strong>{user.getUserDetails().username}</strong>
                    </span>
                  </li>
                  <li>
                    
                    <Observer>
                      {() => (
                        <>
                        <h6>Logged In Location:</h6>
                        <span>
                          
                          <strong>{user.getUserDetails().StationPostedName}</strong>
                        </span>
                        <br></br>
                        <br></br>
                        <br></br>
                        
                        </>
                      )}
                    </Observer>
                  </li>

                  <li onClick={this.logout.bind(this)} className="logout">
                    Sign Out
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="activities">
          <div className="arrow-holder">
            <div className="triangle">
              <div className="empty" />
            </div>

            <div className="activity-holder">
              <div className="internal-activity-holder">
                <ul className="heading">
                  <Observer>
                    {() => (
                      <li
                        className={
                          user.notifications.selected === "user"
                            ? "active-button"
                            : ""
                        }
                        style={{
                          width: "31px",
                        }}
                      >
                        <button
                          onClick={() => {
                            user.notifications.selected = "user";
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Personal
                        </button>
                      </li>
                    )}
                  </Observer>

                  <Observer>
                    {() => (
                      <li
                        className={
                          user.notifications.selected === "group"
                            ? "active-button"
                            : ""
                        }
                        style={{
                          width: "31px",
                        }}
                      >
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            user.notifications.selected = "group";
                          }}
                        >
                          Group
                        </button>
                      </li>
                    )}
                  </Observer>
                </ul>
                <ul className="notes container-fluid">
                  <Observer>
                    {() => (
                      <>
                        {user.notifications.details.filter((notify) => {
                          return notify.target === user.notifications.selected;
                        }).length > 0 ? (
                          <>
                            {user.notifications.details
                              .filter((notify) => {
                                return (
                                  notify.target === user.notifications.selected
                                );
                              })
                              .map((note, index) => {
                                return (
                                  <li key={index}>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <button
                                              className="mark"
                                              onClick={() => {
                                                this.acknowledge(note.id);
                                              }}
                                            >
                                              <i className="icon-cross" />
                                            </button>
                                          </td>
                                          <td>
                                            <div style={{ marginRight: "3px" }}>
                                              <div>
                                                <span className="title">
                                                  {note.title}
                                                </span>
                                              </div>

                                              <div>
                                                <span
                                                  className="description"
                                                  dangerouslySetInnerHTML={{
                                                    __html: note.description,
                                                  }}
                                                ></span>
                                              </div>
                                              <div
                                                style={{ overflow: "hidden" }}
                                              >
                                                <div
                                                  style={{ float: "left" }}
                                                ></div>
                                              </div>
                                            </div>
                                          </td>
                                          <td valign="top">
                                            <span className="date">
                                              {note.date}
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </li>
                                );
                              })}
                          </>
                        ) : (
                          <li className="empty">
                            <div style={{ marginRight: "3px" }}>
                              <table style={{ width: "100%" }}>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "24px" }}>
                                      <div className="avatar">
                                        <i className="mdi mdi-check"></i>
                                      </div>
                                    </td>
                                    <td>
                                      <span className="title">Empty</span>
                                    </td>
                                    <td style={{ width: "90px" }} align="right">
                                      <span className="date"></span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td colSpan="2">
                                      <span className="description">
                                        There are currently no notifications to
                                        display under this selection.
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td colSpan="2" align="right"></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </li>
                        )}
                      </>
                    )}
                  </Observer>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
