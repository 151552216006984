import React, { useState } from 'react';
import computeId from '../../actions/computeId';
import { Observer } from 'mobx-react';
import computeValidation from '../../actions/computeValidation';
import BindModel from '../../../Core/BindModel';
import computeAsterisk from '../../actions/computeAsterisk';
import action from './action';
import { observable } from 'mobx';
import 'lightbox2/dist/css/lightbox.css';
import user from '../../../../utilities/User';
import lightbox from 'lightbox2';
import SignatureStyle from './SignatureStyle';
const Signature = (props) => {
  const validatePassword = observable({
    password: props.EntryDetails[props.Config.key]
      ? props.EntryDetails[props.Config.key]
      : '',
    token: '',
    hasImage: false,
    invalid: false,
    message: '',
  });
  lightbox.option({
    resizeDuration: 200,
    wrapAround: true,
  });
  return (
    <SignatureStyle>
      <Observer>
        {() => (
          <div className="form-group">
            <label>
              {props.Config.label} {computeAsterisk(props.Config)}
            </label>
            <div className="input-group mb-3">
              <input
                type="password"
                readOnly={props.Config.alwaysEditable===true?false:props.ReadOnly}
                className="form-control"
                id={'passkey-' + computeId(props.Config)}
                placeholder={props.Config.placeholder}
                {...BindModel(
                  validatePassword,
                  'password',
                  'value',
                  'onChange'
                )}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    if (validatePassword.password.length > 0) {
                      action(props, validatePassword)
                        .then((token) => {
                          validatePassword.invalid = false;
                          validatePassword.token = token;
                          validatePassword.hasImage = true;
                          validatePassword.message = '';
                          props.EntryDetails[props.Config.key] =
                            validatePassword.password;

                          // props.EntryDetails[props.Config.key] = token;
                        })
                        .catch((err) => {
                          validatePassword.invalid = false;
                          validatePassword.token = '';
                          validatePassword.hasImage = false;
                          validatePassword.message =
                            'Password could not be verified';
                        });
                    } else {
                      validatePassword.invalid = true;
                      validatePassword.message =
                        'Password cannot be left blank';
                    }
                  }}
                  type="button"
                >
                  <i className="mdi mdi-lock"></i>
                </button>
              </div>
            </div>

            {validatePassword.hasImage ? (
              <a
                className="signature-image"
                href={
                  props.Config.authenticate.img +
                  '?id=' +
                  btoa(validatePassword.token) +
                  '&token=' +
                  btoa(user.getUserDetails().token)
                }
                data-lightbox={'signature-' + computeId(props.Config)}
              >
                <img
                  alt="signature"
                  src={
                    props.Config.authenticate.img +
                    '?id=' +
                    btoa(validatePassword.token) +
                    '&token=' +
                    btoa(user.getUserDetails().token)
                  }
                />
              </a>
            ) : (
              <span style={{display:"none"}}></span>
            )}

            {validatePassword.invalid ? (
              <span className="error">{validatePassword.message}</span>
            ) : (
              <span style={{display:"none"}}></span>
            )}

            <input
              id={computeId(props.Config)}
              type="text"   
            style={{ display: 'none' }}  
              {...computeValidation(props.Config, props.FormId)}
              placeholder={props.Config.placeholder}
              {...BindModel(
                props.EntryDetails,
                props.Config.key,
                'value',
                'onChange'
              )}
            />
          </div>
        )}
      </Observer>
    </SignatureStyle>
  );
};
export default Signature;
