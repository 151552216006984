

import Colors from "../../../constants/Colors";
export default {
  duplicates: [],
  access: [],
  track: "Airwaybill",
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator", "Cargo Member"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],

  
  printreport: "",
  caption: "Change Quantity of Packages",
  editSearchSelector: {
    resource: "GetAirwaybillList",
    resourceType: "GET",
    resourceId: ["AirwaybillNumber"],
    resourceIdentity: "Airwaybills",
    resourceValue: "AirwaybillNumber",
    resourceSelected: "GetAirwaybill",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "Airwaybill",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "ChangeAirwaybillPackageQuantity",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true
    }
  },
  addConfirmation: {
    title: 'Confirm',
    text: 'Please confirm package quantity change'
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Old Quantity",
            fields: [
              {
                key: "QuantityOfPackages",
                type: "number",
                format: "0,0",
                defaultValue: "1",
                label: "Quantity Of Packages",
                placeholder: "Quantity Of Packages",
                readOnly: true
              }             
            ]
          },
          {
            title: "New Quantity",
            fields: [
              {
                key: "NewQuantityOfPackages",
                type: "number",
                format: "0,0",
                defaultValue: "1",
                label: "Quantity Of Packages",
                placeholder: "Quantity Of Packages",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 1,
                  "data-parsley-max": 500,
                  "data-parsley-maxlength": 10,
                  "data-parsley-differentpackagequantities": true
                }
              }  
            ]
          },
          {
            title: "",
            fields: [
              {
                type: "spacer"
              }
            ]
          },
          {
            title: "",
            fields: [
              {
                type: "spacer"
              }
            ]
          }
        ]
      }
    ]
  }
};
