import AirwaybillTransaction from "./airwaybilltransaction"

export default {
  duplicates: [],
  access: [],
  track: "Airwaybill Transaction List",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Airwaybill Transactions",
  editSearchSelector: {
  },
  model: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "",
            fields: [
              {
                  type: "editabletable", 
                  data: {
                    getResource: {
                      resourceType: "POST",
                      id: "GetAirwaybillTransactionList",
                      resourceIdentity: "AirwaybillTransactions"
                    }
                  },
                  entry: {
                    resource: {
                      resourceType: "POST",
                      id: "GetAirwaybillTransaction",
                      resourceIdentity: "AirwaybillTransaction"
                    },
                    definition: { ...AirwaybillTransaction }
                  },
                  fields: [
                    {
                      key: "TransactionDate",
                      type: "date",
                      label: "Date",
                      placeholder: "Date",
                      readOnly: true
                    },
                    {
                      key: "SignedTransactionAmount",
                      type: "number",
                      format: "C2",
                      label: "Amount",
                      placeholder: "Amount",
                      readOnly: true
                    },
                    {
                      key: "TransactionTypeDescription",
                      type: "text",
                      label: "Description",
                      placeholder: "Description",
                      readOnly: true
                    },
                    {
                      key: "AgencyName",
                      type: "text",
                      label: "Agency",
                      placeholder: "Agency",
                      readOnly: true
                    },
                    {
                      key: "ReferenceNumber",
                      type: "text",
                      label: "Reference",
                      placeholder: "Reference",
                      readOnly: true
                    }
                  ]
                }
            ]
          }
        ]
      }     
    ]
  }
};



