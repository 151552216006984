export default {
	duplicates: [],
	access: [],
	track: "Airwaybill Transaction",
	formtype: "dataEntry",

	allowSearch: [
		{
			role:  [ "default" ],
			value:  true
		}
	],
	allowDelete: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowAudit: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowEdit: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowAdd: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowPrint: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	printreport: "",
	caption:  "Airwaybill Transaction Details",
	functionactions:
	{
		createnew: {
		},
		update: {
		},
		after: {
		}
	},
	formDefinition: {
		rows: [
			{
			columns: [
				{
				title: "Airwaybill Transaction Information",
				fields: [
					{
						key: "AirwaybillNumber",
						type: "text",
						label: "Airwaybill Number",
						placeholder: "Airwaybill Number",
						readOnly: true
					},
					{
						key: "TransactionDate",
						type: "date",
						label: "Date",
						placeholder: "Date",
						readOnly: true
					},
					{
						key: "TransactionAmount",
						type: "number",
						format: "$0,0.00",
						label: "Amount",
						placeholder: "Amount",
						readOnly: true
					}
				]
			  },
			  {
				title: '\n',
				fields: [
					{
						key: "TransactionTypeDescription",
						type: "text",
						label: "Description",
						placeholder: "Description",
						readOnly: true
					},
					{
						key: "AgencyName",
						type: "text",
						label: "Agency",
						placeholder: "Agency",
						readOnly: true
					},
					{
						key: "ReferenceNumber",
						type: "text",
						label: "Reference Number",
						placeholder: "Reference Number",
						readOnly: true
					}
				]
				}
			]
			}
		]
	}
}