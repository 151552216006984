import { loadData } from "../../../../../utilities";
import buildPostModification from "./buildPostModification";

const loadRemoteData = (Config, ParentData, callback, failure) => {
  const overridedata = buildPostModification(Config, ParentData);

  loadData(
    Config.optionsResource.resource,
    Config.optionsResource.resourceType,
    { ...ParentData, ...overridedata },
    data => {
      callback(
        data[Config.optionsResource.resourceIdentity]
          ? data[Config.optionsResource.resourceIdentity]
          : []
      );
    },
    failure,
    true
  );
};

export default loadRemoteData;
