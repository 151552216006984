import $ from "jquery";
import { addAnimateCSS } from "../../../../utilities";
import loadout from "./loadout";

const linesout = function () {

    addAnimateCSS($(this.refs.leftline), "animate__faster animate__slideOutLeft", () => {
      
    });
    addAnimateCSS($(this.refs.rightline), "animate__faster animate__slideOutRight", () => {
        $(this.refs.lineholder).addClass("hidden");
        const loadoutAnimation = loadout.bind(this);
        loadoutAnimation();
    });

    
};

export default linesout;