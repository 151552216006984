export default {
  track: "CargoInvoiceItemFromAdjustment",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true,
    },
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false,
    },
  ],
  caption: "Cargo Invoice Item From Adjustment",
  editSearchSelector: {},
  functionactions: {
    createnew: {},
    update: {},
    after: {},
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Cargo Invoice Item From Adjustment Information",
            fields: [
              {
                key: "AdjustmentNumber",
                type: "text",
                label: "Adjustment Number",
                placeholder: "Adjustment Number",
                readOnly: true
              },
              {
                key: "ItemAmount",
                type: "number",
                format: "$0,0.00",
                label: "Amount",
                placeholder: "Amount",
                readOnly: true
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "Description",
                type: "text",
                label: "Description",
                placeholder: "Description",
                readOnly: true
              },
              {
                key: "AdjustmentNotes",
                type: "textarea",
                label: "Notes",
                placeholder: "Notes",
                readOnly: true,
              },
            ]
          },
          {
            title: "\n",
            fields: [              
            ]
          }
        ]
      }
    ]
  }
};
