const buttons = (props) => [
  {
    showInAddMode: true,
    showInEditMode: false,
    requiresSelection: true,
    showInLocked: false,
    showInEditableMode: false,
    showInDialog: true, 
    onlyInDialog:true,
    eventType: ['createNew','close'],
    text: 'Done',
    icon: 'mdi mdi-check',
    color: props.theme.add,
    calc:false,
  },
  {
    showInAddMode: false,
    showInEditMode: true,
    requiresSelection: true,
    showInLocked: false,
    showInEditableMode: false,
    showInDialog: true,
    onlyInDialog:true,
    eventType: ['updateExisting','close'],
    text: 'Done',
    icon: 'mdi mdi-check',
    color: props.theme.add,
    calc:false,
  },
  {
    showInEditMode: false,
    showInAddMode: false,
    requiresAllowAdd:true,
    requiresAllowEdit:false,
    requiresSelection: false,
    showInLocked: false,
    showInEditableMode: true,
    showInDialog: false,
    eventType: 'add',
    text: 'Add',
    icon: 'mdi mdi-plus',
    color: props.theme.add,
    calc:false,
  },
  {
    showInEditMode: false,
    showInAddMode: false,
    requiresAllowAdd:false,
    requiresAllowEdit:true,
    showInLocked: false,
    requiresSelection: true,
    showInEditableMode: true,
    eventType: 'edit',
    showInDialog: false,
    text: 'Edit',
    icon: 'mdi mdi-pencil',
    color: props.theme.edit,
    calc:false,
  },
  {
    showInEditMode: false,
    showInAddMode: false,
    requiresAllowAdd:false,
    requiresAllowEdit:false,
    requiresAllowView:true,
    showInLocked: true,
    requiresSelection: true,
    showInEditableMode: true,
    eventType: 'view',
    showInDialog: false,
    showOnWillLaunch:true,
    text: 'View',
    icon: 'mdi mdi-magnify',
    color: props.theme.view,
    calc:false,
  },
  {
    saveAndNew:false,
    hideSaveAndNew:true,
    showInEditMode: false,
    showInAddMode: true,    
    requiresSelection: false,
    showInLocked: false,
    showInEditableMode: false,
    hideInWillLaunch:true,
    showInDialog: false,
    eventType: 'createNew',
    text: 'Save',
    icon: 'mdi mdi-check',
    color: props.theme.save,
    calc:false,
  },
  {
    saveAndNew:false,
    hideSaveAndNew:true,
    showInEditMode: false,
    showInAddMode: true,    
    requiresSelection: false,
    showInLocked: false,
    showInEditableMode: false,
    hideInWillLaunch:true,
    showInDialog: false,
    eventType: 'calculate',
    text: 'Calculate',
    icon: 'mdi mdi-calculator',
    color: props.theme.save,
    calc:true,
  },
  {
    showInEditMode: false,
    showInAddMode: true,    
    requiresSelection: false,
    showInLocked: false,
    showInEditableMode: false,
    hideInWillLaunch:true,
    showInDialog: false,
    saveAndNew:true,
    eventType: ['createNew','add'],
    text: 'Save & New',
    icon: 'mdi mdi-check',
    color: props.theme.save,
    calc:false,
  },
  
  {
    saveAndNew:false,
    showInEditMode: true,
    showInAddMode: false,    
    hideSaveAndNew:true,
    requiresSelection: false,
    showInLocked: false,
    showInEditableMode: false,
    hideInWillLaunch:true,
    showInDialog: false,
    eventType: 'updateExisting',
    text: 'Save',
    icon: 'mdi mdi-check',
    color: props.theme.save,
    calc:false,
  },
  {
    showInEditMode: true,
    showInAddMode: true,
    requiresAllowAdd:false,
    requiresAllowEdit:false,
    requiresSelection: false,
    hideInWillLaunch:true,
    showInLocked: false,
    showInDialog: false,
    showInEditableMode: false,
    text: 'Cancel',
    eventType: 'cancel',
    icon: 'mdi mdi-undo',
    color: props.theme.cancel,
    calc:false,
  }
 
];

export default buttons;
