
export default {
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Company Administration Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  caption: "Cargo Business Rule",
  editSearchSelector: {
    resource: "GetCargoBusinessRuleList",
    resourceType: "GET",
    resourceId: ["CriteriaName"],
    resourceIdentity: "CargoBusinessRules",
    resourceValue: "CriteriaName",
    resourceSelected: "GetCargoBusinessRule",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoBusinessRule",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "AddCargoBusinessRule",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Business Rule Information Added",
      alertText: "Businness Rule Has Been Added"
    },
    update: {
      functionname: "UpdateCargoBusinessRule",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Business Rule Information Updated",
      alertText: "The Business Rule Has Been Updated"
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [ 
            {
              key: "CriteriaName",
              type: "text",
              defaultValue: "",
              label: "Name",
              placeholder: "Name",
              readOnly: false,
              readOnlyOnEdit: true,
              validation: {
                "data-parsley-required": true,
                "data-parsley-minlength": 1,
                "data-parsley-maxlength": 50
              }
            },
            {
                key: "CriteriaValue",
                type: "text",
                defaultValue: "",
                label: "Value",
                placeholder: "Value",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 255
                }
              },
              {
                key: "CriteriaDescription",
                type: "textarea",
                defaultValue: "",
                label: "Description",
                placeholder: "Description",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 100
                }
              }
            ]
          }
        ]
      }
    ]
  }
};
