import Notification from "../../components/Core/Notification";

const showErrorNotification = (suppress,error)=>{
    
    if(!suppress)
    {    
    Notification.error(
        error,
        "Server Side",
        null
      );
    }
}

export default showErrorNotification;