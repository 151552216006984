import CargoInvoiceAdjustment from "./cargoinvoiceadjustment";
import Colors from "../../../constants/Colors";

export default {
  track: "Cargo Invoice Adjustment List",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  caption: "Cargo Invoice Adjustments",
  editSearchSelector: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "editabletable",
                allowInlineEditing:{
                  parentDependentFields: [
                    {
                      key: 'AdjustmentBatchStatus',
                      values: ['Processed', 'Voided'],
                      active: false
                    }
                  ],
                },                  
                multiSelect:false,
                customButtons : [
                  {
                    showInEditMode: false,
                    showInAddMode: false,
                    showInLocked: true,
                    requiresSelection: true,
                    showInEditableMode: true,
                    eventType: 'edit',
                    dialogOnly: false,
                    text: 'Edit',
                    icon: 'mdi mdi-pencil',
                    color: Colors.edit,
                    roles: [
                      {
                        role: ["Accounting Administrator", "Accounting Member"],
                        value: true
                      }        
                    ],
                    parentDependentFields: [
                      {
                        key: 'AdjustmentBatchStatus',
                        values: ['Processed', 'Voided'],
                        active: false
                      }
                    ],
                  }, 
                  {
                    showInEditMode: false,
                    showInAddMode: false,
                    showInLocked: false,
                    requiresSelection: false,
                    showInEditableMode: false,
                    eventType: 'add',
                    dialogOnly: false,
                    text: 'Add',
                    icon: 'mdi mdi-pencil',
                    color: Colors.edit,
                  }
                ],
                data: {
                  getResource: {
                    resourceType: "POST",
                    id: "GetCargoInvoiceAdjustmentListByEmployeeBatchNumber",
                    resourceIdentity: "CargoInvoiceAdjustments"
                  },
                  save:{
                    functionname:"UpdateCargoInvoiceAdjustmentAmount",
                    functiontype:"POST",
                  }
                },
                entry: {
                  resource: {
                    resourceType: "POST",
                    id: "GetCargoInvoiceAdjustment",
                    resourceIdentity: "CargoInvoiceAdjustment"
                  },
                  definition: { ...CargoInvoiceAdjustment }
                },
                fields: [
                  {
                    key: "AgencyName",
                    type: "text",
                    label: "Employee",
                    readOnly: true
                  },  
                  {
                    key: "AdjustmentNumber",
                    type: "text",
                    label: "Adjustment No.",
                    readOnly: true
                  },  
                  {
                    key: "InvoiceNumber",
                    type: "text",
                    label: "Invoice No.",
                    readOnly: true
                  },  
                  {
                    key: "InvoiceAmount",
                    type: "number",
                    label: "Invoice Amount",
                    format: "c2",
                    readOnly: true
                  },  
                  {
                    key: "TransactionTypeDescription",
                    type: "text",
                    label: "Adjustment Type",
                    readOnly: true
                  },
                  {
                    key: "AdjustmentAmount",
                    type: "number",
                    label: "Adjustment Amount",
                    format: "c2",
                    readOnly: false,
                    validation: {
                      "data-parsley-required": true,
                      "data-parsley-min": 0,
                      "data-parsley-max": 1000000,
                      "data-parsley-validemployeecargoadjustmentamount": true
                    }
                  },  
                  {
                    key: "Balance",
                    type: "number",
                    label: "Balance",
                    format: "c2",
                    readOnly: true
                  }
                ]
              }
            ]
          }
        ]
      }     
    ]
  }
};



