export default {
  track: "CargoInvoiceTransaction",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true,
    },
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false,
    },
  ],
  caption: "Cargo Invoice Transaction",
  editSearchSelector: {},
  functionactions: {
    createnew: {},
    update: {},
    after: {},
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Cargo Invoice Transaction Information",
            fields: [
              {
                key: "TransactionType",
                type: "text",
                label: "Transaction Type",
                placeholder: "Transaction Type",
                readOnly: true,
              },
              {
                key: "TransactionDescription",
                type: "text",
                label: "Description",
                placeholder: "Description",
                readOnly: true,
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "TransactionDate",
                type: "date",
                label: "Date",
                placeholder: "Date",
                readOnly: true,
              },
              {
                key: "TransactionAmount",
                type: "number",
                format: "$0,0.00",
                label: "Amount",
                placeholder: "Amount",
                readOnly: true,
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "Reference",
                type: "text",
                label: "Reference Number",
                placeholder: "Reference Number",
                readOnly: true,
              }
            ]
          }
        ]
      }
    ]
  }
};
