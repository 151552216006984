import React from "react";
import SearchResultItemDisplay from "./SearchResultItemDisplay";
import Noop from "../../Core/Noop";

const SearchResultItem = props => (
  <li className="card-1" onClick={()=>{props.onSelected(props.result)}}>
    <div className="result-holder">
     

      <div className="list-text">{props.result[props.Display]}</div>
      <div className="result-holder-animator" />
    </div>
    <div className="list-other">
      {props.ResourceDisplayFields ? (
        props.ResourceDisplayFields.map((other, indexother) => {
          return (
            <SearchResultItemDisplay
              other={other}
              indexother={indexother}
              index={props.index}
              result={props.result}
              key={`other-${props.index}-${indexother}`}
            />
          );
        })
      ) : (
        <Noop />
      )}
    </div>
  </li>
);

export default SearchResultItem;
