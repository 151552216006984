import { create, all } from "mathjs";
import secureMath from "./secureMath";
import convertObjectPropertiesToNumeric from "./convertObjectPropertiesToNumeric";
import getNumerical from "../Numeric/getNumerical";

const math = create(all); // Create Math
const limitedEvaluate = math.evaluate; // Math limited evaluation

const computeExpression = (formula, data,precision) => {  
  secureMath(math); // limit math
  const computeData = convertObjectPropertiesToNumeric(data); // Any non numeric values should be set to 0
  try {
    const result = computeData
      ? limitedEvaluate(formula, computeData)
      : limitedEvaluate(formula); // run math depending if data was provided. This seems to be a requirement of math.js
      let numeral = getNumerical(result);

      if(precision)
      {
        numeral = parseFloat(numeral.toString()).toFixed(precision);
      }

    return numeral; // if returned value nan or undefined return 0;
  } catch (error) {
    //console.warn("compute", error.message); //Any other error
  }
  return 0; // default to 0
};

export default computeExpression;
