import React, { PureComponent } from "react";
import SideNavigatorStyle from "./SideNavigatorStyle";
import $ from "jquery";
import "metismenu";
import { guid } from "../../../utilities";
import SmallNav from "./SmallNav";
import FullNav from "./FullNav";
import { observable } from "mobx";
import Start from "./Start";
import Speech from "./Speech";
import _ from "lodash";
import FuzzySet from "fuzzyset";
import Notification from "../../Core/Notification";
export default class Loader extends PureComponent {
  constructor(props) {
    super(props);
    this.data = observable({});
    this.state = {
      showMenu: false,
      activeMenu: null,
      subMenus: [],
      Speeches: [],
      startRecord: false,
      ShowSpeech: false,
      ShowStart: false,
      showHelp: false,
      menuCollapsed: this.getCurrentMenuState(),
    };

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const SpeechGrammarList =
      window.SpeechGrammarList || window.webkitSpeechGrammarList;
    const SpeechRecognitionEvent =
      window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;
    const fuzzy = FuzzySet();

    const listener2 = (event) => {
      const allowElements = [
        ".submenus",
        ".main-nav",
        ".main-nav-li",
        ".start-table",
        ".container-sidebar",
      ];
      let allow = false;

      allowElements.forEach((item) => {
        if ($(event.target).closest(item).length > 0) {
          allow = true;
        }
      });
      if (!allow) {
        $(this.refs.menu).metisMenu("dispose");
        this.setState({
          activeMenu: null,
          showMenu: false,
          ShowStart: false,
          ShowSpeech: false,
          showHelp: false,
          subMenus: [],
        });
      }
    };

    $(window).on("click", listener2);
    $(window).on("touchend", listener2);

    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      try {
        const menus = [];
        this.props.MenuSystem.forEach((item) => {
          if (item.submenu) {
            this.processSubItems(menus, item.submenu);
          } else {
            menus.push(item);
          }
        });
        var commands = [];
        menus.forEach((command) => {
          commands.push(command.name.toLocaleLowerCase());
          fuzzy.add(command.name.toLocaleLowerCase());
        });

        const grammar =
          "#JSGF V1.0; grammar colors; public <action> = " +
          menus.join(" | ") +
          " ;";

        this.recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition)();
        this.speechRecognitionList = new (window.SpeechGrammarList || window.webkitSpeechGrammarList || window.mozSpeechGrammarList || window.msSpeechGrammarList)();
        this.speechRecognitionList.addFromString(grammar, 1);
        this.recognition.grammars = this.speechRecognitionList;
        this.recognition.continuous = false;
        this.recognition.lang = "en-US";
        this.recognition.interimResults = false;
        this.recognition.maxAlternatives = 1;
        this.recognition.onnomatch = (event) => {
          Notification.info("I'm sorry, I didn't understand the command");
          this.setState({ ...this.state, startRecord: false });
        };
        this.recognition.onerror = (event) => {
          Notification.info("I'm sorry, I didn't understand the command");
          this.setState({ ...this.state, startRecord: false });
        };

        this.recognition.onresult = (event) => {
          //var color = event.results[0][0].transcript;

          var interim_transcript = "";
          var final_transcript = "";
          for (var i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
              final_transcript += event.results[i][0].transcript;
            } else {
              interim_transcript += event.results[i][0].transcript;
            }
          }

          if (final_transcript && final_transcript.length > 0) {
            //diagnostic.textContent = 'Result received: ' + color + '.';
            //bg.style.backgroundColor = color;
            const fuzzer = fuzzy.get(final_transcript);
            const possible = [];

            if (!fuzzer) {
              Notification.info("I'm sorry, I didn't understand the command");
              this.setState({ ...this.state, startRecord: false });
              return;
            }
            if (fuzzer.length > 0) {
              fuzzer.forEach((fuzz) => {
                menus.forEach((item) => {
                  if (
                    item.name.toLocaleLowerCase() ===
                    fuzz[1].toLocaleLowerCase()
                  ) {
                    possible.push(item);
                  }
                });
              });
              if (possible.length === 1) {
                this.onItemSelected(possible[0]);
                this.setState({ ...this.state, startRecord: false });
              } else if (possible.length > 1) {
                this.setState({
                  ...this.state,
                  Speeches: possible,
                  ShowSpeech: true,
                  ShowStart: false,
                  startRecord: false,
                });
              } else {
                Notification.info("I'm sorry, I didn't understand the command");
                this.setState({ ...this.state, startRecord: false });
              }
            } else {
              Notification.info("I'm sorry, I didn't understand the command");
              this.setState({ ...this.state, startRecord: false });
            }
          } else {
            Notification.info("I'm sorry, I didn't understand the command");
            this.setState({ ...this.state, startRecord: false });
          }
        };
      } catch (ex) {
        console.log("unable to enable speech")
      }
    }
  }
  startRecording() {
    this.setState({ ...this.state, startRecord: true });
    try
    {
    this.recognition.start();
    }
    catch(ex)
    {

    }
  }

  stopRecording() {
    this.setState({ ...this.state, startRecord: false });
    try
    {
    this.recognition.stop();
    }
    catch
    {
      
    }
  }

  processSubItems(menuItems, items) {
    items.forEach((item) => {
      if (item.submenu) {
        this.processSubItems(menuItems, item.submenu);
      } else {
        menuItems.push(item);
      }
    });
  }

  getCurrentMenuState() {
    const menuState = {
      collapsed: false,
    };
    const currentState = window.localStorage.getItem("menuState");
    if (currentState) {
      try {
        const jsonCurrentState = JSON.parse(currentState);
        menuState.collapsed = jsonCurrentState.collapsed;
      } catch (ignored) {}
    }

    return menuState.collapsed;
  }

  onMenuClick(menu) {
    $(this.refs.menu).metisMenu("dispose");
    this.setState({
      ...this.state,
      showHelp: false,
      showMenu: true,
      ShowStart: false,
      activeMenu: menu,
      ShowSpeech: false,
      subMenus: menu.submenu,
      name: menu.name,
      icon: menu.icon,
    });
  }
  ShowStart() {
    $(this.refs.menu).metisMenu("dispose");
    this.setState({
      ...this.state,
      showHelp: false,
      showMenu: false,
      ShowStart: true,
      ShowSpeech: false,
      activeMenu: null,
    });
  }

  ShowSpeech(list) {
    $(this.refs.menu).metisMenu("dispose");
    this.setState({
      ...this.state,
      showHelp: false,
      showMenu: false,
      ShowStart: false,
      ShowSpeech: true,
      Speeches: list,
      activeMenu: null,
    });
  }

  onItemSelected(menu) {
    const recentString = window.localStorage.getItem("recent");
    let recent = [];
    if (recentString && recentString.length > 0) {
      recent = JSON.parse(recentString);
    }
    recent.push(menu.resource);
    recent = _.uniq(recent);

    window.localStorage.setItem("recent", JSON.stringify(recent));

    this.setState({
      activeMenu: null,
      showMenu: false,
      ShowStart: false,
      subMenus: [],
    });
    this.props.menuSelected(menu);
  }

  componentDidMount() {
    if (this.state.showMenu) {
      $(this.refs.menu).metisMenu();
    }
  }

  componentDidUpdate() {
    if (this.state.showMenu) {
      try {
        $(this.refs.menu).metisMenu();
      } catch (ignore) {
        //ignored
      }
    }
  }

  onHelpClick() {
    this.setState({ ...this.state, showHelp: true, showMenu: false });
  }

  toggleMenuState(e) {
    try {
      e.preventDefault();
    } catch (ignore) {}
    const menuState = {
      collapsed: false,
    };
    const currentState = window.localStorage.getItem("menuState");
    if (currentState) {
      try {
        const jsonCurrentState = JSON.parse(currentState);
        menuState.collapsed = jsonCurrentState.collapsed;
      } catch (ignored) {}
    }
    menuState.collapsed = !menuState.collapsed;
    window.localStorage.setItem("menuState", JSON.stringify(menuState));
    $(window).trigger("toggle-menu-state", menuState);
    this.setState({
      activeMenu: null,
      showMenu: false,
      showHelp: false,
      subMenus: [],
      menuCollapsed: menuState.collapsed,
    });
  }

  render() {
    return (
      <SideNavigatorStyle>
        {this.state.ShowStart ? (
          <Start
            onItemSelected={this.onItemSelected.bind(this)}
            MenuSystem={this.props.MenuSystem}
            menuCollapsed={this.state.menuCollapsed}
          />
        ) : (
          <span style={{ display: "none" }}></span>
        )}

        {this.state.ShowSpeech ? (
          <Speech
            onItemSelected={this.onItemSelected.bind(this)}
            MenuSystem={this.state.Speeches}
            menuCollapsed={this.state.menuCollapsed}
          />
        ) : (
          <span style={{ display: "none" }}></span>
        )}

        {this.state.showMenu ? (
          <div
            className="submenus"
            style={{ left: this.state.menuCollapsed ? "58px" : "248px" }}
          >
            <div className="submenuholder">
              <div className="submenuholderinner">
                <div className="container-sidebar">
                  <ul className="metismenu" ref="menu" id="menu">
                    {this.state.subMenus.map((menu) => {
                      return menu.submenu ? (
                        <li key={`${guid()}`}>
                          <a
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <span>{menu.name}</span>
                            <span className="mdi mdi-arrow-right arrow" />
                          </a>

                          <ul>
                            {menu.submenu.map((submenu) => {
                              return (
                                <li key={`${guid()}`}>
                                  <a
                                    href="#/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.onItemSelected(submenu);
                                    }}
                                  >
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td valign="middle">
                                            <i
                                              className="mdi mdi-circle"
                                              style={{
                                                marginRight: "8px",
                                                fontSize: "7pt",
                                                display: "inline",
                                              }}
                                            />
                                          </td>
                                          <td>{submenu.name}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      ) : (
                        <li key={`${guid()}`}>
                          <a
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                              this.onItemSelected(menu);
                            }}
                          >
                            {menu.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ul
            style={{ display: "none" }}
            className="metismenu"
            ref="menu"
            id="menu"
          >
            <li />
          </ul>
        )}
        {this.state.menuCollapsed ? (
          <SmallNav
            ShowStart={this.ShowStart.bind(this)}
            MenuSystem={this.props.MenuSystem}
            onToggle={this.toggleMenuState.bind(this)}
            onMenuClick={this.onMenuClick.bind(this)}
            activeMenu={this.state.activeMenu}
            activeStart={this.state.ShowStart}
            activeSpeech={this.state.ShowSpeech}
            recording={this.state.startRecord}
            startRecording={this.startRecording.bind(this)}
            stopRecording={this.stopRecording.bind(this)}
          />
        ) : (
          <FullNav
            activeMenu={this.state.activeMenu}
            ShowStart={this.ShowStart.bind(this)}
            MenuSystem={this.props.MenuSystem}
            onToggle={this.toggleMenuState.bind(this)}
            onMenuClick={this.onMenuClick.bind(this)}
            activeStart={this.state.ShowStart}
            activeSpeech={this.state.ShowSpeech}
            recording={this.state.startRecord}
            startRecording={this.startRecording.bind(this)}
            stopRecording={this.stopRecording.bind(this)}
          />
        )}
      </SideNavigatorStyle>
    );
  }
}
