import React from "react";
import computeId from "../../actions/computeId";
import { Observer } from "mobx-react";
import computeValidation from "../../actions/computeValidation";
import BindModel from "../../../Core/BindModel";
import computeAsterisk from "../../actions/computeAsterisk";
const TextBox = props => (
    <Observer>
    {() => (
      <div className="form-group">
        <label>{props.Config.label} {computeAsterisk(props.Config)}</label>
        <textarea
          style={{ height: props.Config.height ? props.Config.height : "47px" }}
          readOnly={props.ReadOnly}          
          id={computeId(props.Config)}
          className="form-control"
          placeholder={props.Config.placeholder}
          {...BindModel(
            props.EntryDetails,
            props.Config.key,
            "value",
            "onChange"
          )}
        />
        <input
          type="text"
          autoComplete="off"
          style={{display:"none"}}
          {...computeValidation(props.Config,props.FormId)}
          {...BindModel(
            props.EntryDetails,
            props.Config.key,
            "value",
            "onChange"
          )}
        />
      </div>
    )}
  </Observer>
);
export default TextBox;
