import customParsleyValidators from './customParsleyValidators';

const computeValidation = (field, formId,more) => {
  const validators = {};
  
  if (field.validation) {
    for (var valid in field.validation) {
      if (customParsleyValidators.indexOf(valid) > -1) {
        if(formId)
        {
        const parsleyId = formId.replace(new RegExp("-", 'g'),"");
        validators[`${valid}${parsleyId}`] = field.validation[valid];
        }
      } else {
        validators[valid] = field.validation[valid];
      }
    }
  }

  

  if (field.validation) {
    if (more) {
      return {
        ...more,
        ...validators,
        'data-parsley-validate-if-empty': true
      };
    } else {
      return {
        ...validators,
        'data-parsley-validate-if-empty': true
      };
    }
  } else {
    if (more) {
      return {
        ...more,
        'data-parsley-validate-if-empty': true
      };
    } else {
      return {
        'data-parsley-validate-if-empty': true
      };
    }
  }
};

export default computeValidation;
