import styled from "@emotion/styled";

const CheckBoxStyle = styled.div`
width:100%;
	min-height:40.66px;
	padding-top:7px;
	position:relative;
	
.holder
{
	position:relative;
	
	margin-left:-75px;
	
}

table{
  width:100%;
}

.holder1
{
	position:relative;
	width:100%;
  overflow:hidden;
}

.scancode
{
  padding:3px 5px;
  border-bottom: 1px solid ${(props) => props.theme.backgroundDarker};
  
}

.scancode:nth-child(even)
{
  background-color: ${(props) => props.theme.backgroundMedium}
}



.scanneditems
{
	
	width:100%;
  font-size:9pt;
  font-weight:500;
  border: 1px solid ${(props) => props.theme.backgroundDarker};
  border-bottom: 1px solid transparent;
}
.lastScanText
{
  display:block;
  width:100%;
  font-weight:700;
  margin:10px 0 5px 0;
  font-size:9pt;
  font-weight:500;
}

.spacer {
	
}

/* barcode container */
.anim-box {
	position: relative;
	width: 100%;
  overflow:hidden;
	height: 26px;
	transition: transform .6s ease-out;
}

.scanButton {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.add};
    width:100%;
    
    overflow: hidden;
  text-overflow: ellipsis;
    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.add};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight};
    }
  }

  .endScanButton {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float:right;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.cancel};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.cancel};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight};
    }
  }


/* top left corner */
.anim-box:before {
	top: 0; left: 0;
	border-width: 2px 0 0 2px;
}

/* top right corner */
.anim-box:after {
	top: 0; right: 0;
	border-width: 2px 2px 0 0;
}

/* bottom right corner */
.anim-box>:first-of-type:before {
	bottom: 0; right: 0;
	border-width: 0 2px 2px 0;
}

/* bottom left corner */
.anim-box>:first-of-type:after {
	bottom: 0; left: 0;
	border-width: 0 0 2px 2px;
}

/* barcode bars */
.anim-item {
	display: inline-block;
	background-color: ${(props) => props.theme.textMedium};
	height: 26px;
}

.anim-item-sm {
	width: 1px;
	margin-right: 2px;
}

.anim-item-md {
	width: 2px;
	margin-right: 1px;
}

.anim-item-lg {
	width: 3px;
	margin-right: 3px;
}



.anim-box .scanner {
  animation-play-state: running;
}

/* animated laser beam */
.scanner {
	width: 99%;
	-webkit-box-shadow: 0px 0px 4px 0px ${(props) => props.theme.accent}; 
box-shadow: 0px 0px 4px 0px ${(props) => props.theme.accent};
	height: 2px;
	background-color: ${(props) => props.theme.accent};
  opacity: 0.9;
  position:relative;
  
  top:50%;
  animation-name: scan;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: paused;
}

@keyframes scan {
  0% {
    
    top: 50%;
  }  
  25% {
    
    top: 0px;
  }
  75% {
    
    top: 98%;
  }
}
`

export default CheckBoxStyle;
