import { observable, toJS } from "mobx";

import { loadData } from "../../../../utilities";
import onLaunchDialog from "./onLaunchDialog";
import { user } from "../../../../utilities";

const computeDefault = (addobject, field) => {
  if (field["defaultValue"]) {
    if (field["defaultValue"] === "Today") {
      addobject[field.key] = new Date();
      return;
    } 
    
    if (field["defaultValue"] === "User") {
      addobject[field.key] = user.getUserDetails().UserName;
      return;
    } 
    
    if (field["defaultValue"] === "EmployeeId") {
      addobject[field.key] = user.getUserDetails().EmployeeIdNumber;
      return;
    } 
    
    if (field["defaultValue"] === "StationPosted") {
      addobject[field.key] = user.getUserDetails().StationPosted;
      return;
    } 
    
    if (field["defaultValue"] === "StationPostedBillingAgencyId") {
      addobject[field.key] = user.getUserDetails().StationPostedBillingAgencyId;
      return;
    } 
    
    if (field["defaultValue"] === "StationPostedName") {
      addobject[field.key] = user.getUserDetails().StationPostedName;
      return;
    } 
    
    if (!(addobject[field.key] && addobject[field.key].toString().trim().length > 0)) {
      addobject[field.key] = field["defaultValue"];
    }       
  }
};
const computeDefaultValues = (addobject, props) => {
  props.FormDefinition.formDefinition.rows.forEach((item) => {
    item.columns.forEach((col) => {
      col.fields.forEach((field) => {
        if (field instanceof Array) {
          field.forEach((subField) => {
            computeDefault(addobject, subField);
          });
        } else {
          computeDefault(addobject, field);
        }
      });
    });
  });
  return addobject;
};

const CalcStateChanged = (props, afterAddResults, innerOnStateChanged) => {
  return new Promise((resolve, reject) => {
    if (afterAddResults) {
      const resource = props.Config.after.resource;
      loadData(
        resource.id,
        resource.resourceType,
        toJS(afterAddResults),
        (response) => {
          try {
            let entry = observable({
              ...response[resource.resourceIdentity],
            });
            resolve(entry);
          } catch (ex) {
            console.error(ex);
            reject();
          }
        },
        () => {
          resolve();
        }
      );
    } else {
      if (
        props.FormDefinition.functionactions &&
        props.FormDefinition.functionactions.createnew &&
        props.FormDefinition.functionactions.createnew.beforeadd
      ) {
        loadData(
          props.FormDefinition.functionactions.createnew.beforeadd.functionname,
          props.FormDefinition.functionactions.createnew.beforeadd.functiontype,
          toJS(props.EntryDetails),
          (response) => {
            let addobject = {};

            props.FormDefinition.formDefinition.rows.forEach((item) => {
              item.columns.forEach((col) => {
                if (col.fields !== undefined) {
                  col.fields.forEach((field) => {
                    if (
                      response[
                        props.FormDefinition.functionactions.createnew.beforeadd
                          .resourceIdentity
                      ][field.key]
                    ) {
                      addobject[field.key] =
                        response[
                          props.FormDefinition.functionactions.createnew.beforeadd.resourceIdentity
                        ][field.key];
                    }
                  });
                }
              });
            });
            resolve(addobject);
          },
          (error) => {
            console.error(error);
            reject();
          }
        );
      } else if (props.Config && props.Config.addResource) {
        loadData(
          props.Config.addResource.id,
          props.Config.addResource.resourceType,
          toJS(props.EntryDetails),
          (resource) => {
            try {
              let entry = observable({
                ...resource[props.Config.addResource.resourceIdentity],
              });
              if (innerOnStateChanged) {
                innerOnStateChanged("Add");
                onLaunchDialog(props, entry, false, true).then(() => {
                  resolve();
                });
              } else {
                onLaunchDialog(props, entry, false, true).then(() => {
                  resolve();
                });
              }
            } catch (ex) {
              console.error(ex);
              reject();
            }
          },
          () => {
            resolve();
          }
        );
      } else {
        let addobject = {};

        addobject = computeDefaultValues(addobject, props);

        if (props.FormDefinition.addDefaults) {
          loadData(
            props.FormDefinition.addDefaults.resource,
            props.FormDefinition.addDefaults.resourceType,
            props.ParentData,
            (response) => {
              const addobject = {
                ...response[props.FormDefinition.addDefaults.resourceIdentity],
              };
              if (
                props.Config &&
                props.ParentData &&
                props.Config.keylink instanceof Array
              ) {
                props.Config.keylink.forEach((item, index) => {
                  addobject[item] = props.ParentData[props.Config.key[index]];
                });
              } else {
                addobject[props.Config.keylink] =
                  props.ParentData[props.Config.key];
              }
              resolve(addobject);
            },
            () => {
              reject();
            }
          );
        } else {
          if (props.Config && props.ParentData) {
            if (props.Config.keylink instanceof Array) {
              props.Config.keylink.forEach((item, index) => {
                addobject[item] = props.ParentData[props.Config.key[index]];
              });
            } else {
              addobject[props.Config.keylink] =
                props.ParentData[props.Config.key];
            }
          }
          resolve(addobject);
        }
      }
    }
  });
};

const onAdd = (actionDefinition, props, innerOnStateChanged) => {
  return new Promise((resolve) => {
    if (props.willLaunchDialog) {
      CalcStateChanged(props, null, innerOnStateChanged)
        .then((result) => {
          try {
            if (innerOnStateChanged) {
              innerOnStateChanged("Add", result);
              let entry = observable(result);
              onLaunchDialog(
                props,
                entry,
                true,
                false,
                null,
                false,
                (afterAddResults) => {
                  return CalcStateChanged(
                    props,
                    afterAddResults,
                    innerOnStateChanged
                  );
                }
              );
              resolve();
            } else {
              let entry = observable(result);
              onLaunchDialog(
                props,
                entry,
                true,
                false,
                null,
                false,
                (afterAddResults) => {
                  return CalcStateChanged(
                    props,
                    afterAddResults,
                    innerOnStateChanged
                  );
                }
              );
              resolve();
            }
          } catch (ex) {
            console.error(ex);
            resolve();
          }
        })
        .catch((error) => {
          resolve();
        });
    } else {
      CalcStateChanged(props)
        .then((result) => {
          if (innerOnStateChanged) {
            innerOnStateChanged("Add", result);
            resolve(result);
          } else {
            resolve();
          }
        })
        .catch((error) => {
          console.warn(error);
          if (innerOnStateChanged) {
            innerOnStateChanged("Error");
          }
          resolve();
        });
    }
  });
};

export default onAdd;
