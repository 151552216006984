
import CashBoxSearch from "./cashboxsearch";
import CashBoxDetailSummaryList from "../cashboxdetail/cashboxdetailsummarylist";
import CashBoxDetailList from "../cashboxdetail/cashboxdetaillist";
import Colors from "../../../constants/Colors";
export default {
  track: "CashBox",
  formtype: "dataEntry",
  customSearch: {
    searchForm: { ...CashBoxSearch },
    width: 800,
    align: 500,
    height: 800,
    id: "CashBoxSearch"
  },
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {          
      role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor", "Accounting Administrator", "Accounting Member"],
      value: true
    }
  ], 
  customActions: [
    {
      showInEditMode: false,
      showInAddMode: false,
      showInLocked: false,
      requiresSelection: true,
      showInEditableMode: true,
      eventType: "edit",
      refreshAfterAction: true,
      dialogOnly: false,
      text: "Edit",
      icon: "mdi mdi-pencil",
      refreshAfterAction: true,
      color: Colors.edit,
      dependentFields: [
        {
          key: "CashBoxStatus",
          values: ["Closed",  "Voided"],
          active: false
        },
        {
          key: "AuthorizedUser",
          values: ["No"],
          active: false
        }
      ],
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor", 
          "Accounting Administrator", "Accounting Member"],
          value: true
        }
      ]
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-refresh",
      text: "Refresh Cash Box",
      refreshAfterAction: true,
      eventType: "api",
      color: Colors.search,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor", 
          "Accounting Administrator", "Accounting Member"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "CashBoxStatus",
          values: ["Closed",  "Voided"],
          active: false
        },
        {
          key: "AuthorizedUser",
          values: ["No"],
          active: false
        }
      ],
      function: {
        functionName: "RefreshCashBox",
        functionType: "POST",
        validateData: true,
        alertOnComplete: true,
        alertText: "The Cash Box Has Been Refreshed"
      }
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-chevron-double-right",
      text: "Close Cash Box",
      refreshAfterAction: true,
      eventType: "api",
      color: Colors.process,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor", 
          "Accounting Administrator", "Accounting Member"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "CashBoxStatus",
          values: ["Closed",  "Voided"],
          active: false
        },
        {
          key: "AuthorizedUser",
          values: ["No"],
          active: false
        }
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm closing of cash box"
      }, 
      function: {
        functionName: "CloseCashBox",
        functionType: "POST",
        validateData: true,
        alertOnComplete: true,
        alertText: "The Cash Box Has Been Closed"
      }
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-null",
      text: "Void Cash Box",
      eventType: "api",
      refreshAfterAction: true,
      color: Colors.cancel,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor", 
          "Accounting Administrator", "Accounting Member"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "CashBoxStatus",
          values: ["Closed",  "Voided"],
          active: false
        },
        {
          key: "AuthorizedUser",
          values: ["No"],
          active: false
        }
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm voiding of cash box"
      }, 
      function: {
        functionName: "VoidCashBox",
        functionType: "POST",
        validateData: true,
        alertOnComplete: true,
        alertText: "The Cash Box Has Been Voided"
      }
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-printer",
      text: "Print",
      eventType: "report",
      report: {
        id: "Accounting/CargoInvoice",
        header: "Cargo Invoice"
      },
      color: Colors.print,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Viewer", "Accounting Administrator", "Accounting Member", "Accounting Viewer"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "CashBoxStatus",
          values: ["Pending",  "Voided"],
          active: false
        },
        {
          key: "AuthorizedUser",
          values: ["No"],
          active: false
        }
      ]
    }
  ],
  caption: "Cash Box",
  editSearchSelector: {
    resource: "GetCashBoxList",
    resourceType: "GET",
    resourceId: ["CashBoxRecordNumber"],
    resourceIdentity: "CashBoxes",
    resourceValue: "CashBoxRecordNumber",
    resourceSelected: "GetCashBox",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CashBox"},
  functionactions: {
    createnew: {
      functionname: "AddCashBox",
      functiontype: "POST",
      resourceIdentity: {
        id: "CashBox",
        mapping: [
          {
            id: "CashBoxRecordNumber",
            key: "CashBoxRecordNumber"
          },
          {
            id: "CashBoxStatus",
            key: "CashBoxStatus"
          },
          {
            id: "CashBoxDate",
            key: "CashBoxDate"
          },
          {
            id: "CashBoxTime",
            key: "CashBoxTime"
          },
          {
            id: "CreatedBy",
            key: "CreatedBy"
          },
          {
            id: "CreatedDate",
            key: "CreatedDate"
          }
        ]
      },
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cash Box Record Created",
      alertText: "Cash Box Record Created"
    },
    update: {
      functionname: "UpdateCashBox",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cash Box Record Updated",
      alertText: "Cash Box Record Updated"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Agency and Particulars",
            fields: [ 
              {
                key: "AgencyId",
                type: "select",
                label: "Station",
                placeholder: "Station",
                defaultValue: "StationPostedBillingAgencyId",
                readOnly: false,
                readOnlyOnEdit: true,
                optionsResource: {
                  resource: "GetActiveCargoStationList",
                  resourceType: "GET",
                  resourceId: ["BillingAgencyId"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 5
                }
              },
              {
                key: "CashBoxNotes",
                type: "textarea",
                label: "Notes",
                placeholder: "Notes",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 255
                }
              },
              {
                key: "CashBoxTotal",
                type: "number",
                format: "$0,0.00",
                label: "Total",
                placeholder: "Total",
                readOnly: true
              }
            ]
          },
          {
            title: "Status",
            fields: [
              {
                key: "CashBoxStatus",
                type: "text",
                label: "Status",
                placeholder: "Status",
                readOnly: true
              },
              {
                key: "InvoiceNumber",
                type: "text",
                label: "Invoice Number",
                placeholder: "Invoice Number",
                readOnly: true
              },
              {
                key: "CashBoxRecordNumber",
                type: "text",
                label: "Record Number",
                placeholder: "Record Number",
                readOnly: true
              }
            ]
          },
          {
            title: "Creation",
            fields: [  
              [{
                key: "CashBoxDate",
                type: "date",
                label: "Cash Box Date",
                placeholder: "Cash Box Date",
                readOnly: true
              },
              {
                key: "CashBoxTime",
                type: "text",
                label: "Cash Box Time",
                placeholder: "Cash Box Time",
                readOnly: true
              }],
              {
                key: "CreatedBy",
                type: "text",
                label: "Created By",
                placeholder: "Created By",
                readOnly: true
              },
              {
                key: "CreatedDate",
                type: "date",
                label: "Date Created",
                placeholder: "Date Created",
                readOnly: true
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "ClosedBy",
                type: "text",
                label: "Closed By",
                placeholder: "Closed By",
                readOnly: true
              },
              {
                key: "ClosedDate",
                type: "date",
                label: "Closed Date",
                placeholder: "Closed Date",
                readOnly: true
              },
              {
                key: "VoidedBy",
                type: "text",
                label: "Voided By",
                placeholder: "Voided By",
                readOnly: true
              },
              {
                key: "VoidedDate",
                type: "date",
                label: "Void Date",
                placeholder: "Void Date",
                readOnly: true
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "tabs",
                readOnlyOnEdit: true,
                tabs: [
                  {
                    caption: "Agent Summary",
                    definition: { ...CashBoxDetailSummaryList }
                  },
                  {
                    caption: "Detail List",
                    definition: { ...CashBoxDetailList }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
