import BindModel from "../../../Core/BindModel";
import CheckBoxStyle from "./CheckBoxStyle";
import { Observer } from "mobx-react";
import React from "react";
import computeAsterisk from "../../actions/computeAsterisk";
import computeValidation from "../../actions/computeValidation";
import { useTheme } from "@emotion/react";

function CheckBox(props) {
  const theme = useTheme();
  return (
    <Observer>
      {() => (
        <CheckBoxStyle theme={theme} data-testid={props.testid}>
          <div className="form-group">
            <a
              style={{ marginTop: "5px" }}
              onClick={(event) => {
                event.preventDefault();
                if(props.ReadOnly!==true)
                {
                props.EntryDetails[props.Config.key] = !props.EntryDetails[
                  props.Config.key
                ];
                }
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <td valign="middle">
                      <div
                        className={`${
                          props.EntryDetails[props.Config.key] === true
                            ? "yesState"
                            : "noState"
                        } float-left checkState`}
                      >
                        <i className="mdi mdi-check" />
                        <span>
                          {props.EntryDetails[props.Config.key] === true
                            ? "Yes"
                            : "No"}
                        </span>
                      </div>
                    </td>
                    <td valign="middle">
                      <label style={{marginTop:"5px"}}>
                        {props.Config.label} {computeAsterisk(props.Config)}
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </a>
            <input
              {...computeValidation(props.Config, props.FormId)}
              {...BindModel(
                props.EntryDetails,
                props.Config.key,
                "value",
                "onChange"
              )}
            />
          </div>
        </CheckBoxStyle>
      )}
    </Observer>
  );
}

export default CheckBox;
