import React, { useEffect, useRef } from 'react';
import { observable } from 'mobx';
import { guid } from '../../utilities';
import { loadData } from '../../utilities';
import { Observer } from 'mobx-react';
import FormLoaderStyle from './FormLoaderStyle';
import ActionBar from '../../components/Core/ActionBar';
import GridStyle from "../../components/Core/Grid/GridStyle";
import moment from 'moment';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  ColumnChooser,
  Inject,
  Page,
  Filter,
  Sort,
  Group,
  DetailRow,
  ExcelExport,
  PdfExport,
  Aggregate,
} from '@syncfusion/ej2-react-grids';

const GridForm = (props) => {
  useEffect(() => {
    updateDataSet();
  }, []);
  const stack = observable([]);
  const gridState = observable({
    adding: false,
    editing: false,
    loading: false,
    selectedItem: null,
    dataSource: [],
  });

  const onStateChanged = (currentState, data) => {
    switch (currentState) {
      case 'Normal':
        gridState.adding = false;
        gridState.editing = false;
        break;
      case 'Add':
        gridState.adding = true;
        gridState.editing = false;
        break;
      case 'Edit':
        gridState.editing = true;
        gridState.adding = false;

        break;
      case 'View':
        gridState.editing = false;
        gridState.adding = false;

        break;
      case 'Cancel':
        gridState.adding = false;
        gridState.editing = false;
        break;
      default:
        gridState.adding = false;
        gridState.editing = false;
        break;
    }
  };
  const updateDataSet = () => {
    gridState.loading = true;
    loadData(
      props.FormDefiniton.editSearchSelector.resource,
      props.FormDefiniton.editSearchSelector.resourceType,
      props.FormDefiniton.editSearchSelector.resourceParameters,
      (response) => {
        if (
          gridInstance.current &&
          response[props.FormDefiniton.editSearchSelector.resourceIdentity]
        ) {
          gridInstance.current.dataSource =
            response[props.FormDefiniton.editSearchSelector.resourceIdentity];
        } else {
          if (gridInstance.current) {
            gridInstance.current.dataSource = [];
          }
        }

        gridState.loading = false;
      }
    );
  };

  //state = {
  //  loading: true
  //};

  const gridInstance = useRef(null);
  const toolbarClick = (args) => {
    switch (args.item.text) {
      case 'PDF Export':
        gridInstance.current.pdfExport();
        break;
      case 'Excel Export':
        gridInstance.current.excelExport();
        break;

      case 'Refresh':
        updateDataSet();
        break;

      default:
        break;
    }
  };

  const onSelection = (data) => {
    gridState.selectedItem = data;
  };

  return (
    <FormLoaderStyle>
      
        <Observer>
          {() => (
            <>
              {stack.length > 0 ? (
                <div className="dialog-holder-stack">
                  {stack.map((dialog) => (
                    <div key={dialog.id}>{dialog.component}</div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </Observer>
        <div
          className={
            props.noAnimation
              ? 'form-holder'
              : 'form-holder animated animate__faster animate__fadeIn'
          }
        >
        <div className="grid-holder-grid">
          <Observer>
            {() => (
              <>
                {gridState.loading ? (
                  <></>
                ) : (
                  <ActionBar
                    FormDefinition={props.FormDefiniton}
                    AdditionalButtons={props.FormDefiniton.customButtons}
                    onStateChanged={onStateChanged}
                    Config={props.Config}
                    reloadParent={updateDataSet}
                    nothingSelected={!gridState.selectedItem}
                    refreshAfterSave={updateDataSet}
                    stack={stack}
                    readOnly={true}
                    ParentData={gridState.selectedItem}
                    Locked={false}
                    willLaunchDialog={true}
                    isDialog={false}
                    isAdding={gridState.adding}
                    isEditing={gridState.editing}
                    EntryDetails={gridState.selectedItem}
                  />
                )}
              </>
            )}
          </Observer>

          <div>
            {gridState.loading ? (
              <></>
            ) : (
              <div className="grid-holder">
              <GridStyle>
                <GridComponent
                  ref={gridInstance}
                  toolbarClick={toolbarClick}
                  allowGrouping={true}
                  toolbar={[
                    'ExcelExport',
                    'PdfExport',
                    {
                      text: 'Refresh',
                      tooltipText: 'Refresh Data',
                      prefixIcon: 'mdi mdi-refresh',
                      id: 'Refresh',
                    },
                    'ColumnChooser',
                  ]}
                  allowExcelExport={true}
                  allowPdfExport={true}
                  groupSettings={{
                    showToggleButton: true,
                    showGroupedColumn: false,
                    showConfirmDialog: false,
                  }}
                  rowSelected={(args) => {
                    onSelection(args.data);
                  }}
                  width="100%"
                  acceptsReturn={true}
                  textWrapSettings={{
                    wrapMode: 'Content',
                  }}
                  allowTextWrap={true}
                  allowSelection={true}
                  allowFiltering={true}
                  allowSorting={true}
                  dataSource={[]}
                  allowPaging={true}
                  showColumnChooser={true}
                  filterSettings={{
                    type: 'Excel',
                  }}
                  pageSettings={{
                    pageSize: 10,
                    pageCount: 5,
                    pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                  }}
                >
                  <ColumnsDirective>
                    {props.FormDefiniton.formDefinition.columns.map(
                      (column, index) => {
                        switch (column.type) {
                          case 'date': {
                            return (
                              <ColumnDirective
                                key={`${guid()}`}
                                field={column.key}
                                format={column.format}
                                textAlign={column.textAlign}
                                headerText={column.label}
                                type={'dateTime'}
                                template={(args) => {
                                  return (
                                    <span>
                                      {args[column.key] &&
                                      args[column.key] != ''
                                        ? moment(args[column.key]).format(
                                            'DD-MMM-YYYY'
                                          )
                                        : ''}
                                    </span>
                                  );
                                }}
                              />
                            );
                          }
                          case 'text': {
                            return (
                              <ColumnDirective
                                key={`${guid()}`}
                                field={column.key}
                                textAlign={column.textAlign}
                                headerText={column.label}
                                type="string"
                                format={column.format}
                              />
                            );
                          }
                          case 'bool': {
                            return (
                              <ColumnDirective
                                key={`${guid()}`}
                                field={column.key}
                                textAlign={column.textAlign}
                                headerText={column.label}
                                type="boolean"
                                format={column.format}
                              />
                            );
                          }
                          default: {
                            return (
                              <ColumnDirective
                                key={`${guid()}`}
                                field={column.key}
                                headerText={column.label}
                                textAlign={column.textAlign}
                                type={column.type}
                                format={column.format}
                              />
                            );
                          }
                        }
                      }
                    )}
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Toolbar,
                      ExcelExport,
                      PdfExport,
                      ColumnChooser,
                      Page,
                      Filter,
                      Sort,
                      Group,
                      DetailRow,
                      Aggregate,
                    ]}
                  />
                </GridComponent>
                </GridStyle>
              </div>
            )}
          </div>
        </div>
      </div>
    </FormLoaderStyle>
  );
};

export default GridForm;
