const dependentShow = (button, props) => {
  if (button.dependentFields) {
    let display = true;
    if (button.dependentFields) {
      button.dependentFields.forEach(dependent => {
        dependent.values.forEach(value => {
          if (
            props.EntryDetails &&
            props.EntryDetails[dependent.key] &&
            props.EntryDetails[dependent.key]
              .toString()
              .trim()
              .toLowerCase() ===
              value
                .toString()
                .trim()
                .toLowerCase()
          ) {
            display = dependent.active;
          }
        });
      });
    }
    return display;
  } else if (button.parentDependentFields) {
    let display = true;
    if (button.parentDependentFields) {
      button.parentDependentFields.forEach(dependent => {
        dependent.values.forEach(value => {
          if (
            props.ParentData &&
            props.ParentData[dependent.key] &&
            props.ParentData[dependent.key]
              .toString()
              .trim()
              .toLowerCase() ===
              value
                .toString()
                .trim()
                .toLowerCase()
          ) {
            display = dependent.active;
          }
        });
      });
    }
    return display;
  }
  return true;
};

export default dependentShow;
