export default {
  "duplicates": [],
  "access": [],
  "track": "BusinessRules",
  "formtype": "dataEntry",
  "allowSearch": [
    {
      "role": [
        "default"
      ],
      "value": true
    }
  ],
  "allowDelete": [
    {
      "role": [
        "default"
      ],
      "value": false
    },
    {
      "role": [
        ""
      ],
      "value": false
    }
  ],
  "allowAudit": [
    {
      "role": [
        "default"
      ],
      "value": true
    },
    {
      "role": [
        ""
      ],
      "value": true
    }
  ],
  "allowEdit": [
    {
      "role": [
        "default"
      ],
      "value": true
    },
    {
      "role": [
        ""
      ],
      "value": true
    }
  ],
  "allowAdd": [
    {
      "role": [
        "default"
      ],
      "value": true
    },
    {
      "role": [
        ""
      ],
      "value": true
    }
  ],
  "allowPrint": [
    {
      "role": [
        "default"
      ],
      "value": false
    }
  ],
  "printreport": "",
  "caption": "Business Rules Details",
  "model": {
    "modelid": "getBusinessRules",
    "autoload": false
  },
  "functionactions": {
    "createnew": {
      "functionname": "addBusinessRules",
      "functiontype": "POST",
      "validateData": true,
      "validationFailedTitle": "",
      "validationFailedText": "",
      "alertOnComplete": true,
      "alertTitle": "Business Rules Information Added",
      "alertText": "The Business Rules Has Been Added"
    },
    "update": {
      "functionname": "updateBusinessRules",
      "functiontype": "POST",
      "validateData": true,
      "validationFailedTitle": "",
      "validationFailedText": "",
      "alertOnComplete": true,
      "alertTitle": "Business Rules Information Updated",
      "alertText": "The Business Rules Has Been Updated"
    },
    "after": {
      "functionname": "getBusinessRulesList",
      "functiontype": "POST"
    }
  },
  "formDefinition": {
    "rows": [
      {
        "columns": [
          {
            "title": "Business Rules Information",
            "fields": [
              {
                "key": "SalesTaxRate",
                "type": "text",
                "defaultvalue": "",
                "label": "Sales Tax Rate",
                "placeholder": "Sales Tax Rate",
                "required": true,
                "readOnly": false,
                "larger": false,
                "isPrimary": false,
                "validation": {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 10
                }
              },
              {
                "key": "CustomsDutyRate",
                "type": "text",
                "defaultvalue": "",
                "label": "Customs Duty Rate",
                "placeholder": "Customs Duty Rate",
                "required": true,
                "readOnly": false,
                "larger": false,
                "isPrimary": false,
                "validation": {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 10
                }
              }
            ]
          }
        ]
      }
    ]
  }
}