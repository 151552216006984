

export default {
  duplicates: [],
  access: [],
  track: "CargoSpecialCustomerRate",
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Special Customer Rate",
  editSearchSelector: {
    resource: "GetCargoSpecialCustomerRateList",
    resourceType: "GET",
    resourceId: ["CargoSpecialCustomerRate"],
    resourceIdentity: "CargoSpecialCustomerRates",
    resourceValue: "CargoSpecialCustomerRate",
    resourceSelected: "GetCargoSpecialCustomerRate",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoSpecialCustomerRate",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "AddCargoSpecialCustomerRate",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Rate Information Added",
      alertText: "The Cargo Rate Has Been Added",
      resourceIdentity: {
        id: "CargoSpecialCustomerRate",
        mapping: [
          {
            id: "CargoSpecialCustomerRateRecordNumber",
            key: "CargoSpecialCustomerRateRecordNumber"
          }
        ]
      }
    },
    update: {
      functionname: "UpdateCargoSpecialCustomerRate",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Rate Information Updated",
      alertText: "The Cargo Rate Has Been Updated"
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [ 
            {
              key: "ServiceType",
              type: "select",
              defaultValue: "",
              label: "Service Type",
              placeholder: "Service Type",
              readOnly: false,
              optionsResource: {
                resource: "GetCargoServiceTypeList",
                resourceType: "GET",
                resourceId: ["CargoServiceType"],
                resourceIdentity: "CargoServiceTypes",
                resourceValue: "CargoServiceType"
              },
              validation: {
                "data-parsley-required": true,
                "data-parsley-minlength": 1,
                "data-parsley-maxlength": 10
              }
            },
            {
                key: "CityFrom",
                type: "select",
                defaultValue: "",
                label: "City From",
                placeholder: "City From",
                readOnly: false,
                optionsResource: {
                  resource: "GetCargoStationListWithAll",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 4
                }
              },
              {
                key: "CityTo",
                type: "select",
                defaultValue: "",
                label: "City To",
                placeholder: "City To",
                readOnly: false,
                optionsResource: {
                  resource: "GetCargoStationListWithAll",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 4
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "PoundRate",
                type: "number",
                format: "$0,0.00",
                defaultValue: "0",
                label: "Pound Rate",
                placeholder: "Pound Rate",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 0,
                  "data-parsley-max": 1000,
                  "data-parsley-maxlength": 10
                }
              },
              {
                key: "DiscountPercentage",
                type: "number",
                defaultValue: "0",
                label: "Discount Percentage",
                placeholder: "Discount Percentage",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 0,
                  "data-parsley-max": 100,
                  "data-parsley-maxlength": 10
                }
              },
              {
                key: "MinimumCharge",
                type: "number",
                format: "$0,0.00",
                defaultValue: "0",
                label: "Minimum Charge",
                placeholder: "Minimum Charge",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 0,
                  "data-parsley-max": 1000,
                  "data-parsley-maxlength": 10
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "BeginDate",
                type: "date",
                defaultValue: "",
                label: "Begin Date",
                placeholder: "Begin Date",
                validation: {
                  "data-parsley-required": true
                }
              },
              {
                key: "EndDate",
                type: "date",
                defaultValue: "",
                label: "End Date",
                placeholder: "End Date"
              },
              {
                key: "CargoSpecialCustomerRateRecordNumber"
              }
            ]
          }
        ]
      }
    ]
  }
};
