import $ from "jquery";
import format from "./format";
import processResults from "./processResults";
import transport from "./transport";
import "select2";
import { observe } from "mobx";

const renderSelect2 = function(defaultValue, setDefault, alldata) {
  
  const _format = format.bind(this);
  const _transport = transport.bind(this);

  try
  {


    observe(this.props.EntryDetails, change => {      
      
        try

        {
        if (change.name === this.props.Config.key) {
          const searchData = {};
          searchData.id = change.newValue;
          let i = null;
          alldata.forEach(item => {
            if (
              item.id
                .toString()
                .toLowerCase()
                .trim() ===
              change.newValue
                .toString()
                .toLowerCase()
                .trim()
            ) {
              i = item;
              return;
            }
          });

          $(this.refs.select2)
            .find(".sl2")
            .select2("data", i);
          setDefault(i);
          this.forceUpdate();
        }

      }
      catch
      {

      }


      })
    }
    catch
    {
      
    }
      
    
  const me = this;

  //$(this.refs.select2holder).find(".select2").select2('destroy');

  $(this.refs.select2)
    .find(".sl2")
    .select2({
      templateResult: _format,
      allowClear: true,
      disabled: this.props.ReadOnly,
      theme: "bootstrap4",
      placeholder: this.props.Config.placeholder,
      width: "resolve",
      ajax: {
        delay: 250,
        data: params => {
          return {
            search: params.term,
            page: params.page || 1
          };
        },
        processResults: (data, params) => {
          const results = processResults(this.props.Config, data, params);

          return results;
          //return data;

          //return {results:data};
        },

        transport: (params, success, failure) => {
          _transport(success, failure);
        }
      }
    })
    .on("select2:opening", () => {
      me.loadedData = null;
    })
    .on("select2:clear", () => {
      me.props.EntryDetails[me.props.Config.key] = undefined;
    })
    .on("select2:select", e => {
      var data = e.params.data;
      setDefault(data);
      
      if (data) {
        me.props.EntryDetails[me.props.Config.key] = data.id;
      } else {
        me.props.EntryDetails[me.props.Config.key] = undefined;
      }
      
    });

  if (defaultValue.length > 0) {
    $(this.refs.select2)
      .find(".sl2")
      .select2("data", defaultValue[0]);
  }
};

export default renderSelect2;
