import React from 'react';
import { guid } from '../../../utilities';

const SmallNav = (props) => {
  return (
    <div className="main-nav">
      <ul>
        <li
          className="main-nav-li collapsemenu"
          data-balloon="Expand Menu"
          data-balloon-pos="right"
        >
          <button
            onClick={props.onToggle}
            className="collapsemenubutton"
            type="button"
          >
            <div className="menu-start" />
            <i className="icon-menu" />
          </button>
        </li>
        {props.MenuSystem.map((menu) => {
          return (
            <li
              className="main-nav-li"
              key={`${guid()}`}
              data-balloon={menu.name}
              data-balloon-pos="right"
            >
              <button
                className={menu === props.activeMenu ? 'active-main' : ''}
                type="button"
                onClick={() => {
                  props.onMenuClick(menu);
                }}
              >
                <div className="menu-start" />
                <i className={menu.icon} />
              </button>
            </li>
          );
        })}

        <li
          className="main-nav-li"
          style={{
            borderTop: '1px solid rgba(255,255,255,0.1)',
            marginTop: '15px',
          }}
          data-balloon="Start"
          data-balloon-pos="right"
        >
          <button
            className={props.activeStart ? 'active-main' : ''}
            type="button"
            onClick={() => {
              props.ShowStart();
            }}
          >
            <div className="menu-start" />
            <i className="mdi mdi-view-dashboard-outline" />
          </button>
        </li>
        <li
          className="main-nav-li"
          style={{
            borderTop: '1px solid rgba(255,255,255,0.1)',
            marginTop: '15px',
          }}
          data-balloon="Voice Command"
          data-balloon-pos="right"
        >
          {'SpeechRecognition' in window ||
          'webkitSpeechRecognition' in window ? (
            <button
              className={
                props.activeSpeech
                  ? 'active-main'
                  : props.recording
                  ? 'pulse'
                  : ''
              }
              type="button"
              onClick={() => {
                props.recording
                  ? props.stopRecording()
                  : props.startRecording();
              }}
            >
              <div className={'menu-start'} />
              <i className="icon-mic" />
            </button>
          ) : (
            <></>
          )}
        </li>
      </ul>
    </div>
  );
};

export default SmallNav;
