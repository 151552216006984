
export default {
  duplicates: [],
  access: [],
  track: "Aircraft",
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Aircraft",
  editSearchSelector: {
    resource: "GetAircraftList",
    resourceType: "GET",
    resourceId: ["Aircraft"],
    resourceIdentity: "Aircrafts",
    resourceValue: "RegistrationNumber",
    resourceSelected: "GetAircraft",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "Aircraft",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "AddAircraft",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Aircraft Information Added",
      alertText: "The Aircraft Has Been Added"
    },
    update: {
      functionname: "UpdateAircraft",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Aircraft Information Updated",
      alertText: "The Aircraft Has Been Updated"
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Aircraft Information",
            fields: [ 
            {
              key: "RegistrationNumber",
              type: "text",
              defaultValue: "",
              label: "Registration Number",
              placeholder: "Registration Number",
              readOnly: false,
              readOnlyOnEdit: true,
              validation: {
                "data-parsley-required": true,
                "data-parsley-minlength": 1,
                "data-parsley-maxlength": 8
              }
            },
            {
                key: "AircraftType",
                type: "text",
                defaultValue: "",
                label: "Aircraft Type",
                placeholder: "Aircraft Type",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 10
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "MaxTakeOffWeight",
                type: "number",
                label: "Max Take Off Weight",
                placeholder: "Max Take Off Weight",
                readOnly: false,
                validation: {
                  "data-parsley-min": 0,
                  "data-parsley-max": 1000000
                }                
              },
              {
                key: "MaxLandingWeight",
                type: "number",
                label: "Max Landing Weight",
                placeholder: "Max Landing Weight",
                readOnly: false,
                validation: {
                  "data-parsley-min": 0,
                  "data-parsley-max": 1000000
                }                
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "ActiveAircraft",
                type: "checkbox",
                label: "Active",
                placeholder: "Active",
                readOnly: false
              }
            ]
          }
        ]
      }
    ]
  }
};
