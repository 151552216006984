import chroma from 'chroma-js';
import styled from '@emotion/styled';

const FormLoaderStyle = styled.div`
  a {
    text-decoration: none !important;
  }
  button {
    outline: 0 !important;
  }
  /* Old browsers */
  background: ${(props) => props.theme.backgroundDark};
  .form-holder {
    width: 100%;

    padding-top: 0;
    background: ${(props) => props.theme.backgroundDark};
  }
  .form-group {
    
  }
  .row {
    margin: 0;
    padding:0;
  }

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
  }

  .subfield
  {
    .container{
      padding:0;
      margin:0;
    }
    .row{
      padding:0;
      margin:0;
    }
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
      padding-right: 0px;
      padding-left: 0px;
    } 

    .subitem
    {
      padding-left:30px;
      &:first-of-type{
        padding-left:0;
      }
    }
  }
  
  .card {
    border: 0px solid transparent;
    margin:10pt;
    padding: 10pt;
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    border-radius:3px;
  }
  .card-holder {
    
  }

  h2 {
    font-family: ${(props) => props.theme.headerFont};
    font-size: 10pt;
    min-height: 10pt;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
    color: ${(props) => props.theme.accent};
    width: 100%;
    display: block;
    
    padding-bottom: 3pt;
  }


  .overflow,
  .circle-line {
    position: relative;
  }
  .overflow {
    height:31px;
  }

  .circle-line {
    width: 31px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .circle-red,
  .circle-green,
  .circle-yellow,
  .circle-blue {
    position: relative;
    width: 4px;
    height: 4px;
    border-radius: 10px;
    margin: 1px;
  }

  .circle-red {
    background-color: ${(props) => props.theme.accent};
    animation: movingUp 0.5s infinite alternate;
  }

  .circle-blue {
    background-color: ${(props) => props.theme.accent};
    animation: movingUp 0.5s 0.2s infinite alternate;
  }

  .circle-green {
    background-color: ${(props) => props.theme.accent};
    animation: movingUp 0.5s 0.5s infinite alternate;
  }

  .circle-yellow {
    background-color: ${(props) => props.theme.accent};
    animation: movingUp 0.5s 0.7s infinite alternate;
  }

  /* CSS ANIMATION */

  @keyframes movingUp {
    from {
      top: 0px;
    }

    to {
      top: -12px;
    }
  }

  .itemcontainer
  {
    margin-top:10.5pt;
  }

  label {
    font-family: ${(props) => props.theme.mainFont}!important;
    font-weight:600;
    font-size:8.5pt;
    margin-bottom:3.5pt;
    
    text-transform: uppercase;
    color: ${(props) => props.theme.textBlack};
    text-overflow: ellipsis;
    white-space: nowrap;
    width:100%;
    display:block;
    overflow:hidden;
  }

 

.element-container
{
  border-bottom:1pt solid #000000;
  margin-bottom:1pt;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow {
  height:24px!important;
}
.form-group{
    min-height: 0px;
}

  .form-control {
    font-size:10pt;
    padding: 1.5pt 2pt!important;
    margin-top: 1px;
    border-radius:3px;
    color: ${(props) => props.theme.textBlack}!important;
    height:25px;
    font-family: ${(props) => props.theme.mainFont}!important;
    
    background: ${(props) => props.theme.backgroundLight};
    
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    outline: none;
    
  }

  input.e-input, .e-input-group input, .e-input-group.e-control-wrapper input, .e-float-input.e-control-wrapper input, .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input, .e-float-input input, .e-float-input.e-control-wrapper input {
    height:24px;
    
  }
  .e-input-group-icon.e-date-icon, .e-control-wrapper .e-input-group-icon.e-date-icon
  {
    height:24px;
    min-height:24px!important;
  }
  .e-input-group-icon.e-date-icon, .e-control-wrapper .e-input-group-icon.e-date-icon {
    height:25px;
    
    font-size:13px;
  }

  .e-input-group, .e-input-group.e-control-wrapper {
    height:25px;
  }
  
  .form-control:read-only {
    background: ${(props) => props.theme.backgroundDark}!important;
  }
  .form-control:focus {
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    outline: 0;
    outline-color: initial;
    outline-style: initial;
    outline-width: 0px;
    box-shadow: none;
  }

  .form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(props) => props.theme.placeHolder}!important;
    opacity: 1; /* Firefox */
  }

  .e-input-group {
    border-color: ${(props) => props.theme.backgroundDarker}!important;
  }

  
  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input {
    border-color: #eee !important;
    font-family: ${(props) => props.theme.mainFont}!important;
    background: ${(props) => props.theme.backgroundLight};
    color: ${(props) => props.theme.textBlack} !important;
  }

  .e-input[disabled] {
    background: ${(props) => props.theme.backgroundDark}!important;
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    background: ${(props) => props.theme.backgroundDark};
    border-left: 1px solid ${(props) => props.theme.backgroundDarker};
  }

  .addButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.add2};
    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.add2};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .removeButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: #95000c;
    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: #95000c;
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .searchButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.search};
    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.search};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }
  .grid-menu {
    
    
  }
  .editButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.edit2};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.edit2};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }
  .printButton {
    margin-right: 20px;
    cursor: pointer;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.print};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.print};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight};
    }
  }

  .requestButton {
    margin-right: 20px;
    cursor: pointer;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.request};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.request};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight};
    }
  }
  .viewButton {
    margin-right: 20px;

    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.view};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.view};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .verifyButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.verify};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.verify};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .processButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.process};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.process};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .removeButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.edit2};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.edit2};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  

  .ql-toolbar.ql-snow {
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    background: ${(props) => props.theme.backgroundDark};
  }

  .ql-container.ql-snow {
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    
  }

  .ql-editor {
    background: ${(props) => props.theme.backgroundLight};
  }
  .ql-disabled {
    .ql-editor {
      background: ${(props) => props.theme.backgroundDark};
    }
  }
  .parsley-errors-list {
    list-style: none;
    margin: 5px 0 0 0;
    padding: 0;
    font-size: 8px;
    font-weight: 600;
    color: #ad0400;
    text-transform: uppercase;
  }

 

  .form-group {
    margin-bottom: 0px !important;
  }
`;

export default FormLoaderStyle;
