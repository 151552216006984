import { useEffect, useState } from "react";
import removeParsley from "./removeParsley";
import moment from "moment";
import { toJS } from "mobx";
import $ from "jquery";
import "parsleyjs";

export function useParsley(obj) {
  useEffect(() => {
    $(`#${obj.element}`).parsley({
      errorsContainer: (el) => {
        return el.$element.closest(".form-group");
      },
    });

    const parsleyObject = $(`#${obj.element}`).parsley();

    if (parsleyObject && window.Parsley) {
      // verify if departure and destination city are the different
      const validator = window.Parsley;
      setupCustomValidator(validator, obj);
    }
    return () => {
      removeParsley(obj.element);
    };
  });

  return true;
}

export function setupCustomValidator(validator, obj) {
  const parsleyElement = obj.element
    ? obj.element.replace(new RegExp("-", "g"), "")
    : "";
  validator.addValidator(`differentcityfromandcityto${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const CityFrom = currentData.CityFrom;
      const CityTo = currentData.CityTo;

      // return true if the cities are not the same
      if (
        CityFrom &&
        CityTo &&
        CityFrom.toString().toLowerCase() !== CityTo.toString().toLowerCase()
      ) {
        return true;
      }

      // fail if no true condition is met
      return false;
    },

    messages: {
      en: "Departure and Destination Cities Must Be Different.",
    },
  });

  // check that the receive date cannot be after the current date

  validator.addValidator(`maxdropoffdate${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const DropoffDate = currentData.DropoffDate;

      var CurrentDate = new Date();
      var dd = CurrentDate.getDate();
      var mm = CurrentDate.getMonth() + 1; //January is 0!
      var yyyy = CurrentDate.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      CurrentDate = mm + "/" + dd + "/" + yyyy;

      // return true if no received date is entered
      if (DropoffDate === undefined || CurrentDate === undefined) {
        return true;
      }

      // return the result of th
      if (
        DropoffDate.toString().trim().length > 0 &&
        CurrentDate.toString().trim().length > 0
      ) {
        const dDate = moment(DropoffDate).toDate();
        const cDate = moment(CurrentDate).toDate();

        return cDate >= dDate;
      }

      // return true if no error condition is met
      return true;
    },

    messages: {
      en: "Dropoff date cannot be after the current date.",
    },
  });

  // verify if quantity of packages is 1 if the service type has a flat rate

  validator.addValidator(`flatratequantity${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const QuantityOfPackages = parseInt(currentData.QuantityOfPackages, 10);
      const FlatRateCharge = currentData.FlatRateCharge;

      if (!FlatRateCharge) {
        return true;
      }

      // return true if the service type does not have a flat rate charge
      if (FlatRateCharge.toString().toLowerCase().trim() === "no") {
        return true;
      }

      // return true if the quantity is one
      if (QuantityOfPackages && QuantityOfPackages === 1) {
        return true;
      }

      // fail if no true condition is met
      return false;
    },

    messages: {
      en: "Quantity Must Be Equal to 1 For This Service Type.",
    },
  });

  // verify if purchase order is required for the charge account

  validator.addValidator(`purchaseorderrequired${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const PaymentType = currentData.PaymentType;
      const BillingAgent = currentData.BillingAgent;
      const PurchaseOrderRequired = currentData.PurchaseOrderRequired;
      const PurchaseOrderNumber = currentData.PurchaseOrderNumber;

      // exit if no payment type is entered
      if (!PaymentType) {
        return true;
      }

      // exit if the payment type is not charge
      if (
        PaymentType &&
        PaymentType.toString().toLowerCase().trim() !== "charge"
      ) {
        return true;
      }

      // exit if no billing agent is entered
      if (!BillingAgent) {
        return true;
      }

      // exit if no indicator if purchase order is required
      if (!PurchaseOrderRequired) {
        return true;
      }

      // exit if purchase order number is not required
      if (
        PurchaseOrderRequired &&
        PurchaseOrderRequired.toString().toLowerCase().trim() === "n"
      ) {
        return true;
      }

      // exit if a purchase order number is entered
      if (PurchaseOrderNumber && PurchaseOrderNumber.trim().length > 0) {
        return true;
      }

      // fail if no true condition is met
      return false;
    },

    messages: {
      en: "Purchase Order Number Must Be Entered For This Customer.",
    },
  });

  // ensure that charge is not made to a station

  validator.addValidator(`nostationcharge${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const PaymentType = currentData.PaymentType;
      const BillingAgent = currentData.BillingAgent;
      const TypeCode = currentData.TypeCode;

      // exit if no payment type is entered
      if (!PaymentType) {
        return true;
      }

      // exit if the payment type is not charge
      if (
        PaymentType &&
        PaymentType.toString().toLowerCase().trim() !== "charge"
      ) {
        return true;
      }

      // exit if no billing agent is entered
      if (!BillingAgent) {
        return true;
      }

      // exit if no indicator if type code is entered
      if (!TypeCode) {
        return true;
      }

      // exit if no indicator if purchase order is required
      if (TypeCode && TypeCode.toString().toLowerCase().trim() !== "s") {
        return true;
      }

      // fail if no true condition is met
      return false;
    },

    messages: {
      en: "Charge Cannot Be Placed On This Account.",
    },
  });

  // verify that the recommended adjustment is not more than the shipping cost

  validator.addValidator(`verifyrecommendedadjustment${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const ShippingCost = parseFloat(currentData.ShippingCost);
      const RecommendedAdjustment = parseFloat(
        currentData.RecommendedAdjustment
      );

      if (ShippingCost >= RecommendedAdjustment) {
        return true;
      }

      // fail if no true condition is met
      return false;
    },

    messages: {
      en: "Recommended Adjustment Cannot Be More Than The Shipping Cost.",
    },
  });

  // check that the flight time is valid

  validator.addValidator(`validflighttime${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const FlightTime = currentData.FlightTime;

      // return true if no flight time is entered
      if (FlightTime === undefined) {
        return true;
      }
      if (FlightTime.length == 5) {
        return moment(FlightTime, "HH:mm", true).isValid();
      } else {
        return moment(FlightTime, "HH:mm:ss", true).isValid();
      }
    },

    messages: {
      en: "Flight Time Must Be a Valid Time.",
    },
  });

  // verify if passcode and the confirmed passcode are the same

  validator.addValidator(`confirmpasscode${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const PassCode = currentData.PassCode;
      const ConfirmPassCode = currentData.ConfirmPassCode;

      // return true the passcode and confirmed passcode are the same
      if (
        PassCode &&
        ConfirmPassCode &&
        PassCode.toString() === ConfirmPassCode.toString()
      ) {
        return true;
      }

      // fail if no true condition is met
      return false;
    },

    messages: {
      en: "New password and confirmation password must be the same.",
    },
  });

  // verify that the recommended new quantity of packages is different from the old
  // quantity of packages

  validator.addValidator(`differentpackagequantities${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const QuantityOfPackages = parseInt(currentData.QuantityOfPackages);
      const NewQuantityOfPackages = parseInt(currentData.NewQuantityOfPackages);

      if (QuantityOfPackages !== NewQuantityOfPackages) {
        return true;
      }

      // fail if no true condition is met
      return false;
    },

    messages: {
      en: "New quantity of packages must be different from existing.",
    },
  });

  validator.addValidator(`chargebillingagentrequired${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const PaymentType = currentData.PaymentType;
      const BillingAgent = currentData.BillingAgent;

      // exit if no payment type is entered
      if (!PaymentType) {
        return true;
      }

      // exit if the payment type is not charge
      if (
        PaymentType &&
        PaymentType.toString().toLowerCase().trim() === "cod"
      ) {
        return true;
      }

      // exit if no billing agent is entered
      if (BillingAgent) {
        return true;
      }

      // fail if no true condition is met
      return false;
    },

    messages: {
      en: "Billing Agent Must Be Enterd to Charge Account.",
    },
  });

  // verify that cargo payment amount is not more than the amount due on the invoice

  validator.addValidator(`validcargopaymentamount${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const AmountDue = parseFloat(currentData.AmountDue);
      const PaymentAmount = parseFloat(currentData.PaymentAmount);

      if (AmountDue >= PaymentAmount) {
        return true;
      }

      // fail if no true condition is met
      return false;
    },

    messages: {
      en: "Payment Amount Cannot Be More Than The Amount Due.",
    },
  });
  // verify that cargo adjustment amount is not more than the amount due on the invoice

  validator.addValidator(`validemployeecargoadjustmentamount${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const InvoiceAmount = parseFloat(currentData.InvoiceAmount);
      const AdjustmentAmount = parseFloat(currentData.AdjustmentAmount);

      if (InvoiceAmount >= AdjustmentAmount) {
        return true;
      }

      // fail if no true condition is met
      return false;
    },

    messages: {
      en: "Adjustment Amount Cannot Be More Than The Invoice Amount.",
    },
  });

  // verify that only the adjustment amount or percentage is entered.  Not both
  validator.addValidator(`singleadjustmentcriteria${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const AdjustmentAmount = parseFloat(currentData.AdjustmentAmount);
      const AdjustmentPercentage = parseFloat(currentData.AdjustmentPercentage);

      if (AdjustmentAmount > 0.00 && AdjustmentPercentage > 0.00)
      {
        return false;
      }
      else
      {
        return true;
      }
    },

    messages: {
      en: "Only Adjustment Amount OR Percentage Can Be Entered.",
    },
  });

  // verify that the adjustment amount is not more than the invoice amount
  validator.addValidator(`validairwaybilladjustmentamount${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const AirwaybillBalance = parseFloat(currentData.AirwaybillBalance);
      const AdjustmentAmount = parseFloat(currentData.AdjustmentAmount);

      if (AirwaybillBalance >= AdjustmentAmount) {
        return true;
      }

      // fail if no true condition is met
      return false;
    },

    messages: {
      en: "Adjustment Amount Cannot Be More Than The Airwaybill Balance.",
    },
  });

  

  validator.addValidator(`employeediscountrequired${parsleyElement}`, {
    validate: () => {
      const currentData = toJS(obj.data);
      const TypeCode = currentData.TypeCode;
      const MonthlyCargoDiscountAmount = parseFloat(currentData.MonthlyCargoDiscountAmount);

      // exit if no business type is entered
      if (!TypeCode) {
        return true;
      }

      // exit if the business type is not for employee
      if (TypeCode &&
        TypeCode.toString().toLowerCase().trim() != "e")
      {
        return true;
      }

      // exit if monthly cargo amount is entered
      if (MonthlyCargoDiscountAmount > 0.00) {
        return true;
      }

      // fail if no true condition is met
      return false;
    },

    messages: {
      en: "Discount Amount Must Be Entered For Employees.",
    },
  });

  
}
