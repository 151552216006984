import styled from '@emotion/styled';

const TabComponentStyle = styled.div`
 
  button {
    outline: 0 !important;
  }

  

  .Panel {
    width: 100%;
  }

  .Panel__body {
    & > div > div > div > .form-holder {
      background: ${(props) => props.theme.backgroundLight};
      & > .card-holder {
        border: 0px solid transparent;
        margin: 0;
        & > .card {
          padding: 0;
        }
      }
    }
  }
  .Tabs-holder{
    position: relative;
    
    
  }
  .Tabs {
   
    position: relative;
    
    background: ${(props) => props.theme.backgroundDark};
    margin: 0;
    padding:0;
    list-style: none;
  }

  .Tabs:after {
    content: ' ';
    display: table;
    clear: both;
  }

  .Tabs__tab {
    float: left;

    text-align: center;
  }

  

  .Tabs__presentation-slider {
    position: absolute;
    bottom: 0;
    left: 0;

    height: 0px;
    background: ${(props) => props.theme.backgroundLight};
    -webkit-transition: left 0.25s;
    transition: left 0.25s;
  }

.Tab:first-of-type {
    border-left:0 solid ${(props) => props.theme.backgroundLight};
    padding:5px;
  }

  .Tab {
    border-left:1pt solid ${(props) => props.theme.backgroundLight};
    padding:5px;
    
  }

  .Tab:nth-last-of-type(2){
      border-right:0px solid transparent!important;
    }

  .Tab > span {
    display: block;
    
    text-decoration: none;
    
    font-size: 8.5pt;
    font-weight: 600;
    cursor:pointer;
    text-align:center;
    text-transform: uppercase;
    color: ${(props) => props.theme.accent};
    -webkit-transition: color 0.15s;
    transition: all 0.3s;
  }
  .Tab.active
  {
    background: ${(props) => props.theme.accent};
    
  }
  .Tab.active > span {
    color: ${(props) => props.theme.backgroundLight};
    
  }

  
`;

export default TabComponentStyle;
