export default {
    duplicates: [],
    access: [],
    track: "",
    formtype: "dataEntry",
  
    formDefinition: {
      rows: [
        {
          columns: [
            {
              title: "\n",
              fields: [
                {
                  key: "MajorCategory",
                  type: "select",
                  defaultvalue: "",
                  label: "Major Category",
                  placeholder: "Major Category",
                  required: true,
                  readOnly: false,
                  larger: false,
                  isPrimary: true,
                  optionsResource: {
                    resource: "GetUserDocumentationMajorCategoryList",
                    resourceType: "GET",
                    resourceId: ["MajorCategory"],
                    resourceIdentity: "MajorCategories",
                    resourceValue: "MajorCategory"
                  }               
                },
                {
                  key: "MinorCategory",
                  type: "select",
                  defaultvalue: "",
                  label: "Minor Category",
                  placeholder: "Minor Category",
                  required: true,
                  readOnly: false,
                  larger: false,
                  isPrimary: false,
                  optionsResource: {
                    resource: "GetUserDocumentationMinorCategoryListByMajorCategory",
                    resourceType: "POST",
                    resourceId: ["MinorCategory"],
                    resourceIdentity: "MinorCategories",
                    resourceValue: "MinorCategory"
                  }               
                },
                {
                  key: "DocumentationTopic",
                  type: "text",
                  defaultvalue: "",
                  label: "Topic",
                  placeholder: "Topic",
                  required: true,
                  readOnly: false,
                  larger: false,
                  isPrimary: false                 
                }
              ]
            }
          ]
        }    
      ]
    }
  };
  