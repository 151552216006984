import 'parsleyjs';

const removeParsley = (element) => {
 

  const parsleyElement = element
    ? element.replace(new RegExp('-', 'g'), '')
    : '';
 
  if (window.Parsley) {
    const validator = window.Parsley;    

    if (!validator.hasValidator(`differentcityfromandcityto${parsleyElement}`)) {
      validator.removeValidator(`differentcityfromandcityto${parsleyElement}`);
    }

    if (!validator.hasValidator(`maxdropoffdate${parsleyElement}`)) {
      validator.removeValidator(`maxdropoffdate${parsleyElement}`);
    }

    if (!validator.hasValidator(`flatratequantity${parsleyElement}`)) {
      validator.removeValidator(`flatratequantity${parsleyElement}`);
    }

    if (!validator.hasValidator(`purchaseorderrequired${parsleyElement}`)) {
      validator.removeValidator(`purchaseorderrequired${parsleyElement}`);
    }

    if (!validator.hasValidator(`nostationcharge${parsleyElement}`)) {
      validator.removeValidator(`nostationcharge${parsleyElement}`);
    }

    if (!validator.hasValidator(`validflighttime${parsleyElement}`)) {
      validator.removeValidator(`validflighttime${parsleyElement}`);
    }

    if (!validator.hasValidator(`verifyrecommendedadjustment${parsleyElement}`)) {
      validator.removeValidator(`verifyrecommendedadjustment${parsleyElement}`);
    }

    if (!validator.hasValidator(`confirmpasscode${parsleyElement}`)) {
      validator.removeValidator(`confirmpasscode${parsleyElement}`);
    }

    if (!validator.hasValidator(`differentpackagequantities${parsleyElement}`)) {
      validator.removeValidator(`differentpackagequantities${parsleyElement}`);
    }

    if (!validator.hasValidator(`chargebillingagentrequired${parsleyElement}`)) {
      validator.removeValidator(`chargebillingagentrequired${parsleyElement}`);
    }

    if (!validator.hasValidator(`validcargopaymentamount${parsleyElement}`)) {
      validator.removeValidator(`validcargopaymentamount${parsleyElement}`);
    }

    if (!validator.hasValidator(`validemployeecargoadjustmentamount${parsleyElement}`)) {
      validator.removeValidator(`validemployeecargoadjustmentamount${parsleyElement}`);
    }

    if (!validator.hasValidator(`singleadjustmentcriteria${parsleyElement}`)) {
      validator.removeValidator(`singleadjustmentcriteria${parsleyElement}`);
    }

    if (!validator.hasValidator(`validairwaybilladjustmentamount${parsleyElement}`)) {
      validator.removeValidator(`validairwaybilladjustmentamount${parsleyElement}`);
    }

    if (!validator.hasValidator(`employeediscountrequired${parsleyElement}`)) {
      validator.removeValidator(`employeediscountrequired${parsleyElement}`);
    }

  }
  
};

export default removeParsley;
