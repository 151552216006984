import onAdd from './onAdd';
import onCancel from './onCancel';
import onEdit from './onEdit';
import onCreateNew from './onCreateNew';
import onCalculate from './onCalculate';
import onUpdateExisting from './onUpdateExisting';
import onLaunchDialog from './onLaunchDialog';
import onClose from './onClose';
import onView from './onView';
import executeApi from './executeApi';
import executeForm from './executeForm';
import executeReport from './executeReport';
import executeFormDependent from './executeFormDependent';
import executeReportDependent from './executeReportDependent';

const actions = {
    createNew: onCreateNew,
    updateExisting: onUpdateExisting,
    close: onClose,
    cancel: onCancel,
    view: onView,
    add: onAdd,
    edit: onEdit,
    form: executeForm,
    formDependent: executeFormDependent,
    report: executeReport,
    reportDependent: executeReportDependent,
    api:executeApi,
    calculate: onCalculate
  };

export default actions;