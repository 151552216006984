import React, { PureComponent } from "react";
import loadIn from "./actions/loadIn";
import PropTypes from 'prop-types';

export default class LargeLoader extends PureComponent {
  constructor(props) {
    super(props);    
    if (props.Triggers) {
      props.Triggers.onShowLoader = loadIn.bind(this);      
    }   
  }
  render() {
    return (
      <div className="hidden" ref="loaderHolder">

      </div>
    );
  }
}

LargeLoader.protoTypes = {
  OnReady: PropTypes.func.isRequired,
  OnComplete: PropTypes.func.isRequired,
  Text:PropTypes.string,
  Triggers:PropTypes.object
}
