export default {
  duplicates: [],
  access: [],
  track: "FlightNumber",
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true,
    },
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: ["Cargo Administrator"],
      value: true,
    },
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: ["Cargo Administrator"],
      value: true,
    },
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false,
    },
  ],
  printreport: "",
  caption: "FlightNumber",
  editSearchSelector: {
    resource: "GetFlightNumberList",
    resourceType: "GET",
    resourceId: ["FlightNumber"],
    resourceIdentity: "FlightNumbers",
    resourceValue: "FlightNumber",
    resourceSelected: "GetFlightNumber",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "FlightNumber",
    resourceDisplayFields: [],
  },
  functionactions: {
    createnew: {
      functionname: "AddFlightNumber",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Flight Number Information Added",
      alertText: "The Flight Number Has Been Added",
    },
    update: {
      functionname: "UpdateFlightNumber",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Flight Number Information Updated",
      alertText: "The Flight Number Has Been Updated",
    },
    after: {},
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Flight Number Information",
            fields: [
              {
                key: "FlightNumber",
                type: "text",
                defaultValue: "",
                label: "Flight Number",
                placeholder: "Flight Number",
                readOnly: false,
                readOnlyOnEdit: true,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 6,
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "ActiveFlightNumber",
                type: "checkbox",
                label: "Active",
                placeholder: "Active",
                readOnly: false,
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                type: "spacer"
              }
            ]
          }
        ]
      }
    ]
  }
};
