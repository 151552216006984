import { toJS } from "mobx";

const computeShow = (button, prop) => {
  let data = [];
  const entryDetails = toJS(prop.EntryDetails);
  if (prop.FormDefinition && prop.FormDefinition.calculateForm === true) {
    if (button.calc === true) {
      return true;
    } else {
      return false;
    }
  } else {
    if (button.calc === true) {
      return false;
    }
  }

  
  if (entryDetails instanceof Array) {
    data = entryDetails;
  } else {
    data.push(entryDetails);
  }

  const props = { ...prop, EntryDetails: data };

  const formSenarios = {
    readOnlyMode: () => {
      if (button.showInEditableMode === true) {
        if (props.readOnly === true && props.Locked !== true) {
          if (
            props.nothingSelected !== true &&
            button.requiresSelection === true
          ) {
            if (
              button.hideInWillLaunch === true &&
              props.willLaunchDialog === true
            ) {
              return false;
            }
            if (button.showOnWillLaunch === true) {
              if (props.willLaunchDialog !== true) {
                return false;
              }
            }
            if (
              props.EntryDetails &&
              props.EntryDetails.length > 1 &&
              props.runMultiple !== true
            ) {
              return false;
            }
            return true;
          } else if (button.requiresSelection !== true) {
            if (
              button.hideInWillLaunch === true &&
              props.willLaunchDialog === true
            ) {
              return false;
            }
            if (button.showOnWillLaunch === true) {
              if (props.willLaunchDialog !== true) {
                return false;
              }
            }

            return true;
          }
        }
      }
      return false;
    },

    locked: () => {
      if (button.showInLocked === true) {
        if (props.Locked === true) {
          if (
            props.nothingSelected !== true &&
            button.requiresSelection === true
          ) {
            if (
              button.hideInWillLaunch === true &&
              props.willLaunchDialog === true
            ) {
              return false;
            }
            if (button.showOnWillLaunch === true) {
              if (props.willLaunchDialog !== true) {
                return false;
              }
            }

            if (
              props.EntryDetails &&
              props.EntryDetails.length > 1 &&
              props.runMultiple !== true
            ) {
              return false;
            }

            return true;
          } else if (button.requiresSelection !== true) {
            if (button.showOnWillLaunch === true) {
              if (
                button.hideInWillLaunch === true &&
                props.willLaunchDialog === true
              ) {
                return false;
              }
              if (props.willLaunchDialog !== true) {
                return false;
              }
            }
            return true;
          }
        }
      }
      return false;
    },

    adding: () => {
      if (button.showInAddMode === true) {
        if (props.isAdding === true && props.Locked !== true) {
          if (
            props.nothingSelected !== true &&
            button.requiresSelection === true
          ) {
            if (
              button.hideInWillLaunch === true &&
              props.willLaunchDialog === true
            ) {
              return false;
            }
            if (button.showOnWillLaunch === true) {
              if (props.willLaunchDialog !== true) {
                return false;
              }
            }
            if (
              props.EntryDetails &&
              props.EntryDetails.length > 1 &&
              props.runMultiple !== true
            ) {
              return false;
            }
            if (button.saveAndNew === true && props.saveAndNew !== true) {
              return false;
            }
            if (button.hideSaveAndNew === true && props.saveAndNew === true) {
              return false;
            }
            return true;
          } else if (button.requiresSelection !== true) {
            if (
              button.hideInWillLaunch === true &&
              props.willLaunchDialog === true
            ) {
              return false;
            }
            if (button.showOnWillLaunch === true) {
              if (props.willLaunchDialog !== true) {
                return false;
              }
            }
            if (
              props.EntryDetails &&
              props.EntryDetails.length > 1 &&
              props.runMultiple !== true
            ) {
              return false;
            }
            if (button.saveAndNew === true && props.saveAndNew !== true) {
              return false;
            }
            if (button.hideSaveAndNew === true && props.saveAndNew === true) {
              return false;
            }
            return true;
          }
        }
      }
      return false;
    },
    editing: () => {
      if (button.showInEditMode === true) {
        if (props.isEditing === true && props.Locked !== true) {
          if (
            props.nothingSelected !== true &&
            button.requiresSelection === true
          ) {
            if (
              button.hideInWillLaunch === true &&
              props.willLaunchDialog === true
            ) {
              return false;
            }
            if (button.showOnWillLaunch === true) {
              if (props.willLaunchDialog !== true) {
                return false;
              }
            }
            return true;
          } else if (button.requiresSelection !== true) {
            if (
              button.hideInWillLaunch === true &&
              props.willLaunchDialog === true
            ) {
              return false;
            }
            if (button.showOnWillLaunch === true) {
              if (props.willLaunchDialog !== true) {
                return false;
              }
            }
            return true;
          }
        }
      }
      return false;
    },
  };

  let showState = false;
  for (var senario in formSenarios) {
    if (props.isDialog === true) {
      if (formSenarios[senario]() === true && button.showInDialog === true) {
        return true;
      }
    } else {
      if (formSenarios[senario]() === true && button.onlyInDialog !== true) {
        return true;
      }
    }
  }

  if (showState) {
    return true;
  }
  return false;
};

export default computeShow;
