export default {
  duplicates: [],
  access: [],
  track: "Airwaybill",
  formtype: "dataEntry",
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                key: "AgencyId",
                type: "text",
                defaultvalue: "",
                label: "Agency Id",
                placeholder: "Agency Id"
              },
              {
                key: "AgencyName",
                type: "text",
                defaultvalue: "",
                label: "Name",
                placeholder: "Name"
              },
              {
                key: "TypeCode",
                type: "select",
                defaultvalue: "",
                label: "Account Type",
                placeholder: "Account Type",
                optionsResource: {
                  resource: "GetAccountTypeList",
                  resourceType: "GET",
                  resourceId: ["TypeCode"],
                  resourceIdentity: "AccountTypes",
                  resourceValue: "Description"
                }
              }
            ]
          },
          {
            title: '\n',
            fields: [
              {
                key: "AccountRep",
                type: "text",
                label: "Account Rep.",
                placeholder: "Account Rep."
              },
              {
                key: "CargoInvoiceFrequencyType",
                type: "select",
                defaultvalue: "",
                label: "Invoice Frequency",
                placeholder: "Invoice Frequency",
                optionsResource: {
                  resource: "GetCargoInvoiceFrequencyTypeList",
                  resourceType: "GET",
                  resourceId: ["CargoInvoiceFrequencyType"],
                  resourceIdentity: "CargoInvoiceFrequencyTypes",
                  resourceValue: "CargoInvoiceFrequencyType"
                }
              },
              {
                key: "Active",
                type: "checkbox",
                defaultvalue: "",
                label: "Active",
                placeholder: "Active"
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "",
            hideOnAdd: "true",
            fields: [
              {
                type: "searchtable",
                id:"agencies",
                data: {
                  getResource: {
                    id: "GetAgencyListForSearch",
                    resourceType: "POST",
                    resourceIdentity: "Agencies"
                  }
                },
                fields: [
                  {
                    key: "AgencyId",
                    type: "text",
                    label: "Agency Id"
                  },
                  {
                    key: "AgencyName",
                    type: "text",
                    label: "Name"
                  },
                  {
                    key: "TypeCode",
                    type: "text",
                    label: "Account Type"
                  },
                  {
                    key: "Active",
                    type: "text",
                    label: "Active"
                  },
                  {
                    key: "CargoInvoiceFrequencyType",
                    type: "text",
                    label: "Invoice Frequency"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
