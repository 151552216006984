
export default {
  duplicates: [],
  access: ["admin"],
  track: "systemuser",
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["User Administration Administrator"],
      value: true
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    },
    {
      role: ["User Administration Administrator"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: true
    },
    {
      role: ["User Administration Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  caption: "changepassword",
  editSearchSelector: {
    resource: "getsystemuserslist",
    resourceId: ["UserName"],
    resourceIdentity: "SystemUsers",
    resourceValue: "UserName",
    resourceType: "GET",
    resourceSelectedType: "POST",
    resourceSelected: "getsystemusersnopassword",
    resourceSelectedIdentity: "SystemUsers",
    resourceDisplayFields: [],
    updateResourceOnChange: [
      
    ]
  },
  functionactions: {
    createnew: {
      functionname: "changepassword",
      functiontype: "POST",
      validateData: true,
      validationFailedTitle: "",
      validationFailedText: "",
      alertOnComplete: true,
      alertTitle: "",
      alertText: ""
    },
    update: {
      functionname: "changepassword",
      functiontype: "POST",
      validateData: true,
      validationFailedTitle: "",
      validationFailedText: "",
      alertOnComplete: true,
      alertTitle: "",
      alertText: ""
    },
    after: {
      functionname: "getSystemUserList",
      functiontype: "POST"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Change System User Password",
            fields: [
              {
                key: "UserName",
                type: "label",
                label: "User Name",
                placeholder: "User Name",
               
              },
              {
                key: "FullUserName",
                type: "label",
                label: "Full Name",
                placeholder: "Full Name"
              
              },
              {
                key: "UserDescription",
                type: "label",
                label: "User Description",
                placeholder: "User Description"                
              },
              {
                key: "PassCode",
                type: "password",
                label: "Password",
                placeholder: "New Password",      
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 8,
                  "data-parsley-maxlength": 100
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "TelephoneNumber",
                type: "text",
                label: "Telephone Number",
                placeholder: "Telephone Number"
              },
              {
                key: "EmailAddress",
                type: "text",
                label: "Email Address",
                placeholder: "Email Address"
              },
              {
                key: "UserActive",
                type: "checkbox",
                label: "Active",
                placeholder: "Active",
                readOnly: true
              }
            ]
          }
        ]
      }
    ]
  }
};
