import { toJS } from "mobx";

const computeReadOnlyAdd = (field, data) => {
  const EntryDetails = toJS(data);
  if (field.readOnlyDependent !== undefined) {
    let retval = true;
    field.readOnlyDependent.forEach(item => {
      if (
        EntryDetails[item.id] === undefined ||
        EntryDetails[item.id] === null
      ) {
        retval = false;
      } else if (item.value === undefined || item.value === null) {
        retval = false;
      } else if (
        EntryDetails[item.id]
          .toString()
          .toLowerCase()
          .trim() ===
        item.value
          .toString()
          .toLowerCase()
          .trim()
      ) {
        retval = false;
      } else {
        retval = true;
      }
    });
    return retval;
  }
  return false;
};

export default computeReadOnlyAdd;
