import CargoInvoiceAdjustmentLog from "./cargoinvoiceadjustmentlog"
export default {
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Invoice Adjustment Logs",
  editSearchSelector: {
  },
  model: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                  type: "editabletable", 
                  data: {
                    getResource: {
                      resourceType: "POST",
                      id: "GetCargoInvoiceAdjustmentLogList",
                      resourceIdentity: "CargoInvoiceAdjustmentLogs"
                    }
                  },
                  entry: {
                    resource: {
                      resourceType: "POST",
                      id: "GetCargoInvoiceAdjustmentLog",
                      resourceIdentity: "CargoInvoiceAdjustmentLog"
                    },
                    definition: { ...CargoInvoiceAdjustmentLog }
                  },
                  fields: [
                    
                    {
                      key: "ActionTaken",
                      type: "text",
                      label: "Action Taken",
                      placeholder: "Action Taken",
                      readOnly: true
                    },
                    {
                      key: "UserName",
                      type: "text",
                      label: "User",
                      placeholder: "User",
                      readOnly: true
                    },
                    {
                      key: "ActionDate",
                      type: "text",
                      label: "Date",
                      placeholder: "Date",
                      readOnly: true
                    }
                  ]
                }
            ]
          }
        ]
      }     
    ]
  }
};



