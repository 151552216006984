import CargoInvoiceItemFromAdjustment from "./cargoinvoiceitemfromadjustment";
export default {
  track: "Cargo Invoice Item From Adjustment List",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  caption: "Cargo Invoice Items From Adjustment",
  editSearchSelector: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "editabletable", 
                data: {
                  getResource: {
                    resourceType: "POST",
                    id: "GetCargoInvoiceItemFromAdjustmentList",
                    resourceIdentity: "CargoInvoiceItemsFromAdjustment"
                  }
                },
                entry: {
                  resource: {
                    resourceType: "POST",
                    id: "GetCargoInvoiceItemFromAdjustment",
                    resourceIdentity: "CargoInvoiceItemFromAdjustment"
                  },
                  definition: { ...CargoInvoiceItemFromAdjustment }
                },
                fields: [
                  {
                    key: "AdjustmentNumber",
                    type: "text",
                    label: "Adjustment Number",
                    placeholder: "Adjustment Number",
                    readOnly: true
                  },
                  {
                    key: "Description",
                    type: "text",
                    label: "Description",
                    placeholder: "Description",
                    readOnly: true
                  },
                  {
                    key: "AdjustmentNotes",
                    type: "text",
                    label: "Notes",
                    placeholder: "Notes",
                    readOnly: true
                  },
                  {
                    key: "ItemAmount",
                    type: "number",
                    format: "C2",
                    label: "Amount",
                    readOnly: true
                  }
                ]
              }
            ]
          }
        ]
      }     
    ]
  }
};



