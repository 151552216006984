import styled from '@emotion/styled';
import chroma from 'chroma-js';
const SideNavigatorStyle = styled.div`
  /* Old browsers */
  button {
    outline: 0 !important;
  }



  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  .help {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 13px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        padding: 3px 5px;
        a {
          cursor: pointer;
          font-weight: 700;
          font-size: 11px;
          color: ${(props) => props.theme.darkGray}!important;
          outline: none !important;
        }
      }
    }
  }

  @keyframes slide-in-menu {
    from {
      max-width: 0;
    }
    to {
      max-width: 350px;
    }
  }

  .start{
        
        button{
          text-align:left;
          padding: 8px 8px 8px;
          font-size:10pt;
          font-weight: 500;
          cursor: pointer;
          outline: none;
          width: 100%;
          display: block;
          text-transform: capitalize;
          font-family: ${(props) => props.theme.mainFont};
        color: ${(props) => props.theme.textMenu};
        }
      }

  .submenus {
    animation: slide-in-menu 0.2s ease;
    position: absolute;
    top: 33px;
    z-index: 1001;
    left: 43px;
    width: 300px;

    bottom: 10px;
    overflow: hidden;

    
    .submenuholder{
      background: ${(props) =>  chroma(props.theme.accent)
      .darken(1)
      .css()};
      border-radius:5pt;
      padding:10px;
      position:absolute;
      top:5px;
      bottom:5px;
      left:12px;
      right:5px;
    }
    .submenuholderinner{
      background: ${(props) => props.theme.backgroundDark};
      border-radius:3pt;
      padding:5pt;
      position:absolute;
      top:2.5px;
      bottom:2.5pt;
      left:2.5pt;
      right:2.5pt;
    }
    
    .container-sidebar {
      position: absolute;
      top: 10px;      
      left: 0;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      bottom:10px;
      padding:0;

    }
  }

  .main-nav {
    position: absolute;
    left: 5pt;
    top: 30pt;
    min-height: 281px;
    z-index: 1001;
    background: ${(props) => props.theme.accent};
    border-radius: 3px;

    .active-main {
        width: 60px;
  height: 32px;

  color:${(props) => props.theme.accent};
  background: ${(props) =>  chroma(props.theme.accent)
      .darken(1)
      .css()};
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  position: relative;
  margin: 10px 6px;
  
    padding-left: 12px;
  
      
      }

      .pulse {
  
  display: block;
  border-radius: 50%;
  background: ${(props) => props.theme.accent};
  cursor: pointer;
  box-shadow: 0 0 0 ${(props) =>
    chroma(props.theme.backgroundLight)
      .alpha(0.4)
      .css()};
  -webkit-box-shadow: 0 0 0 ${(props) =>
    chroma(props.theme.backgroundLight)
      .alpha(0.4)
      .css()};
  animation: pulse 2s infinite;
}

.pulse-normal {
  
  display: block;
  border-radius: 50%;
  background: ${(props) => props.theme.accent};
  cursor: pointer;
  
  
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 ${(props) =>
      chroma(props.theme.backgroundLight)
        .alpha(0.4)
        .css()};
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px ${(props) =>
        chroma(props.theme.backgroundLight)
          .alpha(0)
          .css()};
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 ${(props) =>
        chroma(props.theme.backgroundLight)
          .alpha(0.0)
          .css()};
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 ${(props) =>
      chroma(props.theme.backgroundLight)
        .alpha(0.4)
        .css()};
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px ${(props) =>
        chroma(props.theme.backgroundLight)
          .alpha(0)
          .css()};
      box-shadow: 0 0 0 10px ${(props) =>
        chroma(props.theme.backgroundLight)
          .alpha(0)
          .css()};
  }
  100% {
      -moz-box-shadow: 0 0 0 0 ${(props) =>
        chroma(props.theme.backgroundLight)
          .alpha(0)
          .css()};
      box-shadow: 0 0 0 0 ${(props) =>
        chroma(props.theme.backgroundLight)
          .alpha(0)
          .css()};
  }
}

.active-main::before,
.active-main::after {
  content: "";
  width: 20px;
  height: 20px;
  right: 0;
  position: absolute;
}

.active-main::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><path fill="${(
    props
  ) =>
     chroma(props.theme.accent)
      .darken(1)
      .css().replace(
      '#',
      '%23'
    )}" d="M0 0 Q20 0 20 20 L20 0Z" /></svg>');
  bottom: -20px;
}

.active-main::after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><path fill="${(
    props
  ) =>
     chroma(props.theme.accent)
      .darken(1)
      .css().replace(
      '#',
      '%23'
    )}" d="M0 20 Q20 20 20 0 L20 20Z" /></svg>');
  top: -20px;
}

    
    ul {
      margin: 0;
      padding: 10px 0 40px 0;
      list-style: none;
      .collapsemenubutton {
        opacity: 1;
           
      }
      button {
        background: transparent;
        outline: none;
        display: block;
        cursor: pointer;
        border: 0 solid transparent;
        text-decoration: none;
        padding: 0;
        color: ${(props) => props.theme.backgroundLight};
        text-align:left;
        padding-left: 8px;

        -webkit-border-top-left-radius: 80px;
        -webkit-border-bottom-left-radius: 80px;
        -moz-border-radius-topleft: 80px;
        -moz-border-radius-bottomleft: 80px;
        border-top-left-radius: 80px;
        border-bottom-left-radius: 80px;

        width: 34px;
        height: 32px;
        margin: 10px;
        
        transition: opacity 0.5s ease;


  


      }

      i {
        font-size: 20px;
        color: ${(props) => props.theme.textLight};
      }
      li {
        display: block;
        width: 55px;
        height:32px;
        font-size: 18px;
        color: ${(props) => props.theme.textLight};
      }
      li:hover {
        button {
          opacity: 1;
        }
        i {
          color: ${(props) => props.theme.textLight};
        }
      }
    }
  }

  .collapsemenu {
    color: ${(props) => props.theme.backgroundLight}!important;

    button {
      color: ${(props) => props.theme.backgroundLight}!important;
    }
    button {
      i {
        color: ${(props) => props.theme.backgroundLight}!important;
      }
      span {
        color: ${(props) => props.theme.backgroundLight}!important;
      }
    }
  }
  .main-nav-li
  {
    1px solid ${(props) => props.theme.borderExtraLight}!important;
  }
  .main-nav-large {
    position: absolute;
    left: 5pt;
    min-height: 281px;
    top: 30pt;
    width: 240px;
    
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1001;
    border-radius: 5px;
    background-color: ${(props) => props.theme.accent};
    .collapsemenubutton {
      opacity: 1;     
      
      
    }

    ul {
      margin: 0;
      padding: 0;

      list-style: none;

      button {
        width: 230px;
  height: 40px;  
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  position: relative;
  margin: 0px;
  border-color:transparent;
        background:transparent;
      }

      .active-main {
        width: 245px;
  height: 40px;
  color:${(props) => props.theme.accent};
  background: ${(props) =>  chroma(props.theme.accent)
      .darken(1)
      .css()};
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  position: relative;
  margin: 0px;
      
      }

      .pulse {
  
  display: block;
  border-radius: 20px;
  background: ${(props) => props.theme.accent};
  cursor: pointer;
  box-shadow: 0 0 0 ${(props) =>
    chroma(props.theme.backgroundLight)
      .alpha(0.4)
      .css()};
  -webkit-box-shadow: 0 0 0 ${(props) =>
    chroma(props.theme.backgroundLight)
      .alpha(0.4)
      .css()};
  animation: pulse 2s infinite;
}

.pulse-normal {
  
  display: block;
  border-radius: 50%;
  background: ${(props) => props.theme.accent};
  cursor: pointer;
  
  
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 ${(props) =>
      chroma(props.theme.backgroundLight)
        .alpha(0.4)
        .css()};
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px ${(props) =>
        chroma(props.theme.backgroundLight)
          .alpha(0)
          .css()};
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 ${(props) =>
        chroma(props.theme.backgroundLight)
          .alpha(0.0)
          .css()};
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 ${(props) =>
      chroma(props.theme.backgroundLight)
        .alpha(0.4)
        .css()};
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px ${(props) =>
        chroma(props.theme.backgroundLight)
          .alpha(0)
          .css()};
      box-shadow: 0 0 0 10px ${(props) =>
        chroma(props.theme.backgroundLight)
          .alpha(0)
          .css()};
  }
  100% {
      -moz-box-shadow: 0 0 0 0 ${(props) =>
        chroma(props.theme.backgroundLight)
          .alpha(0)
          .css()};
      box-shadow: 0 0 0 0 ${(props) =>
        chroma(props.theme.backgroundLight)
          .alpha(0)
          .css()};
  }
}

.active-main::before,
.active-main::after {
  content: "";
  width: 20px;
  height: 20px;
  right: 0;
  position: absolute;
}

.active-main::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><path fill="${(
    props
  ) =>
    chroma(props.theme.accent)
      .darken(1)
      .css().replace(
      '#',
      '%23'
    )}" d="M0 0 Q20 0 20 20 L20 0Z" /></svg>');
  bottom: -20px;
}

.active-main::after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><path fill="${(
    props
  ) =>
     chroma(props.theme.accent)
      .darken(1)
      .css().replace(
      '#',
      '%23'
    )}" d="M0 20 Q20 20 20 0 L20 20Z" /></svg>');
  top: -20px;
}

    


      i {
        display: table-cell;
        vertical-align: middle;

        font-size: 7pt;
        color: ${(props) => props.theme.textLight};
      }
      span {
        display: table-cell;
        vertical-align: middle;
        padding-left: 12px;
        padding-right: 8px;
        font-size: 10pt;
        font-weight: 500;
        white-space: nowrap;
        color: ${(props) => props.theme.textLight};
      }
      li {
        cursor: pointer;
        display: block;

        width: 100%;
        font-size: 12px;
        padding: 0px;
        color: ${(props) => props.theme.textLight};
      }
      li:hover {
        button {
          opacity: 1;
        }
        span {
          color: ${(props) => props.theme.textLight};
        }
        i {
          color: ${(props) => props.theme.textLight};
        }
      }
    }
  }

.clear-all
{
  font-size:7.8pt!important;
  color: ${(props) => props.theme.accent}!important;
  font-weight:600!important;
  padding:3px 8px!important;
  text-align:right!important;
}
.logo{
  height: 41px;
  overflow: hidden;
  background-color: ${(props) => props.theme.backgroundDarker}!important;
}
  .recent {
   
    input {
    }
    .box {
      background-color: ${(props) => props.theme.backgroundDark}!important;
      width: 100%;
      border-radius:3px;

      .input-group-text {
        color: ${(props) => props.theme.accent}!important;
        background-color: ${(props) => props.theme.backgroundDarker}!important;
        border: 1px solid ${(props) => props.theme.backgroundDarker}!important;
        border-radius: 0;
      }

      .form-control {
        color: ${(props) => props.theme.accent}!important;
        background-color: ${(props) => props.theme.backgroundDarker}!important;
        border: 1px solid ${(props) => props.theme.backgroundDarker}!important;
        border-radius:3px;
        transition: none;
      }

      .form-control:focus {
        box-shadow: none !important;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        width: 100%;

        a {
          padding: 8px 8px 8px;
          display: inline-block;
          outline: none;
          cursor: pointer;
          width: 100%;
          margin-right: -36px;
          font-family: ${(props) => props.theme.headerFont};
          font-size: 10pt;
          text-transform: capitalize;
          font-weight: 500;
          text-decoration: none !important;
          color: ${(props) => props.theme.textMenu};
        }
        .close-icon {
          width: 28px;
          margin-right: 0;
          font-size: 20px;
        }
      }
    }
  }

  .metismenu {
    .arrow {
      display: block;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: all 0.5s ease;
    }
    .mm-active .arrow {
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }

    padding: 0;
    margin: 0;
    list-style: none;

    li {
      width: 100%;

      a {
        padding: 8px 8px 8px;
        display: block;
        outline: none;
        cursor: pointer;
        width: 100%;
        font-family: ${(props) => props.theme.mainFont};
        font-size: 10pt;
        text-transform: capitalize;
        font-weight: 500;
        text-decoration: none !important;
        color: ${(props) => props.theme.textMenu};
      }
      i {
        display: block;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {          
          a {
            border-left: 5px solid transparent;
            text-transform: capitalize;
            font-weight: 500;
            font-size: 10pt;
            display: block;
            outline: none;
            padding: 8px 8px 8px;
            color: ${(props) => props.theme.textMenu};
          }
        }
      }
    }
  }
`;

export default SideNavigatorStyle;
