import Colors from "../../../constants/Colors";
export default {
  track: "SalesReportDriver",
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    },
    {
      role: [""],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  customActions: [
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: false,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-printer",
      text: "Print",
      eventType: "report",
      report: {
        id: "Cargo/CargoSalesReport",
        header: "Cargo Sales Report"
      },
      color: Colors.print,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member"],
          value: true
        }
      ]
    }
  ],
  printreport: "",
  caption: "Daily Sales Report",
  editSearchSelector: {
    resource: "GetSalesReportDriver",
    resourceId: ["UserName"],
    resourceMethod:"GET",
    resourceIdentity: "SalesReportDriver",
    resourceValue: "UserName",
    resourceSelectedType: "Get",
    resourceSelected: "GetSalesReportDriver",
    resourceSelectedIdentity: "SalesReportDriver",
    resourceDisplayFields: [],
    after: []
  },
  functionactions: {
    createnew: {
    },
    update: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Daily Sales Report",
            fields: [
              {
                key: "UserName",
                type: "select",
                label: "Agent",
                placeholder: "Agent",
                readOnly: true,
                optionsResource: {
                  resource: "GetSystemUsersList",
                  resourceType: "GET",
                  resourceId: ["UserName"],
                  resourceIdentity: "SystemUsers",
                  resourceValue: "FullUserName"
                },
              }
            ]
          },
          {
            title: "",
            fields: [
              {
                type: "spacer"
              }
            ]
          }
        ]
      }
    ]
  }
}
