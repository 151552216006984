import $ from "jquery";
import { addAnimateCSS } from "../../../../utilities";
import linesout from "./linesout";

const logoout = function () {

    addAnimateCSS($(this.refs.logoholder), "animate__faster animate__zoomOut", () => {
      $(this.refs.logoholder).addClass("hidden");
//      this.props.OnReady(true);
      const linesoutAnimation = linesout.bind(this);
      linesoutAnimation();
    });
  

   
};

export default logoout;