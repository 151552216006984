import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import computeValidation from '../../actions/computeValidation';
import BindModel from '../../../Core/BindModel';
import computeAsterisk from '../../actions/computeAsterisk';
import config from './config';
import { Observer } from 'mobx-react';
import computeId from '../../actions/computeId';

const RichTextEditor = (props) => (
  <Observer>
    {() => (
      <div className="form-group">
        <label>
          {props.Config.label} {computeAsterisk(props.Config)}
        </label>
        <div style={{position:'relative'}}>
          <ReactQuill
            theme="snow"
            modules={config.modules}
            formats={config.formats}
            readOnly={props.ReadOnly}
            placeholder={props.Config.placeholder}
            
            {...BindModel(
              props.EntryDetails,
              props.Config.key,
              'value',
              'onChange'
            )}
          />
        </div>
        <div>
          <input
            id={`rich-text-editor-${computeId(props.Config)}`}
            type="text"
            style={{ display: 'none' }}
            {...computeValidation(props.Config, props.FormId)}
            {...BindModel(
              props.EntryDetails,
              props.Config.key,
              'value',
              'onChange',
              false,
              true
            )}
          />
        </div>
      </div>
    )}
  </Observer>
);

export default RichTextEditor;
