import $ from "jquery";
import { addAnimateCSS } from "../../../../utilities";

const logoin = function() {
  addAnimateCSS($(this.refs.logoholder), "animate__faster animate__zoomIn", () => {    
      this.props.OnReady(true);    
  });

  $(this.refs.logoholder).removeClass("hidden");
};

export default logoin;
