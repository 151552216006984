import disabledFunctionList from "./disabledFunctionList";
const assignedDisabledFunctions = () =>{
    const disabled = {};
    disabledFunctionList.forEach(functionName => {
        disabled[functionName] = () =>{
            console.warn("compute", `Function ${functionName} is disabled`);
        }
    })    
    return disabled;
}

export default assignedDisabledFunctions;