export default {
  duplicates: [],
  access: [],
  track: "AirwaybillPackage",
  formtype: "dataEntry",
  
  allowSearch: [
    {
      role: ["default"],
      value: true,
    },
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: [""],
      value: false,
    },
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: [""],
      value: false,
    },
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: [""],
      value: false,
    },
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: [""],
      value: false,
    },
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false,
    },
  ],
  printreport: "",
  caption: "Deliver Package",
  editSearchSelector: {
    resource: "GetAirwaybillPackageList",
    resourceType: "GET",
    resourceId: ["AirwaybillPackageRecordNumber"],
    resourceValue: "AirwaybillPackageRecordNumber",
    resourceSelected: "GetAirwaybillPackage",
    resourceSelectedType: "POST",
    resourceDisplayFields: [],
  },
  functionactions: {
    createnew: { 
      functionname: "DeliverAirwaybillPackage",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Airway Bill Package Information Delivered",
      alertText: "The Airway Bill Package Has Been Delivered"
    },
    update: {
      functionname: "DeliverAirwaybillPackage",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Airway Bill Package Information Delivered",
      alertText: "The Airway Bill Package Has Been Delivered"
    },
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Deliver Package",
            fields: [
              {
                key: "AirwaybillPackageNumber",
                type: "text",
                label: "AWB Package Number",
                placeholder: "AWB Package No.",
                readOnly: true
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "PackageType",
                type: "text",
                label: "Package Type",
                placeholder: "Package Type",
                readOnly: true,
                validation: {
                  "data-parsley-required": true
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "PaymentType",
                type: "text",
                label: "Payment Type",
                placeholder: "Payment Type",
                readOnly: true,
                validation: {
                  "data-parsley-required": true
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "ShippingCost",
                type: "number",
                format: "$0,0.00",
                label: "Shipping Cost",
                placeholder: "Shipping Cost",
                readOnly: true
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                key: "Recipient",
                type: "text",
                label: "Recipient",
                placeholder: "Recipient",
                readOnly: true
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "PackageDescription",
                type: "text",
                label: "Package Description",
                placeholder: "Package Description",
                readOnly: true
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                key: "PickedUpBy",
                type: "text",
                label: "Picked Up By",
                placeholder: "Picked Up By",
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "PickupIDType",
                type: "select",
                label: "ID Type",
                placeholder: "ID Type",
                optionsResource: {
                  resource: "GetIDTypeList",
                  resourceType: "GET",
                  resourceId: ["IDType"],
                  resourceIdentity: "IDTypes",
                  resourceValue: "IDTypeDescription"
                },
                validation: {
                  "data-parsley-maxlength": 10
                }
              },
              {
                key: "PickupIDNumber",
                type: "text",
                label: "ID Number",
                placeholder: "ID Number",
                validation: {
                  "data-parsley-maxlength": 20
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "DeliveryCondition",
                type: "select",
                label: "Delivery Condition",
                placeholder: "Delivery Condition",
                defaultValue: "Normal",
                optionsResource: {
                  resource: "GetAirwaybillPackageDeliveryConditionList",
                  resourceType: "GET",
                  resourceId: ["DeliveryCondition"],
                  resourceIdentity: "AirwaybillPackageDeliveryConditions",
                  resourceValue: "DeliveryCondition"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 12
                }
              },
              {
                key: "RecommendedAdjustment",
                type: "number",
                format: "$0,0.00",
                defaultValue: "0",
                label: "Recommended Adjustment",
                placeholder: "Recommended Adjustment",
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-verifyrecommendedadjustment": true,
                  "data-parsley-min": 0,
                  "data-parsley-max": 100000
                }
              }
            ]
          }
        ]
      }
    ]
  }
};
