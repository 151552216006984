import { observable, toJS } from "mobx";
const onClose = (actionDefinition, props) => {
  return new Promise((resolve) => {
    if (props.Config && props.Config.preventCloseAndRefresh) {
      if (props.refreshData) {
        props.refreshData(actionDefinition.newData).then(() => {
          resolve();
        });
      }
      if (props.refreshAfterSave) {
        props.refreshAfterSave();
      }
    } else {
      props.stack.pop();
      if (props.stack) {
        var currentStack = toJS(props.stack);
        if (
          currentStack[currentStack.length - 1] &&
          currentStack[currentStack.length - 1].onClose != null &&
          currentStack[currentStack.length - 1].onClose != undefined
        ) {
          currentStack[currentStack.length - 1].onClose();
        }

        props.stack.pop();
      } else {
        if (props.onCloseDialog) {
          props.onCloseDialog();
        }
      }
      if (props.refreshAfterSave) {
        props.refreshAfterSave();
      }
      resolve();
    }
  });
};

export default onClose;
