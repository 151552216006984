

import Colors from "../../../constants/Colors";
export default {
  duplicates: [],
  access: [],
  track: "Airwaybill",
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Accounting Administrator", "Accounting Member"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],

  
  printreport: "",
  caption: "Change Purchase Order Number",
  editSearchSelector: {
    resource: "GetAirwaybillList",
    resourceType: "GET",
    resourceId: ["AirwaybillNumber"],
    resourceIdentity: "Airwaybills",
    resourceValue: "AirwaybillNumber",
    resourceSelected: "GetAirwaybill",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "Airwaybill",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "UpdateAirwaybillPurchaseOrderNumber",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true
    }
  },
  addConfirmation: {
    title: 'Confirm',
    text: 'Please confirm purchase order change'
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Old Purchase Order",
            fields: [
              {
                key: "OldPurchaseOrderNumber",
                type: "text",
                label: "P.O Number",
                placeholder: "P.O Number",
                readOnly: true
              }             
            ]
          },
          {
            title: "New Purchase Order",
            fields: [
              {
                key: "PurchaseOrderNumber",
                type: "text",
                label: "P.O Number",
                placeholder: "P.O Number",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 20
                }
              }
            ]
          },
          {
            title: "",
            fields: [
              {
                type: "spacer"
              }
            ]
          },
          {
            title: "",
            fields: [
              {
                type: "spacer"
              }
            ]
          }
        ]
      }
    ]
  }
};
