

import Colors from "../../../constants/Colors";
export default {
  track: "Airwaybill",
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor", "Accounting Administrator", "Accounting Member"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],

  
  printreport: "",
  caption: "Change Billing Agent",
  editSearchSelector: {
    resource: "GetAirwaybillList",
    resourceType: "GET",
    resourceId: ["AirwaybillNumber"],
    resourceIdentity: "Airwaybills",
    resourceValue: "AirwaybillNumber",
    resourceSelected: "GetAirwaybill",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "Airwaybill",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "UpdateAirwaybillBillingAgent",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true
    }
  },
  addConfirmation: {
    title: 'Confirm',
    text: 'Please confirm billing agent change'
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Old Agency",
            fields: [
              {
                key: "OldBillingAgent",
                type: "select",
                defaultValue: "",
                label: "Agency",
                placeholder: "Agency",
                readOnly: true,
                optionsResource: {
                  resource: "GetAgencyList",
                  resourceType: "GET",
                  resourceId: ["AgencyId"],
                  resourceIdentity: "Agencies",
                  resourceValue: "AgencyName"
                }
              },
              {
                key: "PaymentType"
              }          
            ]
          },
          {
            title: "New Agency",
            fields: [
              {
                key: "BillingAgent",
                type: "select",
                defaultValue: "",
                label: "Agency",
                placeholder: "Agency",
                readOnly: false,
                optionsResource: {
                  resource: "GetAgencyList",
                  resourceType: "GET",
                  resourceId: ["AgencyId"],
                  resourceIdentity: "Agencies",
                  resourceValue: "AgencyName"
                },
                mapping: [
                  {
                    id: "TypeCode",
                    key: "TypeCode"
                  },
                  {
                    id: "PurchaseOrderRequired",
                    key: "PurchaseOrderRequired"
                  }
                ],
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 5,
                  "data-parsley-nostationcharge": true
                }
              },
              {
                key: "PurchaseOrderNumber",
                type: "text",
                defaultValue: "",
                label: "P.O Number",
                placeholder: "P.O Number",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 20,
                  "data-parsley-purchaseorderrequired": true
                }
              },
              {
                key: "TypeCode"
              },
              {
                key: "PurchaseOrderRequired"
              }
            ]
          },
          {
            title: "",
            fields: [
              {
                type: "spacer"
              }
            ]
          },
          {
            title: "",
            fields: [
              {
                type: "spacer"
              }
            ]
          }
        ]
      }
    ]
  }
};
