import React, { PureComponent } from "react";
import LoaderSVG from "./assets/LoaderSVG";
import LoaderStyle from "./LoaderStyle";
import { addAnimateCSS } from "../../../utilities";
import linesin from "./actions/linesin";
import $ from "jquery";
import logoout from "./actions/logoout";

export default class Loader extends PureComponent {
  componentDidMount() {
    this.props.Triggers.onHideLoader = logoout.bind(this);
    addAnimateCSS($(this.refs.before), "animate__faster animate__slideInDown", () => {});
    addAnimateCSS($(this.refs.after), "animate__faster animate__slideInUp", () => {
      const lineInAnimation = linesin.bind(this);
      lineInAnimation();
    });

    $(this.refs.loader).removeClass("hidden");
  }
  render() {
    return (
      <LoaderStyle>
        <div ref="loader" className="loader hidden">
          <div className="before" ref="before" />
          <div className="after" ref="after" />
          <div ref="lineholder" className="hidden">
            <div className="leftline" ref="leftline" />
            <div className="rightline" ref="rightline" />
          </div>
          <div ref="logoholder" className="logoholder hidden">
            <LoaderSVG />
            <div className="logotext">{this.props.Text}</div>
          </div>
        </div>
      </LoaderStyle>
    );
  }
}
