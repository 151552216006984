
import Colors from "../../../constants/Colors";
export default {
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {          
      role: ["Accounting Administrator", "Accounting Member"],
      value: true
    }
  ], 
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Airwaybill Adjustment",
  editSearchSelector: {
    resource: "GetCargoInvoiceAdjustmentList",
    resourceType: "GET",
    resourceId: ["CargoInvoiceAdjustmentRecordNumber"],
    resourceIdentity: "CargoInvoiceAdjustments",
    resourceValue: "AdjustmentNumber",
    resourceSelected: "GetCargoInvoiceAdjustment",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoInvoiceAdjustment"},
  functionactions: {
    createnew: {
      functionname: "AddAirwaybillAdjustment",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true
    }
  },
  addConfirmation: {
    title: 'Confirm',
    text: 'Please confirm adjustment'
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Agency & Adjustment Date",
            fields: [               
              {
                key: "AgencyId",
                type: "select",
                label: "Agency",
                placeholder: "Agency",
                readOnly: false,
                optionsResource: {
                  resource: "GetAirwaybillTransactionAgencyList",
                  resourceType: "POST",
                  resourceId: ["AgencyId"],
                  resourceIdentity: "Agencies",
                  resourceValue: "AgencyName"
                },
                mapping: [
                  {
                    id: "AirwaybillBalance",
                    key: "AirwaybillBalance"
                  }
                ],
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 5
                }
              },
              {
                key: "TransactionType",
                type: "select",
                label: "Adjustment Type",
                placeholder: "Adjustment Type",
                readOnly: true,
                optionsResource: {
                  resource: "GetAirwaybillAdjustmentTypeList",
                  resourceType: "GET",
                  resourceId: ["TransactionType"],
                  resourceIdentity: "AirwaybillTransactionTypes",
                  resourceValue: "TransactionTypeDescription"
                },
                validation: {
                  "data-parsley-required": true
                }
              },
              {
                key: "AdjustmentNotes",
                type: "textarea",
                label: "Notes",
                placeholder: "Notes",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 255
                }
              }
            ]
          },
          {
            title: "Adjustment",
            fields: [ 
              {
                key: "AdjustmentAmount",
                type: "number",
                label: "Amount",
                placeholder: "Amount",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 0,
                  "data-parsley-max": 1000000,
                  "data-parsley-validairwaybilladjustmentamount": true
                }
              },
              {
                key: "AdjustmentPercentage",
                type: "number",
                label: "Percentage",
                placeholder: "Percentage",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 0,
                  "data-parsley-max": 100,
                  "data-parsley-singleadjustmentcriteria": true
                }
              }
            ]
          },
          {
            title: "Balance",
            fields: [
              {
                key: "AirwaybillBalance",
                type: "number",
                format: "$0,0.00",
                label: "Balance",
                placeholder: "Balance",
                readOnly: true
              },
              {
                key: "NewAirwaybillBalance",
                type: "computedtextbox",
                format: "$0,0.00",
                label: "New Balance",
                placeholder: "New Balance",
                readOnly: true,                     
                operation:"((AirwaybillBalance - (AirwaybillBalance * (AdjustmentPercentage / 100))) + (AirwaybillBalance - AdjustmentAmount)) - AirwaybillBalance"
              }
            ]
          }
        ]
      }    
    ]
  }
};
