import chroma from 'chroma-js';
import styled from '@emotion/styled';

const TabContainerStyle = styled.div`
  /* Old browsers */
  a {
    text-decoration: none !important;
  }
  button {
    outline: 0 !important;
  }
  .nothing-selected {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${(props) => props.theme.backgroundDark};
    display: flex;
    align-items: center;
    justify-content: center;
    .plane {
      margin-top: -25px;
      width: 300px;
      img {
        width: 300px;
      }
    }
    .description {
      width: 100%;
      text-align: left;
    }
    .add {
      position: absolute;
      left: 25px;
      top: 20px;
    }
  }

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.backgroundDark};
  overflow: hidden;
  .tab-content {
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => props.theme.backgroundDark};
  }

  .main-load {
    background: ${(props) => props.theme.backgroundDark};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .svg-calLoader {
      width: 230px;
      height: 230px;
      left: 115px;
      transform-origin: 115px 115px;
      animation: 2s linear infinite loader-spin;
    }

    .cal-loader__plane {
      fill: ${(props) => props.theme.accent};
    }

    .cal-loader__path {
      stroke: ${(props) => props.theme.accent};
      opacity: 0.2;
      animation: 2s ease-in-out infinite loader-path;
    }

    @keyframes loader-spin {
      to {
        transform: rotate(360deg);
      }
    }
    @keyframes loader-path {
      0% {
        stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
      }
      50% {
        stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
      }
      100% {
        stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
      }
    }

    .loader-centered {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .loading-txt {
      position: absolute;
      display: block;
      top: 50%;
      width: 230px;
      text-align: center;
      color: ${(props) => props.theme.darkGray};
      font-size: 12px;
      font-weight: 400;
    }
  }

  .close-icon {
    padding: 8px 16px 8px 0;
  }
  .tab-header-line {
    background: ${(props) => props.theme.backgroundLight};
    position: relative;
    width: 100%;
    height: 1px;
    left: 0;
    top: 100%;
    margin-top: -1px;
  }
  .tab-pane {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    background: ${(props) => props.theme.backgroundDark};
    bottom: 0;
    .action-bar {
      padding-left: 5pt;
      position: absolute;
      top: 0px;
      height: 27pt;
      left: 0;
      right: 0;
      .grid-menu {
        top: 11px;
      }
      background: ${(props) => props.theme.backgroundDark};
      h3 {
        padding-top: 10px;
        margin-right: 20px;
        font-size: 12px;
        font-family: ${(props) => props.theme.mainFont};
        color: ${(props) => props.theme.textMedium};
        font-weight: bolder;
        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .section {
        float: left;
        margin-top:3px;
      }

      .speech {
        position: relative;
        padding: 5pt 3pt 7pt 8pt;
        background-color: ${(props) => props.theme.backgroundLight};
        color: ${(props) => props.theme.accent};
        z-index: 2;
        font-size: 9pt;
        font-weight: 600;
        margin-right: 10px;
        padding-right: 10px;
        border-radius: 3px;
      }
      .speachtext {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        height: 10pt;
        display: block;
      }

      .speech:before,
      .speech.pointed.down:before {
        content: '';
        position: absolute;
        bottom: -1em;
        border-style: solid;
        border-width: 1em 1em 0;
        border-color: ${(props) => props.theme.backgroundLight} transparent;
        z-index: -1;
      }
      .speech.pointed.center:before {
        right: 50%;
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
      }
      .speech.pointed.near::before {
        left: 1em;
      }
      .speech.pointed.far::before {
        right: 1em;
      }
      .speech.pointed.up::before {
        bottom: auto;
        top: -1em;
        border-width: 0 1em 1em;
      }
      .speech.pointed.left::before,
      .speech.pointed.right::before,
      .speech.pointed.left.high::before,
      .speech.pointed.right.high::before {
        bottom: auto;
        border-color: transparent ${(props) => props.theme.backgroundLight};
      }
      .speech.pointed.left.middle::before,
      .speech.pointed.right.middle::before {
        bottom: 50%;
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
      }
      .speech.pointed.left.low::before,
      .speech.pointed.right.low::before {
        bottom: 1em;
      }
      .speech.pointed.left::before {
        left: -1em;
        border-width: 1em 1em 1em 0;
        border-color: transparent ${(props) => props.theme.backgroundLight};
      }
      .speech.pointed.right::before {
        right: -8px;
        border-width: 8px 0 8px 8px;
      }
      .speech.bubble {
        border-radius: 1em;
      }
      .speech.round {
        border-radius: 50%;
      }
      .speech.round.pointed.down::before,
      .speech.round.pointed.up::before {
        border: 1.5em solid transparent;
        border-radius: 50%;
        box-shadow: 1em 0 0 0 ${(props) => props.theme.backgroundLight};
      }
      .speech.round.pointed.down.far::before,
      .speech.round.pointed.up.far::before {
        box-shadow: -1em 0 0 0 ${(props) => props.theme.backgroundLight};
      }
      .speech.round.pointed.left::before,
      .speech.round.pointed.right::before {
        border: 1.5em solid transparent;
        border-radius: 50%;
        box-shadow: 0 1em 0 0 ${(props) => props.theme.backgroundLight};
      }
      .speech.round.pointed.left.high::before,
      .speech.round.pointed.right.high::before {
        box-shadow: 0 -1em 0 0 ${(props) => props.theme.backgroundLight};
      }

      .searchHolder {
        position: absolute;
        top: 0;
        right: 3px;
      }
    }
    .from-holder {
      position: absolute;
      top: 27pt;
      bottom: 0;
      left: 0;
      right: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .bordertop {
    position: absolute;
    top: 39px;
    left: 0;
    right: 0;
    height: 1px;
    background: ${(props) => props.theme.backgroundLight};
    z-index: 4;
  }
  .searchButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.search};
    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.search};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }
  .verifyButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.verify};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.verify};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .processButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.process};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.process};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .removeButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: #95000c;
    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: #95000c;
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .addButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.add};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.add};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight};
    }
  }

  .topButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: '#ccc';

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: '#ccc';
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight};
    }
  }

  .printButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.print};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.print};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight};
    }
  }

  .requestButton {
    margin-right: 20px;
    cursor: pointer;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.request};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.request};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight};
    }
  }

  .viewButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.view};

    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.view};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .editButton {
    margin-right: 20px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.edit};
    color: ${(props) => props.theme.textLight};
    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.edit};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight};
    }
  }
  .user-account {
    position: absolute;
    right: 0;
    top: 0px;

    padding-top: 2px;
    height: 33px;
    text-align: right;
    border-bottom: 1px solid #fff;
    padding-right: 15px;

    background: ${(props) => props.theme.backgroundDarker}!important;
  }

  .activities {
    position: absolute;

    z-index: 1100;
    top: 35px;
    right: 38px;

    .triangle {
      position: relative;
      width: 0;
      left: 235px;
      border-bottom: solid 8px ${(props) => props.theme.textLight};
      border-right: solid 8px transparent;
      border-left: solid 8px transparent;
      z-index: 5;
    }
    .triangle .empty {
     
    }
  }

  .search-results-profile {
    position: absolute;

    z-index: 1100;
    top: 35px;
    right: 8px;

    .triangle {
      position: relative;
      width: 0;
      left: 235px;
      border-bottom: solid 8px ${(props) => props.theme.textLight};
      border-right: solid 8px transparent;
      border-left: solid 8px transparent;
      z-index: 5;
    }
    .triangle .empty {
     
    }
  }

  .search-results-profile-holder {
    background: ${(props) => props.theme.textLight};
    border: 1px solid ${(props) => props.theme.textLight};
    width: 300px;
    z-index: 4;
    position: relative;
    .internal-search-results-profile-holder {
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      max-height: 490px;
      margin-top: 0px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 15px 10px 15px 10px;
        text-align: right;
        font-family: ${(props) => props.theme.mainFont};
        font-weight: 600;
        font-size: 13px;
        color: ${(props) => props.theme.textMedium};

        .result-holder {
          position: relative;
        }
        .result-holder-animator {
          transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          top: 0;
          right: 0;
          left: 0;
          position: absolute;
          width: 100%;
          background: ${(props) => props.theme.accent};
          height: 0%;
          z-index: 1;
        }
      }
    }
  }

  .activity-holder {
    background: ${(props) => props.theme.textLight};
    border-radius:3px;
    width: 300px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.02);
    z-index: 4;
    position: relative;
    .internal-activity-holder {
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      max-height: 490px;
      margin-top: 0px;
    }
    .heading {
      list-style: none;
      margin: 0;

      width: 100%;
      display: table;
      padding: 0px 10px;
      h6 {
        font-size: 7pt;
        font-weight: 600;
        text-transform: uppercase;
      }
      li {
        display: table-cell;
        text-align: center;
        button
        {
          width:100%;
          border-radius: 3px;
          font-size:8pt;
          font-weight:600;
          color: ${(props) => props.theme.darkGray};
        }
      }
      .active-button {
        button
        {
        background: ${(props) => props.theme.accent}!important;

        
        
        color: ${(props) => props.theme.textLight}!important;
        }
        
      }
    }
    
    .notes {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      .avatar {
        color: ${(props) => props.theme.textMedium};
        border-radius: 15px;
        padding: 3px 8px;

        i {
          font-size: 18px;
        }
      }

      .title {
        font-size: 8pt;
        font-weight: 600;
        color: ${(props) => props.theme.accent};
      }

      .description {
        font-size: 7.8pt;
        color: ${(props) => props.theme.textMenu};
      }

      .date {
        font-size: 7pt;
        font-weight: 600;
        color: ${(props) => props.theme.textMedium};
        display:block;
        width:60px;
        text-align:right;
      }

      .mark {
        font-size: 9pt;
        font-weight: 600;
        cursor: pointer;
        padding:5px!important;
      }

      .empty{
        border-left: 3px solid transparent!important;
      }

      li {
        display: block;
        border-left: 3px solid ${(props) => props.theme.accent};
        width: 100%;
        text-align: left;
        margin-top: 5px;
        padding: 5px;
        &:nth-of-type(even)
        {
          background-color:${(props) => chroma(props.theme.backgroundDark).alpha(0.4).css()};
        }
      }
    }
  }

  .input-group-text {
    background-color: ${(props) => props.theme.backgroundDark};
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    border-radius: 0;
    color: ${(props) => props.theme.textMedium};
  }

  input:-webkit-autofill {
    -webkit-box-shadow: none !important;
    box-shadow: none;
  }

  .form-control {
    box-shadow: none !important;
    background-color: ${(props) => props.theme.backgroundLight}!important;
    padding: 1.2rem 0.75rem;
    border: 1px solid ${(props) => props.theme.backgroundDarker}!important;
    border-radius:3px;
  }
  .instructions {
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    display: block;
    width: 100%;
  }

  .changepassword {
    cursor: pointer;
    margin: 25px 0 0 0 !important;
    padding: 10px;
    text-align: center !important;
    color: #fff !important;
    background: ${(props) => props.theme.add};
  }

  .logout {
    cursor: pointer;
    margin: 25px 0 0 0 !important;
    padding: 10px;
    text-align: center !important;
    color: #fff !important;
    background: ${(props) => props.theme.toastBackgroundError};
  }
  .loginbutton {
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 3px 20px 3px 10px;
    background: ${(props) => props.theme.add2};
    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      color: ${(props) => props.theme.add2};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
    }
  }

  .parsley-errors-list {
    list-style: none;
    margin: 5px 0 0 0;
    padding: 0;
    font-size: 7px;
    font-weight: 600;
    color: #ad0400;
    text-transform: uppercase;
    li {
      margin: 0 !important;
      color: #ad0400 !important;
      padding: 0 !important;
      background: #fff !important;
      font-size: 9px !important;
      font-weight: 600 !important;
      text-transform: uppercase !important;
    }
  }

  .tab-header {
    position: absolute;
    background: ${(props) => props.theme.backgroundDarker}!important;
    left: 0;
    right: 116px;
    top: 0;
    height: 33px;
    overflow: hidden;
    .active {
      background: ${(props) => props.theme.backgroundDark}!important;
      margin-bottom: -1px;
      button {
        color: ${(props) => props.theme.accent}!important;
        border: 0;
      }
      cursor: default;
    }
    ul {
      position: relative;
      overflow: hidden;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        &:first-of-type {
          border-left: 1px solid ${(props) => props.theme.backgroundLight};
        }
        border-right: 1px solid ${(props) => props.theme.backgroundLight};
        position: relative;
        display: inline-block;
        text-align: center;
        height: 100%;
        margin-bottom: -2px;
        overflow: hidden;

        button {
          position: relative;
          border: 0;
          display: block;
          overflow: hidden;
          padding: 8px 16px 8px 16px;
          color: ${(props) => props.theme.darkGray};
          font-family: ${(props) => props.theme.headerFont};
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 600;
          display: inline-block;
          cursor: pointer;
        }
      }
    }
  }

  .close-dialog {
    position: absolute;
    top: 0;
    cursor: pointer;
    display: block;
    right: 20px;
    padding: 10px;
    background: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.backgroundLight};
    -webkit-border-bottom-right-radius: 30px;
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-radius-bottomright: 30px;
    -moz-border-radius-bottomleft: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    i {
      font-size: 20px;
    }
  }

  .dialog-header-h {
    position: absolute;
    top: 0;
    left: 20px;
    display: block;
    right: 90px;
    height:40px;
    padding: 10px;
    h2 {
      font-size: 12pt;
      font-weight: 600;
      color: ${(props) => props.theme.textMedium};
      border-bottom: none !important;
    }
  }

  .dialog-holder {
    position: absolute;
    z-index: 999;
    display: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.4);
  }
  .dialog-holder-stack {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.4);
  }
  .dialogOverlay {
    background: ${(props) => props.theme.overlay};
    position: absolute !important;
    z-index: 10;
    top: 0px !important;
    left: 0px !important;
    bottom: 0px !important;
    right: 0px !important;
  }
  .dialog {
    position: absolute !important;
    top: 10px !important;
    left: 10px !important;
    bottom: 10px !important;
    right: 10px !important;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.22);
    box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.22);
    background: rgba(255, 255, 255) !important;
    .card {
     
    }
    .card-holder {
      border: 0px solid #dfd9c2 !important;
      margin: 0 !important;
    }

    .form-holder {
      background: ${(props) => props.theme.dialogBackground};
      bottom: 20px;
      top:15px;
    }

    h3 {
      font-size: 11px;
      font-weight: 600;
    }
    .close-btn {
      color: ${(props) => props.theme.accent}!important;
      text-decoration: none !important;
      font-size: 24px;
    }
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${(props) => props.theme.overlay};
    .dialog-header {
      background: ${(props) => props.theme.dialogBackground}!important;
      color: ${(props) => props.theme.accent}!important;
      border: 0 solid #fff !important;

      -webkit-border-top-left-radius: 10px;
      -webkit-border-top-right-radius: 10px;
      -moz-border-radius-topleft: 10px;
      -moz-border-radius-topright: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
    .dialog-inner {
      border: 0 solid #fff !important;
      background: ${(props) => props.theme.dialogBackground}!important;

      -webkit-border-bottom-right-radius: 10px;
      -webkit-border-bottom-left-radius: 10px;
      -moz-border-radius-bottomright: 10px;
      -moz-border-radius-bottomleft: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      position: absolute;
      top: 80px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .pn-ProductNav_Wrapper {
    position: relative;
    padding: 0 11px;
    height:33px;
    box-sizing: border-box;
  }

  .pn-ProductNav {
    /* Make this scrollable when needed */
    overflow-x: auto;
    /* We don't want vertical scrolling */
    overflow-y: hidden;
    margin-left: 15px;
    margin-right: 10px;
    /* For WebKit implementations, provide inertia scrolling */
    -webkit-overflow-scrolling: touch;
    /* We don't want internal inline elements to wrap */
    white-space: nowrap;
    /* If JS present, let's hide the default scrollbar */
    .js & {
      /* Make an auto-hiding scroller for the 3 people using a IE */
      -ms-overflow-style: -ms-autohiding-scrollbar;
      /* Remove the default scrollbar for WebKit implementations */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    /* positioning context for advancers */
    position: relative;
    // Crush the whitespace here
    font-size: 0;
  }

  .pn-ProductNav_Contents {
    float: left;
    transition: transform 0.2s ease-in-out;
    position: relative;
    &li:last-of-type{
      border-right:1px solid ${(props) => props.theme.backgroundLight}!important;
    }
  }

  .pn-ProductNav_Contents-no-transition {
    transition: none;
  }

  .pn-ProductNav_Link {
    text-decoration: none;
    color: #888;
    // Reset the font size
    font-size: 1.2rem;
    font-family: -apple-system, sans-serif;
    display: inline-flex;
    align-items: center;
    min-height: 44px;
    border: 1px solid transparent;
    padding: 0 11px;
    & + & {
      border-left-color: #eee;
    }
    &[aria-selected='true'] {
      color: #111;
    }
  }

  .pn-Advancer {
    /* Reset the button */
    appearance: none;
    background: transparent;
    padding: 0;
    border: 0;
    &:focus {
      outline: 0;
    }
    &:hover {
      cursor: pointer;
    }
    /* Now style it as needed */
    position: absolute;
    top: 0;
    bottom: 0;
    /* Set the buttons invisible by default */
    opacity: 0;
    transition: opacity 0.3s;
  }

  .pn-Advancer_Left {
    left: 0;
    padding-left: 5px;
  }

  .pn-Advancer_Right {
    right: 0;
  }

  .pn-Advancer_Icon {
    width: 20px;
    height: 44px;
    fill: #bbb;
  }

  .pn-ProductNav_Indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100px;
    background-color: red;
    transform-origin: 0 0;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }

  .profile {
    .search-results-profile {
      display: none;
    }
    a {
      text-decoration: none !important;
      color: ${(props) => props.theme.darkGray}!important;
      outline: none !important;
      padding-left: 10px;
    }
    button{
      color: ${(props) => props.theme.darkGray}!important;
      padding-left: 10px;
      padding-top: 5px;
      outline: none !important;
      path:nth-of-type(2){
        fill: ${(props) => props.theme.darkGray}!important;
      }
    }
  }


  /* DEMO 3 */

.wrapper-dropdown-3 {
    /* Size and position */
    position: relative;
    width: 200px;
    margin: 0 auto;
    padding: 10px;

    /* Styles */
    background: #fff;
    border-radius: 7px;
    border: 1px solid rgba(0,0,0,0.15);
    box-shadow: 0 1px 1px rgba(50,50,50,0.1);
    cursor: pointer;
    outline: none;

    /* Font settings */
    font-weight: bold;
    color: #8AA8BD;
}

.wrapper-dropdown-3:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -3px;
    border-width: 6px 6px 0 6px;
    border-style: solid;
    border-color: #8aa8bd transparent;
}

.wrapper-dropdown-3 .dropdown {
  /* Size & position */
    position: absolute;
    top: 140%;
    left: 0;
    right: 0;

    /* Styles */
    background: white;
    border-radius: inherit;
    border: 1px solid rgba(0,0,0,0.17);
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    font-weight: normal;
    -webkit-transition: all 0.5s ease-in;
    -moz-transition: all 0.5s ease-in;
    -ms-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
    list-style: none;

    /* Hiding */
    opacity: 0;
    pointer-events: none;
}

.wrapper-dropdown-3 .dropdown:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    right: 15px;
    border-width: 0 6px 6px 6px;
    border-style: solid;
    border-color: #fff transparent;    
}

.wrapper-dropdown-3 .dropdown:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    right: 13px;
    border-width: 0 8px 8px 8px;
    border-style: solid;
    border-color: rgba(0,0,0,0.1) transparent;    
}

.wrapper-dropdown-3 .dropdown li a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #8aa8bd;
    border-bottom: 1px solid #e6e8ea;
    box-shadow: inset 0 1px 0 rgba(255,255,255,1);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.wrapper-dropdown-3 .dropdown li i {
    float: right;
    color: inherit;
}

.wrapper-dropdown-3 .dropdown li:first-of-type a {
    border-radius: 7px 7px 0 0;
}

.wrapper-dropdown-3 .dropdown li:last-of-type a {
    border: none;
    border-radius: 0 0 7px 7px;
}

/* Hover state */

.wrapper-dropdown-3 .dropdown li:hover a {
    background: #f3f8f8;
}

/* Active state */

.wrapper-dropdown-3.active .dropdown {
    opacity: 1;
    pointer-events: auto;
}

/* No CSS3 support */

.no-opacity       .wrapper-dropdown-3 .dropdown,
.no-pointerevents .wrapper-dropdown-3 .dropdown {
    display: none;
    opacity: 1; /* If opacity support but no pointer-events support */
    pointer-events: auto; /* If pointer-events support but no pointer-events support */
}

.no-opacity       .wrapper-dropdown-3.active .dropdown,
.no-pointerevents .wrapper-dropdown-3.active .dropdown {
    display: block;
}




  .profile {
    .activities {
      display: none;
    }
    a {
      text-decoration: none !important;
      color: ${(props) => props.theme.darkGray}!important;
      outline: none !important;
      padding-left: 10px;
    }
  }
`;

export default TabContainerStyle;
