
import login from "./forms/authentication/login";
import agency from "./forms/agency/agency";
import aircraft from "./forms/aircraft/aircraft";
import airwaybill from "./forms/airwaybill/airwaybill";
import businessrules from "./forms/businessrules/businessrules";
import ComingSoon from "./forms/ComingSoon/ComingSoon";
import cargobusinessrule from "./forms/cargobusinessrule/cargobusinessrule"
import cargochargecalculator from "./forms/airwaybill/cargochargecalculator";
import cargocustomer from "./forms/cargocustomer/cargocustomer";
import cargoinvoice from "./forms/cargoinvoice/cargoinvoice";
import cargoinvoiceadjustment from "./forms/cargoinvoiceadjustment/cargoinvoiceadjustment";
import cargoinvoicebatch from "./forms/cargoinvoicebatch/cargoinvoicebatch";
import cargomanifest from "./forms/cargomanifest/cargomanifest";
import cargopayment from "./forms/cargopayment/cargopayment";
import cargorate from "./forms/cargorate/cargorate";
import cargoservicetype from "./forms/cargoservicetype/cargoservicetype";
import cargospecialcustomer from "./forms/cargospecialcustomer/cargospecialcustomer";
import cargospecialrate from "./forms/cargospecialrate/cargospecialrate";
import cargostation from "./forms/cargostation/cargostation";
import cargotype from "./forms/cargotype/cargotype";
import cashbox from "./forms/cashbox/cashbox"
import changeownpassword from "./forms/accountmanagement/changeownpassword";
import company from "./forms/company/company";
import customer from "./forms/customer/customer";
import deliverairwaybillpackage from "./forms/airwaybillpackage/undeliveredairwaybillpackage";
import employeecargoinvoiceadjustmentbatch from "./forms/employeecargoinvoiceadjustmentbatch/employeecargoinvoiceadjustmentbatch";
import flightnumber from "./forms/flightnumber/flightnumber";
import changepassword from "./forms/systemusers/changepassword";
import receiveairwaybillpackage from "./forms/airwaybillpackage/receiveairwaybillpackage";
import receiveairwaybillpackagemanual from "./forms/airwaybillpackage/receiveairwaybillpackagemanual";
import salesreportdriver from "./forms/salesreportdriver/salesreportdriver";
import systemroles from "./forms/systemusers/systemroles";
import systemusers from "./forms/systemusers/systemusers";
import undeliveredpackages from "./forms/airwaybillpackage/undeliveredairwaybillpackages";
import userdocumentation from "./forms/userdocumentation/userdocumentation";

export default [
  { id: "accountmanagement/changeownpassword", definition: changeownpassword },
  { id: "aircraft/aircraft", definition: aircraft },
  { id: "agency/agency", definition: agency },
  { id: "airwaybillpackage/undeliveredairwaybillpackage", definition: undeliveredpackages },
  { id: "airwaybillpackage/deliverairwaybillpackage", definition: deliverairwaybillpackage },
  { id: "airwaybill/airwaybill", definition: airwaybill },
  { id: "authentication/login", definition: login },
  { id: "businessrules/businessrules", definition: businessrules },
  { id: "airwaybill/cargochargecalculator", definition: cargochargecalculator},
  { id: "cargobusinessrule/cargobusinessrule", definition: cargobusinessrule},
  { id: "cargocustomer/cargocustomer", definition: cargocustomer },
  { id: "cargoinvoice/cargoinvoice", definition: cargoinvoice },
  { id: "cargoinvoicebatch/cargoinvoicebatch", definition: cargoinvoicebatch },
  { id: "cargoinvoiceadjustment/cargoinvoiceadjustment", definition: cargoinvoiceadjustment },
  { id: "cargomanifest/cargomanifest", definition: cargomanifest },
  { id: "cargopayment/cargopayment", definition: cargopayment },
  { id: "cargorate/cargorate", definition: cargorate },
  { id: "cargoservicetype/cargoservicetype", definition: cargoservicetype },
  { id: "cargospecialcustomer/cargospecialcustomer", definition: cargospecialcustomer },
  { id: "cargospecialrate/cargospecialrate", definition: cargospecialrate},
  { id: "cargostation/cargostation", definition: cargostation },
  { id: "cargotype/cargotype", definition: cargotype },
  { id: "cashbox/cashbox", definition: cashbox },
  { id: "company/company", definition: company },
  { id: "customer/customer", definition: customer },
  { id: "employeecargoinvoiceadjustmentbatch/employeecargoinvoiceadjustmentbatch", definition: employeecargoinvoiceadjustmentbatch },
  { id: "flightnumber/flightnumber", definition: flightnumber },
  { id: "airwaybillpackage/receiveairwaybillpackage", definition: receiveairwaybillpackage },
  { id: "airwaybillpackage/receiveairwaybillpackagemanual", definition: receiveairwaybillpackagemanual },
  { id: "salesreportdriver/salesreportdriver", definition: salesreportdriver },
  { id: "systemusers/changepassword", definition: changepassword },
  { id: "systemusers/systemroles", definition: systemroles },
  { id: "systemusers/systemusers", definition: systemusers },
  { id: "userdocumentation/userdocumentation", definition: userdocumentation },
];
