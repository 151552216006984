import $ from "jquery";
const Swal = window.Swal;

const dependentCheck = (button, EntryDetails) => {
  let result = false;

  if (button.confirmationDepentent.dependentCommandFields) {
    button.confirmationDepentent.dependentCommandFields.forEach((dependent) => {
      dependent.values.forEach((value) => {
        if (
          EntryDetails &&
          EntryDetails[dependent.key] &&
          EntryDetails[dependent.key].toString().trim().toLowerCase() ===
            value.toString().trim().toLowerCase()
        ) {
          result = true;
        }
      });
    });
  }

  return result;
};

const onConfirmation = (button, props, onYes, onNo) => {
  const confirmation = button.confirmation
    ? button.confirmation
    : props.Config && props.Config.editConfirmation
    ? props.Config.editConfirmation
    : props.FormDefinition.editConfirmation
    ? props.FormDefinition.editConfirmation
    : null;

  if (confirmation) {
    if (
      !button.confirmation.confirmAccept ||
      button.confirmation.confirmAccept === ""
    ) {
      Swal.fire({
        title: button.confirmation.title,
        text: button.confirmation.text,
        showCancelButton: true,
        confirmButtonText: "Verify",
        allowOutsideClick: () => false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            onYes();
          } else {
            onNo();
          }
        })
        .catch(() => {
          onNo();
        });
    } else {
      confirmation.confirmAccept = confirmation.confirmAccept
        ? confirmation.confirmAccept
        : "yes";
      Swal.fire({
        title: confirmation.title,
        text: confirmation.text,
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Verify",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise((resolve1) => {
            let val = $(".swal2-input").first().val();
            if (
              val.toLowerCase() === confirmation.confirmAccept.toLowerCase()
            ) {
              onYes();
              resolve1();
            } else {
              Swal.fire(
                "Issues",
                "You did not type '" +
                  confirmation.confirmAccept +
                  "'.  Please do so to confirm verification.",
                "error"
              );

              onNo();
              resolve1();
            }
          });
        },
        allowOutsideClick: () => false,
      })
        .then((result) => {
          if (result) {
            if (result.dismiss && result.dismiss === "cancel") {
              onNo();
            }
          }
        })
        .catch(() => {
          onNo();
        });
    }
  } else if (
    button.confirmationDepentent &&
    dependentCheck(button, props.EntryDetails) == true
  ) {
    if (
      !button.confirmationDepentent.confirmAccept ||
      button.confirmationDepentent.confirmAccept === ""
    ) {
      Swal.fire({
        title: button.confirmationDepentent.title,
        text: button.confirmationDepentent.text,
        showCancelButton: true,
        confirmButtonText: "Verify",
        allowOutsideClick: () => false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            onYes();
          } else {
            onNo();
          }
        })
        .catch(() => {
          onNo();
        });
    } else {
      button.confirmationDepentent.confirmAccept = button.confirmationDepentent
        .confirmAccept
        ? button.confirmationDepentent.confirmAccept
        : "yes";

      Swal.fire({
        title: button.confirmationDepentent.title,
        text: button.confirmationDepentent.text,
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Verify",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise((resolve1) => {
            let val = $(".swal2-input").first().val();
            if (
              val.toLowerCase() ===
              button.confirmationDepentent.confirmAccept.toLowerCase()
            ) {
              onYes();
              resolve1();
            } else {
              Swal.fire(
                "Issues",
                "You did not type '" +
                  button.confirmationDepentent.confirmAccept +
                  "'.  Please do so to confirm verification.",
                "error"
              );

              onNo();
              resolve1();
            }
          });
        },
        allowOutsideClick: () => false,
      })
        .then((result) => {
          if (result) {
            if (result.dismiss && result.dismiss === "cancel") {
              onNo();
            }
          }
        })
        .catch(() => {
          onNo();
        });
    }
  } else {
    onYes();
  }
};

export default onConfirmation;
