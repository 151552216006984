import React from 'react';
import { guid } from '../../../utilities';

function FullNav(props) {
  return (
    <div className="main-nav-large">
      <ul>
        <li className="main-nav-li collapsemenu">
          <button
            onClick={props.onToggle}
            className="collapsemenubutton"
            type="button"
          >
            <div className="menu-start" />
            <i className="icon-menu" />
            <span>Collapse Menu</span>
          </button>
        </li>
        {props.MenuSystem.map((menu) => {
          return (
            <li className="main-nav-li" key={`${guid()}`}>
              <button
                type="button"
                className={menu === props.activeMenu ? 'active-main' : ''}
                onClick={() => {
                  props.onMenuClick(menu);
                }}
              >
                <div className="menu-start" />
                <i className={menu.icon} />
                <span>{menu.name}</span>
              </button>
            </li>
          );
        })}

        <li
          className="main-nav-li"
          style={{
            marginTop: '15px',
          }}
        >
          <button
            className={props.activeStart ? 'active-main' : ''}
            type="button"
            onClick={() => {
              props.ShowStart();
            }}
          >
            <div className="menu-start" />
            <i className="mdi mdi-view-dashboard-outline" />
            <span>Start</span>
          </button>

          {'SpeechRecognition' in window ||
          'webkitSpeechRecognition' in window ? (
            <button
              className={
                props.activeSpeech
                  ? 'active-main'
                  : props.recording
                  ? 'pulse'
                  : ''
              }
              type="button"
              onClick={() => {
                props.recording
                  ? props.stopRecording()
                  : props.startRecording();
              }}
            >
              <div className="menu-start" />
              <i className="icon-mic" />
              <span>Voice Command</span>
            </button>
          ) : (
            <></>
          )}
        </li>
      </ul>
    </div>
  );
}

export default FullNav;
