export default {
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true,
    },
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: ["Cargo Administrator"],
      value: true,
    },
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: ["Cargo Administrator"],
      value: true,
    },
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false,
    },
  ],
  printreport: "",
  caption: "CargoStation",
  editSearchSelector: {
    resource: "GetCargoStationList",
    resourceType: "GET",
    resourceId: ["CargoStation"],
    resourceIdentity: "CargoStations",
    resourceValue: "CargoStationName",
    resourceSelected: "GetCargoStation",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoStation",
    resourceDisplayFields: [],
  },
  functionactions: {
    createnew: {
      functionname: "AddCargoStation",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Station Information Added",
      alertText: "The Cargo Station Has Been Added",
    },
    update: {
      functionname: "UpdateCargoStation",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Station Information Updated",
      alertText: "The Cargo Station Has Been Updated",
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Cargo Station Information",
            fields: [
              {
                key: "CargoStation",
                type: "text",
                defaultValue: "",
                label: "Cargo Station",
                placeholder: "Cargo Station",
                readOnly: false,
                readOnlyOnEdit: true,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 4,
                }
              },
              {
                key: "CargoStationName",
                type: "text",
                defaultValue: "",
                label: "Station Name",
                placeholder: "StationName",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 50,
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [              
              {
                key: "BillingAgencyId",
                type: "select",
                defaultValue: "",
                label: "Billing Agency",
                placeholder: "Billing Agency",
                readOnly: false,
                optionsResource: {
                  resource: "GetAgencyList",
                  resourceType: "GET",
                  resourceId: ["AgencyId"],
                  resourceIdentity: "Agencies",
                  resourceValue: "AgencyName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 5,
                }
              },
              {
                key: "ActiveCargoStation",
                type: "checkbox",
                label: "Active",
                placeholder: "Active",
                readOnly: false
              }
            ]
          },
          {
            title: "\n",
            fields: [               
              {
                key: "CountryCode",
                type: "select",
                defaultValue: "",
                label: "Country",
                placeholder: "Country",
                readOnly: false,
                optionsResource: {
                  resource: "GetCountryList",
                  resourceType: "GET",
                  resourceId: ["CountryCode"],
                  resourceIdentity: "Countries",
                  resourceValue: "CountryName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 2,
                }
              }
            ]
          }
        ]
      }
    ]
  }
};
