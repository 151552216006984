import $ from "jquery";
import { addAnimateCSS } from "../../../../utilities";
import logoin from "./logoin";

const linesin = function () {

    addAnimateCSS($(this.refs.leftline), "animate__faster animate__slideInLeft", () => {
      
    });
    addAnimateCSS($(this.refs.rightline), "animate__faster animate__slideInRight", () => {
        const logoinAnimation = logoin.bind(this);
        logoinAnimation();
    });

    $(this.refs.lineholder).removeClass("hidden");
};

export default linesin;