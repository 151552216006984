import styled from '@emotion/styled';

const BellIconStyle = styled.div`
  display: block;
  float: left;
  outline:none!important;
  cursor:pointer;
  .bell-icon {
    position: relative;
    width: 30px;
    height: 20px;    
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    margin-top:5px;
  }
  .bell-icon svg {
    margin: auto;
    position: relative;
    right: 2%;
    width: 80%;
    height: 80%;
    stroke: rgba(0, 0, 0, 0.75);
  }
  .bell-icon .bell-icon__group {
    -webkit-transform-origin: 50% 2px;
    transform-origin: 50% 2px;
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  .bell-icon .bell-icon__ball {
    -webkit-transform-origin: 50% 2px;
    transform-origin: 50% 2px;
    -webkit-transform: translateX(-6.5%);
    transform: translateX(-6.5%);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  
  .bell-icon.active-bell {
    outline: none;
    box-shadow: 0 0 12px -8px rgba(0, 0, 0, 0.6);
  }
  
  .bell-icon.active-bell .bell-icon__group {
    -webkit-animation: animateGroup 2.3s;
    animation: animateGroup 2.3s;
  }
  
  .bell-icon.active-bell .bell-icon__ball {
    -webkit-animation: animateBall 2.3s;
    animation: animateBall 2.3s;
  }
  
  .bell-icon.active-bell .notification-amount {
    opacity: 1;
    visibility: visible;
  }
  
  .bell-icon.active-bell .notification-amount::before {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-delay: 450ms;
    animation-delay: 450ms;
  }

  .notification-amount {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: -5%;
    right: -5%;
    width: 17px;
    height: 13px;
    color: white;
    border-radius: 3px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;    
    font-size: 6pt;
  }
  .notification-amount span {
    position: relative;
  }
  .notification-amount::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${props=>props.theme.accent};
    border-radius: 3px;
    z-index: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes animateGroup {
    0%,
    100% {
      -webkit-transform: rotate(-8deg);
      transform: rotate(-8deg);
    }
    17.542% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    35.084% {
      -webkit-transform: rotate(-20deg);
      transform: rotate(-20deg);
    }
    48.2405% {
      -webkit-transform: rotate(20deg);
      transform: rotate(20deg);
    }
    57.0115% {
      -webkit-transform: rotate(-20deg);
      transform: rotate(-20deg);
    }
    64.9054% {
      -webkit-transform: rotate(8deg);
      transform: rotate(8deg);
    }
    74.5535% {
      -webkit-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }
    78.939% {
      -webkit-transform: rotate(-7deg);
      transform: rotate(-7deg);
    }
  }

  @keyframes animateGroup {
    0%,
    100% {
      -webkit-transform: rotate(-8deg);
      transform: rotate(-8deg);
    }
    17.542% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    35.084% {
      -webkit-transform: rotate(-20deg);
      transform: rotate(-20deg);
    }
    48.2405% {
      -webkit-transform: rotate(20deg);
      transform: rotate(20deg);
    }
    57.0115% {
      -webkit-transform: rotate(-20deg);
      transform: rotate(-20deg);
    }
    64.9054% {
      -webkit-transform: rotate(8deg);
      transform: rotate(8deg);
    }
    74.5535% {
      -webkit-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }
    78.939% {
      -webkit-transform: rotate(-7deg);
      transform: rotate(-7deg);
    }
  }
  @-webkit-keyframes animateBall {
    0%,
    100% {
      -webkit-transform: translateX(-6.5%);
      transform: translateX(-6.5%);
    }
    17.542% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
    21.9275% {
      -webkit-transform: translateX(-1%);
      transform: translateX(-1%);
    }
    35.084% {
      -webkit-transform: translateX(11%);
      transform: translateX(11%);
    }
    48.2405% {
      -webkit-transform: translateX(-11%);
      transform: translateX(-11%);
    }
    52.626% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
    59.6428% {
      -webkit-transform: translateX(10%);
      transform: translateX(10%);
    }
    68.4138% {
      -webkit-transform: translateX(-11%);
      transform: translateX(-11%);
    }
    78.939% {
      -webkit-transform: translateX(11%);
      transform: translateX(11%);
    }
    85.9558% {
      -webkit-transform: translateX(-11%);
      transform: translateX(-11%);
    }
  }
  @keyframes animateBall {
    0%,
    100% {
      -webkit-transform: translateX(-6.5%);
      transform: translateX(-6.5%);
    }
    17.542% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
    21.9275% {
      -webkit-transform: translateX(-1%);
      transform: translateX(-1%);
    }
    35.084% {
      -webkit-transform: translateX(11%);
      transform: translateX(11%);
    }
    48.2405% {
      -webkit-transform: translateX(-11%);
      transform: translateX(-11%);
    }
    52.626% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
    59.6428% {
      -webkit-transform: translateX(10%);
      transform: translateX(10%);
    }
    68.4138% {
      -webkit-transform: translateX(-11%);
      transform: translateX(-11%);
    }
    78.939% {
      -webkit-transform: translateX(11%);
      transform: translateX(11%);
    }
    85.9558% {
      -webkit-transform: translateX(-11%);
      transform: translateX(-11%);
    }
  }
  @-webkit-keyframes bounce {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    14% {
      -webkit-transform: scale(1.15);
      transform: scale(1.15);
    }
    28% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    42% {
      -webkit-transform: scale(1.15);
      transform: scale(1.15);
    }
    70% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes bounce {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    14% {
      -webkit-transform: scale(1.15);
      transform: scale(1.15);
    }
    28% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    42% {
      -webkit-transform: scale(1.15);
      transform: scale(1.15);
    }
    70% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

export default BellIconStyle;
