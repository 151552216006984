import React, { useEffect, useRef } from 'react';
import { toJS, observable } from 'mobx';
import { withTheme } from '@emotion/react';
import $ from 'jquery';
import FormLoader from '../..';
import moment from 'moment';
import { guid, user } from '../../../../utilities';
import buttons from './buttons';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  ColumnChooser,
  Inject,
  Page,
  Filter,
  Sort,
  Group,
  DetailRow,
  ExcelExport,
  PdfExport,
  Aggregate,
  Edit,
} from '@syncfusion/ej2-react-grids';
import { loadData } from '../../../../utilities';
import Notifications from '../../../Core/Notification';
import ActionBar from '../../../Core/ActionBar';
import { Observer } from 'mobx-react';
import Select2Component from '../Select2Component';
import GridStyle from "../../../Core/Grid/GridStyle";
const DataGrid = (props) => {
  const gridInstance = useRef(null);
  useEffect(() => {
    updateDataSet();
  }, []);
  const currentEdit = observable({});
  const gridState = observable({
    adding: false,
    editing: false,
    loading: false,
    selectedItem: null,
    dataSource: [],
  });

  const toolbarClick = (args) => {
    switch (args.item.text) {
      case 'PDF Export':
        gridInstance.current.pdfExport();
        break;
      case 'Excel Export':
        gridInstance.current.excelExport();
        break;
      case 'Refresh':
        updateDataSet();
        break;
      default:
        break;
    }
  };

  const editTemplateSelect = (column, args) => {
    //currentData = observable({...args});
    for (var key in args) {
      currentEdit[key] = args[key];
    }

    return (
      <div>
        <Select2Component
          ReadOnly={false}
          EntryDetails={currentEdit}
          Config={column.config}
          ParentData={props.ParentData}
          FormId={props.FormId}
        />
      </div>
    );
  };

  const isEmpty = (map) => {
    if (map instanceof Array) {
      if (map.length === 0) {
        return true;
      }
    }
    for (var key in map) {
      if (map.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  const updateDataSet = () => {
    gridState.selectedItem = null;

    gridState.loading = true;

    if (props.Config) {
      let postData = toJS(props.ParentData);

      if (isEmpty(props.ParentData)) {
        return;
      }

      //   gridState.loading = true;

      if (isEmpty(postData)) {
        gridInstance.current.dataSource = [];
        return;
      }
      loadData(
        props.Config.data.getResource.id,
        props.Config.data.getResource.resourceType,
        postData,
        (response) => {
          // gridState.dataSource =
          //   response[props.Config.data.getResource.resourceIdentity];
          //  gridState.loading = false;
          if (gridInstance.current) {
            gridInstance.current.dataSource = response[
              props.Config.data.getResource.resourceIdentity
            ]
              ? response[props.Config.data.getResource.resourceIdentity]
              : [];
          }
        }
      );
    }
  };

  const ReloadParent = (previous) => {
    loadData(
      props.Config.entry.resource.id,
      props.Config.entry.resource.resourceType,
      toJS(gridState.selectedItem),
      (resource) => {
        try {
          const current =
            resource[props.Config.entry.resource.resourceIdentity];
          for (var propertyName in current) {
            previous[propertyName] = current[propertyName];
          }
        } catch (ex) {
          console.error(ex);
        }
      }
    );
  };

  const onSave = (complete, adding) => {
    if (isEmpty(toJS(currentEdit))) {
      if (complete) {
        complete();
      }
      return;
    }
    gridInstance.current.endEdit();
    const postData = toJS(currentEdit);
    const formActions = props.Config.data;
    if (adding) {
      loadData(
        formActions.create.functionname,
        formActions.create.functiontype,
        postData,
        () => {
          
            if (props.ReloadParent) {
              props.ReloadParent();
            }
          
          updateDataSet();
          Notifications.success('The information has been saved successfully.');
        },
        () => {}
      );
    } else {
      loadData(
        formActions.save.functionname,
        formActions.save.functiontype,
        postData,
        () => {
          
            if (props.ReloadParent) {
              props.ReloadParent();
            }
          
          updateDataSet();
          Notifications.success('The information has been saved successfully.');
          if (props.AfterSave) {
            props.AfterSave();
          }
          if (complete) {
            complete();
          }
        },
        () => {
          if (complete) {
            complete();
          }
        }
      );
    }
  };

  const setupEditSettings = () => {
    return {
      allowEditing:
        props.Config.editableOnNonReadonly === true
          ? !props.ReadOnly
          : props.ReadOnly,
      allowAdding: false,
      allowDeleting: false,
      showConfirmDialog: false,
      mode: 'Normal',
    };
  };

  const actionComplete = (evnt) => {
    if (evnt.requestType === 'save') {
      $('.sl2').select2('close');
      $('.select2-dropdown').remove();
      onSave(null, false);
    } else if (evnt.requestType === 'beginEdit') {
      for (var key in evnt.rowData) {
        currentEdit[key] = evnt.rowData[key];
      }

      $('.sl2').select2('close');
    }
  };

  const onSelection = (args) => {
    gridState.selectedItem = args;
  };

  const onStateChanged = (currentState, data) => {
    switch (currentState) {
      case 'Normal':
        gridState.adding = false;
        gridState.editing = false;
        break;
      case 'Add':
        gridState.adding = true;
        gridState.editing = false;
        break;
      case 'Edit':
        gridState.editing = true;
        gridState.adding = false;

        break;
      case 'View':
        gridState.editing = false;
        gridState.adding = false;

        break;
      case 'Cancel':
        gridState.adding = false;
        gridState.editing = false;
        break;
      default:
        gridState.adding = false;
        gridState.editing = false;
        break;
    }
  };

  return (
    <div>
      <Observer>
        {() => (
          <>
            {gridState.loading ? (
              <></>
            ) : (
              <ActionBar
                FormDefinition={
                  props.Config.entry ? props.Config.entry.definition : {}
                }
                AdditionalButtons={props.Config.customButtons}
                onStateChanged={onStateChanged}
                Config={props.Config}
                reloadParent={ReloadParent}
                nothingSelected={!gridState.selectedItem}
                refreshAfterSave={updateDataSet}
                ParentData={props.ParentData}
                stack={props.stack}
                readOnly={props.ReadOnly}
                Locked={
                  props.Locked === true ||
                  props.locked === true ||
                  props.Config.disableEditing === true
                }
                willLaunchDialog={true}
                isDialog={false}
                isAdding={gridState.adding}
                isEditing={gridState.editing}
                EntryDetails={gridState.selectedItem}
                
              />
            )}
          </>
        )}
      </Observer>

      <div>
        {gridState.loading ? (
          <></>
        ) : (
          <GridStyle>
          <GridComponent
            ref={gridInstance}
            toolbarClick={toolbarClick}
            allowGrouping={true}
            toolbar={[
              'Update',
              'ExcelExport',
              'PdfExport',
              {
                text: 'Refresh',
                tooltipText: 'Refresh Data',
                prefixIcon: 'mdi mdi-refresh',
                id: 'Refresh',
              },
              'ColumnChooser',
            ]}
            allowExcelExport={true}
            allowPdfExport={true}
            editSettings={setupEditSettings()}
            groupSettings={{
              showDropArea: false,
              showUngroupButton: true,
              showToggleButton: true,
              showGroupedColumn: true,
              showConfirmDialog: false,
            }}
            rowSelected={(args) => {
              onSelection(args.data);
            }}
            width="100%"
            acceptsReturn={true}
            textWrapSettings={{
              wrapMode: 'Content',
            }}
            allowTextWrap={true}
            allowSelection={true}
            actionComplete={actionComplete}
            allowFiltering={true}
            allowSorting={true}
            dataSource={[]}
            allowPaging={true}
            showColumnChooser={true}
            filterSettings={{
              type: 'Excel',
            }}
            pageSettings={{
              pageSize: 10,
              pageCount: 5,
              pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            }}
          >
            <ColumnsDirective>
              {props.Config.fields.map((column) => {
                switch (column.type) {
                  case 'date': {
                    return (
                      <ColumnDirective
                        key={`${guid()}`}
                        allowEditing={!column.readOnly}
                        editType="datepicker"
                        field={column.key}
                        validationRules={column.validationRules}
                        headerText={column.label}
                        type={'dateTime'}
                        template={(args) => {
                          return (
                            <span>
                              {moment(args[column.key]).format('DD-MMM-YYYY')}
                            </span>
                          );
                        }}
                      />
                    );
                  }
                  case 'text': {
                    return (
                      <ColumnDirective
                        key={`${guid()}`}
                        field={column.key}
                        allowEditing={!column.readOnly}
                        validationRules={column.validationRules}
                        headerText={column.label}
                        editType="stringedit"
                        type="string"
                        format={column.format}
                      />
                    );
                  }
                  case 'select': {
                    return (
                      <ColumnDirective
                        key={`${guid()}`}
                        allowEditing={!column.readOnly}
                        validationRules={column.validationRules}
                        editTemplate={() => {
                          return editTemplateSelect(column);
                        }}
                        field={column.key}
                        headerText={column.label}
                        type="boolean"
                        format={column.format}
                      />
                    );
                  }
                  case 'bool': {
                    return (
                      <ColumnDirective
                        key={`${guid()}`}
                        allowEditing={!column.readOnly}
                        validationRules={column.validationRules}
                        editType="booleanedit"
                        field={column.key}
                        headerText={column.label}
                        type="boolean"
                        format={column.format}
                      />
                    );
                  }
                  default: {
                    return (
                      <ColumnDirective
                        key={`${guid()}`}
                        allowEditing={!column.readOnly}
                        validationRules={column.validationRules}
                        editType="stringedit"
                        field={column.key}
                        headerText={column.label}
                        type={column.type}
                        format={column.format}
                      />
                    );
                  }
                }
              })}
            </ColumnsDirective>
            <Inject
              services={[
                Toolbar,
                ExcelExport,
                PdfExport,
                ColumnChooser,
                Page,
                Filter,
                Sort,
                Group,
                DetailRow,
                Aggregate,
                Edit,
              ]}
            />
          </GridComponent>
          </GridStyle>
        )}
      </div>
    </div>
  );
};
export default withTheme(DataGrid);
