import airwaybilladjustment from "./airwaybilladjustment";
import AirwaybillAdjustment from "./airwaybilladjustment"

export default {
  duplicates: [],
  access: [],
  track: "Airwaybill Adjustment List",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Airwaybill Adjustments",
  editSearchSelector: {
  },
  model: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "",
            fields: [
              {
                  type: "editabletable", 
                  data: {
                    getResource: {
                      resourceType: "POST",
                      id: "GetAirwaybillAdjustmentList",
                      resourceIdentity: "AirwaybillAdjustments"
                    }
                  },
                  entry: {
                    resource: {
                      resourceType: "POST",
                      id: "GetAirwaybillAdjustment",
                      resourceIdentity: "AirwaybillAdjustment"
                    },
                    definition: { ...AirwaybillAdjustment }
                  },
                  fields: [
                    {
                      key: "AdjustmentDate",
                      type: "date",
                      label: "Date",
                      placeholder: "Date",
                      readOnly: true
                    },
                    {
                      key: "AdjustmentAmount",
                      type: "number",
                      format: "C2",
                      label: "Amount",
                      placeholder: "Amount",
                      readOnly: true
                    },
                    {
                      key: "TransactionTypeDescription",
                      type: "text",
                      label: "Description",
                      placeholder: "Description",
                      readOnly: true
                    },
                    {
                      key: "AdjustmentNotes",
                      type: "text",
                      label: "Notes",
                      placeholder: "Notes",
                      readOnly: true
                    }
                  ]
                }
            ]
          }
        ]
      }     
    ]
  }
};



