import React from "react";
import ActionButtonStyle from "./ActionButtonStyle";

const ActionButton = (props) => {
  const onClick = (e) => {
    e.preventDefault();
    props.onClick();
  };
  return (
    <ActionButtonStyle>
      <div className="float-left">
        <button onClick={onClick} style={{ backgroundColor: props.color }}>
          <i className={props.icon} style={{ color: props.color }} />
          <span>{props.text}</span>
        </button>
      </div>
    </ActionButtonStyle>
  );
};
export default ActionButton;
