import actions from "./actions";
import { toJS } from "mobx";

const process = (
  actionDefinitions,
  index,
  button,
  props,
  callback,
  innerOnStateChanged
) => {
  if (index < actionDefinitions.length) {
    const innerDef =
          index === actionDefinitions.length - 1 ? innerOnStateChanged : null;
    const currentAction = actionDefinitions[index];
    actions[currentAction](button, props, innerDef)

      .then(() => {
        
        process(
          actionDefinitions,
          index + 1,
          button,
          props,
          callback,
          innerOnStateChanged
        );
      })
      .catch((ex) => {
        callback(false);
      });
  } else {
    callback(true);
  }
};

const computeActionProcessor = (
  actionDefinition,
  props,
  innerOnStateChanged
) => {
  return new Promise((resolve, reject) => {
    let actionDefinitions = [];

    if (actionDefinition.eventType instanceof Array) {
      actionDefinitions = actionDefinition.eventType;
    } else {
      actionDefinitions = [actionDefinition.eventType];
    }
    //actionDefinitions = actionDefinitions.reverse();

    let data = [];
    const entryDetails = toJS(props.EntryDetails);
    if (entryDetails instanceof Array) {
      data = entryDetails;
    } else {
      data.push(entryDetails);
    }

    if (actionDefinition.runMultiple) {
      data.forEach((item) => {
        const p = { ...props, EntryDetails: item };
        process(
          actionDefinitions,
          actionDefinition,
          p,
          (state) => {
            if (state) {
              resolve();
            } else {
              reject();
            }
          },
          innerOnStateChanged
        );
      });
    } else {
      const item = data[0];
      const p = { ...props, EntryDetails: item };
      process(
        actionDefinitions,
        0,
        actionDefinition,
        p,
        (state) => {
          if (state) {
            resolve();
          } else {
            reject();
          }
        },
        innerOnStateChanged
      );
    }
  });
};

export default computeActionProcessor;
