export default {
	formtype: "dataEntry",

	allowSearch: [
		{
			role:  [ "default" ],
			value:  true
		}
	],
	allowDelete: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
			role:  [ "" ],
			value:  false
		}
	],
	allowAudit: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
			role:  [ "" ],
			value:  false
		}
	],
	allowEdit: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
			role:  [ "" ],
			value:  false
		}
	],
	allowAdd: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
			role:  [ "" ],
			value:  false
		}
	],
	allowPrint: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	printreport: "",
	caption:  "Charter Manifest Log Details",
	editSearchSelector: {
		resource: "GetAgencyLogList",
		resourceType: "GET",
		resourceId: [ "AgencyLogRecordNumber" ],
		resourceIdentity: "AgencyLogs",
		resourceValue: "AgencyLogRecordNumber",
		resourceSelected: "GetAgencyLog",
		resourceSelectedType: "POST",
		resourceSelectedIdentity: "AgencyLog",
		resourceDisplayFields: [ ]
	},
	functionactions:
	{
		createnew: {},
		update: {},
		after: {
			functionname: "GetAgencyLogList",
			functiontype: "GET"
		}
	},
	formDefinition: {
		rows: [
			{
			columns: [
				{
				title: "Agency Log Information",
				fields: [
					
					{
						key: "ActionTaken",
						type: "text",
						defaultValue: "",
						label: "Action Taken",
						placeholder: "Action Taken",
						readOnly: false, 
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2,
							"data-parsley-maxlength": 255
						}
					},
					{
						key: "UserName",
						type: "text",
						defaultValue: "",
						label: "User Name",
						placeholder: "User Name",
						readOnly: false, 
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2,
							"data-parsley-maxlength": 20
						}
					},
					{
						key: "ActionDate",
						type: "date",
						defaultValue: "",
						label: "Action Date",
						placeholder: "Action Date",
						readOnly: false, 
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2
						}
					}
				]
			  },
			  {
				title: "\n",
				fields: [
					
				]
				}
			]
			}
		]
	}
}