import AgencySearch from "./agencysearch";
import AgencyLogList from "../agencylog/agencyloglist";
import CargoInvoiceList from "../cargoinvoice/cargoinvoicelistbyagency";
export default {
	track: "Agency",
	formtype: "dataEntry",
	customSearch: {
	  searchForm: { ...AgencySearch },
	  width: 800,
	  align: 500,
	  height: 800,
	  id: "agencysearch"
	},

	allowSearch: [
		{
			role:  [ "default" ],
			value:  true
		}
	],
	allowDelete: [
	  {
		role: ["default"],
		value: false
	  }
	],
	allowAudit: [
	  {
		role: ["default"],
		value: false
	  }
	],
	allowEdit: [
	  {
		role: ["default"],
		value: false
	  },
	  {          
		role: ["Accounting Administrator", "Accounting Member"],
		value: true
	  }
	],
	allowAdd: [
	  {
		role: ["default"],
		value: false
	  },
	  {          
		role: ["Accounting Administrator", "Accounting Member"],
		value: true
	  }
	],  
	caption:  "Agency Details",
	editSearchSelector: {
		resource: "GetAgencyList",
		resourceType: "GET",
		resourceId: [ "AgencyId" ],
		resourceIdentity: "Agencies",
		resourceValue: "AgencyId",
		resourceSelected: "GetAgency",
		resourceSelectedType: "POST",
		resourceSelectedIdentity: "Agency",
		resourceDisplayFields: [ ]
	},
	functionactions:
	{
		createnew: {
			functionname: "AddAgency",
			functiontype: "POST",
			validateData: true,
			alertOnComplete: true,
			alertTitle: "Agency Information Added",
			alertText: "The Agency Has Been Added"
		},
		update: {
			functionname: "UpdateAgency",
			functiontype: "POST",
			validateData: true,
			alertOnComplete: true,
			alertTitle: "Agency Information Updated",
			alertText: "The Agency Has Been Updated"
		}
	},
	formDefinition: {
		rows: [
			{
			columns: [
				{
				title: "Agency Information",
				fields: [
					{
						key: "AgencyId",
						type: "text",
						label: "Agency Id",
						placeholder: "Agency Id",
						readOnly: false, 
						readOnlyOnEdit: true,
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2,
							"data-parsley-maxlength": 5
						}
					},
					{
						key: "AgencyName",
						type: "text",
						defaultValue: "",
						label: "Agency Name",
						placeholder: "Agency Name",
						readOnly: false, 
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2,
							"data-parsley-maxlength": 50
						}
					},
					{
						key: "TypeCode",
						type: "select",
						defaultValue: "",
						label: "Business Type",
						placeholder: "Business Type",
						readOnly: false, 
						optionsResource: {
						  resource: "GetAccountTypeList",
						  resourceType: "GET",
						  resourceId: ["TypeCode"],
						  resourceIdentity: "AccountTypes",
						  resourceValue: "Description"
						},
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 1,
							"data-parsley-maxlength": 4
						}
					},
					{
						key: "Active",
						type: "checkbox",
						defaultValue: "True",
						label: "Active",
						placeholder: "Active",
						readOnly: false
					},
					{
						key: "Taxable",
						type: "checkbox",
						defaultValue: "",
						label: "Taxable",
						placeholder: "Taxable",
						readOnly: false
					},
					{
						key: "PurchaseOrderRequired",
						type: "checkbox",
						defaultValue: "",
						label: "Purchase Order Required",
						placeholder: "Purchase Order Required",
						readOnly: false
					},
					{
						key: "CargoInvoiceFrequencyType",
						type: "select",
						defaultValue: "Semi-Monthly",
						label: "Invoice Frequency",
						placeholder: "Invoice Frequency",
						readOnly: false, 
						optionsResource: {
						  resource: "GetCargoInvoiceFrequencyTypeList",
						  resourceType: "GET",
						  resourceId: ["CargoInvoiceFrequencyType"],
						  resourceIdentity: "CargoInvoiceFrequencyTypes",
						  resourceValue: "CargoInvoiceFrequencyType"
						},
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2,
							"data-parsley-maxlength": 20
						}
					},
					{
						key: "GSTTIN",
						type: "text",
						defaultValue: "",
						label: "GST TIN",
						placeholder: "GST TIN",
						readOnly: false, 
						validation: {
							"data-parsley-maxlength": 10
						}
					},
					{
						key: "AlternateAgencyId",
						type: "text",
						defaultValue: "",
						label: "Alternate Agency Id",
						placeholder: "Alternate Agency Id",
						readOnly: false, 
						validation: {
							"data-parsley-maxlength": 10
						}
					}
				]
			  },
			  {
				title: "Particulars",
				fields: [
					{
						key: "Address1",
						type: "text",
						defaultValue: "",
						label: "Address",
						placeholder: "Address",
						readOnly: false, 
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2,
							"data-parsley-maxlength": 30
						}
					},
					{
						key: "Address2",
						type: "text",
						defaultValue: "",
						label: "Address",
						placeholder: "Address",
						readOnly: false, 
						validation: {
							"data-parsley-maxlength": 30
						}
					},
					{
						key: "City",
						type: "text",
						defaultValue: "",
						label: "City",
						placeholder: "City",
						readOnly: false, 
						validation: {
							"data-parsley-maxlength": 30
						}
					},
					{
						key: "State",
						type: "text",
						defaultValue: "",
						label: "State",
						placeholder: "State",
						readOnly: false, 
						validation: {
							"data-parsley-maxlength": 5
						}
					},
					{
						key: "MailCode",
						type: "text",
						defaultValue: "",
						label: "Mail Code",
						placeholder: "Mail Code",
						readOnly: false, 
						validation: {
							"data-parsley-maxlength": 10
						}
					},
					{
						key: "Country",
						type: "text",
						defaultValue: "",
						label: "Country",
						placeholder: "Country",
						readOnly: false, 
						validation: {
							"data-parsley-maxlength": 25
						}
					},
					{
						key: "Telephone1",
						type: "text",
						defaultValue: "",
						label: "Telephone1",
						placeholder: "Telephone1",
						readOnly: false, 
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2,
							"data-parsley-maxlength": 20
						}
					},
					{
						key: "Telephone2",
						type: "text",
						defaultValue: "",
						label: "Telephone2",
						placeholder: "Telephone2",
						readOnly: false, 
						validation: {
							"data-parsley-maxlength": 20
						}
					},
					{
						key: "WebSiteAddress",
						type: "text",
						defaultValue: "",
						label: "Web Site",
						placeholder: "Web Site",
						readOnly: false, 
						validation: {
							"data-parsley-maxlength": 50
						}
					},
				]
			  },
			  {
				title: "Contact Information",
				fields: [
					{
						key: "ContactName",
						type: "text",
						defaultValue: "",
						label: "Contact",
						placeholder: "Contact",
						readOnly: false, 
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2,
							"data-parsley-maxlength": 50
						}
					},
					{
						key: "EmailAddress",
						type: "text",
						defaultValue: "",
						label: "Email Address",
						placeholder: "Email Address",
						readOnly: false, 
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2,
							"data-parsley-maxlength": 50
						}
					},
					{
						key: "EmailAddress2",
						type: "text",
						defaultValue: "",
						label: "Secondary Email Address",
						placeholder: "Secondary Email Address",
						readOnly: false, 
						validation: {
							"data-parsley-maxlength": 50
						}
					},
					{
						key: "AccountRep",
						type: "select",
						defaultValue: "",
						label: "Account Rep",
						placeholder: "Account Rep",
						readOnly: false,
						optionsResource: {
						  resource: "GetAccountingStaffList",
						  resourceType: "GET",
						  resourceId: ["UserName"],
						  resourceIdentity: "SystemUsers",
						  resourceValue: "FullUserName"
						},
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2,
							"data-parsley-maxlength": 20
						}
					},
					{
						key: "AlternateAccountRep",
						type: "select",
						defaultValue: "",
						label: "Alternate Account Rep.",
						placeholder: "Alternate Account Rep.",
						readOnly: false,
						optionsResource: {
						  resource: "GetAccountingStaffList",
						  resourceType: "GET",
						  resourceId: ["UserName"],
						  resourceIdentity: "SystemUsers",
						  resourceValue: "FullUserName"
						},
						validation: {
							"data-parsley-maxlength": 20
						}
					},
					{
						key: "AccountOpenDate",
						type: "date",
						defaultValue: "",
						label: "Account Open Date",
						placeholder: "Account Open Date",
						readOnly: false, 
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2
						}
					},
					{
						key: "MonthlyCargoDiscountAmount",
						type: "number",
						format: "$0,0.00",
						defaultValue: "",
						label: "Monthly Discount Amount",
						placeholder: "Monthly Discount Amount",
						readOnly: false, 
						validation: {
							"data-parsley-min": 0,
							"data-parsley-max": 100000,
							"data-parsley-employeediscountrequired": true
						}
					},
					{
						key: "Payee",
						type: "text",
						defaultValue: "",
						label: "Payee",
						placeholder: "Payee",
						readOnly: false, 
						validation: {
							"data-parsley-maxlength": 50
						}
					}
				]
			  },
			  {
				title: "Credit Control",
				fields: [
					{
						key: "ApplyCreditLimit",
						type: "checkbox",
						defaultValue: "",
						label: "Apply Credit Limit",
						placeholder: "Apply Credit Limit",
						readOnly: false
					},
					{
						key: "CreditLimit",
						type: "number",
						format: "$0,0.00",
						defaultValue: "0",
						label: "Credit Limit",
						placeholder: "Credit Limit",
						readOnly: false, 
						validation: {
							"data-parsley-min": 0,
							"data-parsley-max": 1000000
						}
					},
					{
						key: "AccountBalance",
						type: "number",
						format: "$0,0.00",
						defaultValue: "",
						label: "Account Balance",
						placeholder: "Account Balance",
						readOnly: true
					},
					{
						key: "CurrentBalance",
						type: "number",
						format: "$0,0.00",
						defaultValue: "",
						label: "Current Balance",
						placeholder: "Current Balance",
						readOnly: true
					},
					{
						key: "CreditLimitWarningLevelPercentage1",
						type: "number",
						defaultValue: "",
						label: "First Credit Limit Warning Level %age",
						placeholder: "Credit Limit Warning Level %age",
						readOnly: false, 
						validation: {
							"data-parsley-min": 0,
							"data-parsley-max": 100
						}
					},
					{
						key: "CreditLimitWarningLevelPercentage2",
						type: "number",
						defaultValue: "",
						label: "Second Credit Limit Warning Level %age",
						placeholder: "Second Credit Limit Warning Level %age",
						readOnly: false, 
						validation: {
							"data-parsley-min": 0,
							"data-parsley-max": 100
						}
					},
					{
						key: "LastCreditLimitWarningLevelPercentageValue",
						type: "number",
						defaultValue: "",
						label: "Last Credit Limit Warning Level %age",
						placeholder: "Last Credit Limit Warning Level %age",
						readOnly: false
					},
					{
						key: "LockoutExemptionBeginDate",
						type: "date",
						defaultValue: "",
						label: "Lockout Exemption Begin Date",
						placeholder: "Lockout Exemption Begin Date",
						readOnly: false
					},
					{
						key: "LockoutExemptionEndDate",
						type: "date",
						defaultValue: "",
						label: "Lockout Exemption End Date",
						placeholder: "Lockout Exemption End Date",
						readOnly: false
					}
				]
				}
			]
			},
			{
			  columns: [
				{
				  title: "",
				  fields: [
					{
					  type: "tabs",
	  
					  tabs: [
						{
						  caption: "Invoices",
						  definition: { ...CargoInvoiceList },
						},
						{
						  caption: "Logs",
						  definition: { ...AgencyLogList },
						}
					  ]
					}
				  ]
				}
			  ]
			}
		  ]
		}
	  };