export default {
  duplicates: [],
  access: [],
  track: "Customer",
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: true
    },
    {
      role: ["Company Administration Administrator"],
      value: true
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    },
    {
      role: ["Company Administration Administrator"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Company Administration Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Company Administration Administrator"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Customer Details",
  editSearchSelector: {
    resource: "getCustomerList",
    resourceType: "GET",
    resourceId: ["CustomerRecordNumber"],
    resourceIdentity: "Customers",
    resourceValue: "CustomerName",
    resourceSelected: "getCustomer",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "Customer",
    resourceDisplayFields: [],
    after: []
  },
  model: {
    modelid: "getCustomer",
    autoload: false
  },
  functionactions: {
    createnew: {
      functionname: "addCustomer",
      functiontype: "POST",
      validateData: true,
      validationFailedTitle: "",
      validationFailedText: "",
      alertOnComplete: true,
      alertTitle: "Customer Information Added",
      alertText: "The Customer Has Been Added"
    },
    update: {
      functionname: "updateCustomer",
      functiontype: "POST",
      validateData: true,
      validationFailedTitle: "",
      validationFailedText: "",
      alertOnComplete: true,
      alertTitle: "Customer Information Updated",
      alertText: "The Customer Has Been Updated"
    },
    after: {
      functionname: "getCustomerList",
      functiontype: "POST"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Customer Information",
            fields: [
              {
                key: "CustomerName",
                type: "text",
                defaultvalue: "",
                label: "Customer Name",
                placeholder: "Customer Name",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 100
                }
              },
              {
                key: "CustomerAddress1",
                type: "text",
                defaultvalue: "",
                label: "Address 1",
                placeholder: "Address 1",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 100
                }
              },
              {
                key: "CustomerAddress2",
                type: "text",
                defaultvalue: "",
                label: "Address 2",
                placeholder: "Address 2",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 100
                }
              },
              {
                key: "CustomerCity",
                type: "text",
                defaultvalue: "",
                label: "City",
                placeholder: "City",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 100
                }
              },
              {
                key: "CustomerState",
                type: "text",
                defaultvalue: "",
                label: "State",
                placeholder: "State",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 2
                }
              },
              {
                key: "CustomerZip",
                type: "text",
                defaultvalue: "",
                label: "Zip",
                placeholder: "Zip",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 10
                }
              },
              {
                key: "CustomerCountry",
                type: "text",
                defaultvalue: "",
                label: "Country",
                placeholder: "Country",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 100
                }
              }
            ]
          },
          {
            title: "Contact Information",
            fields: [
              {
                key: "TelephoneNumber1",
                type: "text",
                defaultvalue: "",
                label: "Telephone Number 1",
                placeholder: "Telephone Number 1",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 12
                }
              },
              {
                key: "TelephoneNumber2",
                type: "text",
                defaultvalue: "",
                label: "Telephone Number 2",
                placeholder: "Telephone Number 2",
                required: false,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 12
                }
              },
              {
                key: "FaxNumber",
                type: "text",
                defaultvalue: "",
                label: "Fax Number",
                placeholder: "Fax Number",
                required: false,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 12
                }
              },
              {
                key: "EmailAddress",
                type: "text",
                defaultvalue: "",
                label: "Email Address",
                placeholder: "Email Address",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-type": "email",
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "WebSiteAddress",
                type: "text",
                defaultvalue: "",
                label: "Web Site Address",
                placeholder: "Web Site Address",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "",
                type: "spacer"
              },
              {
                key: "",
                type: "spacer"
              }
            ]
          },
          {
            title: "Customer Details",
            fields: [
              {
                key: "CustomerActive",
                type: "checkbox",
                defaultvalue: "",
                label: "Active",
                placeholder: "Active",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 10
                }
              },
              {
                key: "Terms",
                type: "text",
                defaultvalue: "",
                label: "Terms",
                placeholder: "Terms",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 15
                }
              },
              {
                key: "Taxable",
                type: "checkbox",
                defaultvalue: "",
                label: "Taxable",
                placeholder: "Taxable",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 10
                }
              },
              {
                key: "CurrencyType",
                type: "select",
                defaultvalue: "",
                label: "Currency Type",
                placeholder: "Currency Type",
                required: true,
                readOnly: false,
                larger: false,
                isPrimary: false,
                optionsResource: {
                  resource: "getCurrencyTypeList",
                  resourceType: "GET",
                  resourceId: ["CurrencyType"],
                  resourceIdentity: "CurrencyTypes",
                  resourceValue: "CurrencyType"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 2
                }
              },
              {
                key: "GSTTIN",
                type: "text",
                defaultvalue: "",
                label: "GST TIN",
                placeholder: "GST TIN",
                required: false,
                readOnly: false,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 10
                }
              },
              {
                key: "EnteredBy",
                type: "text",
                defaultvalue: "",
                label: "Entered By",
                placeholder: "Entered By",
                required: true,
                readOnly: true,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 20
                }
              },
              {
                key: "EnterDate",
                type: "text",
                defaultvalue: "",
                label: "Enter Date",
                placeholder: "Enter Date",
                required: true,
                readOnly: true,
                larger: false,
                isPrimary: false,
                validation: {
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 20
                }
              }
            ]
          }
        ]
      }
    ]
  }
};
