import loadDataFromApi from '../API/index';
import moment from 'moment';
import { observable } from 'mobx';

class UserAuthentication {
  constructor() {
    this.userDetails = {
      isAuthenticated: false,
    };
    this.notifications = observable({ details: [],selected:"user" });
    
  }

  refreshNotifications() {
    loadDataFromApi('notifications', 'POST', {}, (data) => {
     const notes = [];
     
     data.Notifications.forEach((note)=>{
        var data = JSON.parse(note.NotificationMessage);
        data.date = moment(note.NotificationDate).fromNow();
        data.target = note.TargetType;
        data.id=note.NotificationMessageRecordNumber;
        notes.push(data);
     });

      this.notifications.details = notes;
    });
  }

  getUserDetails() {
    return this.userDetails;
  }

  startConnection(callback) {
    
    let pulse = [];
    for(let i=0;i<1000;i++)
    {
      pulse.push(2000);
    }
    this.connection = new window.signalR.HubConnectionBuilder()
      .withUrl('/messageHub')
      .withAutomaticReconnect(pulse)
      .configureLogging(window.signalR.LogLevel.Information)
      .build();

    this.connection.on('message', (data) => {

      const message = JSON.parse(data);

      if(message.typeName==="new_notifications")
      {
        const notificationSound = new Audio('/audio/ding.mp3');
        notificationSound.loop = false;
        notificationSound.play();
      }
      
      this.refreshNotifications();
    });
    this.refreshNotifications();
    this.connection.start().then(callback);
  }

  setUserDetails(details) {
  
    if(details)
    {
      this.userDetails = details;
    }
    else
    {
      this.userDetails = {
        isAuthenticated: false,
      };
    }
  }
}

const userStore = new UserAuthentication();

export default userStore;
