
import Colors from "../../../constants/Colors";

export default {
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator", "Cargo Member"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Charge Calculator",
  calculateForm:true,
  editSearchSelector: {
    resource: "GetCompanyList",
    resourceId: ["CompanyRecordNumber"],
    resourceMethod:"GET",
    resourceIdentity: "Companies",
    resourceValue: "CompanyRecordNumber",
    resourceSelected: "GetCargoCharge",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoCharge",
    resourceDisplayFields: [],
    after: []
  },
  functionactions: {
    calculate: {
      functionname: "GetCargoCharge",
      functiontype: "POST",
      resourceIdentity: {
        id: "CargoCharge",
        mapping: [
          {
            id: "ShippingCost",
            key: "ShippingCost"
          }
        ]
      },
      validateData: true,
      alertOnComplete: false,
      alertTitle: "Airwaybill Information Added",
      alertText: "The Airwaybill Has Been Added"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Route and Payment",
            fields: [
              {
                key: "CityFrom",
                type: "select",
                defaultValue: "StationPosted",
                label: "From",
                placeholder: "From",
                readOnly: false,
                optionsResource: {
                  resource: "GetActiveCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 4
                }
              },
              {
                key: "CityTo",
                type: "select",
                defaultValue: "",
                label: "To",
                placeholder: "To",
                readOnly: false,
                optionsResource: {
                  resource: "GetActiveCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 4,
                  "data-parsley-differentcityfromandcityto": true
                }
              },              
              {
                key: "PaymentType",
                type: "select",
                defaultValue: "",
                label: "Payment Type",
                placeholder: "Payment Type",
                readOnly: false,
                optionsResource: {
                  resource: "GetAirwaybillPaymentTypeList",
                  resourceType: "POST",
                  resourceId: ["AirwaybillPaymentType"],
                  resourceIdentity: "AirwaybillPaymentTypes",
                  resourceValue: "AirwaybillPaymentTypeDescription"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 6
                }
              }
            ]
          },
          {
            title: "Parties",
            fields: [               
              {
                key: "Sender",
                type: "text",
                defaultValue: "",
                label: "Sender",
                placeholder: "Sender",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              },              
              {
                key: "Recipient",
                type: "text",
                defaultValue: "",
                label: "Recipient",
                placeholder: "Recipient",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "BillingAgent",
                type: "select",
                defaultValue: "",
                label: "Billing Agent",
                placeholder: "Billing Agent",
                readOnly: false,
                optionsResource: {
                  resource: "GetAgencyList",
                  resourceType: "GET",
                  resourceId: ["AgencyId"],
                  resourceIdentity: "Agencies",
                  resourceValue: "AgencyName"
                }
              }
            ]
          },
          {
            title: "Service and Weight",
            fields: [
              {
                key: "ServiceType",
                type: "select",
                defaultValue: "Regular",
                label: "Service Type",
                placeholder: "Service Type",
                readOnly: false,
                optionsResource: {
                  resource: "GetActiveCargoServiceTypeList",
                  resourceType: "GET",
                  resourceId: ["CargoServiceType"],
                  resourceIdentity: "CargoServiceTypes",
                  resourceValue: "CargoServiceType"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 10
                }
              }, 
              [{
                key: "TotalWeight",
                type: "number",
                format: "0,0.0",
                defaultValue: 1,
                label: "Total Weight",
                placeholder: "Total Weight",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 1,
                  "data-parsley-max": 1500,
                  "data-parsley-maxlength": 10
                }
              },
              {
                width:6,
                key: "TotalVolume",
                type: "number",
                format: "0,0.0",
                defaultValue: "0",
                label: "Total Volume",
                placeholder: "Total Volume",
                readOnly: false,
                validation: {
                  "data-parsley-min": 0,
                  "data-parsley-max": 1500000,
                  "data-parsley-maxlength": 10
                }
              }]
            ]
          },
          {
            title: "Shipping Cost",
            fields: [       
              {
                key: "ShippingCost",
                type: "number",
                format: "$0,0.00",
                defaultValue: "",
                label: "Shipping Cost",
                placeholder: "Shipping Cost",
                readOnly: true,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 0,
                  "data-parsley-max": 100000,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 10
                }
              }
            ]
          }
        ]
      }
    ]
  }
};
