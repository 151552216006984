import React from "react";
import computeId from "../../actions/computeId";
import { Observer } from "mobx-react";
import computeValidation from "../../actions/computeValidation";
import BindModel from "../../../Core/BindModel";
import computeAsterisk from "../../actions/computeAsterisk";

const PasswordTextBox = props => (
  <Observer>
    {() => (
      <div className="form-group">
        <label>{props.Config.label} {computeAsterisk(props.Config)}</label>
        <input
          id={computeId(props.Config)}
          readOnly={props.ReadOnly}
          autoComplete="off"
          type="password"
          {...computeValidation(props.Config,props.FormId)}
          className="form-control"
          placeholder={props.Config.placeholder}
          {...BindModel(
            props.EntryDetails,
            props.Config.key,
            "value",
            "onChange"
          )}
        />
      </div>
    )}
  </Observer>
);
export default PasswordTextBox;
