import React, { useState, useRef, useEffect } from "react";
import computeId from "../../actions/computeId";
import { Observer } from "mobx-react";
import { observable } from 'mobx';
import computerActionProcessor from "../../../Core/ActionBar/actions/computeActionProcessor";
import Notifications from '../../../Core/Notification';
import BindModel from "../../../Core/BindModel";
import computeAsterisk from "../../actions/computeAsterisk";
import BarCodeStyle from "./BarCodeStyle";
import onScan from 'onscan.js';

import $ from "jquery";



const BarCode = (props) => {
    // Initializing useDropzone hooks with options
    useEffect(() => {
        // Update the document title using the browser API
        if (props.Config.autoScan) {
            activateScan();
        }
    }, []);
    const scanDetails = observable({
        beginScan: false,
        lastScanned: []
    });

    const deactivateScan = (e) => {
        if (e) {
            e.preventDefault();
        }
        setTimeout(() => {
            try {
                onScan.detachFrom(window.document);
            }
            catch
            {

            }
            scanDetails.beginScan = false;
        }, 1000);

    };

    const innerOnStateChanged = (currentState, data) => {
        props.onStateChanged(currentState, data);
    };

    const execAction = (index, codes) => {

        if (index == codes.length) {
            
            if (props.Config.continuousScan !== true) {
                deactivateScan();
            }
            return;
        }

        if (props.Config.action) {

            if (codes[index].trim().length > 0) {


                props.EntryDetails[props.Config.key] = codes[index];


                const newProps = {
                    FormDefinition: props.Config.action.definition,


                    Config: props.Config,

                    ParentData: props.ParentData,
                    stack: props.stack,
                    readOnly: props.ReadOnly,
                    Locked:
                        props.Config.action.Locked === true ||
                        props.Config.action.locked === true ||
                        props.Config.action.disableEditing === true.valueOf,

                    willLaunchDialog: true,
                    isDialog: false,
                    isAdding: props.Config.action.adding,
                    isEditing: props.Config.action.editing,
                    EntryDetails: props.EntryDetails
                }

                computerActionProcessor(props.Config.action, newProps, innerOnStateChanged)

                    .then(() => {
                        execAction(index + 1, codes);
                    })
                    .catch((ex) => {
                        execAction(index + 1, codes);

                    });
            }
            else {
                execAction(index + 1, codes);
            }
        }
        else {
            if (props.Config.continuousScan !== true) {
                deactivateScan();
            }

        }
    }

    const scanHandler = (sScancode, iQuantity) => {
        var FormId = props.FormId;
        var tabTarget = document.getElementById(FormId);
        var closestDiv = tabTarget.closest(".tab-pane");
        var parentDiv = closestDiv.parentElement;
        if (parentDiv.style.display !== 'none') {
            const codes = sScancode.split('\n');
            execAction(0, codes);
        }
        scanDetails.lastScanned.push(sScancode);
    };

    const _getNormalizedKeyNum = (e) => {
        return e.which || e.keyCode;
    };

    const InternalKeyCodeMapper = (oEvent) => {
        const iCode = _getNormalizedKeyNum(oEvent);
        
        switch (true) {
            case iCode >= 48 && iCode <= 90: // numbers and letters
            case iCode == 191: // numbers and letters
            case iCode >= 106 && iCode <= 111: // operations on numeric keypad (+, -, etc.)
                if (oEvent.key !== undefined && oEvent.key !== '') {
                    return oEvent.key;
                }

                const sDecoded = String.fromCharCode(iCode);
                switch (oEvent.shiftKey) {
                    case false: sDecoded = sDecoded.toLowerCase(); break;
                    case true: sDecoded = sDecoded.toUpperCase(); break;
                }
                return sDecoded;
            case iCode >= 96 && iCode <= 105: // numbers on numeric keypad
                return 0 + (iCode - 96);
        };
        return '';
    }
    const activateScan = (e) => {
        if (e) {
            e.preventDefault();
        }
        try {
            onScan.attachTo(window.document, {
                onScan: scanHandler,
                singleScanQty: 1000,
                keyCodeMapper: InternalKeyCodeMapper
            });
        }
        catch
        {
            onScan.detachFrom(window.document);
            onScan.attachTo(window.document, {
                onScan: scanHandler,
                singleScanQty: 1000,
                keyCodeMapper: InternalKeyCodeMapper
            });
        }

        scanDetails.beginScan = true;



    };

    const renderBars = () => {


        const baritem = [];

        for (var i = 0; i < 30; i++) {

            baritem.push(
                <>
                    <div className='anim-item anim-item-sm'></div>
                    <div className='anim-item anim-item-lg'></div>
                    <div className='anim-item anim-item-lg'></div>
                </>
            );
        }
        return baritem;
    }


    return (

        <Observer>
            {() => (
                <BarCodeStyle>

                    <div className="form-group">
                        <div>
                            {!scanDetails.beginScan && <button className="scanButton" onClick={activateScan}><i className="mdi mdi-barcode-scan"></i><span>{props.Config.startText}</span></button>}
                            <div className="holder1">
                                <div className="holder">

                                    {scanDetails.beginScan && <table>
                                        <tr>
                                            <td>

                                                <div className='anim-box center spacer'>


                                                    <div className='scanner'></div>
                                                    {renderBars().map((code, index) =>

                                                        <>{code}</>

                                                    )}


                                                </div>

                                            </td>
                                            <td>

                                                {props.Config.continuousScan === true ?

                                                    <button className="endScanButton" onClick={deactivateScan}><i className="mdi mdi-barcode-scan"></i><span>{props.Config.endText}</span></button> : <span></span>
                                                }

                                            </td>
                                        </tr>
                                    </table>}


                                </div>
                            </div>
                        </div>
                        {scanDetails.lastScanned.length > 0 && <span className="lastScanText">History</span>}
                        {scanDetails.lastScanned.length > 0 ? (<div className="scanneditems">


                            {
                                scanDetails.lastScanned.map((code, index) =>

                                    <div className="scancode" key={code + index.toString()}>
                                        <span>{code}</span>
                                    </div>

                                )
                            }


                        </div>) : (<span />)}

                    </div>


                </BarCodeStyle>
            )}
        </Observer>

    )
}
    ;
export default BarCode;
