import React from 'react';

const TabHeader = props => (
  <div id={props.TabHeaderId}>
    <button
      title="Open an instance of this tab in a new window"      
      className="close-icon"
      style={{ cursor: 'pointer', padding: '8px 0px 8px 5px' }}
      onClick={e => {
        e.preventDefault();
        props.NewWindow(props.FormDefinition, props.Menu, props.ControlId);
      }}
    >
      <i className="icon-popup"></i>
    </button>
    <button
      style={{ cursor: 'pointer', padding: '8px 16px 8px 16px' }}
      onClick={e => {
        e.preventDefault();
        props.Select();
      }}
    >
      {props.Caption}
    </button>
    <button
      title="Close tab"      
      className="close-icon"
      style={{ cursor: 'pointer', padding: '8px 5px 8px 0px' }}
      onClick={e => {
        e.preventDefault();
        props.Close();
      }}
    >
      <i className="icon-cross"></i>
    </button>
  </div>
);

export default TabHeader;
