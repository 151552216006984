import React from "react";
import ReactDOM from "react-dom";
import $ from 'jquery';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { user,getMeta } from "./utilities";
import { Observer } from "mobx-react";
import { ThemeProvider,CacheProvider } from "@emotion/react";
import TabContainer from "./components/SideBarRenderer/TabContainer";
import themeStore from "./stores/themeStore.js";
import NotificationComponent from "./components/Core/Notification/NotificationComponent";
import createCache from "@emotion/cache";
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5UdkBhWn1fc3ZSRWhf');

const nval = getMeta()[0];
const correctlyNoncedEmotionCache = createCache({
  key: "css-key",
  nonce: nval

});

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");



function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
document.documentElement.style.setProperty("--animate-duration", ".4s");

if (!getParameterByName("id")) {
  const Component = () => {
    return (
      <Observer>
        {() => (
          <CacheProvider value={correctlyNoncedEmotionCache}>
            {themeStore.theme.themeLoaded ? (
              <ThemeProvider theme={themeStore.theme.theme}>
                <App />
                <NotificationComponent />
              </ThemeProvider>
            ) : (
              <div></div>
            )}
          </CacheProvider>
        )}
      </Observer>
    );
  };
  ReactDOM.render(<Component />, rootElement);
  
} else {
  window.setUser = function (userdetails, container) {
    const Component = () => {
      return (
        <Observer>
          {() => (
            <CacheProvider value={correctlyNoncedEmotionCache}>
              {themeStore.theme.themeLoaded ? (
                <ThemeProvider theme={themeStore.theme.theme}>
                  <div className="content-holder" style={{ left: 0 }}>
                    <TabContainer
                      Events={{}}
                      contentLeft={"0px"}
                      AutoTab={container}
                    />
                    <NotificationComponent />
                  </div>
                </ThemeProvider>
              ) : (
                <div></div>
              )}
            </CacheProvider>
          )}
        </Observer>
      );
    };
    user.setUserDetails(userdetails);
    ReactDOM.render(<Component />, rootElement);
    
  };
}

themeStore.loadTheme();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


function securityWarning() {
  console.log(
    '%cStop!',
    'background: transparent; font-size: 40px;font-weight:600;color:red;'
  );
  console.log(
    '%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a feature or "hack" someone\'s account, it is a scam and will give them access to your account.\n\nSee https://en.wikipedia.org/wiki/Self-XSS for more information.',
    'background: transparent; font-size: 18px;font-weight:300'
  );
}

function antiClickJacking() {
  if (window.self === window.top) {
    $("body").css("display","inherit");
  } else {
    window.top.location = window.self.location;
  }
}

securityWarning();
antiClickJacking();

if (getParameterByName('id')) {
  window.opener.onReady(getParameterByName('id'), window);
}