import CargoInvoiceAdjustment from "./cargoinvoiceadjustment";
import Colors from "../../../constants/Colors";

export default {
  track: "Cargo Invoice Adjustment List",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Accounting Administrator", "Accounting Member"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  caption: "Cargo Invoices",
  editSearchSelector: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "editabletable",
                customActions: [
                  {
                    showInEditMode: false,
                    showInAddMode: false,
                    showInLocked: false,
                    requiresSelection: true,
                    showInEditableMode: true,
                    eventType: "edit",
                    refreshAfterAction: true,
                    dialogOnly: false,
                    text: "Edit",
                    icon: "mdi mdi-pencil",
                    refreshAfterAction: true,
                    color: Colors.edit,
                    dependentFields: [
                      {
                        key: "AdjustmentStatus",
                        values: ["Processed", "Voided"],
                        active: false
                      }
                    ],
                    roles: [
                      {
                        role: ["Accounting Administrator", "Accounting Member"],
                        value: true
                      }
                    ]
                  }
                ], 
                key: ["InvoiceNumber", "AgencyName"],
                keylink: ["InvoiceNumber", "AgencyName"],
                data: {
                  getResource: {
                    resourceType: "POST",
                    id: "GetCargoInvoiceAdjustmentListByInvoiceNumber",
                    resourceIdentity: "CargoInvoiceAdjustments"
                  }
                },
                entry: {
                  resource: {
                    resourceType: "POST",
                    id: "GetCargoInvoiceAdjustment",
                    resourceIdentity: "CargoInvoiceAdjustment"
                  },
                  definition: { ...CargoInvoiceAdjustment }
                },
                fields: [
                  {
                    key: "AdjustmentNumber",
                    type: "text",
                    label: "Adjustment No."
                  },  
                  {
                    key: "TransactionTypeDescription",
                    type: "text",
                    label: "Adjustment Type"
                  },
                  {
                    key: "AdjustmentDate",
                    type: "date",
                    label: "Adjustment Date"
                  },
                  {
                    key: "AdjustmentAmount",
                    type: "number",
                    label: "Amount",
                    format: "c2",
                  },
                  {
                    key: "AdjustmentStatus",
                    type: "text",
                    label: "Status"
                  }
                ]
              }
            ]
          }
        ]
      }     
    ]
  }
};



