import React from "react";
import { Observer } from "mobx-react";

const Label = props => (
    <Observer>
    {() => (
      <div className="form-group">
        <label>{props.Config.label}</label>
        <span
          className="form-control"
          style={{
            display: "block",
            minHeight: "31px",            
          }}
        >
          {props.EntryDetails[props.Config.key]}
        </span>
      </div>
    )}
  </Observer>
);
export default Label;
