export default {
  track: "CargoInvoiceBatch",
  formtype: "dataEntry",
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                key: "InvoiceNumber",
                type: "text",
                label: "Invoice Number",
                placeholder: "Invoice Number",
                readOnly: false
              },
              {
                key: "AgencyId",
                type: "select",
                label: "Station",
                placeholder: "Station",
                readOnly: false,
                optionsResource: {
                  resource: "GetActiveCargoStationList",
                  resourceType: "GET",
                  resourceId: ["BillingAgencyId"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                }
              }
            ]
          },
          {
            title: '\n',
            fields: [
              {
                key: "BeginDate",
                type: "date",
                label: "Begin Date",
                placeholder: "Begin Date",
                readOnly: false
              },
              {
                key: "EndDate",
                type: "date",
                label: "End Date",
                placeholder: "End Date",
                readOnly: false
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "",
            hideOnAdd: "true",
            fields: [
              {
                type: "searchtable",
                id:"CashboxSearch",
                data: {
                  getResource: {
                    id: "GetCashBoxListForSearch",
                    resourceType: "POST",
                    resourceIdentity: "CashBoxes"
                  }
                },
                fields: [
                  {
                    key: "AgencyName",
                    type: "text",
                    label: "Agency"
                  },
                  {
                    key: "InvoiceNumber",
                    type: "text",
                    label: "Invoice No."
                  },
                  {
                    key: "CashBoxDate",
                    type: "date",
                    label: "Cash Box Date"
                  },
                  {
                    key: "CashBoxStatus",
                    type: "text",
                    label: "Status"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
