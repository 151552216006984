import { loadData } from '../../../../utilities';
import { toJS, observable } from 'mobx';
import onLaunchDialog from './onLaunchDialog';
import $ from 'jquery';
import Notifications from '../../../Core/Notification';
import onConfirmation from './onConfirmation';
const computeDefinition = (button, props, EntryDetails) => {
  let target = null;

  if (button.dependentCommandFields) {
    target = {
      action: null,
      definition: null,
      defaults: null,
    };
    button.dependentCommandFields.forEach((dependent) => {
      dependent.values.forEach((value) => {
        if (
          EntryDetails &&
          EntryDetails[dependent.key] &&
          EntryDetails[dependent.key]
            .toString()
            .trim()
            .toLowerCase() ===
            value
              .toString()
              .trim()
              .toLowerCase()
        ) {
          target.definition = dependent.definition;
          target.action = dependent.action;
          target.defaults = dependent.defaults;

          target.readOnly = false;

          if (dependent.readOnlyCondition) {
            dependent.readOnlyCondition.values.forEach((value) => {
              if (
                EntryDetails &&
                EntryDetails[dependent.readOnlyCondition.key] &&
                EntryDetails[dependent.readOnlyCondition.key]
                  .toString()
                  .trim()
                  .toLowerCase() ===
                  value
                    .toString()
                    .trim()
                    .toLowerCase()
              ) {
                target.readOnly = dependent.readOnlyCondition.readOnly;
              }
            });
          }
        }
      });
    });
  }

  return target;
};

const executeFormDependent = (button, props) => {
  return new Promise((resolve, reject) => {
    let validator = $(`#${props.FormId}`).parsley();
    if (validator && !validator.validate()) {
      Notifications.warning(
        'There was an issue submitting this form. Please ensure all required fields have been filled out.'
      );
      reject();
      return;
    }
    const launch = () => {
      const computed = computeDefinition(button, props, props.EntryDetails);
      if (computed) {
        if (computed.action && computed.action.functionName) {
          loadData(
            computed.action.functionName,
            computed.action.functionType,
            toJS(props.ParentData),
            (response) => {
              const EntryDetails = observable({
                ...response[computed.action.functionResourceIdentity],
              });

              onLaunchDialog(
                props,
                EntryDetails,
                false,
                !computed.readOnly,
                computed.definition,
                computed.readOnly,
                (afterAddResults) => {
                  return new Promise(() => {
                    if (afterAddResults) {
                      loadData(
                        button.after.functionName,
                        button.after.functionType,
                        toJS(afterAddResults),
                        (response1) => {
                          const EntryDetails1 = observable({
                            ...response1[button.after.functionResourceIdentity],
                          });
                          resolve(EntryDetails1);
                        },
                        () => {
                          reject();
                        }
                      );
                    } else {
                      resolve(null);
                    }
                  });
                }
              );

              resolve();
            },
            () => {
              reject();
            }
          );
        } else if (computed.defaults && computed.defaults.defaultValues) {
          const newEntry = { ...button.defaultValues };
          if (computed.defaults.keylink instanceof Array) {
            computed.defaults.keylink.forEach((item, index) => {
              newEntry[item] = props.EntryDetails[computed.defaults.key[index]];
            });
          } else {
            newEntry[computed.defaults.keylink] =
              props.EntryDetails[computed.defaults.key];
          }

          onLaunchDialog(
            props,
            observable(newEntry),
            !computed.readOnly,
            false,
            computed.definition,
            computed.readOnly,
            (afterAddResults) => {
              return new Promise(() => {
                if (afterAddResults) {
                  loadData(
                    button.after.functionName,
                    button.after.functionType,
                    toJS(afterAddResults),
                    (response1) => {
                      const EntryDetails1 = observable({
                        ...response1[button.after.functionResourceIdentity],
                      });
                      resolve(EntryDetails1);
                    },
                    () => {
                      reject();
                    }
                  );
                } else {
                  resolve(null);
                }
              });
            }
          );

          resolve();
        } else {
          onLaunchDialog(
            props,
            props.EntryDetails,
            false,
            !computed.readOnly,
            computed.definition,
            computed.readOnly
          );

          resolve();
        }
      }
    };
    onConfirmation(
      button,
      props,
      () => {
        launch();
        resolve();
      },
      () => {
        reject();
      }
    );
  });

  //props.onLaunchDialog
};

export default executeFormDependent;
