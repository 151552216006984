const Colors = {
    mainFont:"'Work Sans', sans-serif",
    headerFont:"'Work Sans', sans-serif",
    cursive:"'Work Sans', cursive;",
    backgroundDark:"#EBE8DF",    
    overlay:"rgba(235,232,223,0.8)",
    borderLight:"rgba(223,217,194,0.1)",
    borderMedium:"rgba(223,217,194,0.4)",
    backgroundDarker:"#DFD9C2",
    darkGray:"#555E69",
    backgroundLight:"#FFF",
    textMedium:"#555E69",   
    largeButtonColor:"#780032",
    textLight:"#fff",
    textMenu:"#2F353B",
    accent:"#780032",
    accentDarker:"#48001E",
    accentDark:"#600028",
    add:"#396000",
    save:"#1A5157",
    cancel:"#C70030",
    edit:"#FD9048",
    add2:"#396000",
    edit2:"#FD9048",
    search:"#780032",
    process:"#006666",
    verify:"#66A0BB",
    view:"#4C7EB1",
    print:"#663399",
    request:"#F48C41",
    placeHolder:"#C8C8C0",
    backgroundMedium:"#F3F2EC",
    searchButton:"#486978",
    notificationErrorText: '#AD0400',
    notificationInformationText: '#3F5C7F',
    notificationSuccessText: '#427220',
    notificationWarningText: '#FEBF58',
    notificationText: '#565F68',
    notificationSourceText: '#8F8F8F',
    toastBackgroundDefault: '#45A0FB',
    toastBackgroundSuccess: '#427220',
    toastBackgroundError: '#AD0400',
    toastBackgroundInfo: '#3F5C7F',
    toastBackgroundWarning: '#FEBF58',
    dialogBackground:'#fff'
};

export default Colors;
