import CargoManifestPackage from "./cargomanifestpackage";
import CargoManifestPackageAdd from "./cargomanifestpackageadd";
import CargoManifestPackageRemove from "./cargomanifestpackageremove";
import Colors from "../../../constants/Colors";
export default {
  duplicates: [],
  access: [],
  track: "CargoManifest Package List",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    },
    {
      role: ["Cargo Administrator", "Cargo Member", "Cargo Viewer"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Manifest Packages",
  editSearchSelector: {
  },
  model: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "editabletable", 
                customButtons : [
                  {
                    showInEditMode: false,
                    showInAddMode: false,
                    showInLocked: true,
                    requiresSelection: false,
                    showInEditableMode: true,
                    eventType: 'add',
                    dialogOnly: false,
                    text: 'Add',
                    icon: 'mdi mdi-plus',
                    color: Colors.add,
                    roles: [
                      {
                        role: ['Cargo Administrator','Cargo Member'],
                        value: true
                      }        
                    ],
                    parentDependentFields: [
                      {
                        key: 'ManifestStatus',
                        values: ['Closed', 'Voided'],
                        active: false
                      }
                    ]
                  },
                  {
                    showInEditMode: false,
                    showInAddMode: false,
                    showInLocked: true,
                    requiresSelection: false,
                    showInEditableMode: true,
                    eventType: 'form',
                    dialogOnly: false,
                    adding: true,
                    text: 'Scan Packages',
                    icon: 'mdi mdi-package',
                    refreshAfterSave: true,
                    color: Colors.add,
                    definition: { ...CargoManifestPackageAdd},
                    parentDependentFields: [
                      {
                        key: "ManifestStatus",
                        values: ["Closed", "Voided"],
                        active: false
                      }
                    ],
                    roles: [
                      {
                        role: ["Cargo Administrator", "Cargo Member"],
                        value: true
                      }        
                    ],
                    after: {
                      functionName: "GetCargoManifestPackageList",
                      functionType: "Post",
                      resourceIdentity: "CargoManifestPackages"
                    }
                  },
                  {
                    showInEditMode: false,
                    showInAddMode: false,
                    showInLocked: true,
                    requiresSelection: true,
                    showInEditableMode: true,
                    eventType: 'api',
                    dialogOnly: false,
                    text: 'Remove',
                    refreshAfterAction: true,
                    icon: 'mdi mdi-delete',
                    color: Colors.cancel,
                    roles: [
                      {
                        role: ['Cargo Administrator','Cargo Member'],
                        value: true
                      }        
                    ],
                    parentDependentFields: [
                      {
                        key: 'ManifestStatus',
                        values: ['Closed', 'Voided'],
                        active: false
                      }
                    ],
                    confirmation:{                    
                        title:"Confirm",
                        text:"Please confirm removal of package from manifest"
                    }, 
                    function: {
                      functionName: 'RemoveCargoManifestPackage',
                      functionType: 'POST'
                      },
                    validateData: true,
                    alertOnComplete: true,
                    alertText: 'The Package Has Been Removed.',
                    after: {
                      functionName: "GetCargoManifestPackageList",
                      functionType: "Post",
                      resourceIdentity: "CargoManifestPackages"
                    }
                  },
                  {
                    showInEditMode: false,
                    showInAddMode: false,
                    showInLocked: true,
                    requiresSelection: true,
                    showInEditableMode: true,
                    eventType: 'form',
                    dialogOnly: false,
                    adding: true,
                    text: 'Remove Packages - Scan',
                    icon: 'mdi mdi-delete',
                    refreshAfterSave: true,
                    color: Colors.cancel,
                    definition: { ...CargoManifestPackageRemove},
                    parentDependentFields: [
                      {
                        key: "ManifestStatus",
                        values: ["Closed", "Voided"],
                        active: false
                      }
                    ],
                    roles: [
                      {
                        role: ["Cargo Administrator", "Cargo Member"],
                        value: true
                      }        
                    ],
                    after: {
                      functionName: "GetCargoManifestPackageList",
                      functionType: "Post",
                      resourceIdentity: "CargoManifestPackages"
                    }
                  },
                ],
                key: ["ManifestNumber", "PackageType", "CityFrom", "CityTo"],
                keylink: ["ManifestNumber", "PackageType", "CityFrom", "CityTo"],
                data: {
                  getResource: {
                    resourceType: "POST",
                    id: "GetCargoManifestPackageList",
                    resourceIdentity: "CargoManifestPackages"
                  }
                },
                entry: {
                  resource: {
                    resourceType: "POST",
                    id: "GetCargoManifestPackage",
                    resourceIdentity: "CargoManifestPackage"
                  },
                  definition: { ...CargoManifestPackage }
                },
                fields: [
                  {
                    key: "AirwaybillPackageNumber",
                    type: "text",
                    label: "AWB / Pkg No.",
                    placeholder: "AWB / Pkg No.",
                    readOnly: true
                  },
                  {
                    key: "PackageDescription",
                    type: "text",
                    label: "Description",
                    placeholder: "Description",
                    readOnly: true
                  },
                  {
                    key: "Sender",
                    type: "text",
                    label: "Sender",
                    placeholder: "Sender",
                    readOnly: true
                  },
                  {
                    key: "Recipient",
                    type: "text",
                    label: "Recipient",
                    placeholder: "Recipient",
                    readOnly: true
                  },
                  {
                    key: "CityTo",
                    type: "text",
                    label: "Destination",
                    placeholder: "Destination",
                    readOnly: true
                  }
                ]
              }
            ]
          }
        ]
      }     
    ]
  }
};



