import { guid } from '../../../../../utilities';
import $ from 'jquery';

const format = function (data) {
  let other = '';

  if (data.other && this.props.Config.details) {
    other += `<div class="list-other-select">`;

    this.props.Config.details.forEach((item) => {
      other += ` <div><div class="list-icon-select other-item-select"><i class="mdi mdi-circle-medium"></i></div><div class="list-text-select other-item-select">${data.other[item]}</div></div> `;

      other += '</div>';
    });
  }
  const id = guid();
  const $state = $(
    `<div id="${id}" class="card-1-select">
      <div>      
      <div class="item-text-select">${data.text}</div>      
      ${other}
      </div>
      </div>`
  );
  if (this && this.props) {
    setTimeout(() => {
      $('#' + id)
        .parent()
        .on('mousedown', () => {
          if (this.props.updateOthers) {
            this.props.updateOthers.forEach((item) => {
              this.props.EntryDetails[item.key] = data.other[item.id];
            });
          }

          if (!this.props.ReadOnly) {
            if (this.props.Config.mapping) {
              this.props.Config.mapping.forEach((item) => {
                this.props.EntryDetails[item.key] = data.other[item.id];
              });
            }
          }
        });
    }, 10);
  }

  return $state;
};

export default format;
