import DeliverAirwaybillPackage from "./deliverairwaybillpackage";

export default {
  formtype: "dataEntry",
  

  allowEdit: [
    {
      role: ['default'],
      value: false,
    }    
  ],
  allowAdd: [
    {
      role: ['default'],
      value: false,
    },
  ],
  allowView: [
    {
      role: ['default'],
      value: false,
    },
  ],
  customButtons:[
    {
      showInEditMode: false,
      showInAddMode: false,
      showInLocked: true,
      requiresSelection: true,
      showInEditableMode: true,
      confirmationDepentent:{
        title:"Confirm",
        text:"Please Verify that COD payment was received.",  
        dependentCommandFields: [
          {
            key: "PaymentType",
            values: ["COD"]         
          }
        ]
      },
      eventType: 'form',
      dialogOnly: false,
      adding: true,
      text:"Deliver Package",
      icon:"mdi mdi-truck-delivery",
      definition:{...DeliverAirwaybillPackage},
      color:"#005300",
      resource: "GetAirwaybillPackage",
      resourceType: "POST",         
      resourceIdentity: "AirwaybillPackage",
      roles: [
        {
          role: ['Cargo Administrator','Cargo Member'],
          value: true
        }        
      ] 
    }
  ],
  caption: "Undelivered Packages",
  editSearchSelector: {
    resource: "GetUndeliveredAirwaybillPackageList",
    resourceType: "GET",   
    resourceParameters:{}, 
    resourceIdentity: "AirwaybillPackages"   
  },
  formDefinition: {
    columns: [
      {
        key: "AirwaybillPackageNumber",
        type: "text",
        label: "AWB Package No."
      },
      {
        key: "CityFrom",
        type: "text",
        label: "From"
      },
      {
        key: "PackageDescription",
        type: "text",
        label: "Description"
      },
      {
        key: "Recipient",
        type: "text",
        label: "Recipient"
      },
      {
        key: "PackageType",
        type: "text",
        label: "Type"
      },
      {
        key: "PaymentType",
        type: "text",
        label: "Payment Type"
      },
      {
        key: "ShippingCost",
        type: "number",
        format: "C2",
        textAlign:"Right",
        label: "Shipping Cost"
      }
    ]
  }
};
