
export default {
  duplicates: [],
  access: [],
  track: "CargoServiceType",
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo ServiceType",
  editSearchSelector: {
    resource: "GetCargoServiceTypeList",
    resourceType: "GET",
    resourceId: ["CargoServiceType"],
    resourceIdentity: "CargoServiceTypes",
    resourceValue: "ServiceTypeAndDescription",
    resourceSelected: "GetCargoServiceType",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoServiceType",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "AddCargoServiceType",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Service Type Information Added",
      alertText: "The Cargo Service Type Has Been Added"
    },
    update: {
      functionname: "UpdateCargoServiceType",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Service Type Information Updated",
      alertText: "The Cargo Service Type Has Been Updated"
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [ 
            {
              key: "CargoServiceType",
              type: "text",
              defaultValue: "",
              label: "Service Type",
              placeholder: "Service Type",
              readOnly: false,
              readOnlyOnEdit: true,
              validation: {
                "data-parsley-required": true,
                "data-parsley-minlength": 1,
                "data-parsley-maxlength": 10
              }
            },
            {
                key: "CargoServiceTypeDescription",
                type: "textarea",
                defaultValue: "",
                label: "Description",
                placeholder: "Description",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 100
                }
              }              
            ]
          },
          {
            title: "\n",
            fields: [              
              {
                key: "CargoPackageType",
                type: "select",
                defaultValue: "",
                label: "Package Type",
                placeholder: "Package Type",
                readOnly: false,
                optionsResource: {
                  resource: "GetCargoPackageTypeList",
                  resourceType: "GET",
                  resourceId: ["PackageType"],
                  resourceIdentity: "CargoPackageTypes",
                  resourceValue: "PackageTypeDescription"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 10
                }
              },
              {
                key: "FlatRateCharge",
                type: "select",
                defaultValue: "",
                label: "Flat Rate Charge",
                placeholder: "Flat Rate Charge",
                readOnly: false,
                optionsResource: {
                  resource: "GetYesNoList",
                  resourceType: "GET",
                  resourceId: ["ValueDescription"],
                  resourceIdentity: "YesNoList",
                  resourceValue: "ValueDescription"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 3
                }
              },
              {
                key: "PerPackageCharge",
                type: "checkbox",
                label: "Per Package Charge",
                placeholder: "Per Package Charge",
                readOnly: false
              },
              {
                key: "ActiveCargoServiceType",
                type: "checkbox",
                label: "Active",
                placeholder: "Active",
                readOnly: false
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                type: "spacer"
              }
            ]
          }
        ]
      }
    ]
  }
};
