import loadRemoteData from "./loadRemoteData";


const showTextBox = (EntryDetails, Config) => {
  if(!Config.options)
  {
  return new Promise(resolve => {
    loadRemoteData(
      Config,
      EntryDetails,
      data => {
        const searchData = {};
        searchData[Config.optionsResource.resourceId[0]] =
          EntryDetails[Config.key];
        const alldata = [];
        let i = undefined;
        data.forEach(item=>{
          const current = {
            id: item[Config.optionsResource.resourceId[0]],
            text: item[Config.optionsResource.resourceValue]
          };
          alldata.push(current)
          if(current.id && EntryDetails[Config.key])
          {
            if(current.id.toString().trim().toLowerCase() === EntryDetails[Config.key].toString().trim().toLowerCase())
            {
                i = item;
            }
          }
        })
        

        

        

     
        if (i) {
          resolve({
            status: false,
            data: {
              id: i[Config.optionsResource.resourceId[0]],
              text: i[Config.optionsResource.resourceValue]
            },
            alldata
          });
        } else {
          resolve({ status: true, data: null,alldata });
        }
      },
      () => {
        resolve({ status: false, data: null,alldata:[] });
      }
    );
  });
}
else
{
  return new Promise(resolve => {
    try
    {
    const data = Config.options;
        const searchData = {};
        searchData[Config.optionsResource.resourceId[0]] =
          EntryDetails[Config.key];
        const alldata = [];
        let i = undefined;
        data.forEach(item=>{
          const current = {
            id: item[Config.optionsResource.resourceId[0]],
            text: item[Config.optionsResource.resourceValue]
          };
          alldata.push(current)
          if(current.id && EntryDetails[Config.key])
          {
            if(current.id.toString().trim().toLowerCase() === EntryDetails[Config.key].toString().trim().toLowerCase())
            {
                i = item;
            }
          }
        })
        
     
        if (i) {
          resolve({
            status: false,
            data: {
              id: i[Config.optionsResource.resourceId[0]],
              text: i[Config.optionsResource.resourceValue]
            },
            alldata
          });
        } else {
          resolve({ status: true, data: null,alldata });
        }
      }
      catch(ex)
      {
        console.error(ex);
      }
    
    
  });
}

};
export default showTextBox;
