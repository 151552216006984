import styled from '@emotion/styled';

const NotificationStyle = styled.div`
  /* Old browsers */
  
  .ncontainer
  {
  position: fixed;
  z-index: 3001;
  right: 10px;
  bottom: 0;
  width: 300px;
  }
  button{
      outline:none!important;
      border:0px solid transparent;
      background:transparent;
  }
 
  ul {
    padding: 10px;
    margin: 0;
    list-style: none;
    li {
    
    margin-bottom:10px;
  width: 100%;    
  color:${(props) => props.theme.backgroundLight};
  table{
      tr{
          td{
              padding:10px;
          }
      }
  }
}
  }
  .item{
    padding:8px;
  }
  
  font-size:9pt;
h4{
    font-size:10pt;
    font-weight:600;
}
  .error {
    background:${(props) => props.theme.textMenu};
    color: ${(props) => props.theme.notificationErrorText};
    i{
      font-size:11pt;
      color:${(props) => props.theme.notificationErrorText};
    }
  }
  .success {
    background:${(props) => props.theme.textMenu};
    color: ${(props) => props.theme.notificationSuccessText};
    i{
      font-size:11pt;
      color:${(props) => props.theme.notificationSuccessText};
    }
  }
  .information {
    background:${(props) => props.theme.textMenu};
    color: ${(props) => props.theme.notificationInformationText};
    i{
      font-size:11pt;
      color:${(props) => props.theme.notificationInformationText};
    }
  }
  .warning {
    background:${(props) => props.theme.textMenu};
    color: ${(props) => props.theme.notificationWarningText};
    i{
      font-size:11pt;
      color:${(props) => props.theme.notificationWarningText};
    }
  }
`;

export default NotificationStyle;
