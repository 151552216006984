import React from 'react';
import { withTheme } from '@emotion/react';
import { Observer } from 'mobx-react';

import { loadData, guid } from '../../../../utilities';
import Dialog from '../../../SideBarRenderer/TabContainer/Dialog';
const Report = (props) => {
  const onSaveClose = () => {
    props.stack.pop();
  };
  return (
    <Observer>
      {() => (
        <Dialog
          ReadOnly={true}
          onSaveDialog={onSaveClose}
          EntryDetails={[]}

          stack={props.stack}
          header={props.header}
          FormId={guid()}

        >
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              background: props.theme.backgroundDark,
            }}
          >
            <iframe
            sandbox={"allow-scripts allow-forms allow-same-origin allow-modals allow-popups allow-popups-to-escape-sandbox allow-downloads"}
              key={guid()}
              frameBorder="0"
              title={'print work order'}
              src={`/preview/?id=${props.id}&link=${props.response.id}`}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                background: props.theme.backgroundDark,
              }}
            />
          </div>
        </Dialog>
      )}
    </Observer>
  );
};
const ThemeReport = withTheme(Report);

const onLaunchReport = (config) => {
  return new Promise((resolve, reject) => {
    //const holder = this.refs['dialog-holder'];
    const { id, header,data,stack } = config ? config : {};
    const currentId = guid();

    loadData(
      '/setpreview',
      'post',
      data,
      (response) => {
        stack.push({
          id: currentId,
          component: (
            <ThemeReport
              response={response}
              id={id}
              header={header}
              stack={stack}
            />
          ),
        });
        resolve();
      },
      () => {
        reject();
      },
      false,
      true
    );
  });
};

export default onLaunchReport;
