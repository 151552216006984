import React, { PureComponent } from 'react';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';
import { withTheme } from '@emotion/react';
import NotificationStyle from './NotificationStyle';
import NotificationItem from './NotificationItem';
import * as $ from 'jquery';
import {guid} from '../../../utilities';

class NotificationComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.notifications = observable([]);
    const me = this;
    $(window).on('snack-bar', (sender, data) => {
      if(me.notifications)
      {
      me.notifications.push(data);

      setTimeout(() => {
        if (me.notifications.length > 0) {
          me.notifications.shift();
        }
      }, 5000);
    }
    });
  }
  render() {
    return (
      <NotificationStyle>
        <div className="ncontainer">
          <Observer>
            {() => (
              <ul>
                {this.notifications.filter((item)=>item.message && item.title).reverse().map((data, index) => (
                  <li key={guid()}>
                  <NotificationItem
                    key={guid()}
                    message={data.message}
                    source={data.source}
                    title={data.title}
                    icon={data.icon}
                    type={data.type}
                    close={() => {
                      this.notifications.splice(index, 1);
                    }}
                  ></NotificationItem>
                  </li>
                ))}
              </ul>
            )}
          </Observer>
        </div>
      </NotificationStyle>
    );
  }
}
export default withTheme(NotificationComponent);
