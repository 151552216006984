import SystemUsersSecurityRoleEntry from "./systemuserroleentry";
import Colors from "../../../constants/Colors";
export default {
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "System User Security Role",
  editSearchSelector: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "editabletable", 
                customButtons : [
                  {
                    showInEditMode: false,
                    showInAddMode: false,
                    showInLocked: true,
                    requiresSelection: false,
                    showInEditableMode: true,
                    eventType: 'add',
                    dialogOnly: false,
                    text: 'Add',
                    icon: 'mdi mdi-plus',
                    color: '#396000',
                    roles: [
                      {
                        role: ['User Administration Administrator'],
                        value: true
                      }        
                    ]
                  },
                  {
                    showInEditMode:false,
                    showInAddMode:false,
                    showInLocked:false,
                    requiresSelection:true,
                    showInEditableMode:true,
                    eventType:"edit",        
                    dialogOnly:false,
                    text:"Edit",
                    icon:"mdi mdi-pencil",
                    color:Colors.edit,
                    roles:[
                      {
                      role: ['default'],
                      value: false
                    }
                  ]
                  },
                  {
                    showInEditMode: false,
                    showInAddMode: false,
                    requiresSelection: true,
                    showInLocked: false,
                    showInEditableMode: true,
                    refreshAfterSave: true,
                    icon: 'mdi mdi-delete',
                    text: 'Remove Role',
                    eventType: 'api',
                    color:Colors.cancel,
                    roles: [
                      {
                        role: ['User Administration Administrator'],
                        value: true
                      }        
                    ],                    
                    confirmation: {
                      title:'Confirmation',
                      text: 'Please confirm removal of security role'
                    },
                    function: {
                      functionName: 'DeleteSystemUserSecurityRole',
                      functionType: 'POST',
                      validateData: true,
                      alertOnComplete: true,
                      alertText: 'The Security Role Has Been Removed'
                    }
                  },
                ],
                datasource: "getsystemusersecurityrolelist",
                key: "UserName",
                keylink: "UserName",
                data: {
                  getResource: {
                    resourceType: "POST",
                    id: "getsystemusersecurityrolelist",
                    resourceIdentity: "SystemUserSecurityRoles"
                  }                 
                },
                entry: {
                  resource: {
                  },
                  definition: { ...SystemUsersSecurityRoleEntry }
                },
                fields: [
                  {
                    key: "SecurityRole",
                    type: "text",
                    label: "Role",
                    placeholder: "Role"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
