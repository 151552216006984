import AirwaybillAdjustmentEntry from "../airwaybilladjustment/airwaybilladjustmententry";
import AirwaybillAdjustmentList from "../airwaybilladjustment/airwaybilladjustmentlist";
import AirwaybillLogList from "../airwaybilllog/airwaybillloglist";
import AirwaybillNoteList from "../airwaybillnote/airwaybillnotelist";
import AirwaybillPackageList from "../airwaybillpackage/airwaybillpackagelist";
import AirwaybillSearch from "./airwaybillsearch";
import AirwaybillTransactionList from "../airwaybilltransaction/airwaybilltransactionlist";
import ChangeBillingAgent from "./changebillingagent";
import ChangePackageQuantity from "./changepackagequantity";
import ChangePaymentType from "./changepaymenttype";
import ChangePurchaseOrderNumber from "./changepurchaseordernumber";
import Colors from "../../../constants/Colors";
import VoidAirwaybill from "./voidairwaybill";

export default {
  formtype: "dataEntry",
  customSearch: {
    searchForm: { ...AirwaybillSearch },
    width: 1000,
    align: 500,
    height: 800,
    id: "airwaybillsearch"
  },
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],

  customActions: [
    {
      showInEditMode: false,
      showInAddMode: false,
      showInLocked: false,
      requiresSelection: true,
      showInEditableMode: true,
      eventType: "edit",
      refreshAfterAction: true,
      dialogOnly: false,
      text: "Edit",
      icon: "mdi mdi-pencil",
      refreshAfterAction: true,
      color: Colors.edit,
      dependentFields: [
        {
          key: "AirwaybillStatus",
          values: ["Billed", "Delivered", "Shipped", "Voided"],
          active: false
        }
      ],
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor"],
          value: true
        }
      ],
      after: {
        functionName: "GetAirwaybill",
        functionType: "Post",
        resourceIdentity: "Airwaybill"
      }
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-chevron-double-right",
      text: "Process Billing",
      refreshAfterAction: true,
      eventType: "api",
      color: Colors.process,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "AirwaybillStatus",
          values: ["Billed", "Delivered", "Shipped", "Voided"],
          active: false
        }
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm billing of air waybill"
      }, 
      function: {
        functionName: "ProcessAirwaybillBilling",
        functionType: "POST",
        validateData: true,
        alertOnComplete: true,
        alertText: "The Air Waybill Has Been Processed"
      },
      after: {
        functionName: "GetAirwaybill",
        functionType: "Post",
        resourceIdentity: "Airwaybill"
      }
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      showInLocked: true,
      requiresSelection: true,
      showInEditableMode: true,
      dialogOnly: false,
      adding: true,
      eventType: "form",
      text: "Void Air Waybill",
      icon: "mdi mdi-null",
      color: Colors.cancel,
      definition: { ...VoidAirwaybill},
      key: "AirwaybillNumber",
      keylink: "AirwaybillNumber",
      action:{
        functionName:"GetAirwaybillPurchaseOrderNumber",
        functionType:"POST",
        functionResourceIdentity:"Airwaybill"
      },
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "AirwaybillStatus",
          values: ["Delivered", "Voided"],
          active: false
        },
        {
          key: "AllowVoid",
          values: ["No"],
          active: false
        }
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm voiding of air waybill"
      }
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      showInLocked: true,
      requiresSelection: true,
      showInEditableMode: true,
      eventType: 'form',
      dialogOnly: false,
      adding: true,
      text: 'Change Pkg. Qty.',
      icon: 'mdi mdi-pencil',
      color: Colors.edit2,
      definition: { ...ChangePackageQuantity},
      key: "AirwaybillNumber",
      keylink: "AirwaybillNumber",
      action:{
        functionName:"GetAirwaybillPackageQuantity",
        functionType:"POST",
        functionResourceIdentity:"Airwaybill",
      },
      dependentFields: [
        {
          key: "AirwaybillStatus",
          values: ["Delivered", "Shipped", "Voided", "Billed"],
          active: false
        },
        {
          key: "FlatRateCharge",
          values: ["Yes"],
          active: false
        }
      ],
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor"],
          value: true
        }        
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm change of package quantity"
      }        
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      showInLocked: true,
      requiresSelection: true,
      showInEditableMode: true,
      eventType: 'form',
      dialogOnly: false,
      adding: true,
      text: 'Change Payment Type',
      icon: 'mdi mdi-account-cash',
      color: Colors.edit2,
      definition: { ...ChangePaymentType},
      key: "AirwaybillNumber",
      keylink: "AirwaybillNumber",
      action:{
        functionName:"GetAirwaybillPaymentType",
        functionType:"POST",
        functionResourceIdentity:"Airwaybill",
      },
      dependentFields: [
        {
          key: "AirwaybillStatus",
          values: ["Pending", "Voided"],
          active: false
        },
        {
          key: "AllowPaymentTypeChange",
          values: ["No"],
          active: false
        }
      ],
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Supervisor", "Cargo Member"],
          value: true
        }        
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm change of payment type"
      }  
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      showInLocked: true,
      requiresSelection: true,
      showInEditableMode: true,
      eventType: 'form',
      dialogOnly: false,
      adding: true,
      text: 'Change Billing Agent',
      icon: 'mdi mdi-account-convert',
      color: Colors.edit2,
      definition: { ...ChangeBillingAgent},
      key: "AirwaybillNumber",
      keylink: "AirwaybillNumber",
      action:{
        functionName:"GetAirwaybillBillingAgent",
        functionType:"POST",
        functionResourceIdentity:"Airwaybill",
      },
      dependentFields: [
        {
          key: "AirwaybillStatus",
          values: ["Pending", "Voided"],
          active: false
        },
        {
          key: "AllowBillingAgentChange",
          values: ["No"],
          active: false
        }
      ],
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Supervisor", "Cargo Member", "Accounting Administrator", "Accounting Supervisor"],
          value: true
        }        
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm change of billing agent"
      }  
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      showInLocked: true,
      requiresSelection: true,
      showInEditableMode: true,
      eventType: 'form',
      dialogOnly: false,
      adding: true,
      text: 'Change P.O Number',
      icon: 'mdi mdi-check-decagram',
      color: Colors.verify,
      definition: { ...ChangePurchaseOrderNumber},
      key: "AirwaybillNumber",
      keylink: "AirwaybillNumber",
      action:{
        functionName:"GetAirwaybillPurchaseOrderNumber",
        functionType:"POST",
        functionResourceIdentity:"Airwaybill",
      },
      dependentFields: [
        {
          key: "AirwaybillStatus",
          values: ["Pending", "Voided"],
          active: false
        },
        {
          key: "PaymentType",
          values: ["Cash", "COD"],
          active: false
        }
      ],
      roles: [
        {
          role: ["Accounting Administrator", "Accounting Member"],
          value: true
        }        
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm change of P.O number"
      }        
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      showInLocked: true,
      requiresSelection: true,
      showInEditableMode: true,
      eventType: 'form',
      dialogOnly: false,
      adding: true,
      text: 'Adjust Charge',
      icon: 'mdi mdi-delta',
      color: Colors.process,
      definition: { ...AirwaybillAdjustmentEntry},
      key: "AirwaybillNumber",
      keylink: "AirwaybillNumber",
      action:{
        functionName:"PrepareAirwaybillAdjustment",
        functionType:"POST",
        functionResourceIdentity:"AirwaybillAdjustment",
      },
      dependentFields: [
        {
          key: "AirwaybillStatus",
          values: ["Pending", "Voided"],
          active: false
        },
        {
          key: "Invoiced",
          values: ["Yes"],
          active: false
        },
      ],
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Supervisor", "Accounting Administrator", "Accounting Member"],
          value: true
        }        
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm airwaybill adjustment"
      }        
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-printer",
      text: "Print",
      eventType: "report",
      report: {
        id: "Airwaybill/Airwaybill",
        header: "Air Waybill"
      },
      color: Colors.print,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor", "Cargo Viewer"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "AirwaybillStatus",
          values: ["Pending", "Voided"],
          active: false
        }
      ]
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-printer",
      text: "Print Universal AWB",
      eventType: "report",
      report: {
        id: "Airwaybill/UniversalAirwaybill",
        header: "Air Waybill"
      },
      color: Colors.print,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor", "Cargo Viewer"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "AirwaybillStatus",
          values: ["Pending", "Voided"],
          active: false
        },
        {
          key: "InternationalAirwaybill",
          values: ["No"],
          active: false
        }
      ]
    }
  ],
  printreport: "",
  caption: "Air Waybill Details",
  editSearchSelector: {
    resource: "GetAirwaybillList",
    resourceType: "GET",
    resourceId: ["AirwaybillNumber"],
    resourceIdentity: "Airwaybills",
    resourceValue: "AirwaybillNumber",
    resourceSelected: "GetAirwaybill",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "Airwaybill",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "AddAirwaybill",
      functiontype: "POST",
      resourceIdentity: {
        id: "Airwaybill",
        mapping: [
          {
            id: "AirwaybillNumber",
            key: "AirwaybillNumber"
          },
          {
            id: "AirwaybillStatus",
            key: "AirwaybillStatus"
          },
          {
            id: "ShippingCost",
            key: "ShippingCost"
          },
          {
            id: "EnteredBy",
            key: "EnteredBy"
          },
          {
            id: "EnterDate",
            key: "EnterDate"
          },
          {
            id: "DropoffDate",
            key: "DropoffDate"
          },
          {
            id: "DropoffTime",
            key: "DropoffTime"
          }
        ]
      },
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Airwaybill Information Added",
      alertText: "The Airwaybill Has Been Added"
    },
    update: {
      functionname: "UpdateAirwaybill",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Airwaybill Information Updated",
      alertText: "The Airwaybill Has Been Updated"
    },
    after: {
      functionname: "GetAirwaybillList",
      functiontype: "GET"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Sender",
            fields: [
              {
                key: "Sender",
                type: "select",
                defaultValue: "",
                label: "Sender",
                placeholder: "Sender",
                readOnly: false,
                allowText: true,
                optionsResource: {
                  resource: "GetAirwaybillSenderList",
                  resourceType: "GET",
                  resourceId: ["Sender"],
                  resourceIdentity: "Senders",
                  resourceValue: "Sender"
                },
                details: ["SenderTelephone"],
                mapping: [
                  {
                    id: "SenderAddress1",
                    key: "SenderAddress1"
                  },
                  {
                    id: "SenderCity",
                    key: "SenderCity"
                  },
                  {
                    id: "SenderEmail",
                    key: "SenderEmail"
                  },
                  {
                    id: "SenderTelephone",
                    key: "SenderTelephone"
                  }
                ],
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "CityFrom",
                type: "select",
                defaultValue: "StationPosted",
                label: "From",
                placeholder: "From",
                readOnly: false,
                optionsResource: {
                  resource: "GetActiveCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 4
                }
              },
              {
               
                key: "SenderContact",
                type: "text",
                defaultValue: "",
                label: "Contact",
                placeholder: "Contact",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "SenderAddress1",
                type: "text",
                defaultValue: "",
                label: "Address",
                placeholder: "Address",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "SenderCity",
                type: "text",
                defaultValue: "",
                label: "City",
                placeholder: "City",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              },
              [{
                key: "SenderTelephone",
                type: "text",
                defaultValue: "",
                label: "Telephone",
                placeholder: "Telephone",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 7,
                  "data-parsley-maxlength": 10
                }
              },
              {
                width:6,
                key: "SenderEmailAddress",
                type: "text",
                defaultValue: "",
                label: "Email Address",
                placeholder: "Email Address",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              }]
            ]
          },
          {
            title: "Recipient",
            fields: [
              {
                key: "Recipient",
                type: "select",
                defaultValue: "",
                label: "Recipient",
                placeholder: "Recipient",
                readOnly: false,
                allowText: true,
                optionsResource: {
                  resource: "GetAirwaybillRecipientList",
                  resourceType: "GET",
                  resourceId: ["Recipient"],
                  resourceIdentity: "Recipients",
                  resourceValue: "Recipient"
                },
                details: ["RecipientTelephone"],
                mapping: [
                  {
                    id: "RecipientAddress1",
                    key: "RecipientAddress1"
                  },
                  {
                    id: "RecipientCity",
                    key: "RecipientCity"
                  },
                  {
                    id: "RecipientEmail",
                    key: "RecipientEmail"
                  },
                  {
                    id: "RecipientTelephone",
                    key: "RecipientTelephone"
                  }
                ],
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "CityTo",
                type: "select",
                defaultValue: "",
                label: "To",
                placeholder: "To",
                readOnly: false,
                optionsResource: {
                  resource: "GetActiveCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 4,
                  "data-parsley-differentcityfromandcityto": true
                }
              },
              {
                key: "RecipientContact",
                type: "text",
                defaultValue: "",
                label: "Contact",
                placeholder: "Recipient Contact",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "RecipientAddress1",
                type: "text",
                defaultValue: "",
                label: "Address",
                placeholder: "Address",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "RecipientCity",
                type: "text",
                defaultValue: "",
                label: "City",
                placeholder: "City",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              },
              [{
                key: "RecipientTelephone",
                type: "text",
                defaultValue: "",
                label: "Telephone",
                placeholder: "Telephone",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 7,
                  "data-parsley-maxlength": 10
                }
              },
              {
                width:6,
                key: "RecipientEmailAddress",
                type: "text",
                defaultValue: "",
                label: "Email Address",
                placeholder: "Email Address",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              }]
            ]
          },
          {
            title: "Service",
            fields: [
              [{
                key: "ServiceType",
                type: "select",
                defaultValue: "",
                label: "Service Type",
                placeholder: "Service Type",
                readOnly: false,
                optionsResource: {
                  resource: "GetActiveCargoServiceTypeList",
                  resourceType: "GET",
                  resourceId: ["CargoServiceType"],
                  resourceIdentity: "CargoServiceTypes",
                  resourceValue: "CargoServiceType"
                },
                mapping: [
                  {
                    id: "FlatRateCharge",
                    key: "FlatRateCharge"
                  }
                ],
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 10,
                  "data-parsley-flatratequantity": true
                }
              },             
              {
                width:6,
                key: "QuantityOfPackages",
                type: "number",
                format: "0,0",
                defaultValue: "1",
                label: "Qty. Pkg.",
                placeholder: "Quantity Of Packages",
                readOnly: false,
                readOnlyOnEdit: true,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 1,
                  "data-parsley-max": 500,
                  "data-parsley-maxlength": 10,
                  "data-parsley-flatratequantity": true
                }
              }],
              [{
                key: "TotalWeight",
                type: "number",
                format: "0,0.0",
                defaultValue: "1",
                label: "Total Weight",
                placeholder: "Total Weight",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 1,
                  "data-parsley-max": 5000,
                  "data-parsley-maxlength": 10
                }
              },
              {
                width:6,
                key: "TotalVolume",
                type: "number",
                format: "0,0.0",
                defaultValue: "0",
                label: "Total Volume",
                placeholder: "Total Volume",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 0,
                  "data-parsley-max": 1500000,
                  "data-parsley-maxlength": 10
                }
              }],
              [{
                width:6,
                key: "CargoType",
                type: "select",
                defaultValue: "",
                label: "Cargo Type",
                placeholder: "Cargo Type",
                readOnly: false,
                optionsResource: {
                  resource: "GetCargoTypeList",
                  resourceType: "GET",
                  resourceId: ["CargoType"],
                  resourceIdentity: "CargoTypes",
                  resourceValue: "CargoType"
                },
                validation: {
                  "data-parsley-maxlength": 20
                }
              }],
              {
                key: "NatureOfGoods",
                type: "text",
                defaultValue: "",
                label: "Nature Of Goods",
                placeholder: "Nature Of Goods",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 512
                }
              },
              [{
                width:6,
                key: "ValueDeclaredForCarriage",
                type: "number",
                format: "$0,0.00",
                defaultValue: "0",
                label: "Carriage Value",
                placeholder: "Carriage Value",
                readOnly: false,
                validation: {
                  "data-parsley-min": 0,
                  "data-parsley-max": 1000000,
                  "data-parsley-maxlength": 10
                }
              },
              {
                width:6,
                key: "ValueDeclaredForCustoms",
                type: "number",
                format: "$0,0.00",
                defaultValue: "0",
                label: "Customs Value",
                placeholder: "Customs Value",
                readOnly: false,
                validation: {
                  "data-parsley-min": 0,
                  "data-parsley-max": 1000000,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 10
                }
              }],
              {
                key: "ReferenceNumber",
                type: "text",
                defaultValue: "",
                label: "Reference Number",
                placeholder: "Reference Number",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 20
                }
              }
            ]
          },
          {
            title: "Billing",
            fields: [
              {
                key: "PaymentType",
                type: "select",
                defaultValue: "",
                label: "Payment Type",
                placeholder: "Payment Type",
                readOnly: false,
                optionsResource: {
                  resource: "GetAirwaybillPaymentTypeList",
                  resourceType: "POST",
                  resourceId: ["AirwaybillPaymentType"],
                  resourceIdentity: "AirwaybillPaymentTypes",
                  resourceValue: "AirwaybillPaymentTypeDescription"
                },
                mapping: [
                  {
                    id: "CargoBillingStation",
                    key: "BillingStation"
                  },
                  {
                    id: "CargoBillingAgent",
                    key: "BillingAgent"
                  }
                ],
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 6
                }
              },
              {
                key: "BillingStation",
                type: "select",
                defaultValue: "",
                label: "Billing Station",
                placeholder: "Billing Station",
                readOnly: true,
                optionsResource: {
                  resource: "GetCargoStationList",
                  resourceType: "GET",
                  resourceId: ["BillingAgencyId"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 5
                }
              },
              {
                key: "BillingAgent",
                type: "select",
                defaultValue: "",
                label: "Billing Agent",
                placeholder: "Billing Agent",
                readOnly: false,
                optionsResource: {
                  resource: "GetAgencyList",
                  resourceType: "GET",
                  resourceId: ["AgencyId"],
                  resourceIdentity: "Agencies",
                  resourceValue: "AgencyName"
                },
                mapping: [
                  {
                    id: "TypeCode",
                    key: "TypeCode"
                  },
                  {
                    id: "PurchaseOrderRequired",
                    key: "PurchaseOrderRequired"
                  }
                ],
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 5,
                  "data-parsley-nostationcharge": true
                }
              },
              {
                key: "PurchaseOrderNumber",
                type: "text",
                defaultValue: "",
                label: "P.O Number",
                placeholder: "P.O Number",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 20,
                  "data-parsley-purchaseorderrequired": true
                }
              },
              {
                key: "TypeCode"
              },
              {
                key: "PurchaseOrderRequired"
              },
              {
                key: "ShippingCost",
                type: "number",
                format: "$0,0.00",
                defaultValue: "",
                label: "Shipping Cost",
                placeholder: "Shipping Cost",
                readOnly: true,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 0,
                  "data-parsley-max": 100000,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 10
                }
              },
              {
                key: "AirwaybillNotes",
                type: "text",
                defaultValue: "",
                label: "Notes",
                placeholder: "Airwaybill Notes",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 512
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "AirwaybillNumber",
                type: "number",
                defaultValue: "",
                label: "AWB Number",
                placeholder: "AWB Number",
                readOnly: true
              },
              {
                key: "AirwaybillStatus",
                type: "text",
                defaultValue: "Pending",
                label: "Status",
                placeholder: "Status",
                readOnly: true
              },
              {
                key: "EnterDate",
                type: "date",
                defaultValue: "",
                label: "Enter Date",
                placeholder: "Enter Date",
                readOnly: true
              },
              {
                key: "EnteredBy",
                type: "text",
                defaultValue: "",
                label: "Entered By",
                placeholder: "Entered By",
                readOnly: true
              },
              [{
                key: "DropoffDate",
                type: "date",
                defaultValue: "",
                label: "Dropoff Date",
                placeholder: "Dropoff Date",
                readOnly: true
              },
              {
                key: "DropoffTime",
                type: "text",
                defaultValue: "",
                label: "Dropoff Time",
                placeholder: "Dropoff Time",
                readOnly: true
              }],
              [{
                key: "BillableDate",
                type: "date",
                defaultValue: "",
                label: "Billable Date",
                placeholder: "Billable Date",
                readOnly: true
              },
              {
                key: "InvoiceNumber",
                type: "text",
                defaultValue: "",
                label: "Invoice Number",
                placeholder: "Invoice Number",
                readOnly: true
              }]
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "",
            fields: [
              {
                type: "tabs",
                
                tabs: [
                  {
                    caption: "Packages",
                    definition: { ...AirwaybillPackageList }
                  },
                  {
                    caption: "Notes",
                    definition: { ...AirwaybillNoteList }
                  },
                  {
                    caption: "Transactions",
                    definition: { ...AirwaybillTransactionList }
                  },
                  {
                    caption: "Adjustments",
                    definition: { ...AirwaybillAdjustmentList }
                  },
                  {
                    caption: "Logs",
                    definition: { ...AirwaybillLogList }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
