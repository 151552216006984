
import CargoSpecialRateSearch from "./cargospecialratesearch";
export default {
  formtype: "dataEntry",
  customSearch: {
    searchForm: { ...CargoSpecialRateSearch },
    width: 800,
    align: 500,
    height: 800,
    id: "cargospecialratesearch"
  },
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Rate",
  editSearchSelector: {
    resource: "GetCargoSpecialRateList",
    resourceType: "GET",
    resourceId: ["CargoRate"],
    resourceIdentity: "CargoRates",
    resourceValue: "CargoRate",
    resourceSelected: "GetCargoSpecialRate",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoSpecialRate",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "AddCargoSpecialRate",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Special Rate Information Added",
      alertText: "The Cargo Special Rate Has Been Added",
      resourceIdentity: {
        id: "CargoSpecialRate",
        mapping: [
          {
            id: "CargoSpecialRateRecordNumber",
            key: "CargoSpecialRateRecordNumber"
          }
        ]
      }
    },
    update: {
      functionname: "UpdateCargoSpecialRate",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Special Rate Information Updated",
      alertText: "The Cargo Special Rate Has Been Updated"
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [ 
            {
              key: "ServiceType",
              type: "select",
              defaultValue: "",
              label: "Service Type",
              placeholder: "Service Type",
              readOnly: false,
              optionsResource: {
                resource: "GetCargoServiceTypeList",
                resourceType: "GET",
                resourceId: ["CargoServiceType"],
                resourceIdentity: "CargoServiceTypes",
                resourceValue: "CargoServiceType"
              },
              validation: {
                "data-parsley-required": true,
                "data-parsley-minlength": 1,
                "data-parsley-maxlength": 10
              }
            },
            {
                key: "CityFrom",
                type: "select",
                defaultValue: "",
                label: "City From",
                placeholder: "City From",
                readOnly: false,
                optionsResource: {
                  resource: "GetCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 4
                }
              },
              {
                key: "CityTo",
                type: "select",
                defaultValue: "",
                label: "City To",
                placeholder: "City To",
                readOnly: false,
                optionsResource: {
                  resource: "GetCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 4
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "PoundRate",
                type: "number",
                format: "$0,0.00",
                defaultValue: "0",
                label: "Pound Rate",
                placeholder: "Pound Rate",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 0,
                  "data-parsley-max": 1000,
                  "data-parsley-maxlength": 10
                }
              },
              {
                key: "MinimumCharge",
                type: "number",
                format: "$0,0.00",
                defaultValue: "0",
                label: "Minimum Charge",
                placeholder: "Minimum Charge",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": 0,
                  "data-parsley-max": 1000,
                  "data-parsley-maxlength": 10
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [              
              {
                key: "SundayRate",
                type: "checkbox",
                defaultValue: 1,
                label: "Sunday",
                placeholder: "Sunday",
                readOnly: false
              },
              {
                key: "MondayRate",
                type: "checkbox",
                defaultValue: 1,
                label: "Monday",
                placeholder: "Monday",
                readOnly: false
              },
              {
                key: "TuesdayRate",
                type: "checkbox",
                defaultValue: 1,
                label: "Tuesday",
                placeholder: "Tuesday",
                readOnly: false
              },
              {
                key: "WednesdayRate",
                type: "checkbox",
                defaultValue: 1,
                label: "Wednesday",
                placeholder: "Wednesday",
                readOnly: false
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "ThursdayRate",
                type: "checkbox",
                defaultValue: 1,
                label: "Thursday",
                placeholder: "Thursday",
                readOnly: false
              },
              {
                key: "FridayRate",
                type: "checkbox",
                defaultValue: 1,
                label: "Friday",
                placeholder: "Friday",
                readOnly: false
              },
              {
                key: "SaturdayRate",
                type: "checkbox",
                defaultValue: 1,
                label: "Saturday",
                placeholder: "Saturday",
                readOnly: false
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "BeginDate",
                type: "date",
                defaultValue: "",
                label: "Begin Date",
                placeholder: "Begin Date",
                validation: {
                  "data-parsley-required": true
                }
              },
              {
                key: "EndDate",
                type: "date",
                defaultValue: "",
                label: "End Date",
                placeholder: "End Date"
              },
              {
                key: "CargoRateRecordNumber"
              }
            ]
          }
        ]
      }
    ]
  }
};
