const hasProperty = (item,Config, value) => {
    let found = false;
    if (Config.details !== undefined) {
      Config.details.forEach(propertyName => {
        if (
          (item[propertyName] !== null && item[propertyName] !== undefined
            ? item[propertyName].toString().toLowerCase()
            : ""
          ).indexOf(value) >= 0
        ) {
          found = true;
        }
      });
      return found;
    } else {
      return (
        (item[Config.optionsResource.resourceValue] !== null &&
        item[Config.optionsResource.resourceValue] !== undefined
          ? item[Config.optionsResource.resourceValue]
              .toString()
              .toLowerCase()
          : ""
        ).indexOf(value) >= 0
      );
    }
  }

  export default hasProperty;