import React, { useEffect, useRef, useState } from "react";

import $ from "jquery";
import AppStyle from "./AppStyle";
import Help from "./components/SideBarRenderer/SideNavigator/Help";
import LargeLoader from "./components/Core/LargeLoader";
import Login from "./components/LoginRenderer";
import { Observer } from "mobx-react";
import SideNavigator from "./components/SideBarRenderer/SideNavigator";
import TabContainer from "./components/SideBarRenderer/TabContainer";
import { loadData } from "./utilities";
import mapper from "./definitions/mapping";
import { observable } from "mobx";
import { user } from "./utilities";

const App = (props) => {
  const [data] = useState(observable({}));
  const [openHandler] = useState({});
  const contentholder = useRef(null);
  const [state, setState] = useState({
    authenticated: false,
    menu_system: [],
  });
  const [creds] = useState({});
  const [Loader] = useState({});

  const getCurrentMenuState = () => {
    const menuState = {
      collapsed: false,
    };
    const currentState = window.localStorage.getItem("menuState");
    if (currentState) {
      try {
        const jsonCurrentState = JSON.parse(currentState);
        menuState.collapsed = jsonCurrentState.collapsed;
      } catch (ignored) {}
    }

    return menuState.collapsed;
  };

  const LoadAuthenticate = () => {
    loadData(
      "login",
      "POST",
      {
        EmailAddress: creds.username,
        Password: creds.password
      },
      (response) => {
        if (response) {
          user.setUserDetails(response);

        //  user.startConnection(() => {
            setState({
              ...state,
              menu_system: response.menuSystem,
              authenticated: true,
            });

            Loader.onHideLoader();
        //  });
        } else {
          Loader.onHideLoader();
        }
      },
      () => {
        creds.onError();

        Loader.onHideLoader();
      },
      true
    );
  };

  const menuSelected = (menu, resourceData) => {
    const definition = mapper.find(
      (value) => value.id === menu.resource.toLowerCase()
    );
    if (definition) {
      openHandler.OpenTab(menu, definition.definition, resourceData);
    } else {
      openHandler.OpenTab(menu, null, resourceData);
    }
  };

  useEffect(() => {
    $(window).on("toggle-menu-state", (sender, menuState) => {
      $("#tab-container-holder").css(
        "left",
        menuState.collapsed ? "76px" : "195pt"
      );
    });
  }, []);

  return (
    <AppStyle>
      <Observer>
        {() => (
          <div>
            <LargeLoader
              Text="Authenticating"
              Triggers={Loader}
              OnReady={LoadAuthenticate}
              OnComplete={() => {}}
            />
          </div>
        )}
      </Observer>
      {state.authenticated ? (
        <div className="App">
          <div ref={contentholder} className="content-holder">
            <TabContainer
              contentLeft={getCurrentMenuState() ? "76px" : "195pt"}
              menuSelected={menuSelected}
              Events={openHandler}
            />
          </div>
          <SideNavigator
            menuSelected={menuSelected}
            MenuSystem={state.menu_system}
          />
          <Help Data={data} />
        </div>
      ) : (
        <Login
          onLogin={(username, password, onError) => {
            //alert(username);
            creds.username = username;
            creds.password = password;
            creds.onError = onError;
            Loader.onShowLoader();
          }}
        />
      )}
    </AppStyle>
  );
};

export default App;
