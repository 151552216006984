import React, { useEffect, useState, useRef } from 'react';
import Noop from '../../Core/Noop';
import SearchResults from './SearchResults';
import SearchComponentStyle from './SearchComponentStyle';
import SearchFrom from './SearchForm';
import { withTheme } from '@emotion/react';
import { observable, toJS } from 'mobx';
import { Observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { loadData } from '../../../utilities';

const SearchComponent = (props) => {
  if (props.refreshTrigger) {
    props.refreshTrigger.target = () => {
      refresh();
    };
  }
  const inputRef = useRef(null);
  const bounce = debounce((e) => {
    if (e.target) {
      filter(e.target.value);
    }
  }, 100);

  const handleFilter = (e) => {
    e.persist();
    bounce(e);
  };

  const setupSpeach = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true; //
      recognition.lang = 'en-US';
      recognition.interimResults = true;
      recognition.maxAlternatives = 1;

      recognition.onerror = (event) => {
        //  Notification.info("I'm sorry, I didn't understand the command");
        //this.setState({ ...this.state, startRecord: false });
      };
      recognition.onspeechend = () => {
        searchState.startVoice = false;
        if (inputRef.current && inputRef.current.value && inputRef.current.value.length > 0) {
          filter(inputRef.current.value.trim());
        }
       
      };

      recognition.onresult = (event) => {
        var interim_transcript = '';
        var final_transcript = '';
        for (var i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            final_transcript += event.results[i][0].transcript;
          } else {
            interim_transcript += event.results[i][0].transcript;
          }
        }
        //searchState.searchText = interim_transcript;
        if (inputRef.current)
        {
          inputRef.current.value = final_transcript;
        }
        if (inputRef.current && inputRef.current.value && inputRef.current.value.length > 0) {
          
          filter(inputRef.current.value.trim());
        }
        
      };
      setRecognition(recognition);
    }
  };

  const paginate = (array, page_size, page_index) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice(page_index * page_size, (page_index + 1) * page_size);
  };
  const [recognition, setRecognition] = useState(null);
  const [searchState, setSearchState] = useState(
    observable({
      loading: false,
      open: false,
      allData: [],
      filteredData: [],
      currentPage: 0,
      selectedText: '',
      startVoice: false,
    })
  );

  const startvoice = () => {
    if (recognition) {
      recognition.start();
      searchState.startVoice = true;
      inputRef.current.value = '';
    }
  };

  const stopRecording = () => {
    recognition.stop();
  };

  const itemSelected = (selection) => {
    searchState.selectedText = props.FormDefiniton.editSearchSelector
      ? selection[props.FormDefiniton.editSearchSelector.resourceValue]
        ? selection[props.FormDefiniton.editSearchSelector.resourceValue]
        : ''
      : '';
    hideToggle();
    props.onSelected(selection);
  };

  const filter = (filterval) => {
    
    if (filterval.trim().length === 0) {
      searchState.filteredData = searchState.allData;
      searchState.currentPage = 0;
    } else {
      const results = searchState.allData.filter((item) => {
        if (
          item[props.display]
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(filterval.toLowerCase()) >= 0
        ) {
          return true;
        } else {
          if (props.resourceDisplayFields) {
            props.resourceDisplayFields.forEach((other) => {
              if (
                item[other]
                  .toString()
                  .trim()
                  .toLowerCase()
                  .indexOf(filterval.toLowerCase()) >= 0
              ) {
                return true;
              }
            });
          }
        }
        return false;
      });

      searchState.filteredData = results;
      searchState.currentPage = 0;
    }
  };

  const handlePageClick = (data) => {
    searchState.filteredData = searchState.allData;
    searchState.currentPage = data.selected;
  };

  const showToggle = () => {
    searchState.open = true;
    filter('');
  };

  const hideToggle = () => {
    searchState.open = false;
    searchState.startVoice = false;
    recognition.stop();
  };

  useEffect(() => {
    setupSpeach();
    searchState.loading = true;
    if (props.customDefinition) {
      if (props.autoSearch === true) {
        searchState.loading = false;
        searchState.allData = [];
        searchState.filteredData = [];

        searchState.open = props.autoSearch ? true : false;
        searchState.showSearch = props.autoSearch ? true : false;
      } else {
        searchState.loading = false;
        searchState.allData = [];
        searchState.filteredData = [];
      }
    } else {
      loadData(
        props.resource,
        props.resourceMethod,
        props.resourceParameters,
        (response) => {
          searchState.loading = false;
          searchState.allData = response[props.resourceIdentity];
          searchState.filteredData = response[props.resourceIdentity];

          if (props.autoSearch === true) {
            searchState.open = props.autoSearch ? true : false;
            searchState.showSearch = props.autoSearch ? true : false;
          }
          if (props.selected) {
            searchState.selectedText = props.FormDefiniton.editSearchSelector
              ? props.selected[
                  props.FormDefiniton.editSearchSelector.resourceValue
                ]
                ? props.selected[
                    props.FormDefiniton.editSearchSelector.resourceValue
                  ]
                : ''
              : '';
          }
        },
        () => {
          searchState.loading = false;
        }
      );
    }
  }, []);
  const refresh = () => {
    if (props.customDefinition) {
      return;
    }
    hideToggle();
    searchState.loading = true;
    loadData(
      props.resource,
      props.resourceMethod,
      props.resourceParameters,
      (response) => {
        searchState.loading = false;
        searchState.allData = response[props.resourceIdentity];
        searchState.filteredData = response[props.resourceIdentity];

        if (props.autoSearch === true) {
          searchState.open = props.autoSearch ? true : false;
          searchState.showSearch = props.autoSearch ? true : false;
        }
      },
      () => {
        searchState.loading = false;
      }
    );
  };
  return (
    <SearchComponentStyle>
      <Observer>
        {() => (
          <>
            {searchState.loading ? (
              <div className="section icon">
                <div className="overflow">
                  <div className="circle-line">
                    <div className="circle-red">&nbsp;</div>
                    <div className="circle-blue">&nbsp;</div>
                    <div className="circle-green">&nbsp;</div>
                    <div className="circle-yellow">&nbsp;</div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {searchState.open ? (
                  <>
                    {props.customDefinition ? (
                      <>
                        {searchState.selectedText !== '' ? (
                          <div className="section">
                            <div className="speech square triangle pointed middle right">
                              <span className="speachtext">
                                {searchState.selectedText}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <Noop />
                        )}
                        <div
                          className="section icon"
                          style={{ paddingTop: '5px' }}
                          onClick={hideToggle}
                        >
                          <i
                            className="icon-cross"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Close"
                          ></i>
                        </div>
                      </>
                    ) : (
                      <>
                        {searchState.selectedText !== '' ? (
                          <div className="section">
                            <div className="speech square triangle pointed middle right">
                              <span className="speachtext">
                                {searchState.selectedText}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <Noop />
                        )}

                        <div className="search-container">
                          <div
                            className="section icon"
                            style={{ paddingTop: '4px' }}
                          >
                            <svg
                              enableBackground="new 0 0 512 512"
                              height="15px"
                              version="1.1"
                              viewBox="0 0 512 650"
                              width="15px"
                              fill={props.theme.textMenu}
                            >
                              <path d="M497.913,497.913c-18.782,18.782-49.225,18.782-68.008,0l-84.862-84.863c-34.889,22.382-76.13,35.717-120.659,35.717  C100.469,448.767,0,348.312,0,224.383S100.469,0,224.384,0c123.931,0,224.384,100.452,224.384,224.383  c0,44.514-13.352,85.771-35.718,120.676l84.863,84.863C516.695,448.704,516.695,479.131,497.913,497.913z M224.384,64.109  c-88.511,0-160.274,71.747-160.274,160.273c0,88.526,71.764,160.274,160.274,160.274c88.525,0,160.273-71.748,160.273-160.274  C384.657,135.856,312.909,64.109,224.384,64.109z" />
                            </svg>
                          </div>
                          <div className="section searchbox">
                            <input
                              ref={inputRef}
                              className="search-input"
                              onKeyUp={handleFilter}
                              placeholder="Search here..."
                            />
                          </div>
                          {'webkitSpeechRecognition' in window ? (
                            <>
                              {searchState.startVoice ? (
                                <div
                                  className="section icon"
                                  onClick={stopRecording}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Stop Voice"
                                >
                                  <div className="overflow">
                                    <div className="circle-line">
                                      <div className="circle-red">&nbsp;</div>
                                      <div className="circle-blue">&nbsp;</div>
                                      <div className="circle-green">&nbsp;</div>
                                      <div className="circle-yellow">
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="section icon"
                                  style={{ paddingTop: '5px' }}
                                  onClick={startvoice}
                                >
                                  <i
                                    className="icon-mic"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Voice"
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          <div
                            className="section icon"
                            style={{ paddingTop: '5px' }}
                            onClick={refresh}
                          >
                            <i
                              className="icon-cw"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Refresh"
                            />
                          </div>
                          <div
                            className="section icon"
                            style={{ paddingTop: '5px' }}
                            onClick={hideToggle}
                          >
                            <i
                              className="icon-cross"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Close"
                            ></i>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {searchState.selectedText !== '' ? (
                      <div className="section">
                        <div className="speech square triangle pointed middle right">
                          <span className="speachtext">
                            {searchState.selectedText}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <Noop />
                    )}

                    <div
                      className="section icon"
                      style={{ paddingTop: '4px' }}
                      onClick={showToggle}
                    >
                      <svg
                        enableBackground="new 0 0 512 512"
                        height="15px"
                        version="1.1"
                        viewBox="0 0 512 650"
                        width="15px"
                        fill={props.theme.textMenu}
                      >
                        <path d="M497.913,497.913c-18.782,18.782-49.225,18.782-68.008,0l-84.862-84.863c-34.889,22.382-76.13,35.717-120.659,35.717  C100.469,448.767,0,348.312,0,224.383S100.469,0,224.384,0c123.931,0,224.384,100.452,224.384,224.383  c0,44.514-13.352,85.771-35.718,120.676l84.863,84.863C516.695,448.704,516.695,479.131,497.913,497.913z M224.384,64.109  c-88.511,0-160.274,71.747-160.274,160.273c0,88.526,71.764,160.274,160.274,160.274c88.525,0,160.273-71.748,160.273-160.274  C384.657,135.856,312.909,64.109,224.384,64.109z" />
                      </svg>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Observer>
      <Observer>
        {() => (
          <>
            {searchState.open === true ? (
              props.customDefinition ? (
                <SearchFrom
                  onSelection={itemSelected}
                  customDefinition={props.customDefinition}
                />
              ) : (
                <SearchResults
                  currentPage={searchState.currentPage}
                  HandlePageClick={handlePageClick}
                  AllData={searchState.filteredData}
                  onSelected={itemSelected}
                  Data={paginate(
                    searchState.filteredData,
                    15,
                    searchState.currentPage
                  )}
                  Display={props.display}
                  ResourceDisplayFields={props.resourceDisplayFields}
                />
              )
            ) : (
              <Noop />
            )}
          </>
        )}
      </Observer>
    </SearchComponentStyle>
  );
};

export default withTheme(SearchComponent);
