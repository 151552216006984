import $ from "jquery";

export const setupTabs = () => {
  var SETTINGS = {
    navBarTravelling: false,
    navBarTravelDirection: "",
    navBarTravelDistance: 150
  };

  document.documentElement.classList.remove("no-js");
  document.documentElement.classList.add("js");

  var pnAdvancerLeft = $("#pnAdvancerLeft")[0];
  var pnAdvancerRight = $("#pnAdvancerRight")[0];
  var pnProductNav = $("#pnProductNav")[0];
  var pnProductNavContents = $("#pnProductNavContents")[0];

  showButtons(determineOverflow(pnProductNavContents, pnProductNav));

  pnProductNav.setAttribute(
    "data-overflowing",
    determineOverflow(pnProductNavContents, pnProductNav)
  );

  var last_known_scroll_position = 0;
  var ticking = false;

  function doSomething(scroll_pos) {
    showButtons(determineOverflow(pnProductNavContents, pnProductNav));
  }

  function showButtons(status) {
    if (status === "left" || status === "both") {
      $(".pn-Advancer_Left").css("opacity", "1");
    } else {
      $(".pn-Advancer_Left").css("opacity", "0");
    }

    if (status === "right" || status === "both") {
      $(".pn-Advancer_Right").css("opacity", "1");
    } else {
      $(".pn-Advancer_Right").css("opacity", "0");
    }
  }

  pnProductNav.addEventListener("scroll", function() {
    last_known_scroll_position = window.scrollY;
    if (!ticking) {
      window.requestAnimationFrame(function() {
        doSomething(last_known_scroll_position);
        ticking = false;
      });
    }
    ticking = true;
  });

  pnAdvancerLeft.addEventListener("click", function() {
    if (SETTINGS.navBarTravelling === true) {
      return;
    }

    if (
      determineOverflow(pnProductNavContents, pnProductNav) === "left" ||
      determineOverflow(pnProductNavContents, pnProductNav) === "both"
    ) {
      var availableScrollLeft = pnProductNav.scrollLeft;

      if (availableScrollLeft < SETTINGS.navBarTravelDistance * 2) {
        pnProductNavContents.style.transform =
          "translateX(" + availableScrollLeft + "px)";
      } else {
        pnProductNavContents.style.transform =
          "translateX(" + SETTINGS.navBarTravelDistance + "px)";
      }

      pnProductNavContents.classList.remove(
        "pn-ProductNav_Contents-no-transition"
      );

      SETTINGS.navBarTravelDirection = "left";
      SETTINGS.navBarTravelling = true;
    }

    pnProductNav.setAttribute(
      "data-overflowing",
      determineOverflow(pnProductNavContents, pnProductNav)
    );
  });

  pnAdvancerRight.addEventListener("click", function() {
    if (SETTINGS.navBarTravelling === true) {
      return;
    }

    if (
      determineOverflow(pnProductNavContents, pnProductNav) === "right" ||
      determineOverflow(pnProductNavContents, pnProductNav) === "both"
    ) {
      var navBarRightEdge = pnProductNavContents.getBoundingClientRect().right;
      var navBarScrollerRightEdge = pnProductNav.getBoundingClientRect().right;

      var availableScrollRight = Math.floor(
        navBarRightEdge - navBarScrollerRightEdge
      );

      if (availableScrollRight < SETTINGS.navBarTravelDistance * 2) {
        pnProductNavContents.style.transform =
          "translateX(-" + availableScrollRight + "px)";
      } else {
        pnProductNavContents.style.transform =
          "translateX(-" + SETTINGS.navBarTravelDistance + "px)";
      }

      pnProductNavContents.classList.remove(
        "pn-ProductNav_Contents-no-transition"
      );

      SETTINGS.navBarTravelDirection = "right";
      SETTINGS.navBarTravelling = true;
    }

    pnProductNav.setAttribute(
      "data-overflowing",
      determineOverflow(pnProductNavContents, pnProductNav)
    );
  });

  pnProductNavContents.addEventListener(
    "transitionend",
    function() {
      var styleOfTransform = window.getComputedStyle(
        pnProductNavContents,
        null
      );
      var tr =
        styleOfTransform.getPropertyValue("-webkit-transform") ||
        styleOfTransform.getPropertyValue("transform");

      var amount = Math.abs(parseInt(tr.split(",")[4]) || 0);
      pnProductNavContents.style.transform = "none";
      pnProductNavContents.classList.add(
        "pn-ProductNav_Contents-no-transition"
      );

      if (SETTINGS.navBarTravelDirection === "left") {
        pnProductNav.scrollLeft = pnProductNav.scrollLeft - amount;
      } else {
        pnProductNav.scrollLeft = pnProductNav.scrollLeft + amount;
      }
      SETTINGS.navBarTravelling = false;
    },
    false
  );

  pnProductNavContents.addEventListener("click", function(e) {
    var links = [].slice.call(document.querySelectorAll(".pn-ProductNav_Link"));
    links.forEach(function(item) {
      item.setAttribute("aria-selected", "false");
    });
    e.target.setAttribute("aria-selected", "true");
  });

  function determineOverflow(content, container) {
    var containerMetrics = container.getBoundingClientRect();
    var containerMetricsRight = Math.floor(containerMetrics.right);
    var containerMetricsLeft = Math.floor(containerMetrics.left);
    var contentMetrics = content.getBoundingClientRect();
    var contentMetricsRight = Math.floor(contentMetrics.right);
    var contentMetricsLeft = Math.floor(contentMetrics.left);
    if (
      containerMetricsLeft > contentMetricsLeft &&
      containerMetricsRight < contentMetricsRight
    ) {
      return "both";
    } else if (contentMetricsLeft < containerMetricsLeft) {
      return "left";
    } else if (contentMetricsRight > containerMetricsRight) {
      return "right";
    } else {
      return "none";
    }
  }
};
