import {
  Aggregate,
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  DetailRow,
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  PdfExport,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import React, { PureComponent } from "react";
import { guid, loadData } from "../../../../utilities";

import $ from "jquery";
import GridStyle from "../../../Core/Grid/GridStyle";
import Notifications from "../../../Core/Notification";
import moment from "moment";
import { toJS } from "mobx";

export default class SearchDataGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.gridInstance = null;
  }
  toolbarClick(args) {
    switch (args.item.text) {
      case "PDF Export":
        this.gridInstance.pdfExport();
        break;
      case "Excel Export":
        this.gridInstance.excelExport();
        break;

      default:
        break;
    }
  }

  isEmpty(map) {
    for (var key in map) {
      if (map.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  updateDataSet(e) {
    e.preventDefault();
    $(this.refs.searching).show();
    $(this.refs.searchbutton).hide();
    let validator = $(`#${this.props.HostFormId}`).parsley();
    if (!validator.validate()) {
      Notifications.warning(
        "There was an issue submitting this form. Please ensure all required fields have been filled out."
      );
      return;
    }

    if (this.props.Config !== undefined) {
      //    const postData = toJS(this.props.ParentData);

      /* if (this.isEmpty(postData)) {
        this.gridInstance.dataSource = [];
        return;
      }*/
      loadData(
        this.props.Config.data.getResource.id,
        this.props.Config.data.getResource.resourceType,
        toJS(this.props.ParentData),
        (response) => {
          if (this.gridInstance) {
            this.gridInstance.dataSource =
              response[this.props.Config.data.getResource.resourceIdentity];
          }
          $(this.refs.searching).hide();
          $(this.refs.searchbutton).show();
        },
        () => {
          $(this.refs.searching).hide();
          $(this.refs.searchbutton).show();
        }
      );
    }
  }

  componentDidMount() {}

  componentDidUpdate() {}

  onSelection(args) {
    if (args.data === null) {
      $(this.refs.edit).hide();
    } else {
      this.selected = args;
      $(this.refs.edit).show();
    }
  }

  onDone(e) {
    e.preventDefault();
    this.props.onSelection(this.selected);
  }

  render() {
    return (
      <div className="row" style={{position:'relative',width:'100%',height:'400px'}}>
        <div
          
          style={{
            position:'absolute',
            top:0,
            left:0,
            height:'36px',
            width:'100%'
          }}
        >
          <div className="grid-menu">
            <button
              ref="searchbutton"
              type="button"
              onClick={this.updateDataSet.bind(this)}
              className="searchButton float-left"
            >
              <i className="mdi mdi-magnify" />
              <span>Search</span>
            </button>
            <div style={{ display: "none" }} ref="searching">
              <div className="overflow">
                <div className="circle-line">
                  <div className="circle-red">&nbsp;</div>
                  <div className="circle-blue">&nbsp;</div>
                  <div className="circle-green">&nbsp;</div>
                  <div className="circle-yellow">&nbsp;</div>
                </div>
              </div>
            </div>
            <button
              type="button"
              onClick={this.onDone.bind(this)}
              ref="edit"
              style={{ display: "none" }}
              className="editButton float-left"
            >
              <i className="mdi mdi-check" />
              <span>Choose</span>
            </button>
          </div>
        </div>

        <div
          
          style={{
          position:'absolute',
          top:'36px',
          left:0,
          width:'100%',
          bottom:0,  
          }}
        >
          <GridStyle>
            <GridComponent
              ref={(grid) => {
                this.gridInstance = grid;
                //this.props.onSelection(this.gridInstance, null);
              }}
              toolbarClick={this.toolbarClick.bind(this)}
              allowGrouping={true}
              height='260px' width='100%'
              groupSettings={{
                showDropArea: false,
                showUngroupButton: true,
                showToggleButton: true,
                showGroupedColumn: true,
                showConfirmDialog: false,
              }}
              rowSelected={(args) => {
                this.onSelection(args.data);
              }}
              width="100%"
              acceptsReturn={true}
              textWrapSettings={{
                wrapMode: "Content",
              }}
              allowTextWrap={true}
              allowSelection={true}
              allowFiltering={true}
              allowSorting={true}
              dataSource={this.props.dataSource ? this.props.dataSource : []}
              pageSettings={{
                pageSize: 10,
                pageCount: 10,
                pageSizes: [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
              }}
              allowPaging={true}
              filterSettings={{
                type: "Excel",
              }}
            >
              <ColumnsDirective>
                {this.props.Config.fields.map((column, index) => {
                  switch (column.type) {
                    case "date": {
                      return (
                        <ColumnDirective
                          key={`${guid()}`}
                          field={column.key}
                          headerText={column.label}
                          type={"dateTime"}
                          template={(args) => {
                            return (
                              <span>
                                {moment(args[column.key]).format("DD-MMM-YYYY")}
                              </span>
                            );
                          }}
                        />
                      );
                    }
                    case "text": {
                      return (
                        <ColumnDirective
                          key={`${guid()}`}
                          field={column.key}
                          headerText={column.label}
                          type="string"
                        />
                      );
                    }
                    case "number": {
                      return (
                        <ColumnDirective
                          key={`${guid()}`}
                          field={column.key}
                          headerText={column.label}
                          type="number"
                          format={column.format}
                        />
                      );
                    }
                    case "bool": {
                      return (
                        <ColumnDirective
                          key={`${guid()}`}
                          allowEditing={!column.readOnly}
                          field={column.key}
                          headerText={column.label}
                          displayAsCheckBox={true}
                        />
                      );
                    }
                    case "checkbox": {
                      return (
                        <ColumnDirective
                          key={`${guid()}`}
                          allowEditing={!column.readOnly}
                          field={column.key}
                          headerText={column.label}
                          displayAsCheckBox={true}
                        />
                      );
                    }
                    default: {
                      return (
                        <ColumnDirective
                          key={`${guid()}`}
                          field={column.key}
                          headerText={column.label}
                          type={column.type}
                        />
                      );
                    }
                  }
                })}
              </ColumnsDirective>
              <Inject
                services={[
                  Toolbar,
                  ExcelExport,
                  PdfExport,
                  ColumnChooser,
                  Filter,
                  Sort,
                  Group,
                  DetailRow,
                  Aggregate,
                  Page,
                ]}
              />
            </GridComponent>
          </GridStyle>
        </div>
      </div>
    );
  }
}
