import CashBoxDetail from "./cashboxdetail";
export default {
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  caption: "Cash Box Summary",
  editSearchSelector: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "editabletable", 
                data: {
                  getResource: {
                    resourceType: "POST",
                    id: "GetCashBoxDetailList",
                    resourceIdentity: "CashBoxDetails"
                  }
                },
                entry: {
                  resource: {
                    resourceType: "POST",
                    id: "GetCashBoxDetail",
                    resourceIdentity: "CashBoxDetail"
                  },
                  definition: { ...CashBoxDetail }
                },
                fields: [
                  {
                    key: "CargoAgentName",
                    type: "text",
                    label: "Cargo Agent",
                    readOnly: true
                  },
                  {
                    key: "PaymentType",
                    type: "text",
                    label: "Payment Type",
                    readOnly: true
                  },
                  {
                    key: "AirwaybillNumber",
                    type: "text",
                    label: "AWB Number",
                    readOnly: true
                  },
                  {
                    key: "AirwaybillAmount",
                    type: "number",
                    format: "C2",
                    label: "AWB Amount",
                    readOnly: true
                  },
                  {
                    key: "Sender",
                    type: "text",
                    label: "Sender",
                    readOnly: true
                  },
                  {
                    key: "Recipient",
                    type: "text",
                    label: "Recipient",
                    readOnly: true
                  },
                  {
                    key: "Route",
                    type: "text",
                    label: "Route",
                    readOnly: true
                  },
                ]
              }
            ]
          }
        ]
      }     
    ]
  }
};



