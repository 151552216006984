import SystemUserSecurityRoleList from "./systemusersecurityrolelist";
import SystemUserLogList from "../systemuserlog/systemuserloglist";
export default {
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["User Administration Administrator"],
      value: true
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["User Administration Administrator"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["User Administration Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["User Administration Administrator"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  caption: "System User",
  editSearchSelector: {
    resource: "getsystemuserslist",
    resourceId: ["UserName"],
    resourceIdentity: "SystemUsers",
    resourceValue: "UserName",
    resourceType: "GET",
    resourceSelectedType: "POST",
    resourceSelected: "getsystemusers",
    resourceSelectedIdentity: "SystemUsers",
    resourceDisplayFields: [],
    updateResourceOnChange: [
      {
        resource: "getsystemusersecurityrolelist",
        resourceSelectedType: "POST",
        resourceId: ["UserName"]
      }
    ]
  },
  functionactions: {
    createnew: {
      functionname: "addSystemUsers",
      functiontype: "POST",
      validateData: true,
      validationFailedTitle: "",
      validationFailedText: "",
      alertOnComplete: true,
      alertTitle: "",
      alertText: ""
    },
    update: {
      functionname: "updateSystemUsers",
      functiontype: "POST",
      validateData: true,
      validationFailedTitle: "",
      validationFailedText: "",
      alertOnComplete: true,
      alertTitle: "",
      alertText: ""
    },
    after: {
      functionname: "getSystemUserList",
      functiontype: "GET",
      resourceIdentity: "SystemUsers",
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "System User Information",
            fields: [
              {
                key: "UserName",
                type: "text",
                label: "User Name",
                placeholder: "User Name",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 3,
                  "data-parsley-maxlength": 20
                }
              },
              {
                key: "FullUserName",
                type: "text",
                label: "Full Name",
                placeholder: "Full Name",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 3,
                  "data-parsley-maxlength": 100
                }
              },
              {
                key: "UserDescription",
                type: "text",
                label: "User Description",
                placeholder: "User Description",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 3,
                  "data-parsley-maxlength": 50
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "TelephoneNumber",
                type: "text",
                label: "Telephone Number",
                placeholder: "Telephone Number",
                required: "false",
                readOnly: false
              },
              {
                key: "EmailAddress",
                type: "text",
                label: "Email Address",
                placeholder: "Email Address",
                readOnly: false
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "PassCode",
                type: "password",
                label: "Password",
                placeholder: "Password",
                hideOnEdit: "true",
                readOnlyOnEdit: true,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 8,
                  "data-parsley-maxlength": 100
                }
              },
              {
                key: "UserActive",
                type: "checkbox",
                label: "Active",
                placeholder: "Active",
                defaultValue: "true",
                readOnly: false,
                validation: {
                  "data-parsley-required": true
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "StationPosted",
                type: "select",
                label: "Station Posted",
                placeholder: "Station Posted",
                readOnly: false,
                optionsResource: {
                  resource: "GetActiveCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 4
                }
              },
              {
                key: "AccountingStaff",
                type: "checkbox",
                label: "Accounting Staff",
                placeholder: "Accounting Staff",
                defaultValue: "true",
                readOnly: false
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "tabs",
                readOnlyOnEdit: true,
                tabs: [
                  {
                    caption: "Security Roles",
                    definition: { ...SystemUserSecurityRoleList }
                  },
                  {
                    caption: "Logs",
                    definition: { ...SystemUserLogList }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
