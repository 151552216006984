import chroma from 'chroma-js';
import styled from "@emotion/styled";

const GridStyle = styled.div`
 .e-griderror
  {
    display: none!important;
  }
.e-grid td.e-active {
    border-top:1px solid ${(props) => chroma(props.theme.accent).alpha(0.15).brighten(1).css()};
    background-color: ${(props) => chroma(props.theme.accent).alpha(0.15).brighten(1).css()};
    color: ${(props) => props.theme.accent};
  }
  .e-grid.e-gridhover tr[role='row']:not(.e-editedrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role='row']:hover .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role='row']:hover .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
    border-top:1px solid ${(props) => chroma(props.theme.accent).alpha(0.15).brighten(1).css()};
    background-color: ${(props) => chroma(props.theme.accent).alpha(0.15).brighten(1).css()};
    color: ${(props) => props.theme.accent};
    cursor:pointer;
  }

  .e-grid .e-rowcell
  {
    padding:1.5pt 2pt;
  }

  .e-input-group .e-input-group-icon, .e-input-group.e-control-wrapper .e-input-group-icon
  {
    min-height:24px;
  }
  .e-grid .e-toolbar {
    border: 0px solid #eaeaea;
  }
  .e-grid {
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    font-family: ${(props) => props.theme.mainFont};
    margin-bottom: 20px;
  }
  .e-pager {
    -webkit-border-bottom-right-radius: 3px;
-webkit-border-bottom-left-radius: 3px;
-moz-border-radius-bottomright: 3px;
-moz-border-radius-bottomleft: 3px;
border-bottom-right-radius: 3px;
border-bottom-left-radius: 3px;
    background-color: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.backgroundLight};
    font-size: 8pt;
  }
  
  .e-row{
    &:nth-of-type(even)
        {
          background-color:${(props) => chroma(props.theme.backgroundDark).alpha(0.4).css()};
        }
  }
  .e-pager .e-prevpagedisabled,
  .e-pager .e-prevpage,
  .e-pager .e-nextpage,
  .e-pager .e-nextpagedisabled,
  .e-pager .e-lastpagedisabled,
  .e-pager .e-lastpage,
  .e-pager .e-firstpage,
  .e-pager .e-firstpagedisabled {
    background-color: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.backgroundLight};
  }

  .e-pager .e-numericitem:not(.e-active):not(.e-np):not(.e-pp):hover {
    background-color: ${(props) => props.theme.accent}!important;
  }

  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: ${(props) => props.theme.accent};
    border-color: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.backgroundLight};
  }

  .e-btn.e-flat.e-primary:active,
  .e-btn.e-flat.e-primary.e-active,
  .e-css.e-btn.e-flat.e-primary:active,
  .e-css.e-btn.e-flat.e-primary.e-active {
    background-color: ${(props) => props.theme.accent}!important;
    border-color: ${(props) => props.theme.accent}!important;
    color: ${(props) => props.theme.backgroundLight};
  }

  .e-pager .e-active {
    background-color: ${(props) => props.theme.backgroundDark}!important;
    border: 0px solid transparent !important;
    border-radius: 100px;
    padding: 10px 3px;
    color: ${(props) => props.theme.accent}!important;
    opacity: 1;
  }

  .e-pager .e-pagercontainer {
    background-color: ${(props) => props.theme.accent};
    border-color: ${(props) => props.theme.accent};
  }

  .e-pager .e-numericitem {
    background: ${(props) => props.theme.accent};
    border-right-color: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.backgroundLight};
  }

  .e-toolbar .e-tbar-btn {
    background: ${(props) => props.theme.backgroundDark}!important;
    border-color: ${(props) => props.theme.backgroundDark}!important;
    font-family: ${(props) => props.theme.headerFont};
    font-weight: 700;

    text-transform: uppercase;
  }
  .e-tbar-btn-text {
    font-size: 11px !important;
  }
  .e-toolbar.e-control[class*='e-toolbar'] {
    background: ${(props) => props.theme.backgroundDark};
  }
  .e-headertext {
    font-family: ${(props) => props.theme.headerFont};
    font-weight: 600;
    font-size: 8pt !important;
    text-transform: capitalize;
  }
  .e-grid .e-gridcontent tr.e-row:first-of-type .e-rowcell {
    border-top: 1px solid transparent;
}
  .e-grid .e-headercontent {
    border: 0px solid transparent;
    
  }

  .e-hscroll .e-scroll-nav.e-scroll-right-nav {
    border-left: 0px solid #a6a6a6;
  }

  .e-hscroll .e-scroll-nav.e-scroll-left-nav {
    border-left: 0px solid #a6a6a6;
  }

  .e-hscroll .e-scroll-nav {
    background: ${(props) => props.theme.backgroundDark}!important;
    border: 0px solid #000;
  }
  .e-hscroll-content {
    border: 0px solid #000;
  }

  .e-pager {
    border: 0px solid #000;
    padding:1.5pt 2pt;
  }

  .e-grid .e-headercell,
  .e-grid .e-detailheadercell {
    background-color: ${(props) => props.theme.backgroundDark};
    border-color: #eaeaea;
  }

  .e-grid .e-gridheader {
    background-color: ${(props) => props.theme.backgroundDark}!important;
    border-bottom-color: ${(props) => props.theme.backgroundDark}!important;
    border-top-color: ${(props) => props.theme.backgroundDark}!important;
    color: #666;
  }

  .e-toolbar .e-toolbar-items {
    background: ${(props) => props.theme.backgroundDark};
  }
`

export default GridStyle;
