export default {
  formtype: "dataEntry",
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                key: "EmployeeCargoInvoiceAdjustmentBatchNumber",
                type: "text",
                label: "Batch Number",
                placeholder: "Batch Number",
                readOnly: false
              },
              {
                key: "AdjustmentDate",
                type: "date",
                label: "Adjustment Date",
                placeholder: "Adjustment Date",
                readOnly: false
              }
            ]
          },
          {
            title: '\n',
            fields: [
              {
                
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "",
            hideOnAdd: "true",
            fields: [
              {
                type: "searchtable",
                data: {
                  getResource: {
                    id: "GetEmployeeCargoInvoiceAdjustmentBatchListForSearch",
                    resourceType: "POST",
                    resourceIdentity: "EmployeeCargoInvoiceAdjustmentBatches"
                  }
                },
                fields: [                  
                  {
                    key: "EmployeeCargoInvoiceAdjustmentBatchNumber",
                    type: "text",
                    label: "Batch No."
                  },
                  {
                    key: "AdjustmentDate",
                    type: "date",
                    label: "Adjustment Date"
                  },
                  {
                    key: "AdjustmentBatchStatus",
                    type: "text",
                    label: "Status"
                  },
                  {
                    key: "EnteredBy",
                    type: "text",
                    label: "Entered By"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
