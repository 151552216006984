import { observable, toJS } from "mobx";

import $ from "jquery";
import Notifications from "../../../Core/Notification";
import { loadData } from "../../../../utilities";
import onConfirmation from "./onConfirmation";
import onLaunchDialog from "./onLaunchDialog";
import { user } from "../../../../utilities";

const computeDefault = (addobject, field) => {
  if (field["defaultValue"]) {
    if (field["defaultValue"] === "Today") {
      addobject[field.key] = new Date();
      return;
    } 
    
    if (field["defaultValue"] === "User") {
      addobject[field.key] = user.getUserDetails().UserName;
      return;
    } 
    
    if (field["defaultValue"] === "EmployeeId") {
      addobject[field.key] = user.getUserDetails().EmployeeIdNumber;
      return;
    } 

    if (addobject[field.key]) {
      if(addobject[field.key].toString().trim().length === 0)
      { 
        addobject[field.key] = field["defaultValue"];
      }
    }
    else
    {
      addobject[field.key] = field["defaultValue"];
    }
  }
};

const computeDefaultValues = (addobject, props) => {
  props.formDefinition.rows.forEach((item) => {
    item.columns.forEach((col) => {
      col.fields.forEach((field) => {
        if (field instanceof Array) {
          field.forEach((subField) => {
            computeDefault(addobject, subField);
          });
        } else {
          computeDefault(addobject, field);
        }
      });
    });
  });
  return addobject;
};

const executeForm = (button, props) => {
  return new Promise((resolve, reject) => {
    let validator = $(`#${props.FormId}`).parsley();
    if (validator && !validator.validate()) {
      Notifications.warning(
        "There was an issue submitting this form. Please ensure all required fields have been filled out."
      );
      reject();
      return;
    }
    const launch = () => {
      if (button.action && button.action.functionName) {
        loadData(
          button.action.functionName,
          button.action.functionType,
          toJS(props.ParentData),
          (response) => {
            let newEntry = {
              ...response[button.action.functionResourceIdentity],
            };
            newEntry = computeDefaultValues(newEntry, toJS(button.definition));

            const EntryDetails = observable(newEntry);
            //launchDialog(button, props, EntryDetails);
            onLaunchDialog(
              props,
              EntryDetails,
              button.adding,
              button.editing,
              button.definition,
              button.locked,
              (afterAddResults) => {
                return new Promise((resolve1, reject1) => {
                  if (afterAddResults) {
                    loadData(
                      button.after.functionName,
                      button.after.functionType,
                      toJS(afterAddResults),
                      (response1) => {
                        const EntryDetails1 = observable({
                          ...response1[button.after.functionResourceIdentity],
                        });
                        resolve1(EntryDetails1);
                      },
                      () => {
                        reject1();
                      }
                    );
                  } else {
                    if (button.refreshAfterSave && props.refreshAfterSave) {
                      props.refreshAfterSave();
                    }
                    resolve1(null);
                  }
                });
              }
            );
            resolve();
          },
          () => {
            reject();
          }
        );
      } else if (button.defaultValues) {
        let newEntry = { ...button.defaultValues };
        if (button.keylink instanceof Array) {
          button.keylink.forEach((item, index) => {
            newEntry[item] = props.EntryDetails[button.key[index]];
          });
        } else {
          newEntry[button.keylink] = props.EntryDetails[button.key];
        }

        newEntry = computeDefaultValues(newEntry, toJS(button.definition));

        onLaunchDialog(
          props,
          observable(newEntry),
          button.adding,
          button.editing,
          button.definition,
          button.locked,
          (afterAddResults) => {
            return new Promise((resolve1, reject1) => {
              if (afterAddResults) {
                loadData(
                  button.after.functionName,
                  button.after.functionType,
                  toJS(afterAddResults),
                  (response1) => {
                    const EntryDetails1 = observable({
                      ...response1[button.after.functionResourceIdentity],
                    });
                    resolve1(EntryDetails1);
                  },
                  () => {
                    reject1();
                  }
                );
              } else {
                if (button.refreshAfterSave && props.refreshAfterSave) {
                  props.refreshAfterSave();
                }
                resolve(null);
              }
            });
          }
        );

        resolve();
      } else {

        let newEntry = { ...toJS( props.EntryDetails) };
        newEntry = computeDefaultValues(newEntry, toJS(button.definition));
        onLaunchDialog(
          props,
          observable(newEntry),
          button.adding,
          button.editing,
          button.definition,
          button.locked,
          (afterAddResults)=>{
            if (afterAddResults) {
              loadData(
                button.after.functionName,
                button.after.functionType,
                toJS(afterAddResults),
                (response1) => {
                  const EntryDetails1 = observable({
                    ...response1[button.after.functionResourceIdentity],
                  });
                  resolve(EntryDetails1);
                },
                () => {
                  reject();
                }
              );
            } else {
              if (button.refreshAfterSave && props.refreshAfterSave) {
                props.refreshAfterSave();
              }
              resolve(null);
            }
          }
        );

        resolve();
      }
    };

    onConfirmation(
      button,
      props,
      () => {
        launch();
      },
      () => {
        reject(false);
      }
    );
  });
};

export default executeForm;
