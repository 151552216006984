import React from "react";
import plane from "./plane";

const NothingSelected = props => (
  <div className="nothing-selected">
   
  </div>
);

export default NothingSelected;
