import UserDocumentationSearch from "./userdocumentationsearch";
export default {
  duplicates: [],
  access: [],
  formtype: "dataEntry",
  customSearch:{
    searchForm:{...UserDocumentationSearch},    
    width:800,
    align:500,
    height:800,
    id:"userdocumentationsearch"
  },
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    },
    {
      role: [""],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["User Documentation Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["User Documentation Administrator"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "User Documentation",
  editSearchSelector: {
    resource: "GetUserDocumentationMajorCategoryList",
    resourceType: "GET",
    resourceId: ["MajorCategory"],
    resourceIdentity: "MajorCategories",
    resourceValue: "MajorCategory",
    resourceSelected: "getUserDocumentation",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "UserDocumentation",
    resourceDisplayFields: ["UserDocumentation"],
    after: []
  },
  functionactions: {
    createnew: {
      functionname: "AddUserDocumentation",
      functiontype: "POST",
      validateData: true,
      validationFailedTitle: "",
      validationFailedText: "",
      alertOnComplete: true,
      alertTitle: "User Documentation Information Added",
      alertText: "The User Documentation Record Has Been Added"
    },
    update: {
      functionname: "UpdateUserDocumentation",
      functiontype: "POST",
      validateData: true,
      validationFailedTitle: "",
      validationFailedText: "",
      alertOnComplete: true,
      alertTitle: "User Documentation Information Updated",
      alertText: "The User Documentation Has Been Updated"
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "User Documentation Information",
            fields: [
              {
                key: "MajorCategory",
                type: "select",
                allowText: true,
                defaultvalue: "",
                label: "Major Category",
                placeholder: "Major Category",
                readOnly: false,
                optionsResource: {
                  resource: "GetUserDocumentationMajorCategoryList",
                  resourceType: "GET",
                  resourceId: ["MajorCategory"],
                  resourceIdentity: "MajorCategories",
                  resourceValue: "MajorCategory"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "MinorCategory",
                type: "select",
                allowText: true,
                defaultvalue: "",
                label: "Minor Category",
                placeholder: "Minor Category",
                readOnly: false,
                optionsResource: {
                  resource: "GetUserDocumentationMinorCategoryList",
                  resourceType: "GET",
                  resourceId: ["MinorCategory"],
                  resourceIdentity: "MinorCategories",
                  resourceValue: "MinorCategory"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "DocumentationTopic",
                type: "text",
                defaultvalue: "",
                label: "Topic",
                placeholder: "Topic",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 255
                }
              },
              {
                key: "DocumentationContent",
                type: "richtext",
                defaultvalue: "",
                label: "Content",
                placeholder: "Content",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2
                }
              }
            ]
          }
        ]
      }
    ]
  }
};
