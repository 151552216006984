import loadRemoteData from "./loadRemoteData";
import { toJS } from "mobx";

const transport = function(success, failure) {
  if (this.loadedData) {
    success(this.loadedData);
  } else {
    if(!this.props.Config.options)
    {
    loadRemoteData(
      this.props.Config,
      toJS(this.props.EntryDetails),
      response => {
        this.loadedData = { status: "ok", ok: "ok", data: response };
        success(this.loadedData);
      },
      () => {
        failure({ status: "500", ok: "error", data: null });
      }
    );
    }
    else
    {
      this.loadedData = { status: "ok", ok: "ok", data: this.props.Config.options };
      success(this.loadedData);
    }
  }
};

export default transport;
