export default {
  track: "CargoRate",
  formtype: "dataEntry",
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                key: "ServiceType",
                type: "select",
                defaultValue: "",
                label: "Service Type",
                placeholder: "Service Type",
                optionsResource: {
                  resource: "GetCargoServiceTypeList",
                  resourceType: "GET",
                  resourceId: ["CargoServiceType"],
                  resourceIdentity: "CargoServiceTypes",
                  resourceValue: "CargoServiceType"
                }
              },
              {
                key: "CityFrom",
                type: "select",
                label: "City From",
                placeholder: "City From",
                readOnly: false,
                optionsResource: {
                  resource: "GetCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                }
              },
              {
                key: "CityTo",
                type: "select",
                label: "City To",
                placeholder: "City To",
                readOnly: false,
                optionsResource: {
                  resource: "GetCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                }
              }
            ]
          },
          {
            title: '\n',
            fields: [
              {
                key: "BeginDate",
                type: "date",
                defaultValue: "",
                label: "Begin Date",
                placeholder: "Begin Date",
                readOnly: false
              },
              {
                key: "ActiveCargoSpecialRate",
                type: "checkbox",
                defaultValue: 1,
                label: "Active Rates Only",
                placeholder: "Active Rates",
                readOnly: false
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "",
            hideOnAdd: "true",
            fields: [
              {
                type: "searchtable",
                id:"CargoSpecialRate",
                data: {
                  getResource: {
                    id: "GetCargoSpecialRateListForSearch",
                    resourceType: "POST",
                    resourceIdentity: "CargoSpecialRates"
                  }
                },
                fields: [
                  {
                    key: "ServiceType",
                    type: "text",
                    label: "Service Type"
                  },
                  {
                    key: "CityFrom",
                    type: "text",
                    label: "From"
                  },
                  {
                    key: "CityTo",
                    type: "text",
                    label: "To"
                  },
                  {
                    key: "PoundRate",
                    type: "number",
                    format: "c2",
                    label: "Pound Rate"
                  },
                  {
                    key: "BeginDate",
                    type: "text",
                    label: "Begin Date"
                  },
                  {
                    key: "EndDate",
                    type: "text",
                    label: "End Date"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
