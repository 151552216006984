export default {
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Customer Details",
  editSearchSelector: {
    resource: "GetCargoCustomerList",
    resourceId: ["CargoCustomerRecordNumber"],
    resourceType:"GET",
    resourceIdentity: "CargoCustomers",
    resourceValue: "CustomerName",
    resourceSelectedType: "POST",
    resourceSelected: "GetCargoCustomer",
    resourceSelectedIdentity: "CargoCustomer",
    resourceDisplayFields: [],
    after: []
  },
  functionactions: {
    createnew: {
      functionname: "AddCargoCustomer",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Customer Information Added",
      alertText: "The Cargo Customer Has Been Added"
    },
    update: {
      functionname: "UpdateCargoCustomer",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Customer Information Updated",
      alertText: "The Cargo Customer Has Been Updated"
    },
    after: {
      functionname: "GetCargoCustomerList",
      functiontype: "GET"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Cargo Customer Information",
            fields: [
              {
                key: "CustomerName",
                type: "text",
                label: "Customer Name",
                placeholder: "Customer Name",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "CustomerAddress",
                type: "text",
                label: "Address",
                placeholder: "Address",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "CustomerCity",
                type: "text",
                label: "City",
                placeholder: "City",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "CustomerCountryCode",
                type: "text",
                label: "Country Code",
                placeholder: "Country Code",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 2
                }
              }
            ]
          },
          {
            title: "Contact Information",
            fields: [
              {
                key: "CustomerTelephone",
                type: "text",
                label: "Telephone Number",
                placeholder: "Telephone Number",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 10
                }
              },
              {
                key: "CustomerEmailAddress",
                type: "text",
                label: "Email Address",
                placeholder: "Email Address",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 50
                }
              },
              {
                key: "CustomerTIN",
                type: "text",
                label: "Tax Id Number",
                placeholder: "Tax Id Number",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 10
                }
              }
            ]
          }
        ]
      }
    ]
  }
};
