import CargoInvoiceItem from "./cargoinvoiceitem";
export default {
  track: "Cargo Invoice Item List",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  caption: "Cargo Invoice Items",
  editSearchSelector: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "editabletable", 
                data: {
                  getResource: {
                    resourceType: "POST",
                    id: "GetCargoInvoiceItemList",
                    resourceIdentity: "CargoInvoiceItems"
                  }
                },
                entry: {
                  resource: {
                    resourceType: "POST",
                    id: "GetCargoInvoiceItem",
                    resourceIdentity: "CargoInvoiceItem"
                  },
                  definition: { ...CargoInvoiceItem }
                },
                fields: [
                  {
                    key: "AirwaybillNumber",
                    type: "text",
                    label: "Air Waybill Number",
                    placeholder: "Air Waybill Number",
                    readOnly: true
                  },
                  {
                    key: "ServiceType",
                    type: "text",
                    label: "Service",
                    placeholder: "Service",
                    readOnly: true
                  },
                  {
                    key: "ShippingRoute",
                    type: "text",
                    label: "Route",
                    placeholder: "Route",
                    readOnly: true
                  },
                  {
                    key: "ItemAmount",
                    type: "number",
                    format: "C2",
                    label: "Amount",
                    readOnly: true
                  }
                ]
              }
            ]
          }
        ]
      }     
    ]
  }
};



