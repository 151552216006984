export default {
  formtype: "dataEntry",
  saveAndNew:false,
hideSearch:true,
  allowSearch: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false,
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false,
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false,
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false,
    },
  ],
  caption: "Add Packages",
  editSearchSelector: {
    resource: "GetCompanyList",
    resourceId: ["CompanyRecordNumber"],
    resourceMethod:"GET",
    resourceIdentity: "Companies",
    resourceValue: "CompanyRecordNumber",
    resourceSelectedType: "POST",
    resourceSelected: "getCompany",
    resourceSelectedIdentity: "Company",
    resourceDisplayFields: [],
    after: []
  },
  functionactions: {
    createnew: {
    },
    update: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [  
              {
                key: "ManifestNumber",
                type: "text",
                label: "Manifest Number",
                placeholder: "Manifest Number",
                readOnly: true
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "PackageType",
                type: "text",
                label: "Package Type",
                placeholder: "Package Type",
                readOnly: true
              }
            ]
          },
          {
            title: "\n",
            fields: [
              
            ]
          },
          {
            title: "\n",
            fields: [              
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "Add Packages",
            fields: [
              {
                key:"AirwaybillPackageNumber",
                type:"barcode",
                startText:"Begin Scanning of Packages",
                endText: "End Scanning of Packages",
                continuousScan: true,
                autoScan: true,
                action:{
                  eventType: 'api',
                  roles: [
                    {
                      role: ['Cargo Administrator','Cargo Member'],
                      value: true
                    }        
                  ],
                  function: {
                    functionName: 'AddCargoManifestPackage',
                    functionType: 'POST',
                    alertText: 'The Package Has Been Added.'
                    },
                  validateData: true,
                  alertOnComplete: true,
                  alertText: 'The Package Has Been Added.',
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
            ]
          },
          {
            title: "\n",
            fields: [
              
            ]
          },
          {
            title: "\n",
            fields: [              
            ]
          }
        ]
      }
    ]
  }
};
