import React from "react";
import $ from "jquery";
import Loader from "../Loader";
import ReactDOM from "react-dom";
import { Observer } from "mobx-react";
import { ThemeProvider } from "@emotion/react";
import themeStore from "../../../../stores/themeStore";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { user, getMeta } from "../../../../utilities";

const nval = getMeta()[0];
const correctlyNoncedEmotionCache = createCache({
  key: "css-key",
  nonce: nval,
});

const loadIn = function () {
  const target = $(this.refs.loaderHolder);
  target.removeClass("hidden");
  ReactDOM.render(
    <Observer>
      {() => (
        <CacheProvider value={correctlyNoncedEmotionCache}>
          <ThemeProvider theme={themeStore.theme.theme}>
            <Loader
              OnComplete={this.props.OnComplete}
              LoaderHolder={this.refs.loaderHolder}
              Triggers={this.props.Triggers}
              Text={this.props.Text}
              OnReady={this.props.OnReady}
            />
          </ThemeProvider>
        </CacheProvider>
      )}
    </Observer>,
    target[0]
  );
};

export default loadIn;
