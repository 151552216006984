import CargoPaymentLog from "./cargopaymentlog"
export default {
  duplicates: [],
  access: [],
  track: "Cargo Payment Log List",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Payment Logs",
  editSearchSelector: {
  },
  model: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                  type: "editabletable", 
                  data: {
                    getResource: {
                      resourceType: "POST",
                      id: "GetCargoPaymentLogList",
                      resourceIdentity: "CargoPaymentLogs"
                    }
                  },
                  entry: {
                    resource: {
                      resourceType: "POST",
                      id: "GetCargoPaymentLog",
                      resourceIdentity: "CargoPaymentLog"
                    },
                    definition: { ...CargoPaymentLog }
                  },
                  fields: [
                    
                    {
                      key: "ActionTaken",
                      type: "text",
                      label: "Action Taken",
                      placeholder: "Action Taken",
                      readOnly: true
                    },
                    {
                      key: "UserName",
                      type: "text",
                      label: "User",
                      placeholder: "User",
                      readOnly: true
                    },
                    {
                      key: "ActionDate",
                      type: "text",
                      label: "Date",
                      placeholder: "Date",
                      readOnly: true
                    }
                  ]
                }
            ]
          }
        ]
      }     
    ]
  }
};



