import createGuid from './GUID';
import checkNumerical from './Numeric/checkNumerical';
import computeExpression from './Compute';
import convertObjectPropertiesToNumeric from './Compute/convertObjectPropertiesToNumeric';
import getNumerical from './Numeric/getNumerical';
import userStore from './User';
import loadDataFromApi from './API';
import addAnimate from './Animate/addAnimation';
import removeAnimate from './Animate/removeAnimation';
import WindowManager from './WindowManager';
export const guid = createGuid;
export const isNumeric = checkNumerical;
export const compute = computeExpression;
export const objectPropertiesToNumerical = convertObjectPropertiesToNumeric;
export const asNumber = getNumerical;
export const user = userStore;
export const loadData = loadDataFromApi;
export const addAnimateCSS = addAnimate;
export const removeAnimateCSS = removeAnimate;
export const winManager = WindowManager;
export const getMeta=()=> {
    const metas = document.getElementsByTagName('meta');
    const nonce = [];
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('http-equiv') === "Content-Security-Policy") {
  
        const contents = metas[i].getAttribute('content').split(';');
  
        
        contents.forEach((item)=>{
          if(item.trim().startsWith('style-src'))
          {
            const tags = item.split(' ');
        
            tags.forEach(tag=>{
              if(tag.indexOf('nonce')>-1)
              {
                nonce.push(tag.split("'").join("").replace("nonce-",""));
              }
            });
            
          }
        });
  
  
       // return metas[i].getAttribute('content')
        //;
      }
    }
  
    return nonce;
  }
 