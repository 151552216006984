export default {
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true,
    },
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false,
    },
  ],
  caption: "Cash Box Detail",
  editSearchSelector: {},
  functionactions: {
    createnew: {},
    update: {},
    after: {},
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Cash Box Detail Information",
            fields: [
              {
                key: "CargoAgentName",
                type: "text",
                label: "Cargo Agent",
                placeholder: "Air Waybill Number",
                readOnly: true,
              },
              {
                key: "PaymentType",
                type: "text",
                label: "Payment Type",
                placeholder: "Payment Type",
                readOnly: true,
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "AirwaybillNumber",
                type: "text",
                label: "AWB Number",
                placeholder: "AWB Number",
                readOnly: true,
              },
              {
                key: "AWB Amount",
                type: "number",
                format: "$0,0.00",
                label: "Amount",
                placeholder: "Amount",
                readOnly: true,
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "Sender",
                type: "text",
                label: "Sender",
                placeholder: "Sender",
                readOnly: true,
              },
              {
                key: "Recipient",
                type: "text",
                label: "Recipient",
                placeholder: "Recipient",
                readOnly: true,
              },
              {
                key: "Route",
                type: "text",
                label: "Route",
                placeholder: "Route",
                readOnly: true,
              },
            ]
          }
        ]
      }
    ]
  }
};
