import moment from 'moment';
const BindModel = (
  data,
  dataProperty,
  componentProperty,
  onChangeMethod,
  isDate,
  isHtml
) => {
  return {
    [componentProperty]: data[dataProperty] !==null && data[dataProperty] != undefined
      ? isHtml
        ? data[dataProperty].replace(/<\/?[^>]+>/gi, '').trim()
        : isDate
        ? data[dataProperty]
          ? moment(data[dataProperty],["YYYY/MM/DD"]).toDate()
          : null
        : data[dataProperty]
      : '',
    [onChangeMethod](event) {
      if (event.hasOwnProperty && event.hasOwnProperty('itemData')) {
        data[dataProperty] = event.itemData ? event.itemData.id : '';
      } else if (event.hasOwnProperty && event.hasOwnProperty('value')) {
        data[dataProperty] = event.value !==null && event.value !==undefined ? event.value : '';
      } else if (event.target) {
        data[dataProperty] = event.target.value ? event.target.value : '';
      } else {
        data[dataProperty] = event ? event : '';
      }
    },
  };
};



export default BindModel;