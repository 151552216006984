// Secure math library to prevent security vunerabilities
import assignedDisabledFunctions from "./assignedDisabledFunctions";
const secureMath = (math) => {    
    
    math.import(
        assignedDisabledFunctions(),
      { override: true }
    );
  };

  export default secureMath;