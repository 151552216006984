import styled from "@emotion/styled";

const CheckBoxStyle = styled.div`
height:40.98px;
padding:5pt 0pt;
table{
  width:100%;
  td:nth-of-type(1){
    width:45pt;
  }
}
.readOnly{
  position:absolute;
  left:0;
  width:20pt;
  height:20pt;
  display:block;
  background:transparent;
}
.hiddenCheck
{
  display:none;
}



a {
  outline:none;
  cursor:pointer;
  display:block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
input{
  display:none;
}
label {
  font-family: ${(props) => props.theme.mainFont};
  cursor:pointer;
  font-size: 7.5pt;
  font-weight: 600;
  text-transform: uppercase;
  text-overflow: ellipsis,
  whiteSpace: nowrap,
  display:inline-block;
  color: ${(props) => props.theme.textMedium};
}


.checkState{
  font-family: ${props => props.theme.headerFont};
  font-size: 600;
  text-transform: uppercase;
  font-size: 6pt;
  border-radius: 25pt;
  text-overflow:ellipsis;
  white-space:nowrap;
  border: 0pt solid transparent;
  padding: 1pt 8pt 1pt 5pt;
  i {
    display: inline-block;
    background: ${props => props.theme.textLight};
    color: ${props => props.theme.add};
    border-radius: 25pt;
    padding: 0 2pt;
    margin: 3pt 0;
    margin-right: 5pt;
  }
  span {
    display: inline-block;
    color: ${props => props.theme.textLight};
  }
}

.yesState {
    background: ${props => props.theme.add};
  }

  .noState {
    background: ${props => props.theme.darkGray};
  }
`

export default CheckBoxStyle;
