import processResponse from "./processResponse";

const processGet = options => {
  
  fetch(options.url, {
    headers: {
      "X-CSRF-TOKEN-VERIFY-HEADER": options.xsrfToken,
      "user-token": options.token,
      "site-token":"eLtM2kzxUkuXVyhEN3UqtCzpM"
    }
  })
    .then(processResponse)
    .then((payload) => {      
      if(payload.error)
      {         
          options.errorCallback(payload.error);        
      }
      else
      {
        if (options.callback) {        
          options.callback(payload.data);
        }
      }
    })
    .catch(error => {
      
      console.error(error);
      options.errorCallback(error);    
    });
};

export default processGet;
