import styled from '@emotion/styled';

const SearchComponentStyle = styled.div`
  margin-top: 3px;

  float: left;
  .search-input {
    width: 100%;
    border: 0px solid transparent !important;
    height: 30px;
    outline: none !important;
  }
  .search-container {
    background-color: ${(props) => props.theme.backgroundLight};
    border-radius: 3px;
    overflow: hidden;
    margin-top: 3px;
  }
  .ui.blue.inverted {
    padding: 5em;
  }

  .overflow,
  .circle-line {
    position: relative;
  }
  .overflow {
    top: 18px;
  }

  .circle-line {
    width: 31px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .circle-red,
  .circle-green,
  .circle-yellow,
  .circle-blue {
    position: relative;
    width: 4px;
    height: 4px;
    border-radius: 10px;
    margin: 1px;
  }

  .circle-red {
    background-color: ${(props) => props.theme.accent};
    animation: movingUp 0.5s infinite alternate;
  }

  .circle-blue {
    background-color: ${(props) => props.theme.accent};
    animation: movingUp 0.5s 0.2s infinite alternate;
  }

  .circle-green {
    background-color: ${(props) => props.theme.accent};
    animation: movingUp 0.5s 0.5s infinite alternate;
  }

  .circle-yellow {
    background-color: ${(props) => props.theme.accent};
    animation: movingUp 0.5s 0.7s infinite alternate;
  }

  /* CSS ANIMATION */

  @keyframes movingUp {
    from {
      top: 0px;
    }

    to {
      top: -12px;
    }
  }

  .section {
    float: left;
  }

  .icon {
    width: 31px;
    height: 31px;
    text-align: center;
    cursor: pointer;
    color: ${(props) => props.theme.accent}!important;
  }
  .searchbox {
    width: 245px;
  }

  .form-holder {
    background: ${(props) => props.theme.backgroundLight}!important;
  }

  .pagination-holder {
    padding: 3px;
    background: ${(props) => props.theme.accent};
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .pagination {
    display: table;

    width: 100%;

    .next {
      a {
        font-weight: 600;
        color: ${(props) => props.theme.backgroundLight}!important;
        &::after {
          content: '>';
        }
      }
    }

    .previous {
      a {
        font-weight: 600;
        color: ${(props) => props.theme.backgroundLight}!important;
        content: 'test';
        &::after {
          content: '<';
        }
      }
    }
    .active {
      background: ${(props) => props.theme.accent}!important;
      border: 0px solid transparent;
      a {
        border: 2px solid ${(props) => props.theme.backgroundLight};
        background: ${(props) => props.theme.backgroundDark};
        color: ${(props) => props.theme.darkGray};
        cursor: default !important;
      }
    }
    li {
      display: table-cell;
      align-content: center;
      text-align: center;
      background: ${(props) => props.theme.accent}!important;

      padding: 8px 0;
    }
    a {
      font-family: ${(props) => props.theme.mainFont};
      font-weight: 600;
      font-size: 11px;
      border: 3px solid transparent;
      border-radius: 20px;
      display: block;
      cursor: pointer;
      padding: 7px 0px;
      width: 30px;
      height: 30px;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
      text-decoration: none !important;
      color: ${(props) => props.theme.backgroundLight};
      outline: none;
    }
  }

  .search-results {
    position: absolute;
    right: 5px;
    z-index: 1099;
    top: 40px;

    .triangle {
      position: relative;
      width: 0;
      left: 100%;
      margin-left:-20px;
      border-bottom: solid 8px ${(props) => props.theme.textLight};
      border-right: solid 8px transparent;
      border-left: solid 8px transparent;
      z-index: 5;
    }
    .triangle .empty {
    
    }
  }

  /* Portrait and Landscape */
@media only screen 
  
  and (max-device-width: 1024px) 
   {
   .search-results-holder
  {
    width:400px!important;  
    margin-left:-200px!important;
  }

}


@media only screen 
  
  and (max-device-width: 500px) 
   {
   .search-results-holder
  {
    width:350px!important;  
    margin-left:-175px!important;
  }

}
  

  .search-results-holder {
    background: ${(props) => props.theme.textLight};
    width: 300px;
    border-radius: 3px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.02);
    z-index: 4;
    position: relative;
    .internal-search-results-holder {
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        background: ${(props) => props.theme.backgroundDarker};
        margin: 15px 10px 15px 10px;
        cursor: pointer;
        
        font-family: ${(props) => props.theme.mainFont};
        font-weight: 600;
        font-size: 13px;
        color: ${(props) => props.theme.textMedium};

        -webkit-border-top-left-radius: 3px;
        -webkit-border-top-right-radius: 3px;
        -moz-border-radius-topleft: 3px;
        -moz-border-radius-topright: 3px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        .list-other {
          background: ${(props) => props.theme.backgroundLight};
        }
        &:hover {
          color: ${(props) => props.theme.backgroundLight}!important;
          .result-holder-animator {
            top: 0;
            right: 0;
            -webkit-border-top-left-radius: 3px;
        -webkit-border-top-right-radius: 3px;
        -moz-border-radius-topleft: 3px;
        -moz-border-radius-topright: 3px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
            left: 0;
            position: absolute;
            width: 100%;
            background: ${(props) => props.theme.accent};
            height: 100%;
            z-index: 1;
          }

          .list-other {
            background: ${(props) => props.theme.backgroundDark};
            color: ${(props) => props.theme.textMedium};
            .other-item {
              font-weight: 600;
            }
          }
        }

        .result-holder {
          position: relative;
        }
        .result-holder-animator {
          transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          top: 0;
          right: 0;
          left: 0;
          position: absolute;
          width: 100%;
          background: ${(props) => props.theme.accent};
          height: 0%;
          z-index: 1;
        }

        .list-icon {
          display: table-cell;
          padding: 8px;
          position: relative;
          z-index: 2;
          vertical-align: middle;
        }
        .list-text {
          display: table-cell;
          padding: 8px;
          position: relative;
          z-index: 2;
          vertical-align: middle;
        }
        .list-other {
          -webkit-border-bottom-right-radius: 3px;
          -webkit-border-bottom-left-radius: 3px;
          -moz-border-radius-bottomright: 3px;
          -moz-border-radius-bottomleft: 3px;
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;
          color: ${(props) => props.theme.textMenu};

          .other-item {
            font-weight: 600;
            font-size: 7.8pt;
          }
        }
      }
    }
  }

  .other-functions {
    float: left;
    opacity: 0;
    overflow: hidden;
    width: 0;

    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    button {
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      transform: rotate(180deg);
      margin: -100 8px;
      outline: none;
    }
  }
  .active-close {
    width: auto;
    opacity: 1;
    /*   */
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    button {
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      transform: rotate(0deg);
      margin: 0 8px;
    }
  }
  .active {
    background: ${(props) => props.theme.backgroundLight};
    input {
      width: 200px;
      opacity: 1;
    }
    .search-icon {
      transition: all 0.3s ease;
    }
  }

  

  .card {
    padding: 0 !important;
    margin: 0 !important;
    border:0 solid transparent;
  }

  .card-holder {
  
    background:${(props) => props.theme.backgroundLight};
  }
  .form-holder {
    padding-top: 0 !important;
     
  }
`;

export default SearchComponentStyle;
