export default [
  'data-parsley-differentcityfromandcityto',
  'data-parsley-maxdropoffdate',
  'data-parsley-flatratequantity',
  'data-parsley-purchaseorderrequired',
  'data-parsley-nostationcharge',
  'data-parsley-validflighttime',
  'data-parsley-verifyrecommendedadjustment',
  'data-parsley-confirmpasscode',
  'data-parsley-differentpackagequantities',  
  'data-parsley-chargebillingagentrequired',
  'data-parsley-validcargopaymentamount',
  'data-parsley-validemployeecargoadjustmentamount',
  'data-parsley-singleadjustmentcriteria',
  'data-parsley-validairwaybilladjustmentamount',
  'data-parsley-employeediscountrequired'
];
