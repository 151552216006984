export default {
  "duplicates": [],
  "access": [
    "admin"
  ],
  "track": "SystemRole",
  "formtype": "dataEntry",
  "caption": "SystemRole",
  "editSearchSelector": {
    "resource": "catalogs/systemrole/getsystemuserlist",
    "resourceId": "SystemRole",
    "resourceValue": "SystemRole",
    "resourceSelectedType": "GET",
    "resourceSelected": "catalogs/SystemRole/getsystemuser",
    "resourceDisplayFields": [
      "Description"
    ]
  },
  "model": {
    "modelid": "catalogs/SystemRole/getsystemuser",
    "autoload": false
  },
  "functionactions": {
    "createnew": {
      "functionname": "catalogs/SystemRole/createsystemuser",
      "functiontype": "POST",
      "validateData": true,
      "validationFailedTitle": "",
      "validationFailedText": "",
      "alertOnComplete": true,
      "alertTitle": "",
      "alertText": ""
    },
    "update": {
      "functionname": "catalogs/SystemRole/updatesystemuser",
      "functiontype": "POST",
      "validateData": true,
      "validationFailedTitle": "",
      "validationFailedText": "",
      "alertOnComplete": true,
      "alertTitle": "",
      "alertText": ""
    },
    "after": {
      "functionname": "catalogs/SystemRole/getsystemuserlist",
      "functiontype": "POST"
    }
  },
  "formDefinition": {
    "rows": [
      {
        "columns": [
          {
            "title": "General Information",
            "fields": [
              {
                "key": "OldSystemRole",
                "type": "hidden",
                "label": "UserName",
                "placeholder": "UserName",
                "required": "true",
                "isPrimary": "false"
              },
              {
                "key": "SystemRole",
                "type": "text",
                "label": "User Name",
                "placeholder": "User Name",
                "required": "true",
                "validation": {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 3,
                  "data-parsley-maxlength": 100
                },
                "isPrimary": "false"
              },
              {
                "key": "Description",
                "type": "text",
                "label": "Full Name",
                "placeholder": "Full Name",
                "required": "true",
                "validation": {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 3,
                  "data-parsley-maxlength": 100
                }
              }
            ]
          }
        ]
      },
      {
        "columns": [
          {
            "title": "Permissions",
            "hideOnAdd": "true",
            "fields": [
              {
                "type": "editabletable",
                "datasource": "catalogs/systemrolepermission/getrolepermissionlist",
                "key": "SystemRole",
                "keylink": "SystemRole",
                "data": {
                  "getResource": {
                    "resourceType": "POST",
                    "id": "catalogs/systemrolepermission/getrolepermissionlist"
                  },
                  "createResource": {
                    "resourceType": "POST",
                    "id": "catalogs/systemrolepermission/createrolepermission"
                  },
                  "updateResource": {
                    "resourceType": "POST",
                    "id": "catalogs/systemrolepermission/updaterolepermission"
                  },
                  "deleteResource": {
                    "resourceType": "POST",
                    "id": "catalogs/systemrolepermission/deleterolepermission"
                  }
                },
                "fields": [
                  {
                    "key": "Permission",
                    "type": "select",
                    "label": "Permission",
                    "placeholder": "Permission",
                    "optionsResource": {
                      "resource": "catalogs/systemrolepermission/getpermissions",
                      "resourceId": "Permission",
                      "resourceValue": "Description"
                    },
                    "validation": {
                      "data-parsley-required": true,
                      "data-parsley-minlength": 1
                    },
                    "larger": "false"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}