export default {
  duplicates: [],
  access: [],
  track: "CargoManifest",
  formtype: "dataEntry",
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                key: "ManifestNumber",
                type: "text",
                label: "Manifest Number",
                placeholder: "Manifest Number",
                readOnly: false
              },
              {
                  key: "CityFrom",
                  type: "select",
                  label: "City From",
                  placeholder: "City From",
                  readOnly: false,
                  optionsResource: {
                    resource: "GetCargoStationList",
                    resourceType: "GET",
                    resourceId: ["CargoStation"],
                    resourceIdentity: "CargoStations",
                    resourceValue: "CargoStationName"
                  }
                },
                {
                  key: "CityTo",
                  type: "select",
                  label: "City To",
                  placeholder: "City To",
                  readOnly: false,
                  optionsResource: {
                    resource: "GetCargoStationList",
                    resourceType: "GET",
                    resourceId: ["CargoStation"],
                    resourceIdentity: "CargoStations",
                    resourceValue: "CargoStationName"
                  }
                },             
                {
                  key: "PackageType",
                  type: "select",
                  defaultValue: "",
                  label: "Package Type",
                  placeholder: "Package Type",
                  readOnly: false,
                  optionsResource: {
                    resource: "GetCargoPackageTypeList",
                    resourceType: "GET",
                    resourceId: ["PackageType"],
                    resourceIdentity: "CargoPackageTypes",
                    resourceValue: "PackageTypeDescription"
                  }
                },            
                {
                  key: "ReferenceNumber",
                  type: "number",
                  defaultValue: "",
                  label: "Reference Number",
                  placeholder: "Reference Number",
                  readOnly: false
                }
            ]
          },
          {
            title: '\n',
            fields: [
              {
                key: "FlightDate",
                type: "date",
                defaultValue: "",
                label: "Flight Date",
                placeholder: "Flight Date",
                readOnly: false
              },
              {
                key: "FlightNumber",
                type: "text",
                defaultValue: "",
                label: "Flight Number",
                placeholder: "Flight Number",
                readOnly: false
              },
              {
                key: "AircraftId",
                type: "text",
                defaultValue: "",
                label: "Aircraft",
                placeholder: "Aircraft",
                readOnly: false
              },
              {
                key: "PreparedBy",
                type: "text",
                label: "Prepared By",
                placeholder: "Prepared By",
                readOnly: false
              },             
              {
                key: "ManifestStatus",
                type: "select",
                defaultValue: "",
                label: "Manifest Status",
                placeholder: "Manifest Status",
                readOnly: false,
                optionsResource: {
                  resource: "GetCargoManifestStatusList",
                  resourceType: "GET",
                  resourceId: ["ManifestStatus"],
                  resourceIdentity: "ManifestStatus",
                  resourceValue: "ManifestStatus"
                }
              }  
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "",
            hideOnAdd: "true",
            fields: [
              {
                type: "searchtable",
                id:"CargoManifest",
                data: {
                  getResource: {
                    id: "GetCargoManifestListForSearch",
                    resourceType: "POST",
                    resourceIdentity: "CargoManifests"
                  }
                },
                fields: [
                  {
                    key: "ManifestNumber",
                    type: "text",
                    label: "Manifest No."
                  },
                  {
                    key: "FlightDate",
                    type: "text",
                    label: "Date"
                  },
                  {
                    key: "CityFrom",
                    type: "text",
                    label: "From"
                  },
                  {
                    key: "CityTo",
                    type: "text",
                    label: "To"
                  },
                  {
                    key: "PackageType",
                    type: "text",
                    label: "Type"
                  },
                  {
                    key: "FlightNumber",
                    type: "text",
                    label: "Flight"
                  },
                  {
                    key: "AircraftId",
                    type: "text",
                    label: "Aircraft"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
