
import CargoInvoiceBatchSearch from "./cargoinvoicebatchsearch";
import CargoInvoiceList from "../cargoinvoice/cargoinvoicelist";
import Colors from "../../../constants/Colors";
export default {
  track: "CargoInvoice",
  formtype: "dataEntry",
  customSearch: {
    searchForm: { ...CargoInvoiceBatchSearch },
    width: 800,
    align: 500,
    height: 800,
    id: "cargoInvoiceBatchSearch"
  },
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {          
      role: ["Cargo Administrator", "Accounting Administrator", "Accounting Member"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Invoice",
  editSearchSelector: {
    resource: "GetCargoInvoiceBatchList",
    resourceType: "GET",
    resourceId: ["CargoInvoiceBatches"],
    resourceIdentity: "CargoInvoiceBatchs",
    resourceValue: "InvoiceNumber",
    resourceSelected: "GetCargoInvoiceBatch",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoInvoiceBatch"},
  functionactions: {
    createnew: {
      functionname: "AddCargoInvoiceBatch",
      functiontype: "POST",
      resourceIdentity: {
        id: "CargoInvoiceBatch",
        mapping: [
          {
            id: "CargoInvoiceBatchNumber",
            key: "CargoInvoiceBatchNumber"
          },
          {
            id: "CreatedBy",
            key: "CreatedBy"
          },
          {
            id: "CreatedDate",
            key: "CreatedDate"
          }
        ]
      },
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Invoice Batch Created",
      alertText: "Cargo Invoice Batch Created"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Agency",
            fields: [ 
              {
                key: "AgencyId",
                type: "select",
                label: "Agency",
                placeholder: "Agency",
                readOnly: false,
                optionsResource: {
                  resource: "GetAgencyList",
                  resourceType: "GET",
                  resourceId: ["AgencyId"],
                  resourceIdentity: "Agencies",
                  resourceValue: "AgencyName"
                }
              },
              {
                key: "TypeCode",
                type: "select",
                defaultValue: "C",
                label: "Business Type",
                placeholder: "Business Type",
                readOnly: false, 
                optionsResource: {
                  resource: "GetNonStationAccountTypeList",
                  resourceType: "GET",
                  resourceId: ["TypeCode"],
                  resourceIdentity: "AccountTypes",
                  resourceValue: "Description"
                },
                validation: {
                  "data-parsley-maxlength": 4
                }
              },
              {
                key: "AllAgencies",
                type: "checkbox",
                defaultValue: "0",
                label: "All Agencies",
                placeholder: "All Agencies",
                readOnly: false
              }
            ]
          },
          {
            title: "\n",
            fields: [  
              {
                key: "CargoInvoiceFrequencyType",
                type: "select",
                defaultValue: "On-Demand",
                label: "Invoice Frequency",
                placeholder: "Invoice Frequency",
                readOnly: false, 
                optionsResource: {
                  resource: "GetCargoInvoiceFrequencyTypeList",
                  resourceType: "GET",
                  resourceId: ["CargoInvoiceFrequencyType"],
                  resourceIdentity: "CargoInvoiceFrequencyTypes",
                  resourceValue: "CargoInvoiceFrequencyType"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 20
                }
              }
            ]
          },
          {
            title: "Invoice Period",
            fields: [  
              {
                key: "EndingInvoicePeriod",
                type: "date",
                label: "Ending Date",
                placeholder: "Ending Date",
                readOnly: false,
                validation: {
                  "data-parsley-required": true
                }
              },
              {
                key: "PaymentDueDate",
                type: "date",
                label: "Due Date",
                placeholder: "Payment Due Date",
                readOnly: false,
                validation: {
                  "data-parsley-required": true
                }
              }
            ]
          },
          {
            title: "Batch Particulars",
            fields: [
              {
                key: "CargoInvoiceBatchNumber",
                type: "text",
                label: "Batch Number",
                placeholder: "Batch Number",
                readOnly: true
              },
              {
                key: "CreatedBy",
                type: "text",
                label: "Created By",
                placeholder: "Created By",
                readOnly: true
              },
              {
                key: "CreatedDate",
                type: "date",
                label: "Created Date",
                placeholder: "Created Date",
                readOnly: true
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "tabs",
                readOnlyOnEdit: true,
                tabs: [
                  {
                    caption: "Invoices",
                    definition: { ...CargoInvoiceList }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
