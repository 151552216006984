export default {
  duplicates: [],
  access: [],
  track: "",
  formtype: "dataEntry",

  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                key: "MajorCategory",
                type: "select",
                defaultvalue: "",
                label: "Major Category",
                placeholder: "Major Category",
                optionsResource: {
                  resource: "GetUserDocumentationMajorCategoryList",
                  resourceType: "GET",
                  resourceId: ["MajorCategory"],
                  resourceIdentity: "MajorCategories",
                  resourceValue: "MajorCategory"
                }
              },
              {
                key: "MinorCategory",
                type: "select",
                defaultvalue: "",
                label: "Minor Category",
                placeholder: "Minor Category",
                optionsResource: {
                  resource: "GetUserDocumentationMinorCategoryListByMajorCategory",
                  resourceType: "POST",
                  resourceId: ["MinorCategory"],
                  resourceIdentity: "MinorCategories",
                  resourceValue: "MinorCategory"
                }
              },
              {
                key: "DocumentationTopic",
                type: "text",
                defaultvalue: "",
                label: "Topic",
                placeholder: "Topic"
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "",
            hideOnAdd: "true",
            fields: [
              {
                type: "searchtable",
                id:"userdocumentationlist",
                data: {
                  getResource: {
                    id: "GetUserDocumentationList",
                    resourceType: "POST",
                    resourceIdentity: "UserDocumentations"
                  }
                },
                fields: [
                  {
                    key: "MajorCategory",
                    type: "text",
                    label: "Major Category"
                  },
                  {
                    key: "MinorCategory",
                    type: "text",
                    label: "MinorCategory"
                  },
                  {
                    key: "DocumentationTopic",
                    type: "text",
                    label: "Topic"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
