import { toJS } from "mobx";

const computeLocked = (field, parentData, data, locked, adding) => {
  const entryDetails = toJS(data);
  const parent = toJS(parentData);
  if (locked === true) {
    return true;
  }
  
  if (field.locked === true || field.Locked === true) {
    return true;
  } 
  
  if (field.parentlockedDependentException !== undefined) {
    var rtp = false;

    field.parentlockedDependentException.forEach(item => {
      if (parent[item.id]) {
        if (
          parent[item.id]
            .toString()
            .toLowerCase()
            .trim() ===
          item.value
            .toString()
            .toLowerCase()
            .trim()
        ) {
          rtp = true;
        }
      }
    });

    return rtp;
  }
  
  
  if (field.lockedDependent) {
    
    let retval = true;
    field.lockedDependent.forEach(item => {      
      if (entryDetails[item.id] && item.value &&       
        entryDetails[item.id]
          .toString()
          .toLowerCase()
          .trim() ===
        item.value
          .toString()
          .toLowerCase()
          .trim()
      ) {        
        retval = true;
      } else {
        retval = false;        
      }
    });
    
    return retval;
  }
  
  return false;
};
export default computeLocked;
