export default {
  track: "Airwaybill",
  formtype: "dataEntry",
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                key: "AirwaybillNumber",
                type: "text",
                defaultvalue: "",
                label: "AWB Number",
                placeholder: "AWB Number"
              },
              {
                key: "Sender",
                type: "text",
                defaultvalue: "",
                label: "Sender",
                placeholder: "Sender"
              },
              {
                key: "CityFrom",
                type: "select",
                defaultvalue: "",
                label: "From",
                placeholder: "From",
                optionsResource: {
                  resource: "GetCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                }
              },
              {
                key: "ServiceType",
                type: "select",
                defaultvalue: "",
                label: "Service Type",
                placeholder: "Service Type",
                optionsResource: {
                  resource: "GetCargoServiceTypeList",
                  resourceType: "GET",
                  resourceId: ["CargoServiceType"],
                  resourceIdentity: "CargoServiceTypes",
                  resourceValue: "CargoServiceType"
                },
              }
            ]
          },
          {
            title: '\n',
            fields: [
              {
                key: "DropoffDate",
                type: "date",
                defaultvalue: "",
                label: "Drop Off Date",
                placeholder: "Drop Off Date"
              },
              {
                key: "Recipient",
                type: "text",
                defaultvalue: "",
                label: "Recipient",
                placeholder: "Recipient"
              },
              {
                key: "CityTo",
                type: "select",
                defaultvalue: "",
                label: "To",
                placeholder: "To",
                optionsResource: {
                  resource: "GetCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                }
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "",
            hideOnAdd: "true",
            fields: [
              {
                type: "searchtable",
                id:"airwaybill",
                data: {
                  getResource: {
                    id: "GetAirwaybillListForSearch",
                    resourceType: "POST",
                    resourceIdentity: "Airwaybills"
                  }
                },
                fields: [
                  {
                    key: "AirwaybillNumber",
                    type: "text",
                    label: "AWB No."
                  },
                  {
                    key: "DropoffDate",
                    type: "text",
                    label: "Date"
                  },
                  {
                    key: "Route",
                    type: "text",
                    label: "Route"
                  },
                  {
                    key: "Sender",
                    type: "text",
                    label: "Sender"
                  },
                  {
                    key: "Recipient",
                    type: "text",
                    label: "Recipient"
                  },
                  {
                    key: "AirwaybillStatus",
                    type: "text",
                    label: "Status"
                  },
                  {
                    key: "ShippingCost",
                    type: "number",
                    label: "Cost",
                    format: "C2"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
