export default {
	duplicates: [],
	access: [],
	track: "AirwaybillPackage",
	formtype: "dataEntry",

	allowSearch: [
		{
			role:  [ "default" ],
			value:  true
		}
	],
	allowDelete: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
			role:  [ "" ],
			value:  false
		}
	],
	allowAudit: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
			role:  [ "" ],
			value:  false
		}
	],
	allowEdit: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
			role:  [ "" ],
			value:  false
		}
	],
	allowAdd: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
			role:  [ "" ],
			value:  false
		}
	],
	allowPrint: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	printreport: "",
	caption:  "Airway Bill Package Details",
	editSearchSelector: {
		resource: "GetAirwaybillPackageList",
		resourceType: "GET",
		resourceId: [ "AirwaybillPackageRecordNumber" ],
		resourceValue: "AirwaybillPackageRecordNumber",
		resourceSelected: "GetAirwaybillPackage",
		resourceSelectedType: "POST",
		resourceDisplayFields: [ ]
	},
	functionactions:
	{
		createnew: {
			functionname: "AddAirwaybillPackage",
			functiontype: "POST",
			validateData: true,
			alertOnComplete: true,
			alertTitle: "Airway Bill Package Information Added",
			alertText: "The Airway Bill Package Has Been Added"
		},
		update: {
			functionname: "UpdateAirwaybillPackageDescription",
			functiontype: "POST",
			validateData: true,
			alertOnComplete: true,
			alertTitle: "Airway Bill Package Information Updated",
			alertText: "The Airway Bill Package Has Been Updated"
		},
		after: {
			functionname: "GetAirwaybillPackageList",
			functiontype: "GET"
		}
	},
	formDefinition: {
		rows: [
			{
			columns: [
				{
				title: "Airway Bill Package Information",
				fields: [
					{
						key: "AirwaybillNumber",
						type: "number",
						label: "AWB Number",
						placeholder: "AWB Number",
						readOnly: true
					},
					{
						key: "PackageNumber",
						type: "number",
						label: "Package Number",
						placeholder: "Package Number",
						readOnly:true
					},
					{
						key: "AirwaybillPackageNumber",
						type: "text",
						label: "Airwaybill Package Number",
						placeholder: "Airwaybill Package Number",
						readOnly: true
					},
					{
						key: "PackageType",
						type: "text",
						label: "Package Type",
						placeholder: "Package Type",
						readOnly: true
					}
				]
			  },
			  {
				title: "\n",
				fields: [
					{
						key: "PackageDescription",
						type: "text",
						label: "Package Description",
						placeholder: "Package Description",
						readOnly: false, 
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2,
							"data-parsley-maxlength": 255
						}
					},
					{
						key: "PackageWeight",
						type: "text",
						label: "Package Weight",
						placeholder: "Package Weight",
						readOnly: true
					},
					{
						key: "PackageVolume",
						type: "text",
						label: "Package Volume",
						placeholder: "Package Volume",
						readOnly: true
					}
				]
			  },
			  {
				title: "\n",
				fields: [
					{
						key: "PickupDate",
						type: "date",
						label: "Pickup Date",
						placeholder: "Pickup Date",
						readOnly: true
					},
					{
						key: "PickupTime",
						type: "text",
						label: "Pickup Time",
						placeholder: "Pickup Time",
						readOnly: true
					},
					{
						key: "PickedUpBy",
						type: "text",
						label: "Picked Up By",
						placeholder: "Picked Up By",
						readOnly: true
					},
					{
						key: "PickupIDType",
						type: "text",
						label: "Pickup ID Type",
						placeholder: "Pickup I D Type",
						readOnly: true
					},
					{
						key: "PickupIDNumber",
						type: "text",
						label: "Pickup ID Number",
						placeholder: "Pickup I D Number",
						readOnly: true
					}
				]
			  },
			  {
				title: "\n",
				fields: [
					{
						key: "PackageStatus",
						type: "text",
						defaultValue: "",
						label: "Package Status",
						placeholder: "Package Status",
						readOnly: true
					},
					{
						key: "PackageStatusDate",
						type: "date",
						label: "Package Status Date",
						placeholder: "Package Status Date",
						readOnly: true
					},
					{
						key: "DeliveredBy",
						type: "text",
						label: "Delivered By",
						placeholder: "Delivered By",
						readOnly: true
					},
					{
						key: "DeliveryCondition",
						type: "text",
						label: "Delivery Condition",
						placeholder: "Delivery Condition",
						readOnly: true
					},
					{
						key: "RecommendedAdjustment",
						type: "number",
						format: "$0,0.00",
						label: "Recommended Adjustment",
						placeholder: "Recommended Adjustment",
						readOnly: true
					}
				]
				}
			]
			}
		]
	}
}