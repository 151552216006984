export default {
	allowSearch: [
		{
			role:  [ "default" ],
			value:  true
		}
	],
	allowDelete: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowAudit: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowEdit: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowAdd: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowPrint: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	printreport: "",
	caption:  "Cargo Invoice Adjustment Log Details",
	editSearchSelector: {
		resource: "GetCargoPaymentLogList",
		resourceType: "GET",
		resourceId: [ "CargoPaymentpackageLogRecordNumber" ],
		resourceValue: "CargoPaymentpackageLogRecordNumber",
		resourceSelected: "GetCargoPaymentLog",
		resourceSelectedType: "POST",
		resourceDisplayFields: [ ]
	},
	functionactions:
	{
		createnew: {
		},
		update: {
		},
		after: {
		}
	},
	formDefinition: {
		rows: [
			{
			columns: [
				{
				title: "Cargo Invoice Adjustment Log Information",
				fields: [
					{
						key: "AdjustmentNumber",
						type: "label",
						defaultValue: "",
						label: "Adjustment Number",
						placeholder: "Adjustment Number",
						readOnly: true
					},
					{
						key: "ActionTaken",
						type: "text",
						defaultValue: "",
						label: "Action Taken",
						placeholder: "Action Taken",
						readOnly: true
					}
				]
			  },
			  {
				title: '\n',
				fields: [
					{
						key: "UserName",
						type: "text",
						defaultValue: "",
						label: "User Name",
						placeholder: "User Name",
						readOnly: true
					},
					{
						key: "ActionDate",
						type: "text",
						defaultValue: "",
						label: "Action Date",
						placeholder: "Action Date",
						readOnly: true
					}
				]
			  },
			  {
				title: '\n',
				fields: [
					{
						type: "spacer"
					}
				]
				}
			]
			}
		]
	}
}