import chroma from 'chroma-js';
import styled from '@emotion/styled';

const LoginStyle = styled.div`
   /* Old browsers */

  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
        


  
  background: -moz-linear-gradient(
    -45deg,
    ${(props) =>
      chroma(props.theme.accent).darken(2).css()} 0%,
      ${(props) =>
        props.theme.accent} 48%,
        ${(props) =>
          props.theme.accent} 56%,
          ${(props) =>
            chroma(props.theme.accent).saturate(1).css()} 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    ${(props) =>
      chroma(props.theme.accent).darken(2).css()} 0%,
      ${(props) =>
        props.theme.accent} 48%,
        ${(props) =>
          props.theme.accent} 56%,
          ${(props) =>
            props.theme.accent} 56%,
            ${(props) =>
              chroma(props.theme.accent).saturate(1).css()} 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    ${(props) =>
      chroma(props.theme.accent).darken(2).css()} 0%,
      ${(props) =>
        props.theme.accent} 48%,
        ${(props) =>
          props.theme.accent} 56%,
          ${(props) =>
            props.theme.accent} 56%,
            ${(props) =>
              chroma(props.theme.accent).saturate(1).css()} 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=${(props) =>
    chroma(props.theme.accent).darken(2).css()}, endColorstr=${(props) =>
  props.theme
    .accentDark2},GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }

input:-webkit-autofill {
  -webkit-box-shadow:none !important;
}

.form-control {
  box-shadow:none!important;
background-color:${(props) => props.theme.backgroundLight}!important;
    padding: 1.2rem 0.75rem;    
    border: 1px solid ${(props) => props.theme.backgroundDarker}!important;
    border-radius:3px;
    
}

    
.select2-container--bootstrap4 .select2-selection__clear {
  width: 1.2em;
  height: 1.2em;
  line-height: 1.15em;
  padding-left: 0.3em;
  margin-top: 0.3em;
  border-radius: 100%;
  background-color: ${props => props.theme.darkGray};
  color: #f8f9fa;
  float: right;
  border-bottom: 1px solid ${props => props.theme.backgroundDarker};
  margin-right: 0.3em;
}

.toggleOn{
cursor:pointer;
color:${props => props.theme.accent}!important;
i{
  font-size:24px;
}
}

.toggleBtn{
background-color:transparent;
border:0;
outline:0;


}

.toggleOff{
color:${props => props.theme.textMedium}!important;
i{
  font-size:24px;
}
cursor:pointer;
}

.select2-container--disabled
{
  background:${props => props.theme.backgroundDark}!important;
}
.select2-container--bootstrap4
.select2-selection--single
.select2-selection__rendered {
  line-height: 2rem;
  font-size: 14px;
}

.select2-container--bootstrap4
.select2-selection--single
.select2-selection__arrow {
  
  border-left: 1px solid ${props => props.theme.backgroundDarker};
  border-top: 1px solid ${props => props.theme.backgroundDarker};
  height: 42px !important;
  border-right: 1px solid ${props => props.theme.backgroundDarker};
}

.select2-container--bootstrap4 .select2-selection--single {
  height: 42px !important;
}

.select2-container--bootstrap4.select2-container--focus .select2-selection {
  border-color: ${props => props.theme.backgroundDarker};
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: none;    
}
.select2-container--bootstrap4 .select2-selection {
  border: 1px solid ${props => props.theme.backgroundDarker};
  border-radius: 3px;
  width: 100%;
  
}
.select2-container--bootstrap4
{
  background:${props => props.theme.backgroundLight};
}


.select2-results__option--highlighted {
    
  background-color: ${(props) => props.theme.accent}!important;  
}
  .loginbutton {
      margin-top:40px;
    color: ${(props) => props.theme.textLight}!important;
    font-family: ${(props) => props.theme.headerFont};
    text-transform:uppercase;
    background-color: ${(props) => props.theme.largeButtonColor} !important;
    border: 1px solid ${(props) => props.theme.largeButtonColor};
    border-radius: 30px;
    padding: 10px 0;
    width: 100%;
    font-weight: 400;
    box-shadow: 0 10px 20px rgba(120, 0, 50, 0.09), 0 6px 6px rgba(120, 0, 50, 0.13);
   
    i{
        margin-left:20px;
    }
}

  .login-holder {
    font-family: ${(props) => props.theme.mainFont};
    background: ${(props) => props.theme.backgroundLight};
    position: absolute;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);
    border-radius:20px;
    width: 400px;
   
    padding: 10pt;
   
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.2), 0 15px 12px rgba(0, 0, 0, 0.01);

    .arrow-holder {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
    }
    .title{
        
        width:100%;
        background: ${(props) => props.theme.backgroundDark};        
        text-transform:uppercase;
        border:0px solid transparent;
        border-bottom:2px solid ${(props) => props.theme.backgroundDarker};
        .content{
            padding:8px 8px;
            font-weight:700;
            color:${(props) => props.theme.textMedium};
            font-family:${(props) => props.theme.headerFont};
        }
    }

    .parsley-errors-list {
    list-style: none;
    margin: 5px 0 0 0;
    padding: 0;
    font-size: 8px;
    font-weight: 600;
    color: #ad0400;
    text-transform: uppercase;
    li {
      margin:0!important;
      color: #ad0400!important;
      padding: 0!important;
      background: #fff!important;
      font-size: 8px!important;
      font-weight: 600!important;
      text-transform: uppercase!important;
    }
  }


    .holder-right {
      .content {
        padding: 10px 15px;
        font-weight:600;
        font-size:9pt;
        text-align:justify;
        .instructions{
            display:block;
            margin-bottom:20px;
        }
        
      }
    }
    .logo {
        margin-top:30px;
        margin-bottom:20px;
      text-align: center;
      img {
        width: 200px;
      }
    }
    
    .holder-left {
      width: 300px;
      bottom:0;
      
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      
      top:50px;
      left: 0;
    }
    .arrow-down {
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      left: 50%;
      position: absolute;

      border-top: 20px solid ${(props) => props.theme.backgroundLight};
    }
  
`;

export default LoginStyle;
