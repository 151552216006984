import $ from "jquery";
import ReactDOM from "react-dom";
import { addAnimateCSS } from "../../../../utilities";

const loadout = function() {
  addAnimateCSS($(this.refs.before), "animate__faster animate__slideOutUp", () => {});
  addAnimateCSS($(this.refs.after), "animate__faster animate__slideOutDown", () => {
    $(this.props.LoaderHolder).addClass("hidden");
    ReactDOM.unmountComponentAtNode(this.props.LoaderHolder);
    this.props.OnComplete(true);
  });
};

export default loadout;
