import { observable, toJS } from "mobx";

import { loadData } from "../../../../utilities";
import onLaunchDialog from "./onLaunchDialog";

const CalcStateChanged = (props) => {};
const onView = (actionDefinition, props, innerOnStateChanged) => {
  return new Promise((resolve, reject) => {
    if (props.willLaunchDialog) {
      if (props.Config && props.Config.entry.resource) {
        const resource = props.Config.entry.resource;
        loadData(
          resource.id,
          resource.resourceType,
          toJS(props.EntryDetails),
          (response) => {
            try {
              let entry = observable({
                ...response[resource.resourceIdentity],
              });
              if (innerOnStateChanged) {
                if (innerOnStateChanged) {
                  innerOnStateChanged("View");
                }
                onLaunchDialog(
                  props,
                  entry,
                  false,
                  false,
                  null,
                  props.Locked,
                  () => {}
                );
                resolve();
              } else {
                onLaunchDialog(
                  props,
                  entry,
                  false,
                  false,
                  null,
                  props.Locked,
                  () => {}
                );
                resolve();
              }
            } catch (ex) {
              console.error(ex);
              reject();
            }
          },
          () => {
            resolve();
          }
        );
      } else {
        reject();
      }
    } else {
      if (innerOnStateChanged) {
        if (innerOnStateChanged) {
          innerOnStateChanged("View");
        }
        resolve();
      } else {
        resolve();
      }
    }
  });
};

export default onView;
