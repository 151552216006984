import { observable } from 'mobx';
import loadDataFromApi from '../utilities/API/index';

class ThemeStore {
  constructor() {    
    this.theme = observable({theme:{},themeLoaded:false});
  }
  loadTheme()
  {
      loadDataFromApi("theme","GET",{},(response)=>{
          this.theme.theme=response;
          this.theme.themeLoaded=true;
      });
  }
}

const themeStore = new ThemeStore();

export default themeStore;