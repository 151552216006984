



const addAnimation = (node, animationName, callback) => {
  node.addClass(`animate__animated ${animationName}`);
  let timedOut = false;
  
  const handleAnimationEnd = () => {
    timedOut = true;
    node.removeClass(`animate__animated ${animationName}`);
    node[0].removeEventListener("animationend", handleAnimationEnd);
    if (typeof callback === "function") callback();
  };

  node[0].addEventListener("animationend", handleAnimationEnd);
  setTimeout(() => {
    if (!timedOut) {
      handleAnimationEnd();
    }
  }, 600);
};

export default addAnimation;
