export default {
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["User Administration Administrator"],
      value: true
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    },
    {
      role: ["User Administration Administrator"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: true
    },
    {
      role: ["User Administration Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: true
    },
    {
      role: ["User Administration Administrator"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  caption: "systemuser",
  editSearchSelector: {
    resource: "getsystemuserslist",
    resourceId: ["UserName"],
    resourceIdentity: "SystemUserss",
    resourceValue: "UserName",
    resourceType: "GET",
    resourceSelectedType: "POST",
    resourceSelected: "getsystemusers",
    resourceSelectedIdentity: "SystemUsers",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "addsystemusersecurityrole",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Security Role Information Added",
      alertText: "The Security Role Has Been Added"
    }    
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                key: "SecurityRole",
                type: "select",
                label: "Security Role",
                placeholder: "Security Role",
                larger: false,
                isPrimary: false,
                details:["SecurityRoleDescription"],
                optionsResource: {
                  resource: "getsecurityrolelist",
                  resourceId: ["SecurityRole"],
                  resourceValue: "SecurityRole",
                  resourceType: "GET",
                  resourceIdentity: "SecurityRoles"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 255
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
              }
            ]
          }
        ]
      }
    ]
  }
};
