import hasProperty from "./hasProperty";
const pageMax = 5;

const processResults = (Config, data, params) => {
  const pg = params.page ? params.page : 1;
  const response = data.data;
  const results = [];

  let cache = [];

  try {
    if (params.term && params.term.length > 0) {
      response.forEach(item => {
        if (
          hasProperty(item,Config, params.term.toLowerCase()) ||
          (item[Config.optionsResource.resourceValue]
            ? item[Config.optionsResource.resourceValue]
                .toString()
                .toLowerCase()
            : ""
          ).indexOf(params.term.toLowerCase()) >= 0
        ) {
          cache.push(item);
        }
      });
    }
    else
    {
      cache = response;
    }
  } catch (ex) {
  
  }
  

  for (let i = (pg - 1) * pageMax; i < pg * pageMax && i < cache.length; i++) {
    const item = cache[i];

    results.push({
      id: item[Config.optionsResource.resourceId[0]],
      text: item[Config.optionsResource.resourceValue],
      other: item
    });
  }
  // }

  return {
    results,
    pagination: {
      more: pg * pageMax <= cache.length
    }
  };
};

export default processResults;
