
import $ from 'jquery';
import { loadData } from '../../../../utilities';
import { toJS } from 'mobx';
import Notifications from '../../../Core/Notification';
import onConfirmation from './onConfirmation';

const executeApi = (button, props) => {
  return new Promise((resolve, reject) => {
    let validator = $(`#${props.FormId}`).parsley();
    if (validator && !validator.validate()) {
      Notifications.warning(
        'There was an issue submitting this form. Please ensure all required fields have been filled out.'
      );
      reject();
      return;
    }
    if (button.confirmation) {
      onConfirmation(
        button,
        props,
        () => {
          loadData(
            button.function.functionName,
            button.function.functionType,
            toJS(props.EntryDetails),
            () => {
              if (button.after) {
                loadData(
                  button.after.functionName,
                  button.after.functionType,
                  toJS(props.EntryDetails),
                  () => {
                    if (props.refreshAfterSave) {
                      props.refreshAfterSave();
                    }

                    resolve();
                    if (button.function.alertOnComplete) {
                      Notifications.success(button.function.alertText);
                    }
                  },
                  () => {
                    loadData(
                      button.after.functionName,
                      button.after.functionType,
                      toJS(props.EntryDetails),
                      () => {
                        if (props.refreshAfterSave) {
                          props.refreshAfterSave();
                        }
                        resolve();
                        if (button.function.alertOnComplete) {
                          Notifications.success(button.function.alertText);
                        }
                      },
                      () => {
                        reject(false);
                      }
                    );
                  }
                );
              } else {
                if (props.refreshAfterSave) {
                  props.refreshAfterSave();
                }
                resolve();
                if (button.function.alertOnComplete) {
                  Notifications.success(button.function.alertText);
                }
              }
            },
            () => {
              reject(false);
            }
          );
        },
        () => {
          reject(false);
        }
      );
    } else {
      loadData(
        button.function.functionName,
        button.function.functionType,
        toJS(props.EntryDetails),
        () => {
          if (button.after) {
            loadData(
              button.after.functionName,
              button.after.functionType,
              toJS(props.EntryDetails),
              () => {
                if (props.refreshAfterSave) {
                  props.refreshAfterSave();
                  Notifications.success(button.function.alertText);
                  resolve(true);
                } else {
                  Notifications.success(button.function.alertText);
                  resolve(true);
                }
              },
              () => {
                reject(false);
              }
            );
          } else {
            if (props.refreshAfterSave) {
              props.refreshAfterSave();
              Notifications.success(button.function.alertText);
              resolve(true);
            } else {
              Notifications.success(button.function.alertText);
              resolve(true);
            }
          }
        },
        () => {
          reject(false);
        }
      );
    }
  });
};

export default executeApi;
