import "parsleyjs";

import {
  Aggregate,
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  DetailRow,
  Edit,
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  PdfExport,
  SelectionSettingsModel,
  Sort,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider, withTheme } from "@emotion/react";
import { getMeta, guid, loadData, user } from "../../../utilities";
import { observable, observe, toJS } from "mobx";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import $ from "jquery";
import ActionBar from "../ActionBar";
import BindModel from "../BindModel";
import { CacheProvider } from "@emotion/react";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import GridStyle from "./GridStyle";
import Notifications from "../Notification";
import { Observer } from "mobx-react";
import ReactDOM from "react-dom";
import Select2Component from "../../FormRenderer/Components/Select2Component";
import computeValidation from "../../FormRenderer/actions/computeValidation";
import createCache from "@emotion/cache";
import moment from "moment";
import themeStore from "../../../stores/themeStore";
import computeReadOnly from "../../FormRenderer/actions/computeReadOnly";
import {setupCustomValidator} from "../../FormRenderer/actions/setupParsley";
const currentEdit = observable({});

const nval = getMeta()[0];
const correctlyNoncedEmotionCache = createCache({
  key: "css-key",
  nonce: nval,
});

let inspector = null;
let element = {};
let elementdate = {};
let elementtext = {};
const primary = "primary" + guid().split("-").join("");
const DataGrid = (props) => {
  const gridInstance = useRef(null);
  useEffect(() => {
    updateDataSet();
  }, []);

  const [gridState] = useState(
    observable({
      adding: false,
      editing: false,
      loading: false,
      selectedItem: {},
      dataSource: [],
      waitReadOnly: false,
      nothingSelected: true,
    })
  );

  let currentedit = observable({});

  const onSave = (event) => {
    const formActions = props.Config.data;
    const data = toJS(currentedit);
    if (event.action === "add") {
      loadData(
        formActions.create.functionname,
        formActions.create.functiontype,
        data,
        () => {
          if (props.ReloadParent) {
            props.ReloadParent();
          }

          updateDataSet();
          Notifications.success("The information has been saved successfully.");
        },
        () => {
          updateDataSet();
        }
      );
    } else {
      loadData(
        formActions.save.functionname,
        formActions.save.functiontype,
        data,
        () => {
          if (props.ReloadParent) {
            props.ReloadParent();
          }

          updateDataSet();
          Notifications.success("The information has been saved successfully.");
          if (props.AfterSave) {
            props.AfterSave();
          }
        },
        () => {
          updateDataSet();
        }
      );
    }
  };

  const toolbarClick = (args) => {
    switch (args.item.text) {
      case "PDF Export":
        gridInstance.current.pdfExport();
        break;
      case "Excel Export":
        gridInstance.current.excelExport();
        break;
      case "Refresh":
        updateDataSet();
        break;
      default:
        break;
    }
  };

  const isEmpty = (map) => {
    if (map instanceof Array) {
      if (map.length === 0) {
        return true;
      }
    }
    for (var key in map) {
      if (map.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  const updateDataSet = () => {
    if (props.datasource) {
      gridInstance.current.dataSource = props.datasource;
      return;
    }
    gridState.selectedItem = {};
    gridState.nothingSelected = true;
    if (props.Config.keylink instanceof Array) {
      props.Config.keylink.forEach((item, index) => {
        gridState.selectedItem[item] =
          props.ParentData[props.Config.key[index]];
      });
    } else {
      gridState.selectedItem[props.Config.keylink] =
        props.ParentData[props.Config.key];
    }

    gridState.loading = true;

    if (props.Config) {
      let postData = toJS(props.ParentData);

      if (isEmpty(props.ParentData)) {
        return;
      }

      gridState.loading = true;

      if (isEmpty(postData)) {
        if (gridInstance.current) {
          gridInstance.current.dataSource = [];
        }
        return;
      }
      loadData(
        props.Config.data.getResource.id,
        props.Config.data.getResource.resourceType,
        postData,
        (response) => {
          if (
            gridInstance.current &&
            response[props.Config.data.getResource.resourceIdentity]
          ) {
            response[props.Config.data.getResource.resourceIdentity].forEach(
              (item, index) => {
                item[primary] = index;
              }
            );
            gridInstance.current.dataSource =
              response[props.Config.data.getResource.resourceIdentity];
          } else {
            if (gridInstance.current) {
              gridInstance.current.dataSource = [];
            }
          }

          gridState.loading = false;
        }
      );
    }
  };

  const computeDefault = (addobject, field) => {
    if (field["defaultValue"]) {
      if (field["defaultValue"] === "Today") {
        addobject[field.key] = new Date();
      } else {
        if (field["defaultValue"] === "User") {
          addobject[field.key] = user.getUserDetails().UserName;
        } else {
          if (field["defaultValue"] === "EmployeeId") {
            addobject[field.key] = user.getUserDetails().EmployeeIdNumber;
          } else {
            if (
              !(
                addobject[field.key] &&
                addobject[field.key].toString().trim().length > 0
              )
            ) {
              addobject[field.key] = field["defaultValue"];
            }
          }
        }
      }
    }
  };

  const computeDefaultValues = (addobject) => {
    props.Config.fields.forEach((field) => {
      if (field instanceof Array) {
        field.forEach((subField) => {
          computeDefault(addobject, subField);
        });
      } else {
        computeDefault(addobject, field);
      }
    });

    return addobject;
  };

  const CalcStateChanged = () => {
    return new Promise((resolve, reject) => {
      if (
        props.Config.data &&
        props.Config.data.createnew &&
        props.Config.data.createnew.beforeadd
      ) {
        loadData(
          props.Config.data.createnew.beforeadd.functionname,
          props.Config.data.createnew.beforeadd.functiontype,
          toJS(props.Config.ParentData),
          (response) => {
            let addobject = {};

            props.FormDefinition.formDefinition.rows.forEach((item) => {
              item.columns.forEach((col) => {
                if (col.fields !== undefined) {
                  col.fields.forEach((field) => {
                    if (
                      response[
                        props.Config.data.createnew.beforeadd.resourceIdentity
                      ][field.key]
                    ) {
                      addobject[field.key] =
                        response[
                          props.Config.data.createnew.beforeadd.resourceIdentity
                        ][field.key];
                    }
                  });
                }
              });
            });
            resolve(addobject);
          },
          (error) => {
            console.error(error);
            reject();
          }
        );
      } else {
        let addobject = {};

        addobject = computeDefaultValues(addobject);

        if (props.Config.data.addDefaults) {
          loadData(
            props.Config.data.addDefaults.resource,
            props.Config.data.addDefaults.resourceType,
            props.Config.ParentData,
            (response) => {
              const addobject = {
                ...response[props.Config.data.addDefaults.resourceIdentity],
              };
              if (
                props.Config &&
                props.ParentData &&
                props.Config.keylink instanceof Array
              ) {
                props.Config.keylink.forEach((item, index) => {
                  addobject[item] = props.ParentData[props.Config.key[index]];
                });
              } else {
                addobject[props.Config.keylink] =
                  props.ParentData[props.Config.key];
              }
              resolve(addobject);
            },
            () => {
              reject();
            }
          );
        } else {
          if (props.Config && props.ParentData) {
            if (props.Config.keylink instanceof Array) {
              props.Config.keylink.forEach((item, index) => {
                addobject[item] = props.ParentData[props.Config.key[index]];
              });
            } else {
              addobject[props.Config.keylink] =
                props.ParentData[props.Config.key];
            }
          }
          resolve(addobject);
        }
      }
    });
  };

  const ReloadParent = (previous) => {
    loadData(
      props.Config.entry.resource.id,
      props.Config.entry.resource.resourceType,
      toJS(gridState.selectedItem[0]),
      (resource) => {
        try {
          const current =
            resource[props.Config.entry.resource.resourceIdentity];
          for (var propertyName in current) {
            previous[propertyName] = current[propertyName];
          }
        } catch (ex) {
          console.error(ex);
        }
      }
    );
  };

  const actionComplete = (evnt) => {
    if (evnt.requestType === "save") {
      $(".sl2").select2("close");
      $(".select2-dropdown").remove();
      onSave(evnt);
    } else if (evnt.requestType === "beginEdit") {
      for (var key in evnt.rowData) {
        currentEdit[key] = evnt.rowData[key];
      }

      $(".sl2").select2("close");
    }
  };

  const computeAllowEditing = () => {
    if (props.Config.allowInlineEditing && props.ReadOnly) {
      let display = true;
      if (props.Config.allowInlineEditing.parentDependentFields) {
        props.Config.allowInlineEditing.parentDependentFields.forEach(
          (dependent) => {
            dependent.values.forEach((value) => {
              if (
                props.ParentData &&
                props.ParentData[dependent.key] &&
                props.ParentData[dependent.key]
                  .toString()
                  .trim()
                  .toLowerCase() === value.toString().trim().toLowerCase()
              ) {
                display = dependent.active;
              }
            });
          }
        );
        return display;
      } else if (
        gridState.nothingSelected === false &&
        props.Config.allowInlineEditing.dependentFields
      ) {
        let display = true;
        const data = toJS(gridState.selectedItem);
        props.Config.allowInlineEditing.dependentFields.forEach((dependent) => {
          dependent.values.forEach((value) => {
            if (
              data &&
              data[dependent.key] &&
              data[dependent.key].toString().trim().toLowerCase() ===
                value.toString().trim().toLowerCase()
            ) {
              display = dependent.active;
            }
          });
        });
        return display;
      } else {
        return true;
      }
    }
    return false;
  };

  const setupEditSettings = () => {
    if (props.Config && props.Config.allowInlineEditing) {
      const allowEditing = computeAllowEditing();
      return {
        allowEditing: allowEditing,
        allowAdding: props.ReadOnly && allowEditing && props.Config.allowAdding,
        allowDeleting: props.Config.allowDelete && allowEditing,
        showConfirmDialog: false,
        mode: "Normal",
      };
    }
    return {
      allowEditing: false,
      allowAdding: false,
      allowDeleting: false,
      showConfirmDialog: false,
      mode: "Normal",
    };
  };

  const onSelection = (args) => {
    gridState.nothingSelected = false;
    gridState.selectedItem = args;
  };

  const onStateChanged = (currentState, data) => {
    switch (currentState) {
      case "Normal":
        gridState.adding = false;
        gridState.editing = false;
        break;
      case "Add":
        gridState.adding = true;
        gridState.editing = false;
        break;
      case "Edit":
        gridState.editing = true;
        gridState.adding = false;

        break;
      case "View":
        gridState.editing = false;
        gridState.adding = false;

        break;
      case "Cancel":
        gridState.adding = false;
        gridState.editing = false;
        break;
      default:
        gridState.adding = false;
        gridState.editing = false;
        break;
    }
  };

  return (
    <GridStyle>
      <Observer>
        {() => (
          <>
            {gridState.loading ? (
              <></>
            ) : (
              <ActionBar
                FormDefinition={
                  props.Config.entry ? props.Config.entry.definition : null
                }
                AdditionalButtons={props.Config.customButtons}
                onStateChanged={onStateChanged}
                Config={props.Config}
                reloadParent={ReloadParent}
                nothingSelected={gridState.nothingSelected}
                refreshAfterSave={updateDataSet}
                ParentData={props.ParentData}
                stack={props.stack}
                readOnly={props.ReadOnly}
                Locked={
                  props.Locked === true ||
                  props.locked === true ||
                  props.Config.disableEditing === true
                }
                willLaunchDialog={true}
                isDialog={false}
                isAdding={gridState.adding}
                isEditing={gridState.editing}
                EntryDetails={gridState.selectedItem}
              />
            )}
          </>
        )}
      </Observer>

      <GridComponent
         rowRenderingMode={"Horizontal"}
         enableAdaptiveUI={false}
        ref={gridInstance}
        toolbarClick={toolbarClick}
        width="100%"
        height={300}
        allowGrouping={true}
        toolbar={
          (props.Config && props.Config.allowInlineEditing !== null && props.Config.allowInlineEditing !== undefined)
            ? [
                "Add",
                "Update",
                "Cancel",
                "ExcelExport",
                "PdfExport",
                {
                  text: "Refresh",
                  tooltipText: "Refresh Data",
                  prefixIcon: "mdi mdi-refresh",
                  id: "Refresh",
                },
                "ColumnChooser",
              ]
            : [
                "ExcelExport",
                "PdfExport",
                {
                  text: "Refresh",
                  tooltipText: "Refresh Data",
                  prefixIcon: "mdi mdi-refresh",
                  id: "Refresh",
                },
                "ColumnChooser",
              ]
        }
        allowExcelExport={true}
        allowPdfExport={true}
        editSettings={setupEditSettings()}
        groupSettings={{
          showDropArea: false,
          showUngroupButton: true,
          showToggleButton: true,
          showGroupedColumn: true,
          showConfirmDialog: false,
        }}
        rowSelected={(args) => {
          onSelection(args.data);
        }}
        width="100%"
        acceptsReturn={true}
        textWrapSettings={{
          wrapMode: "Content",
        }}
        allowTextWrap={true}
        selectionSettings={{
          enableSimpleMultiRowSelection: props.Config.multiSelect === true,
          type: props.Config.multiSelect === true ? "Multiple" : "Single",
        }}
        allowSelection={true}
        actionComplete={actionComplete}
        allowFiltering={true}
        actionBegin={(args) => {
          if (args.requestType === "add") {
            for (const col in args.rowData) {
              currentedit[col] = args.rowData[col];
            }
            gridState.waitReadOnly = true;
            CalcStateChanged().then((addObject) => {
              for (const col in addObject) {
                currentedit[col] = addObject[col];
              }
              gridState.waitReadOnly = false;
            });
          }
        }}
        beginEdit={(args) => {
          var count = 0;
          props.Config.fields.forEach((field) => {
            if (
              computeReadOnly(
                field,
                props.ParentData,
                args.rowData,
                field.readOnly,
                false
              )
            ) {
              count++;
            }
          });

          if (count === props.Config.fields.length) {
            args.cancel = true;
          } else {
            for (const col in args.rowData) {
              currentedit[col] = args.rowData[col];
            }
          }
        }}
        allowSorting={true}
        dataSource={[]}
        allowPaging={true}
        showColumnChooser={true}
        filterSettings={{
          type: "Excel",
        }}
        pageSettings={{
          pageSize: 10,
          pageCount: 5,
          pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        }}
      >
        <ColumnsDirective>
          {props.Config.multiSelect === true ? (
            <ColumnDirective type="checkbox" width="50" />
          ) : (
            <></>
          )}

          {props.Config.fields.map((column) => {
            switch (column.type) {
              case "date": {
                return (
                  <ColumnDirective
                    key={`${guid()}`}
                    allowEditing={!column.readOnly}
                    editType="datepicker"
                    field={column.key}
                    textAlign={column.textAlign}
                    validationRules={{
                      minLength: [
                        (args) => {
                          const validator = $(
                            `#${primary}${column.key.toLowerCase()}`
                          ).parsley();

                          
                          const test = validator && validator.validate();
                          return test;
                        },
                        ,
                        "Please Correct The Specified Issues Before Saving",
                      ],
                    }}
                    headerText={column.label}
                    type={"dateTime"}
                    edit={{
                      create: () => {
                        elementdate[column.key] = document.createElement("div");
                        elementdate[column.key].value = "1";
                        return elementdate[column.key];
                      },
                      destroy: () => {
                        try {
                          ReactDOM.unmountComponentAtNode(
                            elementdate[column.key]
                          );
                        } catch {}
                      },
                      read: () => {
                        const result = toJS(currentedit)[column.key];
                        return result;
                      },
                      write: () => {
                        var readOnly = computeReadOnly(
                          column,
                          props.ParentData,
                          currentedit,
                          column.readOnly,
                          false
                        );

                        ReactDOM.render(
                          <form
                            id={`${primary}${column.key.toLowerCase()}`}
                            style={{ height: "64px", paddingTop: "10px" }}
                          >
                            <CacheProvider value={correctlyNoncedEmotionCache}>
                              <ThemeProvider theme={themeStore.theme.theme}>
                                <Observer>
                                  {() => (
                                    <>
                                      <DatePickerComponent
                                        format={"dd-MMM-yyyy"}
                                        showClearButton={true}
                                        showTodayButton={true}
                                        readonly={column.readOnly}
                                        {...BindModel(
                                          currentedit,
                                          column.key,
                                          "value",
                                          "change",
                                          true
                                        )}
                                        className="form-control"
                                      />
                                      <input
                                        readOnly={
                                          readOnly || gridState.waitReadOnly
                                        }
                                        {...computeValidation(column,`${primary}${column.key.toLowerCase()}`)}
                                        type="text"
                                        style={{ display: "none" }}
                                        className="form-control"
                                        {...BindModel(
                                          currentedit,
                                          column.key,
                                          "value",
                                          "onChange"
                                        )}
                                      />
                                    </>
                                  )}
                                </Observer>
                              </ThemeProvider>
                            </CacheProvider>
                          </form>,
                          elementdate[column.key]
                        );
                        var validator = $(`#${primary}${column.key.toLowerCase()}`).parsley();
                        setupCustomValidator(window.Parsley,{element:`${primary}${column.key.toLowerCase()}`,data:currentedit});
                      },
                    }}
                    template={(args) => {
                      return (
                        <span>
                          {moment(args[column.key]).format("DD-MMM-YYYY")}
                        </span>
                      );
                    }}
                  />
                );
              }
              case "text": {
                return (
                  <ColumnDirective
                    key={`${guid()}`}
                    field={column.key}
                    allowEditing={!column.readOnly}
                    textAlign={column.textAlign}
                    validationRules={{
                      'required': [
                        (args) => {
                          const validator = $(
                            `#${primary}${column.key.toLowerCase()}`
                          ).parsley();
                          
                          const test = validator && validator.validate();
                          return test;
                        },
                        ,
                        "Please Correct The Specified Issues Before Saving",
                      ],
                    }}
                    headerText={column.label}
                    type="string"
                    edit={{
                      create: () => {
                        elementtext[column.key] = document.createElement("div");
                        elementtext[column.key].value = "1";
                        return elementtext[column.key];
                      },
                      destroy: () => {
                        try {
                          ReactDOM.unmountComponentAtNode(
                            elementtext[column.key]
                          );
                        } catch {}
                      },
                      read: () => {
                        const result = toJS(currentedit)[column.key];
                        return result;
                      },
                      write: () => {
                        var readOnly = computeReadOnly(
                          column,
                          props.ParentData,
                          currentedit,
                          column.readOnly,
                          false
                        );
                        ReactDOM.render(
                          <form
                            id={`${primary}${column.key.toLowerCase()}`}
                            style={{ height: "64px", paddingTop: "10px" }}
                          >
                            <CacheProvider value={correctlyNoncedEmotionCache}>
                              <ThemeProvider theme={themeStore.theme.theme}>
                                <Observer>
                                  {() => (
                                    <input
                                      readOnly={
                                        readOnly || gridState.waitReadOnly
                                      }
                                      {...computeValidation(column,`${primary}${column.key.toLowerCase()}`)}
                                      type="text"
                                      className="form-control"
                                      {...BindModel(
                                        currentedit,
                                        column.key,
                                        "value",
                                        "onChange"
                                      )}
                                    />
                                  )}
                                </Observer>
                              </ThemeProvider>
                            </CacheProvider>
                          </form>,
                          elementtext[column.key]
                        );
                        var validator = $(`#${primary}${column.key.toLowerCase()}`).parsley();
                        setupCustomValidator(window.Parsley,{element:`${primary}${column.key.toLowerCase()}`,data:currentedit});
                      },
                    }}
                    format={column.format}
                  />
                );
              }
              case "number": {
                return (
                  <ColumnDirective
                    key={`${guid()}`}
                    field={column.key}
                    allowEditing={!column.readOnly}
                    textAlign={column.textAlign}
                    validationRules={{
                      'required': [
                        (args) => {
                          const validator = $(
                            `#${primary}${column.key.toLowerCase()}`
                          ).parsley();
                          
                          const test = validator && validator.validate();
                          return test;
                        },
                        ,
                        "Please Correct The Specified Issues Before Saving",
                      ],
                    }}
                    headerText={column.label}
                    type="number"
                    edit={{
                      create: () => {
                        elementtext[column.key] = document.createElement("div");
                        elementtext[column.key].value = "1";
                        return elementtext[column.key];
                      },
                      destroy: () => {
                        try {
                          ReactDOM.unmountComponentAtNode(
                            elementtext[column.key]
                          );
                        } catch {}
                      },
                      read: () => {
                        const result = toJS(currentedit)[column.key];
                        return result;
                      },
                      write: () => {
                        var readOnly = computeReadOnly(
                          column,
                          props.ParentData,
                          currentedit,
                          column.readOnly,
                          false
                        );
                        ReactDOM.render(
                          <form
                            id={`${primary}${column.key.toLowerCase()}`}
                            style={{ height: "64px", paddingTop: "10px" }}
                          >
                            <CacheProvider value={correctlyNoncedEmotionCache}>
                              <ThemeProvider theme={themeStore.theme.theme}>
                                <Observer>
                                  {() => (
                                    <input
                                      readOnly={
                                        readOnly || gridState.waitReadOnly
                                      }
                                      {...computeValidation(column,`${primary}${column.key.toLowerCase()}`)}
                                      type="text"
                                      className="form-control"
                                      {...BindModel(
                                        currentedit,
                                        column.key,
                                        "value",
                                        "onChange"
                                      )}
                                    />
                                  )}
                                </Observer>
                              </ThemeProvider>
                            </CacheProvider>
                          </form>,
                          elementtext[column.key]
                        );
                        const validator = $(`#${primary}${column.key.toLowerCase()}`).parsley();
                        setupCustomValidator(window.Parsley,{element:`${primary}${column.key.toLowerCase()}`,data:currentedit});
                      },
                    }}
                    format={column.format}
                  />
                );
              }
              case "select": {
                return (
                  <ColumnDirective
                    key={`${guid()}`}
                    allowEditing={column.readOnly}
                    textAlign={column.textAlign}
                    validationRules={{
                      'required': [
                        (args) => {
                          const validator = $(
                            `#${primary}${column.key.toLowerCase()}`
                          ).parsley();
                          
                          const test = validator && validator.validate();
                          return test;
                        },
                        ,
                        "Please Correct The Specified Issues Before Saving",
                      ],
                    }}
                    edit={{
                      create: () => {
                        element[column.key] = document.createElement("div");
                        element[column.key].value = "1";
                        return element[column.key];
                      },
                      destroy: () => {
                        try {
                          ReactDOM.unmountComponentAtNode(element[column.key]);
                        } catch {}
                      },
                      read: () => {
                        const result = toJS(currentedit)[column.key];
                        return result;
                      },
                      write: () => {
                        var readOnly = computeReadOnly(
                          column,
                          props.ParentData,
                          currentedit,
                          column.readOnly,
                          false
                        );
                        ReactDOM.render(
                          <form
                            id={`${primary}${column.key.toLowerCase()}`}
                            style={{ height: "64px", paddingTop: "10px" }}
                          >
                            <CacheProvider value={correctlyNoncedEmotionCache}>
                              <ThemeProvider theme={themeStore.theme.theme}>
                                <Observer>
                                  {() => (
                                    <>
                                      <Select2Component
                                        ReadOnly={false}
                                        EntryDetails={currentedit}
                                        Config={column.config}
                                        ParentData={props.ParentData}
                                        FormId={props.FormId}
                                      />
                                      <input
                                        readOnly={
                                          readOnly || gridState.waitReadOnly
                                        }
                                        {...computeValidation(column,`${primary}${column.key.toLowerCase()}`)}
                                        type="text"
                                        style={{ display: "none" }}
                                        className="form-control"
                                        {...BindModel(
                                          currentedit,
                                          column.key,
                                          "value",
                                          "onChange"
                                        )}
                                      />
                                    </>
                                  )}
                                </Observer>
                              </ThemeProvider>
                            </CacheProvider>
                          </form>,
                          element[column.key]
                        );
                        const validator = $(`#${primary}${column.key.toLowerCase()}`).parsley();
                        setupCustomValidator(window.Parsley,{element:`${primary}${column.key.toLowerCase()}`,data:currentedit});
                      },
                    }}
                    field={column.key}
                    headerText={column.label}
                    type="string"
                    format={column.format}
                  />
                );
              }
              case "bool":
              case "checkbox": {
                var readOnly = computeReadOnly(
                  column,
                  props.ParentData,
                  currentedit,
                  column.readOnly,
                  false
                );
                return (
                  <ColumnDirective
                    key={`${guid()}`}
                    field={column.key}
                    allowEditing={!column.readOnly}
                    displayAsCheckBox={true}
                    textAlign={column.textAlign}
                    validationRules={{
                      minLength: [
                        (args) => {
                          const validator = $(
                            `#${primary}${column.key.toLowerCase()}`
                          ).parsley();
                          
                          const test = validator && validator.validate();
                          return test;
                        },
                        ,
                        "Please Correct The Specified Issues Before Saving",
                      ],
                    }}
                    headerText={column.label}
                    type="boolean"
                    edit={{
                      create: () => {
                        elementtext[column.key] = document.createElement("div");
                        elementtext[column.key].value = "1";
                        return elementtext[column.key];
                      },
                      destroy: () => {
                        try {
                          ReactDOM.unmountComponentAtNode(
                            elementtext[column.key]
                          );
                        } catch {}
                      },
                      read: () => {
                        const result = toJS(currentedit)[column.key];
                        return result;
                      },
                      write: () => {
                        var readOnly = computeReadOnly(
                          column,
                          props.ParentData,
                          currentedit,
                          column.readOnly,
                          false
                        );
                        ReactDOM.render(
                          <form
                            id={`${primary}${column.key.toLowerCase()}`}
                            style={{ height: "64px", paddingTop: "10px" }}
                          >
                            <CacheProvider value={correctlyNoncedEmotionCache}>
                              <ThemeProvider theme={themeStore.theme.theme}>
                                <Observer>
                                  {() => (
                                    <>
                                      <CheckBoxComponent
                                        change={(e) => {
                                          currentedit[column.key] = e.checked;
                                        }}
                                        checked={currentedit[column.key]}
                                      />
                                    </>
                                  )}
                                </Observer>
                              </ThemeProvider>
                            </CacheProvider>
                          </form>,
                          elementtext[column.key]
                        );
                        const validator = $(`#${primary}${column.key.toLowerCase()}`).parsley();
                        setupCustomValidator(window.Parsley,{element:`${primary}${column.key.toLowerCase()}`,data:currentedit});
                      },
                    }}
                    format={column.format}
                  />
                );
              }
              default: {
                var readOnly = computeReadOnly(
                  column,
                  props.ParentData,
                  currentedit,
                  column.readOnly,
                  false
                );
                return (
                  <ColumnDirective
                    key={`${guid()}`}
                    textAlign={column.textAlign}
                    field={column.key}
                    allowEditing={!readOnly}
                    headerText={column.label}
                    type={column.type}
                    format={column.format}
                  />
                );
              }
            }
          })}
        </ColumnsDirective>
        <Inject
          services={[
            Toolbar,
            ExcelExport,
            PdfExport,
            ColumnChooser,
            Page,
            Filter,
            Sort,
            Group,
            DetailRow,
            Aggregate,
            Edit,
          ]}
        />
      </GridComponent>
    </GridStyle>
  );
};
export default withTheme(DataGrid);
