import FormLoader from '../../../FormRenderer';
import { Observer } from 'mobx-react';
import React from 'react';
import TabComponentStyle from './TabComponentStyle';
import { guid } from '../../../../utilities';
import { observable } from 'mobx';
import { withTheme } from '@emotion/react';

const TabComponent = (props) => {
  const tabs = [];

  const details = observable({
    selectedIndex: 0,
  });
  const showTab = (index) => {
    details.selectedIndex = index;
  };
  const tabWidth = 100 / props.Config.tabs.length;
  if (props.Config && props.Config.tabs) {
    props.Config.tabs.forEach((tab) => {
      tabs.push({
        id: guid(),
        caption: tab.caption,
        component: (
          <div>
            <FormLoader
              FormId={guid()}
              NoForm={true}
              stack={props.stack}
              Adding={false}
              Editing={false}
              refreshAfterSave={() => {}}
              ReloadParent={props.ReloadParent}
              Locked={props.Locked}
              ReadOnly={
                props.ReadOnly //&&
                // props.Config.readOnlyOnEdit !== true
              }
              EntryDetails={props.ParentData}
              ParentData={props.ParentData}
              FormDefinition={tab.definition}
            />
          </div>
        ),
      });
    });
  }

  return (
    <Observer>
      {() => (
        <>
        <TabComponentStyle>
          <div className="Tabs-holder">
            <nav>
              <ul className="Tabs">
                {tabs.map((tab, index) => {
                  return (
                    <li
                      className={
                        index === details.selectedIndex
                          ? 'Tabs__tab active Tab'
                          : 'Tabs__tab Tab'
                      }
                      style={{ width: `${tabWidth}%` }}
                      onClick={() => {
                        showTab(index);
                      }}
                      key={tab.id}
                    >
                      <span>{tab.caption}</span>
                    </li>
                  );
                })}
                <li
                  className="Tabs__presentation-slider"
                  style={{left:`${tabWidth*details.selectedIndex}%`, width: `${tabWidth}%` }}
                  role="presentation"
                ></li>
              </ul>
            </nav>
            <div className="Panel__body">
              {tabs.map((tab, index) => {
                return (
                  <div
                    style={{
                      display:
                        index === details.selectedIndex ? 'inherit' : 'none',
                    }}
                    key={tab.id}
                  >
                    {tab.component}
                  </div>
                );
              })}
            </div>
          </div>
          
        </TabComponentStyle>
        
        </>
      )}
    </Observer>
  );
};

/*class TabComponent extends PureComponent {
 

  constructor(props) {
    super(props);
    openedDialogs = [];
    tagid = guid();
  }
 
  componentDidMount() {
    showTab(0);
  }
  render() {
   
  }
}*/
export default withTheme(TabComponent);
