import { loadData } from '../../../../utilities';
import { toJS, observable } from 'mobx';
import onLaunchReport from './onLaunchReport';

const computeDefinition = (button, props,EntryDetails) => {
  let target = null;

  if (button.dependentCommandFields) {
    target={
      action:null,
      definition:null,
      defaults:null
    }
    button.dependentCommandFields.forEach(dependent => {
      dependent.values.forEach(value => {
        if (
          EntryDetails &&
          EntryDetails[dependent.key] &&
          EntryDetails[dependent.key]
            .toString()
            .trim()
            .toLowerCase() ===
            value
              .toString()
              .trim()
              .toLowerCase()
        ) {
          target.definition = dependent.definition;
          target.action = dependent.action;
          target.defaults = dependent.defaults;
        }
      });
    });
  }

  return target;
};

const launchReport = (props, EntryDetails,definition) => {
  
  onLaunchReport(
    definition.id,
    definition.header,
    toJS(EntryDetails),
    props.stack
  );
};

const executeReportDependent = (button,props) => {

  return new Promise((resolve,reject)=>{
    const computed = computeDefinition(props, props.EntryDetails);

    if (computed.action && computed.action.functionName) {
      loadData(
        computed.action.functionName,
        computed.action.functionType,
        toJS(props.ParentData),
        response => {
          const EntryDetails = observable({
            ...response[computed.action.functionResourceIdentity]
          });
          launchReport(props, EntryDetails, computed.definition);
          resolve();
        },
        recoverableError => {
          console.warn(recoverableError);
          reject();
        }
      );
      
    }
    else if (computed.defaults && computed.defaults.defaultValues) {
      const newEntry = { ...button.defaultValues };
      if (computed.defaults.keylink instanceof Array) {
        computed.defaults.keylink.forEach((item, index) => {
          newEntry[item] = props.EntryDetails[computed.defaults.key[index]];
        });
       
      } else {
        newEntry[computed.defaults.keylink] = props.EntryDetails[computed.defaults.key];
        
      }
  
      launchReport(props, observable(newEntry), computed.definition);
      resolve();
    } else {
      launchReport(props, props.EntryDetails, computed.definition);
      resolve();
    }
  
  })
  



  //props.onlaunchReport
 
  
};




export default executeReportDependent;
