import React, { useState } from 'react';
import ActionButtonStyle from './ActionButtonStyle';
import { guid } from '../../../utilities';

const ActionButtonGroup = (props) => {
  const [open, setOpen] = useState(false);
  const id = 'button' + guid();
  return (
    <ActionButtonStyle>
      <div className="dropdown float-left">
        <button
          id={id}
          type="button"
          className="btn btn-secondary dropdown-toggle"
          data-toggle="dropdown"
          onClick={()=>{setOpen(!open)}}
          style={{ backgroundColor: props.color }}
          
        >
          <i className={props.icon} style={{ color: props.color }}></i> {props.text}
        </button>
        <div className="dropdown-menu dropdown-menu-tip-nw" style={{"display":open?'inherit':'none'}}>
          {props.children}
        </div>
      </div>
    </ActionButtonStyle>
  );
};
export default ActionButtonGroup;
