
import EmployeeCargoAdjustmentBatchSearch from "./employeecargoinvoiceadjustmentbatchsearch";
import Colors from "../../../constants/Colors";
import CargoInvoiceAdjustmentList from "../cargoinvoiceadjustment/cargoinvoiceadjustmentlistbyemployeebatchnumber";
export default {
  formtype: "dataEntry",
  customSearch: {
    searchForm: { ...EmployeeCargoAdjustmentBatchSearch },
    width: 800,
    align: 500,
    height: 800,
    id: "EmployeeCargoAdjustmentBatchSearch"
  },
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {          
      role: ["Accounting Administrator", "Accounting Member"],
      value: true
    }
  ],  
  customActions: [    
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-chevron-double-right",
      text: "Process Adjustment Batch",
      refreshAfterAction: true,
      eventType: "api",
      color: Colors.process,
      roles: [
        {
          role: ["Accounting Administrator", "Accounting Member"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "AdjustmentBatchStatus",
          values: ["Processed",  "Voided"],
          active: false
        }
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm processing of adjustment batch"
      }, 
      function: {
        functionName: "ProcessEmployeeCargoInvoiceAdjustmentBatch",
        functionType: "POST",
        validateData: true,
        alertOnComplete: true,
        alertText: "The Employee Cargo Adjustment Batch Has Been Processed"
      }
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-null",
      text: "Void Adjustment Batch",
      eventType: "api",
      refreshAfterAction: true,
      color: Colors.cancel,
      roles: [
        {
          role: ["Accounting Administrator", "Accounting Member"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "AdjustmentBatchStatus",
          values: ["Processed", "Voided"],
          active: false
        }
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm voiding of adjustment batch"
      }, 
      function: {
        functionName: "VoidEmployeeCargoInvoiceAdjustmentBatch",
        functionType: "POST",
        validateData: true,
        alertOnComplete: true,
        alertText: "The Employee Cargo Adjustment Batch Has Been Voided"
      }
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Employee Cargo Batch Adjustment",
  editSearchSelector: {
    resource: "GetEmployeeCargoInvoiceAdjustmentList",
    resourceType: "GET",
    resourceId: ["EmployeeCargoInvoiceAdjustmentBatchNumber"],
    resourceIdentity: "EmployeeCargoInvoiceAdjustmentBatch",
    resourceValue: "EmployeeCargoInvoiceAdjustmentBatchNumber",
    resourceSelected: "GetEmployeeCargoInvoiceAdjustmentBatch",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "EmployeeCargoInvoiceAdjustmentBatch"},
  functionactions: {
    createnew: {
      functionname: "AddEmployeeCargoInvoiceAdjustmentBatch",
      functiontype: "POST",
      resourceIdentity: {
        id: "EmployeeCargoInvoiceAdjustmentBatch",
        mapping: [
          {
            id: "EmployeeCargoInvoiceAdjustmentBatchNumber",
            key: "EmployeeCargoInvoiceAdjustmentBatchNumber"
          },
          {
            id: "AdjustmentBatchStatus",
            key: "AdjustmentBatchStatus"
          }
        ]
      },
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Invoice Adjustment Created",
      alertText: "Cargo Invoice Adjustment Created"
    },
    update: {
      
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Dates",
            fields: [ 
              {
                key: "BeginDate",
                type: "date",
                label: "Begin Date",
                placeholder: "Begin Date",
                readOnly: false,
                validation: {
                  "data-parsley-required": true
                }
              },
              {
                key: "EndDate",
                type: "date",
                label: "End Date",
                placeholder: "End Date",
                readOnly: false,
                validation: {
                  "data-parsley-required": true
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [  
              {
                key: "EmployeeCargoInvoiceAdjustmentBatchNumber",
                type: "text",
                label: "Batch Number",
                placeholder: "Batch Number",
                readOnly: true
              },
              {
                key: "AdjustmentBatchStatus",
                type: "text",
                label: "Status",
                placeholder: "Status",
                readOnly: true
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "EnteredBy",
                type: "text",
                label: "Entered By",
                placeholder: "Entered By",
                readOnly: true
              },
              {
                key: "ProcessDate",
                type: "date",
                label: "Process Date",
                placeholder: "Process Date",
                readOnly: true
              },
              {
                key: "ProcessedBy",
                type: "text",
                label: "Processed By",
                placeholder: "Processed By",
                readOnly: true
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "VoidDate",
                type: "date",
                label: "Void Date",
                placeholder: "Void Date",
                readOnly: true
              },
              {
                key: "VoidedBy",
                type: "text",
                label: "Voided By",
                placeholder: "Voided By",
                readOnly: true
              },
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "tabs",
                readOnlyOnEdit: true,
                tabs: [
                  {
                    caption: "Adjustments",
                    definition: { ...CargoInvoiceAdjustmentList }
                  }
                ]
              }
            ]
          }
        ]
      }    
    ]
  }
};
