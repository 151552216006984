
import CargoPaymentSearch from "./cargopaymentsearch";
import CargoPaymentDetailList from "../cargopaymentdetail/cargopaymentdetaillist";
import CargoPaymentLogList from "../cargopaymentlog/cargopaymentloglist";
import Colors from "../../../constants/Colors";
export default {
  track: "CargoPayment",
  formtype: "dataEntry",
  customSearch: {
    searchForm: { ...CargoPaymentSearch },
    width: 800,
    align: 500,
    height: 800,
    id: "cargoInvoiceBatchSearch"
  },
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {          
      role: ["Accounting Administrator", "Accounting Member"],
      value: true
    }
  ],  
  customActions: [
    {
      showInEditMode: false,
      showInAddMode: false,
      showInLocked: false,
      requiresSelection: true,
      showInEditableMode: true,
      eventType: "edit",
      refreshAfterAction: true,
      dialogOnly: false,
      text: "Edit",
      icon: "mdi mdi-pencil",
      refreshAfterAction: true,
      color: Colors.edit,
      dependentFields: [
        {
          key: "PaymentStatus",
          values: ["Processed",  "Voided"],
          active: false
        }
      ],
      roles: [
        {
          role: ["Accounting Administrator", "Accounting Member"],
          value: true
        }
      ]
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-chevron-double-right",
      text: "Process Payment",
      refreshAfterAction: true,
      eventType: "api",
      color: Colors.process,
      roles: [
        {
          role: ["Accounting Administrator", "Accounting Member"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "PaymentStatus",
          values: ["Processed",  "Voided"],
          active: false
        }
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm processing of payment"
      }, 
      function: {
        functionName: "ProcessCargoPayment",
        functionType: "POST",
        validateData: true,
        alertOnComplete: true,
        alertText: "The Cargo Payment Has Been Processed"
      }
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-null",
      text: "Void Payment",
      eventType: "api",
      refreshAfterAction: true,
      color: Colors.cancel,
      roles: [
        {
          role: ["Accounting Administrator", "Accounting Member"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "PaymentStatus",
          values: ["Voided"],
          active: false
        },
        {
          key: "VoidablePayment",
          values: ["No"],
          active: false
        }
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm voiding of payment"
      }, 
      function: {
        functionName: "VoidCargoPayment",
        functionType: "POST",
        validateData: true,
        alertOnComplete: true,
        alertText: "The Cargo Payment Has Been Voided"
      }
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Payment",
  editSearchSelector: {
    resource: "GetCargoPaymentList",
    resourceType: "GET",
    resourceId: ["CargoPaymentRecordNumber"],
    resourceIdentity: "CargoPayments",
    resourceValue: "InvoiceNumber",
    resourceSelected: "GetCargoPayment",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoPayment"},
  functionactions: {
    createnew: {
      functionname: "AddCargoPayment",
      functiontype: "POST",
      resourceIdentity: {
        id: "CargoPayment",
        mapping: [
          {
            id: "CargoPaymentRecordNumber",
            key: "CargoPaymentRecordNumber"
          },
          {
            id: "PaymentStatus",
            key: "PaymentStatus"
          }
        ]
      },
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Payment Created",
      alertText: "Cargo Payment Created"
    },
    update: {
      functionname: "UpdateCargoPayment",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Payment Information Updated",
      alertText: "The Cargo Payment Has Been Updated"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Agency & Payment Date",
            fields: [ 
              {
                key: "AgencyId",
                type: "select",
                label: "Agency",
                placeholder: "Agency",
                readOnly: false,
                readOnlyOnEdit: true,
                optionsResource: {
                  resource: "GetAgencyList",
                  resourceType: "GET",
                  resourceId: ["AgencyId"],
                  resourceIdentity: "Agencies",
                  resourceValue: "AgencyName"
                },
                validation: {
                  "data-parsley-required": true
                }
              },
              {
                key: "PaymentDate",
                type: "date",
                label: "Payment Date",
                placeholder: "Payment Date",
                readOnly: false,
                validation: {
                  "data-parsley-required": true
                }
              },
              {
                key: "PaymentAmount",
                type: "number",
                format: "$0,0.00",
                label: "Amount",
                placeholder: "Amount",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-min": .01,
                  "data-parsley-max": 1000000,
                }
              }
            ]
          },
          {
            title: "Description",
            fields: [  
              {
                key: "PaymentMethod",
                type: "select",
                label: "Payment Method",
                placeholder: "Payment Method",
                readOnly: false,
                optionsResource: {
                  resource: "GetCargoPaymentMethodList",
                  resourceType: "GET",
                  resourceId: ["CargoPaymentMethod"],
                  resourceIdentity: "CargoPaymentMethods",
                  resourceValue: "CargoPaymentMethod"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 15
                }
              },
              {
                key: "Reference",
                type: "text",
                label: "Reference / Description",
                placeholder: "Reference / Description",
                readOnly: false,
                validation: {
                  "data-parsley-maxlength": 255
                }
              },
              {
                key: "PaymentApplied",
                type: "number",
                format: "$0,0.00",
                label: "Amount",
                placeholder: "Amount",
                readOnly: true
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "CargoPaymentRecordNumber",
                type: "text",
                label: "Receipt Number",
                placeholder: "Receipt Number",
                readOnly: true
              },
              {
                key: "PaymentStatus",
                type: "text",
                label: "Status",
                placeholder: "Status",
                readOnly: true
              },
              {
                key: "VoidablePayment",
                readOnly: true
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "tabs",
                readOnlyOnEdit: true,
                tabs: [
                  {
                    caption: "Invoices",
                    definition: { ...CargoPaymentDetailList }
                  },
                  {
                    caption: "Logs",
                    definition: { ...CargoPaymentLogList }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
