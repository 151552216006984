import React, { useState, useEffect } from 'react';
import { guid } from '../../../utilities';
import { withTheme } from '@emotion/react';

const Start = (props) => {
  const [searchText, setSearchText] = useState('');
  const [recentUsed, setRecentUsed] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const menus = [];
    props.MenuSystem.forEach((item) => {
      if (item.submenu) {
        processSubItems(menus, item.submenu);
      } else {
        menus.push(item);
      }
    });

    menus.sort((a, b) => {
      if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
        return 1;
      } else if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
        return -1;
      } else {
        return 0;
      }
    });

    setMenuItems(menus);
    updateRecent();
  }, []);

  const processSubItems = (menuItems, items) => {
    items.forEach((item) => {
      if (item.submenu) {
        processSubItems(menuItems, item.submenu);
      } else {
        menuItems.push(item);
      }
    });
  };

  const updateRecent = () => {
    const recentString = window.localStorage.getItem('recent');
    const recentItems = [];

    const menus = [];
    props.MenuSystem.forEach((item) => {
      if (item.submenu) {
        processSubItems(menus, item.submenu);
      } else {
        menus.push(item);
      }
    });

    if (recentString && recentString.length > 0) {
      const recent = JSON.parse(recentString);

      recent.forEach((rec) => {
        menus
          .filter((men) => {
            return men.resource === rec;
          })
          .forEach((menu) => {
            recentItems.push(menu);
          });
      });
    }

    setRecentUsed(
      recentItems.sort((a, b) => {
        if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
          return 1;
        } else if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      })
    );
  };

  const clearAll = () => {
    window.localStorage.setItem('recent', JSON.stringify([]));
    updateRecent();
  };
  const removeRecent = (menu) => {
    const recentString = window.localStorage.getItem('recent');
    if (recentString && recentString.length > 0) {
      const recent = JSON.parse(recentString);
      const newRecent = [];
      recent.map((rec) => {
        if (rec !== menu.resource) {
          newRecent.push(rec);
        }
      });
      window.localStorage.setItem('recent', JSON.stringify(newRecent));
    }
    updateRecent();
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div
      className="submenus recent"
      style={{ left: props.menuCollapsed ? '58px' : '248px' }}
    >
      <div className="submenuholder">
        <div className="submenuholderinner">
          <div className="logo">
            <div className="box">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    
                  <svg
                              enableBackground="new 0 0 512 512"
                              height="15px"
                              version="1.1"
                              viewBox="0 0 512 650"
                              width="15px"
                              fill={props.theme.textMenu}
                            >
                              <path d="M497.913,497.913c-18.782,18.782-49.225,18.782-68.008,0l-84.862-84.863c-34.889,22.382-76.13,35.717-120.659,35.717  C100.469,448.767,0,348.312,0,224.383S100.469,0,224.384,0c123.931,0,224.384,100.452,224.384,224.383  c0,44.514-13.352,85.771-35.718,120.676l84.863,84.863C516.695,448.704,516.695,479.131,497.913,497.913z M224.384,64.109  c-88.511,0-160.274,71.747-160.274,160.273c0,88.526,71.764,160.274,160.274,160.274c88.525,0,160.273-71.748,160.273-160.274  C384.657,135.856,312.909,64.109,224.384,64.109z" />
                            </svg>


                  </span>
                </div>
                <input
                  type="text"
                  value={searchText}
                  onChange={handleChange}
                  placeholder="Search here..."
                  className="form-control"
                  aria-label="Search"
                  aria-describedby="search"
                />
              </div>
            </div>
          </div>
          <div className="container-sidebar start" style={{ top: '70px' }}>
            {searchText.trim().length === 0 ? (
              <>
                <h6
                  style={{
                    padding: '8px',
                    fontSize: '11pt',
                    lineHeight: '1.2em',
                    margin: '0',
                  }}
                >
                  Recently Used Items
                </h6>
                <button className="clear-all" onClick={clearAll}>
                  Clear All
                </button>
                {recentUsed && recentUsed.length > 0 ? (
                  <ul>
                    {recentUsed.map((menu) => {
                      return (
                        <li key={guid()}>
                          <table
                            style={{ width: '100%' }}
                            className="start-table"
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      props.onItemSelected(menu);
                                    }}
                                  >
                                    {menu.name}
                                  </button>
                                </td>
                                <td style={{ width: '18px' }}>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      removeRecent(menu);
                                    }}
                                    style={{ fontSize: '18px' }}
                                  >
                                    <i className="icon-cross"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {menuItems.filter((menu) => {
                  return (
                    menu.name
                      .toLocaleLowerCase()
                      .indexOf(searchText.trim().toLocaleLowerCase()) > -1
                  );
                }).length === 0 ? (
                  <h6
                    style={{
                      padding: '8px',
                      fontSize: '11pt',
                      lineHeight: '1.2em',
                      margin: '0',
                    }}
                  >
                    No results found.
                  </h6>
                ) : (
                  <>
                    <h6
                      style={{
                        padding: '8px',
                        fontSize: '11pt',
                        lineHeight: '1.2em',
                        margin: '0',
                      }}
                    >
                      {
                        menuItems.filter((menu) => {
                          return (
                            menu.name
                              .toLocaleLowerCase()
                              .indexOf(searchText.trim().toLocaleLowerCase()) >
                            -1
                          );
                        }).length
                      }{' '}
                      results
                    </h6>
                    <ul>
                      {menuItems
                        .filter((menu) => {
                          return (
                            menu.name
                              .toLocaleLowerCase()
                              .indexOf(searchText.trim().toLocaleLowerCase()) >
                            -1
                          );
                        })
                        .map((menu) => (
                          <li key={guid()}>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                props.onItemSelected(menu);
                              }}
                            >
                              {menu.name}
                            </button>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTheme(Start);
