export default {
	formtype: "dataEntry",

	allowSearch: [
		{
			role:  [ "default" ],
			value:  true
		}
	],
	allowDelete: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowAudit: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowEdit: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowAdd: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
		  role: ["Cargo Administrator", "Cargo Member", "Cargo Supervisor"],
		  value: true
		}
	],
	allowPrint: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	printreport: "",
	caption:  "Airwaybill Note Details",
	editSearchSelector: {
		resource: "GetAirwaybillNoteList",
		resourceType: "GET",
		resourceId: [ "AirwaybillNoteRecordNumber" ],
		resourceValue: "AirwaybillNoteRecordNumber",
		resourceSelected: "GetAirwaybillNote",
		resourceSelectedType: "POST",
		resourceDisplayFields: [ ]
	},
	functionactions:
	{
		createnew: {
			functionname: "AddAirwaybillNote",
			functiontype: "POST",
			validateData: true,
			alertOnComplete: true,
			alertTitle: "Airwaybill Note Information Added",
			alertText: "The Airwaybill Note Has Been Added"
		},
		update: {
		},
		after: {
		}
	},
	formDefinition: {
		rows: [
			{
			columns: [
				{
				title: "Airwaybill Note Information",
				fields: [
					{
						key: "AirwaybillNumber",
						type: "label",
						defaultValue: "",
						label: "Airwaybill Number",
						placeholder: "Airwaybill Number",
						readOnly: true
					},
					{
						key: "AirwaybillNote",
						type: "textarea",
						defaultValue: "",
						label: "Note",
						placeholder: "Note",
						readOnly: false,
						validation: {
						  "data-parsley-required": true,
						  "data-parsley-minlength": 2,
						  "data-parsley-maxlength": 1024
						}
					}
				]
			  },
			  {
				title: '\n',
				fields: [
					{
						key: "UserName",
						type: "text",
						defaultValue: "",
						label: "User Name",
						placeholder: "User Name",
						readOnly: true
					},
					{
						key: "NoteDate",
						type: "date",
						defaultValue: "",
						label: "Date",
						placeholder: "Date",
						readOnly: true
					},
					{
						key: "NoteTime",
						type: "text",
						defaultValue: "",
						label: "Time",
						placeholder: "Time",
						readOnly: true
					}
				]
				}
			]
			}
		]
	}
}