import CargoInvoice from "./cargoinvoice";
export default {
  track: "Cargo Invoice List",
  formtype: "dataEntry",

  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],

  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  caption: "Cargo Invoices",
  editSearchSelector: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "editabletable", 
                data: {
                  getResource: {
                    resourceType: "POST",
                    id: "GetCargoInvoiceListByBatchNumber",
                    resourceIdentity: "CargoInvoices"
                  }
                },
                entry: {
                  resource: {
                    resourceType: "POST",
                    id: "GetCargoInvoice",
                    resourceIdentity: "CargoInvoice"
                  },
                  definition: { ...CargoInvoice }
                },
                fields: [
                  {
                    key: "InvoiceNumber",
                    type: "text",
                    label: "Invoice No."
                  },
                  {
                    key: "InvoiceDate",
                    type: "text",
                    label: "Date"
                  },
                  {
                    key: "AgencyName",
                    type: "text",
                    label: "Agency"
                  },
                  {
                    key: "InvoiceAmount",
                    type: "number",
                    format: "C2",
                    label: "Amount"
                  }
                ]
              }
            ]
          }
        ]
      }     
    ]
  }
};



