import styled from '@emotion/styled';

const HelpStyle = styled.div`
display:none;
position: absolute;
    
    right: 0;
    top: 0px;
    bottom: 0;
    width: 300px;
    overflow: hidden;
    border-left:1px solid #fff;
    z-index:1100;

.logo {
    
    position: absolute;
    top: 0;
    left: 0;
    height: 33px;
    text-align:center;
    overflow:hidden;
    width: 100%;      
    background:${props => props.theme.accent}!important;
    text-align: color-interpolation-filters;

    overflow: hidden;
    position: absolute;
    top: 0;
    
    background: ${props => props.theme.backgroundDark};
    z-index: 1000;
    right: 0;
    bottom: 0;
    width: 300px;


    border-bottom: 1px solid ${props => props.theme.textLight};
    h4{
        font-size: 14px;
        font-weight: 400;
        line-height: 1.8;        
      color:${props => props.theme.textLight}    
    }

    img {
      width: 150px;
      display: block;
    }
    a{
        color:${props => props.theme.textLight}!important
    }
    table{
        width:100%;
        
    }
    td{
        padding: 5px 8px;
        text-align: left;
        vertical-align: top;
    }
    }
  }



.help-contents{
  
    position: absolute;
    top: 0px;
    left:300px;
    bottom:0;
    right:0;
    overflow-x:hidden;
    overflow-y:auto; 
    -webkit-overflow-scrolling: touch;
    background: ${props => props.theme.backgroundLight};
    padding:8px;
    font-size:14px;
    h4{
      font-size: 18px;
      font-weight: 600;
    }
    ul{
      margin:0;
      padding:0;
      list-style:none;
      li{
        margin-top:5px;
       
       
      }
    }
  }
  
  
    
  .container-sidebar {
    padding-top:20px;
    position: absolute;
    top: 0px;
    background: ${props => props.theme.backgroundDark};
    bottom: 0;
    left: 0;
    width: 300px;
    overflow-x:hidden;
    overflow-y:auto; 
    -webkit-overflow-scrolling: touch;
    .card {
 
      background:${props => props.theme.backgroundDark}!important;
      margin: 0!important;
      padding: 0px!important;
  }
  .card-holder {
  border: 0px solid ${props => props.theme.backgroundDarker}!important;
    margin: 0!important;
}

  .form-holder {
   
    padding-top: 0!important;
 
}
                                  .help{      
                                    margin-left:10px;
                                    margin-right:10px;
                                    margin-top:13px;                             
                                    ul{
                                      margin:0;
                                      padding:0;
                                      list-style:none;
                                      li{
                                        padding:3px 5px;
                                        a{
                                          cursor:pointer;
                                          font-weight:600;
                                          font-size:9pt;
                                          color: ${props => props.theme.darkGray}!important;
                                          outline: none!important;
                                        }
                                        
                                      }
                                    }
                                  }

                                  .findTopic
                                  {
                                    margin-left:20px;
                                      font-family:${props => props.theme.headerFont};
                                      font-size:600;
                                      cursor:pointer;
                                      text-transform:uppercase;
                                      font-size:11px;
                                    border-radius:25px;
                                    border:0px solid transparent;
                                    padding: 3px 20px 3px 10px;
                                    background:${props => props.theme.accent};    
                                    i{
                                        display:inline-block;
                                        background:${props => props.theme.textLight};
                                        color:${props => props.theme.accent};
                                        border-radius:25px;
                                        padding:0 3px;
                                        margin:4px 0;
                                        margin-right:10px;
                                    }
                                    span{
                                        display:inline-block;
                                        color:${props => props.theme.textLight}!important;
                                    }
                                  }


                                 
                                
                                



 
  
`;

export default HelpStyle;
