import $ from "jquery";
import Notifications from "../../Notification";
import { loadData } from "../../../../utilities";
import onConfirmation from "./onConfirmation";
import { toJS } from "mobx";

const onCalculate = (button, props, innerOnStateChanged) => {
  return new Promise((resolve, reject) => {
    let validator = $(`#${props.FormId}`).parsley();
    if (validator && !validator.validate()) {
      Notifications.warning(
        "There was an issue submitting this form. Please ensure all required fields have been filled out."
      );
      reject();
      return;
    }

    const launch = () => {
      //let prop = this.state.adding ? 'createnew' : 'update';
      const formDefinition = props.FormDefinition.functionactions;
      const execution = {
        url: formDefinition["calculate"].functionname,
        method: formDefinition["calculate"].functiontype,
      };

      loadData(
        execution.url,
        execution.method,
        toJS(props.EntryDetails),
        (response) => {
          let newSelected = { ...toJS(props.EntryDetails) };
          if (
            formDefinition["calculate"] &&
            formDefinition["calculate"].resourceIdentity !== undefined
          ) {
            formDefinition["calculate"].resourceIdentity.mapping.forEach(
              (mapItem) => {
                newSelected[mapItem.key] =
                  response[formDefinition["calculate"].resourceIdentity.id][
                    mapItem.id
                  ];
              }
            );
          }
          Notifications.success("The information has been saved successfully.");
          if (props.refreshAfterSave) {
            button.newData = newSelected;
            props.refreshAfterSave(newSelected);
            if (innerOnStateChanged) {
              innerOnStateChanged("Calculate");
            }
          } else {
            if (innerOnStateChanged) {
              innerOnStateChanged("Calculate");
            }
          }

          resolve();
        },
        () => {
          reject();
        }
      );
    };

    onConfirmation(
      button,
      props,
      () => {
        launch();
        resolve();
      },
      () => {
        reject();
      }
    );
  });
};

export default onCalculate;
