export default {
	formtype: "dataEntry",

	allowSearch: [
		{
			role:  [ "default" ],
			value:  true
		}
	],
	allowDelete: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowAudit: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowEdit: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowAdd: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowPrint: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	printreport: "",
	caption:  "Airwaybill Adjustment",
	functionactions:
	{
		createnew: {
		},
		update: {
		},
		after: {
		}
	},
	formDefinition: {
		rows: [
			{
			columns: [
				{
				title: "Airwaybill Adjustment Information",
				fields: [
					{
						key: "AirwaybillAdjustmentNumber",
						type: "text",
						label: "Adjustment Number",
						placeholder: "Adjustment Number",
						readOnly: true
					},
					{
						key: "AdjustmentDate",
						type: "date",
						label: "Date",
						placeholder: "Date",
						readOnly: true
					},
					{
						key: "AdjustmentAmount",
						type: "number",
						format: "$0,0.00",
						label: "Amount",
						placeholder: "Amount",
						readOnly: true
					}
				]
			  },
			  {
				title: '\n',
				fields: [
					{
						key: "TransactionTypeDescription",
						type: "text",
						label: "Description",
						placeholder: "Description",
						readOnly: true
					},
					{
						key: "AgencyName",
						type: "text",
						label: "Agency",
						placeholder: "Agency",
						readOnly: true
					},
					{
						key: "AdjustmentNotes",
						type: "textarea",
						label: "Notes",
						placeholder: "Notes",
						readOnly: true
					}
				]
				}
			]
			}
		]
	}
}