export default {
	formtype: "dataEntry",

	allowSearch: [
		{
			role:  [ "default" ],
			value:  true
		}
	],
	allowDelete: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowAudit: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowEdit: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowAdd: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	allowPrint: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	printreport: "",
	caption:  "System User Log Details",
	editSearchSelector: {
		resource: "GetSystemUserLogList",
		resourceType: "GET",
		resourceId: [ "UserName" ],
		resourceValue: "UserName",
		resourceSelected: "GetSystemUserLog",
		resourceSelectedType: "POST",
		resourceDisplayFields: [ ]
	},
	functionactions:
	{
		createnew: {
		},
		update: {
		},
		after: {
		}
	},
	formDefinition: {
		rows: [
			{
			columns: [
				{
				title: "System User Log Information",
				fields: [
					{
						key: "UserName",
						type: "label",
						defaultValue: "",
						label: "User",
						placeholder: "User",
						readOnly: true
					},
					{
						key: "ActionTaken",
						type: "text",
						defaultValue: "",
						label: "Action Taken",
						placeholder: "Action Taken",
						readOnly: true
					},
					{
						key: "SecurityRole",
						type: "text",
						defaultValue: "",
						label: "Security Role",
						placeholder: "Security Role",
						readOnly: true
					}
				]
			  },
			  {
				title: '\n',
				fields: [
					{
						key: "ActionTakenBy",
						type: "text",
						defaultValue: "",
						label: "Taken By",
						placeholder: "Taken By",
						readOnly: true
					},
					{
						key: "ActionDate",
						type: "text",
						defaultValue: "",
						label: "Action Date",
						placeholder: "Action Date",
						readOnly: true
					}
				]
				}
			]
			}
		]
	}
}