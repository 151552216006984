import styled from '@emotion/styled';
import chroma from 'chroma-js';



const ActionBarStyle = styled.div`padding-top: 5pt;
height: 30px; 

margin-bottom:5pt;

.wrapper-holder
{
  position: relative;
  width:100%;

}
.wrapper {
  position: relative;
  width: 252px;
  height: 30px; 
  transform: scale(0.4);
  
}

.block {
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  box-shadow: 0 0 10px ${props => chroma(chroma.scale([props.theme.accent,chroma(props.theme.accent).saturate(4)]).colors(5)[3]).alpha(0.05).css()};
}
.block:nth-of-type(1) {
  left: 0;
  background-color: #1994fd;
}
.block:nth-of-type(2) {
  left: 54px;
  background-color: ${props => chroma.scale([props.theme.accent,chroma(props.theme.accent).saturate(4)]).colors(5)[0]};
}
.block:nth-of-type(3) {
  left: 108px;
  background-color: ${props => chroma.scale([props.theme.accent,chroma(props.theme.accent).saturate(4)]).colors(5)[1]};
}
.block:nth-of-type(4) {
  left: 162px;
  background-color: ${props => chroma.scale([props.theme.accent,chroma(props.theme.accent).saturate(4)]).colors(5)[2]};
}
.block:nth-of-type(5) {
  left: 216px;
  background-color: ${props => chroma.scale([props.theme.accent,chroma(props.theme.accent).saturate(4)]).colors(5)[3]};
}
@keyframes slide {
  0%,
  8% {
    transform: translateX(0);
    background-color: ${props => props.theme.accent};
  }
  92%,
  100% {
    transform: translateX(216px);
    background-color: ${props => chroma.scale([props.theme.accent,chroma(props.theme.accent).saturate(4)]).colors(5)[3]};
  }
}
.mover {
  animation: slide 1.3s infinite alternate cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes jump1 {
  0% {
    top: 0;
    transform: rotate(0);
  }
  7.5% {
    top: -36px;
    transform: rotateZ(-90deg);
  }
  15% {
    top: 0;
    height: 36px;
    transform: rotateZ(-180deg);
  }
  19% {
    top: 12px;
    height: 24px;
    transform: rotateZ(-180deg);
  }
  23%,
  70% {
    top: 0;
    height: 36px;
    transform: rotateZ(-180deg);
  }
  77.5% {
    top: -36px;
    transform: rotateZ(-90deg);
  }
  85% {
    top: 0;
    height: 36px;
    transform: rotateZ(0);
  }
  89% {
    top: 12px;
    height: 24px;
    transform: rotateZ(0);
  }
  94%,
  100% {
    top: 0;
    height: 36px;
    transform: rotateZ(0);
  }
}
@keyframes jump2 {
  0% {
    top: 0;
    transform: rotate(0);
  }
  7.5% {
    top: -36px;
    transform: rotateZ(-90deg);
  }
  15% {
    top: 0;
    height: 36px;
    transform: rotateZ(-180deg);
  }
  19% {
    top: 12px;
    height: 24px;
    transform: rotateZ(-180deg);
  }
  23%,
  57.5% {
    top: 0;
    height: 36px;
    transform: rotateZ(-180deg);
  }
  65% {
    top: -36px;
    transform: rotateZ(-90deg);
  }
  72.5% {
    top: 0;
    height: 36px;
    transform: rotateZ(0);
  }
  76.5% {
    top: 12px;
    height: 24px;
    transform: rotateZ(0);
  }
  80.5%,
  100% {
    top: 0;
    height: 36px;
    transform: rotateZ(0);
  }
}
@keyframes jump3 {
  0% {
    top: 0;
    transform: rotate(0);
  }
  7.5% {
    top: -36px;
    transform: rotateZ(-90deg);
  }
  15% {
    top: 0;
    height: 36px;
    transform: rotateZ(-180deg);
  }
  19% {
    top: 12px;
    height: 24px;
    transform: rotateZ(-180deg);
  }
  23%,
  45% {
    top: 0;
    height: 36px;
    transform: rotateZ(-180deg);
  }
  52.5% {
    top: -36px;
    transform: rotateZ(-90deg);
  }
  60% {
    top: 0;
    height: 36px;
    transform: rotateZ(0);
  }
  64% {
    top: 12px;
    height: 24px;
    transform: rotateZ(0);
  }
  68%,
  100% {
    top: 0;
    height: 36px;
    transform: rotateZ(0);
  }
}
@keyframes jump4 {
  0% {
    top: 0;
    transform: rotate(0);
  }
  7.5% {
    top: -36px;
    transform: rotateZ(-90deg);
  }
  15% {
    top: 0;
    height: 36px;
    transform: rotateZ(-180deg);
  }
  19% {
    top: 12px;
    height: 24px;
    transform: rotateZ(-180deg);
  }
  23%,
  32.5% {
    top: 0;
    height: 36px;
    transform: rotateZ(-180deg);
  }
  40% {
    top: -36px;
    transform: rotateZ(-90deg);
  }
  47.5% {
    top: 0;
    height: 36px;
    transform: rotateZ(0);
  }
  51.5% {
    top: 12px;
    height: 24px;
    transform: rotateZ(0);
  }
  55.5%,
  100% {
    top: 0;
    height: 36px;
    transform: rotateZ(0);
  }
}
.jumper {
  transform-origin: -9px;
}
.jumper:nth-of-type(2) {
  animation: jump1 2.6s 0.2s infinite linear;
}
.jumper:nth-of-type(3) {
  animation: jump2 2.6s 0.35s infinite linear;
}
.jumper:nth-of-type(4) {
  animation: jump3 2.6s 0.5s infinite linear;
}
.jumper:nth-of-type(5) {
  animation: jump4 2.6s 0.65s infinite linear;
}








`;

export default ActionBarStyle;
