
export default {
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Type",
  editSearchSelector: {
    resource: "GetCargoTypeList",
    resourceType: "GET",
    resourceId: ["CargoType"],
    resourceIdentity: "CargoTypes",
    resourceValue: "CargoTypeAndDescription",
    resourceSelected: "GetCargoType",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoType",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "AddCargoType",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Type Information Added",
      alertText: "The Cargo Type Has Been Added"
    },
    update: {
      functionname: "UpdateCargoType",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Type Information Updated",
      alertText: "The Cargo Type Has Been Updated"
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [ 
            {
              key: "CargoType",
              type: "text",
              defaultValue: "",
              label: "Type",
              placeholder: "Type",
              readOnly: false,
              readOnlyOnEdit: true,
              validation: {
                "data-parsley-required": true,
                "data-parsley-minlength": 1,
                "data-parsley-maxlength": 20
              }
            },
            {
                key: "CargoTypeDescription",
                type: "textarea",
                defaultValue: "",
                label: "Description",
                placeholder: "Description",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 1,
                  "data-parsley-maxlength": 50
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                type: "spacer"
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                type: "spacer"
              }
            ]
          }
        ]
      }
    ]
  }
};
