import styled from "@emotion/styled";
import chroma from "chroma-js";

const SignatureCaptureComponentStyle = styled.div`
  width: 100%;
  button {
    outline: 0 !important;
  }
  .bottom-padding {
    background: ${(props) => props.theme.backgroundLight};
    height: 20px;
    width: 100%;
    display: block;
  }
  .bottom-padding-space {
    height: 30px;
    width: 100%;
    display: block;
  }

  .Panel {
    width: 100%;
  }
  .signature-edit {
    margin-top: 1px;
    border-radius: 3px;
    padding: 4px 0.75rem !important;
    font-family: "Work Sans", sans-serif !important;
    height: auto;

    border: 1px solid #dfd9c2;
    outline: none;
    font-size: 14px;
    &.disable{
      background: ${(props) => props.theme.backgroundDark};
    }
    &.signed {
      background: ${(props) => chroma(props.theme.add).alpha(0.2).css()};
    }

    &.unsigned {
      background: ${(props) => chroma(props.theme.cancel).alpha(0.2).css()};
    }

    &.uploading {
      background: ${(props) => chroma(props.theme.save).alpha(0.2).css()};
    }
  }

  .signature-holder {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
  .caption {
    
    font-size: 8pt;
    display: block;
    margin-bottom:10px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .signature-upload {
    position: relative;
    background-color: ${(props) => props.theme.backgroundLight};
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    border-radius: 3px;
    display: inline-block;
    padding: 10px;

  }
  .signature {
    position: relative;
    background-color: ${(props) => props.theme.backgroundLight};
    border: 1px solid ${(props) => props.theme.backgroundDarker};
    height: 300px;
    border-radius: 3px;
    display: inline-block;
    padding: 10px;
    canvas {
      background: transparent;
    }
    input {
      margin-top: 60px;
      text-align: center;
      background: transparent;
      border: 0px solid transparent;
      border-bottom: 1px solid ${(props) => props.theme.backgroundDarker};
      width: 100%;
      display: block;
      outline: none !important;
    }
  }

  .pulse {
    position: relative;
    left:50%;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    background: ${(props) => props.theme.add};
    border-radius: 50%;
    color: ${(props) => props.theme.backgroundLight};
    font-size: 20px;
    text-align: center;
    line-height: 50px;
    animation: animate 2s linear infinite;
}
@keyframes animate {
    0% {
        box-shadow: 0 0 0 0 ${(props) => chroma(props.theme.add).alpha(0.7).css()}, 0 0 0 0  ${(props) => chroma(props.theme.add).alpha(0.7).css()};
    }
    40% {
        box-shadow: 0 0 0 50px ${(props) => chroma(props.theme.add).alpha(0.0).css()}, 0 0 0 0  ${(props) => chroma(props.theme.add).alpha(0.7).css()};
    }
    80% {
        box-shadow: 0 0 0 50px ${(props) => chroma(props.theme.add).alpha(0.0).css()}, 0 0 0 30px  ${(props) => chroma(props.theme.add).alpha(0).css()};
    }
    100% {
        box-shadow: 0 0 0 0 ${(props) => chroma(props.theme.add).alpha(0.0).css()}, 0 0 0 30px  ${(props) => chroma(props.theme.add).alpha(0).css()};
    }
}


  .Panel__body {
    & > div > div > div > .form-holder {
      background: ${(props) => props.theme.backgroundLight};
      & > .card-holder {
        border: 0px solid transparent;
        margin: 0;
        & > .card {
          padding: 0;
        }
      }
    }
  }
  .Tabs-holder{
    position: relative;
    
    border:1px solid ${(props) => props.theme.backgroundDark};
    border-radius:3px;
  }
  .Tabs {
   
    position: relative;
    
    background: ${(props) => props.theme.backgroundDarker};
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .Tabs:after {
    content: ' ';
    display: table;
    clear: both;
  }

  .Tabs__tab {
    float: left;

    text-align: center;
  }

  

  .Tabs__presentation-slider {
    position: absolute;
    bottom: 0;
    left: 0;

    height: 0px;
    background: ${(props) => props.theme.accent};
    -webkit-transition: left 0.25s;
    transition: left 0.25s;
  }

  .Tab {
    border-right:1px solid ${(props) => props.theme.backgroundLight};
    
  }

  .Tab:nth-last-of-type(2){
      border-right:0px solid transparent!important;
    }

  .Tab > span {
    display: block;
    padding: 4px 0px;
    text-decoration: none;
    font-family: ${(props) => props.theme.mainFont}!important;
    font-size: 11px;
    font-weight: 600;
    cursor:pointer;
    text-align:center;
    text-transform: uppercase;
    color: ${(props) => props.theme.textMedium};
    -webkit-transition: color 0.15s;
    transition: color 0.15s;
  }
  .Tab.active
  {
    background: ${(props) => props.theme.backgroundDark};
  }
  .Tab.active > span {
    color: ${(props) => props.theme.accent};
    
  }

  .Tab:hover > span {
    color: ${(props) => props.theme.accent};
  }


  .dropzone-content {
    width: 400px;
    height: 300px;
  }
`;

export default SignatureCaptureComponentStyle;
