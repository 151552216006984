import getNumerical from "../Numeric/getNumerical";

const convertObjectPropertiesToNumeric = data => {
    if (data) {
      const computeData = {}; // initial
      for (var propertyName in data) {
        // cycle all properties in data
        computeData[propertyName] = getNumerical(data[propertyName]); //only try to parse numeric values others set to 0
      }
      return computeData; // return object with numeric only properties
    }
    return data; // data was undefined just pass it back
};

export default convertObjectPropertiesToNumeric;