import BindModel from "../../../Core/BindModel";
import { Observer } from "mobx-react";
import React from "react";
import TextStyle from "./TextBoxStyle";
import computeAsterisk from "../../actions/computeAsterisk";
import computeId from "../../actions/computeId";
import computeValidation from "../../actions/computeValidation";

const TextBox = (props) => (
  <Observer>
    {() => (
      <TextStyle>
        <div className="form-group">
          <label>
            {props.Config.label} {computeAsterisk(props.Config)}
          </label>
          <input
            id={computeId(props.Config)}
            readOnly={props.ReadOnly}
            autoComplete="off"
            type="text"
            {...computeValidation(props.Config, props.FormId)}
            className="form-control"
            placeholder={props.Config.placeholder}
            {...BindModel(
              props.EntryDetails,
              props.Config.key,
              "value",
              "onChange"
            )}
          />
        </div>
      </TextStyle>
    )}
  </Observer>
);
export default TextBox;
