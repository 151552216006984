import React from "react";
import { guid } from "../../../../utilities";
import { Observer } from "mobx-react";
import computeValidation from "../../actions/computeValidation";
import BindModel from "../../../Core/BindModel";
import Select2Component from "../Select2Component";
import computeAsterisk from "../../actions/computeAsterisk";

const SelectOrTextBox = props => (
  <Observer>
    {() => (
      <div className="form-group">
        <label>{props.Config.label} {computeAsterisk(props.Config)}</label>
        {props.Config.changeTypeEvaluate && props.EntryDetails[props.Config.changeTypeEvaluate.id] ===
        props.Config.changeTypeEvaluate.value ? (
          <input
            id={props.Config.id !== undefined ? props.Config.id : guid()}
            readOnly={props.ReadOnly}
            type="text"
            {...computeValidation(props.Config,props.FormId)}
            className="form-control"
            placeholder={props.Config.placeholder}
            {...BindModel(
              props.EntryDetails,
              props.Config.key,
              "value",
              "onChange"
            )}
          />
        ) : (
          <div>
            <Select2Component
              ReadOnly={props.ReadOnly}
              FormId={props.FormId}
              EntryDetails={props.EntryDetails}
              Config={props.Config}
              ParentData={props.EntryDetails}
            />            
          </div>
        )}
      </div>
    )}
  </Observer>
);
export default SelectOrTextBox;
