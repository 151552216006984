import CargoSpecialCustomerRate from "./cargospecialcustomerrate";
export default {
  formtype: "dataEntry",
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }, 
    {
      role: ["Cargo Administrator", "Accounting Administrator"],
      value: true
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator", "Accounting Administrator"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],
  printreport: "",
  caption: "Cargo Special Customer Rates",
  editSearchSelector: {
  },
  model: {
  },
  functionactions: {
    update: {
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "editabletable", 
                key: ["CargoSpecialCustomerId"],
                keylink: ["CargoSpecialCustomerId"],
                data: {
                  getResource: {
                    resourceType: "POST",
                    id: "GetCargoSpecialCustomerRateList",
                    resourceIdentity: "CargoSpecialCustomerRates"
                  }
                },
                entry: {
                  resource: {
                    resourceType: "POST",
                    id: "GetCargoSpecialCustomerRate",
                    resourceIdentity: "CargoSpecialCustomerRate"
                  },
                  definition: { ...CargoSpecialCustomerRate }
                },
                fields: [
                  {
                    key: "ServiceType",
                    type: "text",
                    label: "Service Type"
                  },
                  {
                    key: "CityFrom",
                    type: "text",
                    label: "From"
                  },
                  {
                    key: "CityTo",
                    type: "text",
                    label: "To"
                  },
                  {
                    key: "PoundRate",
                    type: "number",
                    format: "c2",
                    label: "Pound Rate"
                  },
                  {
                    key: "DiscountPercentage",
                    type: "number",
                    label: "Discount %age"
                  },
                  {
                    key: "BeginDate",
                    type: "text",
                    label: "Begin Date"
                  },
                  {
                    key: "EndDate",
                    type: "text",
                    label: "End Date"
                  }
                ]
              }
            ]
          }
        ]
      }     
    ]
  }
};



