export default {
  formtype: "dataEntry",
  saveAndNew:false,
hideSearch:true,
  allowSearch: [
    {
      role: ["default"],
      value: false,
    },
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: [""],
      value: false,
    },
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false,
    },
    {
      role: [""],
      value: false,
    },
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false,
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false,
    },
  ],
  caption: "Receive Package",
  editSearchSelector: {
    resource: "MockAPI",
    resourceId: ["MockValue"],
    resourceMethod:"GET",
    resourceIdentity: "MockList",
    resourceValue: "MockValue",
    resourceSelectedType: "GET",
    resourceSelected: "GetSystemUserStationPosted",
    resourceSelectedIdentity: "SystemUsers",
    resourceDisplayFields: [],
    after: []
  },
  functionactions: {
    createnew: { 
      functionname: "ReceiveAirwaybillPackage",
      functiontype: "POST",
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Airway Bill Package Received",
      alertText: "The Airway Bill Package Has Been Received"
    },
    update: {
      functionname: "ReceiveAirwaybillPackage",
      functiontype: "POST",     
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Airwaybill Information Updated",
      alertText: "The Airwaybill Has Been Updated"
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "Current Location",
            fields: [
              {
                key: "StationPostedName",
                type: "text",
                defaultValue: "StationPostedName",
                label: "Cargo Station",
                readOnly: true
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "Receive Package",
            fields: [              
              {
                key:"AirwaybillPackageNumber",
                type:"barcode",
                startText:"Begin Scanning of Packages",
                endText: "End Scanning of Packages",
                continuousScan: true,
                autoScan: true,
                action:{
                  eventType: 'api',
                  roles: [
                    {
                      role: ['Cargo Administrator','Cargo Member'],
                      value: true
                    }        
                  ],
                  function: {
                    functionName: 'ReceiveAirwaybillPackage',
                    functionType: 'POST',
                    alertText: 'The Package Has Been Received.'
                    },
                  validateData: true,
                  alertOnComplete: true,
                  alertText: 'The Package Has Been Received.',
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
            ]
          },
          {
            title: "\n",
            fields: [
              
            ]
          },
          {
            title: "\n",
            fields: [              
            ]
          }
        ]
      }
    ]
  }
};
