import 'parsleyjs';

import React, { Component } from 'react';
import { observable, toJS } from 'mobx';

import $ from 'jquery';
import BindModel from '../../components/Core/BindModel';
import Colors from '../../constants/Colors';
import LoginStyle from './LoginStyle';
import { Observer } from 'mobx-react';

export default class FormLoader extends Component {
  constructor(props) {
    super(props);
    this.userCredentials = observable({
      username: '',
      password: '',
      authenticationError: false
    });
  }

  componentDidMount() {
    $('.loginform1').parsley({
      errorsContainer: el => {
        return el.$element.closest('.form-group');
      }
    });
   
  }

  
  componentDidUpdate() {
    $('.loginform1').parsley({
      errorsContainer: el => {
        return el.$element.closest('.form-group');
      }
    });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  login(e) {
    e.preventDefault();
    let validator = $('.loginform1').parsley();
    if (validator.validate()) {
      this.userCredentials.authenticationError = false;
      const details = toJS(this.userCredentials);
      this.props.onLogin(details.username, details.password, () => {
        this.userCredentials.password = '';
        this.userCredentials.authenticationError = true;
      });
    }
  }

  render() {
    return (
      <LoginStyle>
        <Observer>
          {() =>
            <div className="login-holder">
              
              
              <div className="holder-right">
                <div className="container" style={{textAlign:"center"}}>
                <h5>Welcome To Cargo Management System</h5>
                </div>
                <div className="container">
                  <div className="logo">
                    <img alt="logo" src="/api/data/getcompanylogo" />
                  </div>
                </div>
                <div className="content">
                  <span className="instructions">
                    Please provide your assigned user name and password below,
                    then click sign in to gain access to this system.
                  </span>
                  <form
                    className="loginform1"
                    autoComplete="on"
                    onSubmit={this.login.bind(this)}
                  >
                    <div>
                      <div>
                        <div className="form-group">
                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="mdi mdi-email-outline" />
                              </div>
                            </div>
                            <input
                              name="username"
                              type="text"
                              // {...BindModel(entryDetails, "email", "value", "onChange")}
                              data-parsley-required="true"
                              {...BindModel(
                                this.userCredentials,
                                'username',
                                'value',
                                'onChange'
                              )}
                              className="form-control"
                              id="username"
                              placeholder="User Name"
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="mdi mdi-key" />
                              </div>
                            </div>
                            <input
                              //  {...BindModel(entryDetails, "password", "value", "onChange")}
                              data-parsley-required="true"
                              name="password"
                              type="password"
                              className="form-control"
                              id="password"
                              placeholder="Password"
                              {...BindModel(
                                this.userCredentials,
                                'password',
                                'value',
                                'onChange'
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.userCredentials.authenticationError
                      ? <div>
                          <span
                            style={{
                              color: Colors.notificationErrorText,
                              fontSize: '11px',
                              fontWeight: '900'
                            }}
                          >
                            Your username and password combination could not be
                            verified. Please ensure that you entered your
                            credentials properly.
                          </span>
                        </div>
                      : <span />}

                    <button
                      type="submit"
                      name="doLogin"
                      className="btn loginbutton"
                    >
                      <span>
                        Sign In
                        <i className="mdi mdi-arrow-right" />
                      </span>
                    </button>
                  </form>
                </div>
              </div>
              
            </div>}
        </Observer>
      </LoginStyle>
    );
  }
}
