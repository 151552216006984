import React from 'react';
import { withTheme } from '@emotion/react';
import { Observer } from 'mobx-react';
import { toJS } from 'mobx';
import { guid } from '../../../../utilities';
import Dialog from '../../../SideBarRenderer/TabContainer/Dialog';
import FormLoader from '../../../FormRenderer';
const DialogHolder = (props) => {
  return (
    <Observer>
      {() => (
        <Dialog
          ReadOnly={props.ReadOnly}
          FormDefinition={toJS(props.FormDefinition)}
          FormId={props.FormId}
          EntryDetails={props.EntryDetails}
          confirmation={props.Confirmation}
          onStateChanged={props.onStateChanged}
          Locked={props.Locked}
          stack={props.stack}
          isAdding={props.isAdding}
          isEditing={props.isEditing}
          refreshAfterSave={props.refreshAfterSave}
          header={props.header}
        >
          <FormLoader
            Adding={props.isAdding}
            Editing={props.isEditing}
            stack={props.stack}
            isAdding={props.isAdding}
            isEditing={props.isEditing}
            FormId={props.FormId}
            Locked={props.Locked}
            ReadOnly={props.ReadOnly}
            EntryDetails={props.EntryDetails}
            FormDefinition={toJS(props.FormDefinition)}
            ReloadParent={props.reloadParent}
          />
        </Dialog>
      )}
    </Observer>
  );
};
const ThemeDialog = withTheme(DialogHolder);

const onLaunchDialog = (
  props,
  dataEntry,
  adding,
  editing,
  definitionOverRide,
  locked,
  afterClose
) => {
  const currentId = guid();
  props.stack.push({
    id: currentId,
    onClose: afterClose,
    component: (
      <ThemeDialog
        FormId={currentId}
        ReadOnly={!adding && !editing}
        Locked={locked}
        isAdding={adding}
        isEditing={editing}
        stack={props.stack}
        FormDefinition={
          definitionOverRide ? definitionOverRide : props.FormDefinition
        }
        EntryDetails={dataEntry}
        onStateChanged={props.onStateChanged}
        confirmation={props.confirmation}
        refreshAfterSave={props.refreshAfterSave}
        //onSaveDialog={onSave}
        header={props.FormDefinition.caption}
      />
    ),
  });
};

export default onLaunchDialog;
