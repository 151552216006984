import { observable, toJS } from "mobx";

import FormLoader from "../../../FormRenderer";
import { loadData } from "../../../../utilities";
import onLaunchDialog from "./onLaunchDialog";

const CalcStateChanged = (props) => {
  return new Promise((resolve, reject) => {
    const resource = props.Config.entry.resource;
    loadData(
      resource.id,
      resource.resourceType,
      toJS(props.EntryDetails),
      (response) => {
        try {
          let entry = observable({
            ...response[resource.resourceIdentity],
          });
          resolve(entry);
        } catch (ex) {
          console.error(ex);
          reject();
        }
      },
      () => {
        resolve();
      }
    );
  });
};
const onEdit = (actionDefinition, props, innerOnStateChanged) => {
  return new Promise((resolve, reject) => {
    if (props.willLaunchDialog) {
      if (props.Config && props.Config.entry.resource) {
        CalcStateChanged(props).then((entry) => {
          if (innerOnStateChanged) {
            innerOnStateChanged("Edit");
            onLaunchDialog(props, entry, false, true, null, false, () => {
              return CalcStateChanged(props);
            });
            resolve();
          } else {
            onLaunchDialog(props, entry, false, true, null, false, () => {
              return CalcStateChanged(props);
            });
            resolve();
          }
        });
      } else {
        reject();
      }
    } else {
      if (innerOnStateChanged) {
        if (innerOnStateChanged) {
          innerOnStateChanged("Edit");
        }
        resolve();
      } else {
        resolve();
      }
    }
  });
};

export default onEdit;
