import styled from "@emotion/styled";

const Select2Style = styled.div`
  /* Old browsers */

  .select2-container--bootstrap4 .select2-selection__clear {
    width: 2em;
    
    line-height: 0.8em;
    padding-left: 4pt;
    
    border-radius: 100%;
    background-color: ${props => props.theme.darkGray};
    color: #f8f9fa;
    float: right;
    
    border-bottom:1px solid transparent!important;
    margin: 0;
    
}



.toggleOn{
  cursor:pointer;
  color:${props => props.theme.accent}!important;
  i{
    display:block;
    margin-top:-5px;
    font-size:24px;
  }
}

.toggleBtn{
  background-color:transparent;
  border:0;
  outline:0;
  height:24px;
  overflow:hidden;
  display:block;

}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered {
  color:${props => props.theme.textBlack}!important;
}

.toggleOff{
  color:${props => props.theme.textMedium}!important;
  i{
    display:inline-block
    font-size:24px;
  }
  cursor:pointer;
}

.select2-container--disabled
{
    background:${props => props.theme.backgroundDark}!important;
}
.select2-container--bootstrap4
 .select2-selection--single
 .select2-selection__rendered {
  padding-left: 2pt;
  line-height:18pt;
    font-size: 10pt;
}



.select2-container--bootstrap4 .select2-selection--single .select2-selection__placeholder {
  line-height:18pt;
}

.select2-container--bootstrap4
 .select2-selection--single
 .select2-selection__arrow {
    height:24px;
    border-left: 1px solid ${props => props.theme.backgroundDarker};
    border-top: 1px solid ${props => props.theme.backgroundDarker};
    border-right: 1px solid ${props => props.theme.backgroundDarker};
}

.select2-container--bootstrap4 .select2-selection--single {
    height: 25px !important;
}

.select2-container--bootstrap4.select2-container--focus .select2-selection {
    border-color: ${props => props.theme.backgroundDarker};
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: none;    
}
.select2-container--bootstrap4 .select2-selection {
    border: 1px solid ${props => props.theme.backgroundDarker};
    border-radius: 3pt;
    width: 100%;
    
}
.select2-container
{
  margin-top:0.3pt;
}
.select2-container--bootstrap4
{
    background:${props => props.theme.backgroundLight};
}


&:hover {
          color: ${props => props.theme.backgroundLight}!important;
          .result-holder-animator {
            top: 0;
            right: 0;
            left: 0;
            position: absolute;
            width: 100%;
            background: ${props => props.theme.accent};
            height: 100%;
            z-index: 1;
          }

          .list-other {
            background: ${props => props.theme.backgroundLight};
            color: ${props => props.theme.textMedium};
            .other-item {
              font-weight: 600;
            }
          }
        }


`

export default Select2Style;
