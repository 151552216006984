import React, { useRef, useEffect, useState } from "react";
import SignatureCaptureComponentStyle from "./SignatureCaptureComponentStyle";
import { Observer } from "mobx-react";
import { withTheme,useTheme } from "@emotion/react";
import { observable, toJS } from "mobx";
import { useDropzone } from "react-dropzone";
import SignaturePad from "react-signature-pad-wrapper";
import Select2Style from "../Select2Component/Select2Style";
import _format from "../Select2Component/actions/format";
import "select2";
import $ from "jquery";
import ActionButton from "../../../Core/ActionBar/ActionButton";
import { guid } from "../../../../utilities";
import { CirclePicker } from "react-color";
import cookie from "cookie_js";

import user from "../../../../utilities/User";
import computeValidation from "../../actions/computeValidation";
import BindModel from "../../../Core/BindModel";

import Notification from "../../../Core/Notification";

const SignatureCaptureComponent = (props) => {
  const theme = useTheme();
  const Swal = window.Swal;
  const [details] = useState(
    observable({
      selectedIndex: 0,
      selectedFont: 0,
      loading: true,
      activeFont: null,
      color: props.Config.colors[0],
      signatureMode: false,
      files: [],
      previewURL: "",
      uploading: false,
    })
  );
  const [fontList] = useState(observable({ fonts: [] }));
  const [state] = useState(observable({ signMode: false }));
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.Config.allowedFileTypes,
    maxFiles: 1,
    submitButtonDisabled: true,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        details.previewURL = URL.createObjectURL(acceptedFiles[0]);
        details.files = acceptedFiles;
      }
    },
  });

  const toggleMode = () => {
    state.signMode = !state.signMode;
    details.selectedIndex = 0;
    details.selectedFont = 0;
    details.color = props.Config.colors[0];
    details.files = [];
    details.previewURL = "";
    details.uploading = false;
    
  };
  const signaturePad = useRef(null);
  const select2Ref = useRef(null);
  const inputText = useRef(null);

  const showTab = (index) => {
    details.selectedIndex = index;
  };

  

  

  const tabs = [];
  const changeColor = (color) => {
    details.color = color.hex;
    signaturePad.current.penColor = color.hex;
  };
  const blobCreationFromURL = (inputURI) => {
    let binaryVal;
    const byteArrays = [];
    const inputMIME = inputURI.split(",")[0].split(":")[1].split(";")[0];
    if (inputURI.split(",")[0].indexOf("base64") >= 0) {
      binaryVal = atob(inputURI.split(",")[1]);
    } else {
      binaryVal = unescape(inputURI.split(",")[1]);
    }

    const blobArray = [];
    for (let index = 0; index < binaryVal.length; index++) {
      blobArray.push(binaryVal.charCodeAt(index));
    }

    const byteArray = new Uint8Array(blobArray);
    byteArrays.push(byteArray);

    return new Blob(byteArrays, {
      type: inputMIME,
    });
  };
  const uploadDraw = () => {

    Swal.fire({
      title: 'Confirm?',
      text: "you are about to upload the provided signature to the corresponding record.",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: props.theme.add,
      cancelButtonColor: props.theme.textMenu,
      confirmButtonText: 'Confirm'
    }).then((result) => {
      if (result.isConfirmed) {
        const inputURL = signaturePad.current.toDataURL("image/svg+xml");
        details.uploading = true;
        const formData = new FormData();
        const data = toJS(props.EntryDetails);
        for (var key in data) {
          formData.append(key, data[key]);
        }
        const blobObject = blobCreationFromURL(inputURL);
        formData.append("Blob File", blobObject);
    
        fetch(props.Config.uploadURL, {
          headers: {
            "X-CSRF-TOKEN-VERIFY-HEADER": cookie.get("XSRF-TOKEN"),
            "user-token": user.getUserDetails().token,
            "site-token": "eLtM2kzxUkuXVyhEN3UqtCzpM",
          },
          method: "post",
          body: formData,
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          referrer: "no-referrer",
        }).then(response => response.json())
          .then((dataServer) => {
            if(!dataServer.error)
            {
              
              toggleMode();
              props.EntryDetails[props.Config.key] = "signed";
              Notification.success("Signature has been successfully updated");
            }
            else
            {
              details.uploading = false;
              Notification.error(dataServer.error, "Server Side", null);
            }
          })
          .catch((ex) => {
            details.uploading = false;
            Notification.error(ex.message, "Server Side", null);
          });
      }
    })

    
  };


  const uploadFile = () => {

    Swal.fire({
      title: 'Confirm?',
      text: "you are about to upload the provided signature to the corresponding record.",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: props.theme.add,
      cancelButtonColor: props.theme.textMenu,
      confirmButtonText: 'Confirm'
    }).then((result) => {
      if (result.isConfirmed) {
        details.uploading = true;
        const formData = new FormData();
        const data = toJS(props.EntryDetails);
        for (var key in data) {
          formData.append(key, data[key]);
        }
    
        formData.append("image", details.files[0]);
    
        fetch(props.Config.uploadURL, {
          headers: {
            "X-CSRF-TOKEN-VERIFY-HEADER": cookie.get("XSRF-TOKEN"),
            "user-token": user.getUserDetails().token,
            "site-token": "eLtM2kzxUkuXVyhEN3UqtCzpM",
          },
          method: "post",
          body: formData,
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          referrer: "no-referrer",
        }).then(response => response.json())
          .then((dataServer) => {
            if(!dataServer.error)
            {
              
              toggleMode();
              props.EntryDetails[props.Config.key] = "signed";
              Notification.success("Signature has been successfully updated");
            }
            else
            {
              details.uploading = false;
              Notification.error(dataServer.error, "Server Side", null);
            }
            
          })
          .catch((ex) => {
            details.uploading = false;
            Notification.error(ex.message, "Server Side", null);
          });
      }
    })

   
  };
  const clear = () => {
    signaturePad.current.clear();
  };
  if (props.Config.showCanvas) {
    tabs.push({
      caption: "Draw",
      content: (
        <Observer>
          {() => (
            <>
              <div>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td></td>
                      <td style={{ width: "252px" }}>
                        <label>Color</label>
                        <div>
                          <CirclePicker
                            color={details.color}
                            colors={props.Config.colors}
                            onChangeComplete={changeColor}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="signature-holder">
                <div className="signature">
                  <SignaturePad
                    ref={signaturePad}
                    options={{ penColor: details.color }}
                    width={500}
                    height={250}
                  />
                </div>
              </div>
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <label>Sign here using a pen or mouse</label>{" "}
                <ActionButton
                  onClick={clear}
                  icon="mdi mdi-close"
                  text="Clear"
                  color={theme.cancel}
                ></ActionButton>
              </div>
              <br />
              <span className="caption">{props.Config.caption}</span>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>
                      <ActionButton
                        onClick={toggleMode}
                        icon="mdi mdi-close"
                        text="Cancel"
                        color={theme.cancel}
                      ></ActionButton>
                    </td>
                    <td align="right">
                      <ActionButton
                        onClick={uploadDraw}
                        icon="mdi mdi-check"
                        text="Accept And Sign"
                        color={theme.add}
                      ></ActionButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </Observer>
      ),
    });
  }

  if (props.Config.showType) {
    tabs.push({
      caption: "Type",
      content: (
        <Observer>
          {() => (
            <>
              <div>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <label>Select Font</label>
                        <Select2Style>
                          {fontList.fonts ? (
                            <select
                              value={
                                details.activeFont ? details.activeFont.id : ""
                              }
                              ref={select2Ref}
                            >
                              {fontList.fonts.map((font) => (
                                <option key={guid()} value={font.id}>
                                  {font.family}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <></>
                          )}
                        </Select2Style>
                      </td>
                      <td style={{ width: "252px" }}>
                        <label>Color</label>
                        <div>
                          <CirclePicker
                            color={details.color}
                            colors={props.Config.colors}
                            onChangeComplete={changeColor}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="signature-holder">
                <div className="signature">
                  {details.activeFont ? (
                    <input
                      ref={inputText}
                      placeholder="Type signature here..."
                      type="text"
                      style={{
                        fontFamily: details.activeFont.family,
                        fontSize: "60px",
                        color: details.color,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <br />
              <span className="caption">{props.Config.caption}</span>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>
                      <ActionButton
                        onClick={toggleMode}
                        icon="mdi mdi-close"
                        text="Cancel"
                        color={theme.cancel}
                      ></ActionButton>
                    </td>
                    <td align="right">
                      <ActionButton
                        onClick={()=>{}}
                        icon="mdi mdi-check"
                        text="Accept And Sign"
                        color={theme.add}
                      ></ActionButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </Observer>
      ),
    });
  }

  if (props.Config.showUpload) {
    tabs.push({
      caption: "Upload",
      content: (
        <Observer>
          {() => (
            <>
              <div className="signature-holder">
                <div {...getRootProps()}>
                  <input className="dropzone-input" {...getInputProps()} />
                  <div className="text-center">
                    {isDragActive ? (
                      <div className="signature-upload">
                        <div style={{ textAlign: "center", width: "100%" }}>
                          {details.previewURL !== "" ? (
                            <>
                              <div style={{ width: "300px" }}>
                                <img
                                  alt="preview"
                                  src={details.previewURL}
                                  style={{
                                    width: "100%",
                                    height: "82px",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <label>Preview</label>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="dropzone-content">
                          <br />
                          <br />
                          <br />
                          <label>Release File Here...</label>
                          <br />
                          <br />
                          <div className="pulse">
                            <i className="mdi mdi-arrow-up-circle-outline"></i>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="signature-upload">
                        <div style={{ textAlign: "center", width: "100%" }}>
                          {details.previewURL !== "" ? (
                            <>
                              <div style={{ width: "300px" }}>
                                <img
                                  alt="preview"
                                  src={details.previewURL}
                                  style={{
                                    width: "100%",
                                    height: "82px",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <label>Preview</label>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="dropzone-content">
                          <br />
                          <br />
                          <br />
                          <label>Drag & drop signature file here</label>
                          <br />
                          <br />
                          or
                          <br />
                          <br />
                          <ActionButton
                            onClick={() => {}}
                            icon="mdi mdi-folder"
                            text="Browse..."
                            color={theme.save}
                          ></ActionButton>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <br />
              </div>
              <span className="caption">{props.Config.caption}</span>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>
                      <ActionButton
                        onClick={toggleMode}
                        icon="mdi mdi-close"
                        text="Cancel"
                        color={theme.cancel}
                      ></ActionButton>
                    </td>
                    <td align="right">
                      <ActionButton
                        onClick={uploadFile}
                        icon="mdi mdi-check"
                        text="Accept And Sign"
                        color={theme.add}
                      ></ActionButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </Observer>
      ),
    });
  }

  const tabWidth = 100 / tabs.length;

  useEffect(() => {
   
      details.loading = false;
    
  });

  return (
    <Observer>
      {() => (
        <SignatureCaptureComponentStyle>
          {!state.signMode || details.uploading ? (
            <>
              {!props.ReadOnly || details.uploading ? (
                <div className="form-group">
                  <div
                      className={`signature-edit ${
                      details.uploading
                        ? "uploading"
                        : props.EntryDetails[props.Config.key] &&
                          props.EntryDetails[props.Config.key] !== ""
                        ? "disable"
                        : "disable"
                    }`}
                  >
                    {
                      details.uploading
                        ? <span><i className="mdi mdi-information-outline"></i> Uploading Signature Please Wait...</span>
                        : props.EntryDetails[props.Config.key] &&
                          props.EntryDetails[props.Config.key] !== ""
                        ? <span><i className="mdi mdi-information-outline"></i>  Signature Already Present - <span style={{color:props.theme.cancel,fontWeight:600}}>Click Here To Edit</span></span>
                        : <span><i className="mdi mdi-information-outline"></i>  No Signature Present - <span style={{color:props.theme.cancel,fontWeight:600}}>Click Here To Add</span></span>
                    }
                  </div>
                </div>
              ) : (
                <div style={{ cursor: "pointer" }} className="form-group">
                  <div
                    onClick={toggleMode}
                    className={`signature-edit ${
                      details.uploading
                        ? "uploading"
                        : props.EntryDetails[props.Config.key] &&
                          props.EntryDetails[props.Config.key] !== ""
                        ? "signed"
                        : "unsigned"
                    }`}
                  >
                    {
                      details.uploading
                        ? <span><i className="mdi mdi-information-outline"></i> Uploading Signature Please Wait...</span>
                        : props.EntryDetails[props.Config.key] &&
                          props.EntryDetails[props.Config.key] !== ""
                        ? <span><i className="mdi mdi-information-outline"></i>  Signature Already Present - <span style={{color:props.theme.cancel,fontWeight:600}}>Click Here To Edit</span></span>
                        : <span><i className="mdi mdi-information-outline"></i>  No Signature Present - <span style={{color:props.theme.cancel,fontWeight:600}}>Click Here To Add</span></span>
                    }
                  </div>
                </div>
              )}

              <input
                type="text"
                style={{ display: "none" }}
                {...computeValidation(props.Config, props.FormId)}
                {...BindModel(
                  props.EntryDetails,
                  props.Config.key,
                  "value",
                  "onChange"
                )}
              />
            </>
          ) : (
            <>
              <div className="Tabs-holder">
                <nav>
                  <ul className="Tabs">
                    {tabs.map((tab, index) => (
                      <li
                        onClick={() => {
                          showTab(index);
                        }}
                        className={
                          details.selectedIndex === index
                            ? "Tabs__tab active Tab"
                            : "Tabs__tab Tab"
                        }
                        style={{ width: `${tabWidth}%` }}
                      >
                        <span>{tab.caption}</span>
                      </li>
                    ))}
                    {tabs.length > 0 ? (
                      <li
                        className="Tabs__presentation-slider"
                        style={{
                          left: `${tabWidth * details.selectedIndex}%`,
                          width: `${tabWidth}%`,
                        }}
                        role="presentation"
                      ></li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </nav>
                <div className="Panel__body">
                  {tabs.map((tab, index) => (
                    <div
                      style={{
                        display:
                          details.selectedIndex === index ? "inherit" : "none",
                      }}
                    >
                      {tab.content}
                    </div>
                  ))}
                </div>
              </div>
              <div className="bottom-padding-space" />
            </>
          )}
        </SignatureCaptureComponentStyle>
      )}
    </Observer>
  );
};

export default withTheme(SignatureCaptureComponent);
