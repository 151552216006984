export default {
	duplicates: [],
	access: [],
	track: "CargoManifestPackage",
	formtype: "dataEntry",

	allowSearch: [
		{
			role:  [ "default" ],
			value:  true
		}
	],
	allowDelete: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
			role:  [ "" ],
			value:  false
		}
	],
	allowAudit: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
			role:  [ "" ],
			value:  false
		}
	],
	allowEdit: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
			role:  [ "" ],
			value:  false
		}
	],
	allowAdd: [
		{
			role:  [ "default" ],
			value:  false
		},
		{
			role:  [ "" ],
			value:  false
		}
	],
	allowPrint: [
		{
			role:  [ "default" ],
			value:  false
		}
	],
	printreport: "",
	caption:  "Cargo Manifest Package Details",
	editSearchSelector: {
		resource: "GetCargoManifestPackageList",
		resourceType: "GET",
		resourceId: [ "CargoManifestPackageRecordNumber" ],
		resourceValue: "CargoManifestPackageRecordNumber",
		resourceSelected: "GetCargoManifestPackage",
		resourceSelectedType: "POST",
		resourceDisplayFields: [ ]
	},
	functionactions:
	{
		createnew: {
			functionname: "AddCargoManifestPackage",
			functiontype: "POST",
			validateData: true,
			alertOnComplete: true,
			alertTitle: "Cargo Manifest Package Information Added",
			alertText: "The Cargo Manifest Package Has Been Added"
		},
		update: {
			functionname: "UpdateCargoManifestPackageDescription",
			functiontype: "POST",
			validateData: true,
			alertOnComplete: true,
			alertTitle: "Cargo Manifest Package Information Updated",
			alertText: "The Cargo Manifest Package Has Been Updated"
		},
		after: {
			functionname: "GetCargoManifestPackageList",
			functiontype: "GET"
		}
	},
	formDefinition: {
		rows: [
			{
			columns: [
				{
				title: "Cargo Manifest Package Information",
				fields: [
					{
						key: "AirwaybillPackageNumber",
						type: "select",
						label: "CargoManifest Package Number",
						placeholder: "CargoManifest Package Number",
						readOnly: false, 
						allowText: false,
						optionsResource: {
						  resource: "GetPendingAirwaybillPackageList",
						  resourceType: "POST",
						  resourceId: ["AirwaybillPackageNumber"],
						  resourceIdentity: "AirwaybillPackages",
						  resourceValue: "AirwaybillPackageNumber"
						},
						details: ["PackageDescription"],
						mapping: [
						  {
							id: "PackageDescription",
							key: "PackageDescription"
						  },
						  {
							id: "Sender",
							key: "Sender"
						  },
						  {
							id: "Recipient",
							key: "Recipient"
						  },
						  {
							id: "CityTo",
							key: "CityTo"
						  }
						],
						validation: {
							"data-parsley-required": true,
							"data-parsley-minlength": 2,
							"data-parsley-maxlength": 13
						}
					},
					{
						key: "PackageDescription",
						type: "text",
						label: "Package Description",
						placeholder: "Package Description",
						readOnly: true
					}
				]
			  },
			  {
				title: "\n",
				fields: [
					{
						key: "Sender",
						type: "text",
						label: "Sender",
						placeholder: "Sender",
						readOnly: true
					},
					{
						key: "Recipient",
						type: "text",
						label: "Recipient",
						placeholder: "Recipient",
						readOnly: true
					},
					{
					  key: "CityTo",
					  type: "select",
					  defaultValue: "",
					  label: "Destination",
					  placeholder: "Destination",
					  readOnly: true,
					  optionsResource: {
						resource: "GetCargoStationList",
						resourceType: "GET",
						resourceId: ["CargoStation"],
						resourceIdentity: "CargoStations",
						resourceValue: "CargoStationName"
					  }
					}
				]
				}
			]
			}
		]
	}
}