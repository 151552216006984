import processResponse from "./processResponse";

const processPost = options => {
  fetch(options.url, {
    headers: {
      "X-CSRF-TOKEN-VERIFY-HEADER": options.xsrfToken,
      "user-token": options.token,
      "site-token":"eLtM2kzxUkuXVyhEN3UqtCzpM"
    },
    method:"post",
    body: JSON.stringify(options.data),
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",    
    referrer: "no-referrer"
  })
    .then(processResponse)
    .then(payload => {
      if(payload.error)
      {         
          options.errorCallback(payload.error);        
      }
      else
      {
        if (options.callback) {        
          options.callback(payload.data);
        }
      }
    })
    .catch(error => {
      console.error(error);
        options.errorCallback(error);
            
    });
};

export default processPost;
