import React, { PureComponent } from 'react';
//import computeId from '../../actions/computeId';
import { guid } from '../../../../utilities';
import { Observer } from 'mobx-react';
import computeValidation from '../../actions/computeValidation';
import BindModel from '../../../Core/BindModel';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import computeAsterisk from '../../actions/computeAsterisk';
import { observe, toJS } from 'mobx';
import moment from 'moment';
export default class DatePicker extends PureComponent {
  componentDidMount() {
    if (
      this.props.Config.interval &&
      this.props.Config.addValue &&
      this.props.Config.watchKey
    ) {
      observe(this.props.EntryDetails, (change) => {
        try {
          if (change.name === this.props.Config.watchKey) {
            this.dependentOnAnotherValue();
          }
        } catch (ex) {}
      });
      this.dependentOnAnotherValue();
    }
  }
  dependentOnAnotherValue() {
    const inspect = toJS(this.props.EntryDetails);
    const watchValue = inspect[this.props.Config.watchKey];
    const currentValue = inspect[this.props.Config.key];
    if (watchValue && watchValue.toString().trim().length > 0) {
      if (!currentValue || currentValue.toString().trim().length === 0) {
        this.props.EntryDetails[this.props.Config.key] = moment(watchValue, [
          'YYYY/MM/DD',
        ]).add(this.props.Config.interval, this.props.Config.addValue);
      }
    }
  }
  render() {
    return (
      <Observer>
        {() => (
          <div className="form-group">
            <label>
              {this.props.Config.label} {computeAsterisk(this.props.Config)}
            </label>
            <DatePickerComponent
              format={'dd-MMM-yyyy'}
              showClearButton={true}
              showTodayButton={true}

              readonly={this.props.ReadOnly}
              {...BindModel(
                this.props.EntryDetails,
                this.props.Config.key,
                'value',
                'change',
                true
              )}
              className="form-control"
              placeholder={this.props.Config.placeholder}
            />
            <input
              type="text"
              style={{ display: 'none' }}
              {...computeValidation(this.props.Config, this.props.FormId)}
              {...BindModel(
                this.props.EntryDetails,
                this.props.Config.key,
                'value',
                'onChange'
              )}
            />
          </div>
        )}
      </Observer>
    );
  }
}
