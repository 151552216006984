import React from "react";
const Welcome = props => (
    
  <div style={{padding:"0 50px"}}>           
    <br/>
    <br/>
    <h2>Cargo Management System</h2>
    <h4>About This System</h4>
    <p>
      The Cargo Management System is designed to be 
      a user friendly application that manages the receipt, shipping, and delivery
      of cargo.
    </p>
    <p>
      It is designed as a reactive web application that is very responsive
      and accessible via a variety of mediums, including desktop computers, 
      tablets, and other mobile devices.
    </p>
    <br/>
    <br/>
    <h4>Upcoming Features</h4>
    <strong>Dashboard</strong>
    <p>
      This opening page will be the host of dashboard functionalities
      that provide an overall view of several aspects of the operation at a glance. 
    </p>
    <p>
      The dashboard will be tailored to meet specific needs and display information 
      that is relevant to the role of the user that is logged in.
    </p>
  </div>
    
  
);

export default Welcome;