import React, { PureComponent } from 'react';
import FormLoader from '../../FormRenderer';
import { observable } from 'mobx';
import $ from 'jquery';
import 'parsleyjs';
import { guid } from '../../../utilities';

export default class SearchForm extends PureComponent {
  constructor(props) {
    super(props);
    this.SearchDetails = observable({});
    this.FormId = guid();
    
  }
  componentDidMount() {
    //workordersearch
    
    $(`#${this.FormId}`).parsley({
      errorsContainer: el => {
        return el.$element.closest('.form-group');
      }
    });
  }
  render() {
    return (
      <div className="search-results">
        <div className="arrow-holder">
          <div className="triangle">
            <div className="empty" />
          </div>

          <div
            className="search-results-holder"
            style={{
              width: `${this.props.customDefinition.width}px`,
              marginLeft: `${this.props.customDefinition.align * -1}px`,
              
            }}
          >
            <div
              className="internal-search-results-holder"
              
            >
              <FormLoader
                FormId={this.FormId}
                noAnimation={true}
                isAdding={false}
                ReadOnly={false}
                EntryDetails={this.SearchDetails}
                FormDefinition={this.props.customDefinition.searchForm}
                onSelection={this.props.onSelection}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
