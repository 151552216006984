
import CargoManifestSearch from "./cargomanifestsearch";
import CargoManifestPackageList from "../cargomanifestpackage/cargomanifestpackagelist";
import Colors from "../../../constants/Colors";
export default {
  duplicates: [],
  access: [],
  track: "CargoManifest",
  formtype: "dataEntry",
  customSearch: {
    searchForm: { ...CargoManifestSearch },
    width: 1000,
    align: 500,
    height: 800,
    id: "cargomanifestsearch"
  },
  allowSearch: [
    {
      role: ["default"],
      value: true
    }
  ],
  allowDelete: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAudit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowEdit: [
    {
      role: ["default"],
      value: false
    }
  ],
  allowAdd: [
    {
      role: ["default"],
      value: false
    },
    {
      role: ["Cargo Administrator", "Cargo Member"],
      value: true
    }
  ],
  allowPrint: [
    {
      role: ["default"],
      value: false
    }
  ],

  customActions: [
    {
      showInEditMode: false,
      showInAddMode: false,
      showInLocked: false,
      requiresSelection: true,
      showInEditableMode: true,
      eventType: "edit",
      refreshAfterAction: true,
      dialogOnly: false,
      text: "Edit",
      icon: "mdi mdi-pencil",
      refreshAfterAction: true,
      color: Colors.edit,
      dependentFields: [
        {
          key: "ManifestStatus",
          values: ["Closed", "Voided"],
          active: false
        }
      ],
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Manifest Member"],
          value: true
        }
      ],
      after: {
        functionName: "GetCargoManifest",
        functionType: "Post",
        resourceIdentity: "CargoManifest"
      }
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-chevron-double-right",
      text: "Close Manifest",
      refreshAfterAction: true,
      eventType: "api",
      color: Colors.process,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Manifest Member"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "ManifestStatus",
          values: ["Closed", "Voided"],
          active: false
        },
        {
          key: "AllowClose",
          values: ["No"],
          active: false
        }
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm closing of cargo manifest"
      }, 
      function: {
        functionName: "CloseCargoManifest",
        functionType: "POST",
        validateData: true,
        alertOnComplete: true,
        alertText: "The Cargo Manifest Has Been Closed"
      },
      after: {
        functionName: "GetCargoManifest",
        functionType: "Post",
        resourceIdentity: "CargoManifest"
      }
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-null",
      text: "Void Cargo Manifest",
      eventType: "api",
      refreshAfterAction: true,
      color: Colors.cancel,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "ManifestStatus",
          values: ["Closed", "Voided"],
          active: false
        },
        {
          key: "AllowClose",
          values: ["No"],
          active: false
        }
      ],
      confirmation:{                    
          title:"Confirm",
          text:"Please confirm voiding of cargo manifest"
      }, 
      function: {
        functionName: "VoidCargoManifest",
        functionType: "POST",
        validateData: true,
        alertOnComplete: true,
        alertText: "The Cargo Manifest Has Been Voided"
      },
      after: {
        functionName: "GetCargoManifest",
        functionType: "Post",
        resourceIdentity: "CargoManifest"
      }
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-printer",
      text: "Print",
      eventType: "report",
      report: {
        id: "CargoManifest/CargoManifest",
        header: "Cargo manifest"
      },
      color: Colors.print,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Viewer", "Manifest Member", "Manifest Viewer"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "ManifestStatus",
          values: ["Voided"],
          active: false
        }
      ]
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-printer",
      text: "Print All For Flight",
      eventType: "report",
      report: {
        id: "CargoManifest/CargoManifestByFlightNumber",
        header: "Cargo manifest"
      },
      color: Colors.print,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Viewer", "Manifest Member", "Manifest Viewer"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "ManifestStatus",
          values: ["Voided"],
          active: false
        }
      ]
    },
    {
      showInEditMode: false,
      showInAddMode: false,
      requiresSelection: true,
      showInLocked: false,
      showInEditableMode: true,
      icon: "mdi mdi-printer",
      text: "Print Int'l Manifest",
      eventType: "report",
      report: {
        id: "CargoManifest/InternationalCargoManifest",
        header: "Cargo manifest"
      },
      color: Colors.print,
      roles: [
        {
          role: ["Cargo Administrator", "Cargo Member", "Cargo Viewer", "Manifest Viewer"],
          value: true
        }
      ],
      dependentFields: [
        {
          key: "ManifestStatus",
          values: ["Voided"],
          active: false
        },
        {
          key: "InternationalCargoManifest",
          values: ["No"],
          active: false
        }
      ]
    }
  ],
  printreport: "",
  caption: "Cargo Manifest",
  editSearchSelector: {
    resource: "GetCargoManifestList",
    resourceType: "GET",
    resourceId: ["ManifestNumber"],
    resourceIdentity: "CargoManifests",
    resourceValue: "ManifestNumber",
    resourceSelected: "GetCargoManifest",
    resourceSelectedType: "POST",
    resourceSelectedIdentity: "CargoManifest",
    resourceDisplayFields: []
  },
  functionactions: {
    createnew: {
      functionname: "AddCargoManifest",
      functiontype: "POST",
      resourceIdentity: {
        id: "CargoManifest",
        mapping: [
          {
            id: "ManifestNumber",
            key: "ManifestNumber"
          },
          {
            id: "PreparedBy",
            key: "PreparedBy"
          },
          {
            id: "ManifestStatus",
            key: "ManifestStatus"
          },
          {
            id: "StatusDate",
            key: "StatusDate"
          },
          {
            id: "StatusChanger",
            key: "StatusChanger"
          }
        ]
      },
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Manifest Information Added",
      alertText: "The Cargo Manifest Has Been Added"
    },
    update: {
      functionname: "UpdateCargoManifest",
      functiontype: "POST",
      resourceIdentity: {
        id: "CargoManifest",
        mapping: [
          {
            id: "StatusDate",
            key: "StatusDate"
          },
          {
            id: "StatusChanger",
            key: "StatusChanger"
          }
        ]
      },
      validateData: true,
      alertOnComplete: true,
      alertTitle: "Cargo Manifest Information Updated",
      alertText: "The Cargo Manifest Has Been Updated"
    },
    after: {
    }
  },
  formDefinition: {
    rows: [
      {
        columns: [
          {
            title: "\n",
            fields: [ 
            {
              key: "ManifestNumber",
              type: "text",
              defaultValue: "",
              label: "Manifest Number",
              placeholder: "Manifest Number",
              readOnly: true
            },
            {
                key: "CityFrom",
                type: "select",
                defaultValue: "StationPosted",
                label: "City From",
                placeholder: "City From",
                readOnly: false,
                optionsResource: {
                  resource: "GetActiveCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 4
                }
              },
              {
                key: "CityTo",
                type: "select",
                defaultValue: "",
                label: "City To",
                placeholder: "City To",
                readOnly: false,
                optionsResource: {
                  resource: "GetActiveCargoStationList",
                  resourceType: "GET",
                  resourceId: ["CargoStation"],
                  resourceIdentity: "CargoStations",
                  resourceValue: "CargoStationName"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-minlength": 2,
                  "data-parsley-maxlength": 4,
                  "data-parsley-differentcityfromandcityto": true
                }
              },             
              {
                key: "PackageType",
                type: "select",
                defaultValue: "",
                label: "Package Type",
                placeholder: "Package Type",
                readOnly: false,
                optionsResource: {
                  resource: "GetCargoPackageTypeList",
                  resourceType: "GET",
                  resourceId: ["PackageType"],
                  resourceIdentity: "CargoPackageTypes",
                  resourceValue: "PackageTypeDescription"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 10
                }
              },            
              {
                key: "ReferenceNumber",
                type: "number",
                defaultValue: "",
                label: "Reference Number",
                placeholder: "Reference Number",
                readOnly: false,
                validation: {
                  "data-parsley-min": 0,
                  "data-parsley-max": 10000000,
                  "data-parsley-maxlength": 10
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "FlightDate",
                type: "date",
                defaultValue: "Today",
                label: "Flight Date",
                placeholder: "Flight Date",
                readOnly: false,
                validation: {
                  "data-parsley-required": true
                }
              },
              {
                key: "FlightNumber",
                type: "select",
                defaultValue: "",
                label: "Flight Number",
                placeholder: "Flight Number",
                readOnly: false,
                allowText: true,
                optionsResource: {
                  resource: "GetFlightScheduleList",
                  resourceType: "POST",
                  resourceId: ["FlightNumber"],
                  resourceIdentity: "FlightSchedules",
                  resourceValue: "FlightNumber"
                },
                details: ["FlightNumberTime"],
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 10
                },
                mapping: [
                  {
                    id: "DepartureTime",
                    key: "FlightTime"
                  }
                ]
              },
              {
                key: "FlightTime",
                type: "text",
                defaultValue: "",
                label: "Flight Time",
                placeholder: "Flight Time",
                readOnly: false,
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 10,
                  "data-parsley-validflighttime": true
                }
              },
              {
                key: "AircraftId",
                type: "select",
                defaultValue: "",
                label: "Aircraft",
                placeholder: "Aircraft",
                readOnly: false,
                optionsResource: {
                  resource: "GetActiveAircraftList",
                  resourceType: "GET",
                  resourceId: ["RegistrationNumber"],
                  resourceIdentity: "Aircrafts",
                  resourceValue: "RegistrationNumber"
                },
                validation: {
                  "data-parsley-required": true,
                  "data-parsley-maxlength": 6
                }
              }
            ]
          },
          {
            title: "\n",
            fields: [
              {
                key: "ManifestStatus",
                type: "text",
                defaultValue: "Pending",
                label: "Status",
                placeholder: "Status",
                readOnly: true
              },
              {
                key: "PreparedBy",
                type: "text",
                label: "Prepared By",
                placeholder: "Prepared By",
                readOnly: true
              },
              {
                key: "StatusDate",
                type: "date",
                label: "Status Date",
                placeholder: "Status Date",
                readOnly: true
              },
              {
                key: "StatusChanger",
                type: "text",
                defaultValue: "",
                label: "Status Changer",
                placeholder: "Status Changer",
                readOnly: true
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            title: "\n",
            fields: [
              {
                type: "tabs",
                readOnlyOnEdit: true,
                tabs: [
                  {
                    caption: "Packages",
                    definition: { ...CargoManifestPackageList }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
