import { guid, user } from '../../utilities';
import $ from 'jquery';

class WindowManager {
  constructor() {
    this.closing = false;
    window.onbeforeunload = () => {
      this.windowSystem.forEach(comp => {
        try {
          this.closing = true;
          comp.windowHandle.close();
        } catch (ex) {}
      });
    };

    this.windowSystem = [];
    window.onReady = (id, win) => {
      const windowHanderIndex = this.windowSystem.findIndex(
        windowHandler => windowHandler.id === id
      );

      if (windowHanderIndex < 0) {
        return;
      }
      const currentComponent = this.windowSystem[windowHanderIndex];
      win.onbeforeunload = () => {
        if (!this.closing) {
          try {
            const removeWindowHanderIndex = this.windowSystem.findIndex(
              windowHandler => windowHandler.id === currentComponent.id
            );         
            this.windowSystem.splice(removeWindowHanderIndex, 1);
          } catch (ex) {}
        }
      };

      $(win.document)
        .find('head')
        .find('title')
        .html(`ARMS - ${currentComponent.menu.name}`);

      currentComponent.windowHandle = win;
      win.setUser(user.getUserDetails(), currentComponent);
    };
  }

  popTabToWindow(menu, formDefinition) {
    const componentItem = {
      id: guid(),
      menu,
      formDefinition,
      windowHandle: null
    };
    this.windowSystem.push(componentItem);
    const id = new Date().getTime();
    var full =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '');
    window.open(
      `${full}/index.html?id=${componentItem.id}`,
      id,
      'toolbar=0,scrollbars=0,location=1,statusbar=0,menubar=0,resizable=1,width=800,height=600,left = 0,top = 0'
    );
  }

  closeTab(index) {
    this.windowSystem.openedWindows.splice(index, 1);
    if (this.windowSystem.activeIndex === index) {
      let next = this.windowSystem.activeIndex - 1;
      if (next < 0) {
        next = 0;
      }
      if (this.windowSystem.openedWindows.length === 0) {
        this.windowSystem.activeIndex = -1;
      } else {
        this.windowSystem.activeIndex = next;
      }
    }
  }

  openRecent() {}

  setActive(index) {
    this.windowSystem.activeIndex = index;
  }

  getItemByIdentifier(id) {
    return this.windowSystem.openedWindows[
      this.windowSystem.findIndex(windowHandler => windowHandler.id === id)
    ];
  }

  getWindowHandler() {
    return this.windowSystem;
  }
}

const windowManager = new WindowManager();

export default windowManager;
