//Core Components
import TextBox from "./Components/TextBox";
import Spacer from "./Components/Spacer";
import NumberTextBox from "./Components/NumberTextBox";
import PasswordTextBox from "./Components/PasswordTextBox";
import TextArea from "./Components/TextArea";
import Label from "./Components/Label";
import DatePicker from "./Components/DatePicker";
import ComputedTextBox from "./Components/ComputedTextBox";
import CheckBox from "./Components/CheckBox";
import DataGrid from "./Components/DataGrid";
import Select2 from "./Components/Select2";
import SelectOrTextBox from "./Components/SelectOrTextBox";
import TabComponent from "./Components/TabComponent";
import SearchDataGrid from "./Components/SearchDataGrid";
import InlineDataGrid from "./Components/InlineDataGrid";
import RichTextEditor from "./Components/RichTextEditor";
import Signature from "./Components/Signature";
//Custom Components

import FileUpLoader from "./Components/FileUpload";
import SignatureCaptureComponent from "./Components/SignatureCaptureComponent";
import BarCode from "./Components/BarCode";

const componentRegistry = {
  Components: {
    text: TextBox,
    barcode:BarCode,
    password: PasswordTextBox,
    spacer: Spacer,
    number: NumberTextBox,
    date: DatePicker,
    textarea: TextArea,
    label: Label,
    computedtextbox: ComputedTextBox,
    checkbox: CheckBox,
    select: Select2,
    selectortext: SelectOrTextBox,
    tabs: TabComponent,
    editabletable: DataGrid,
    inlineeditabletable:InlineDataGrid,
    searchtable: SearchDataGrid,
    richtext: RichTextEditor,
    signature: Signature,
    fileuploader: FileUpLoader,
    signatureeditor: SignatureCaptureComponent
  },
  Custom: {
    
  }
};

export default componentRegistry;