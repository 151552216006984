import styled from '@emotion/styled';

const ActionButtonStyle = styled.span`
  button {
    margin-right: 10px;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 25px;
    border: 0px solid transparent;
    padding: 1.5pt 14pt 1.5pt 9pt;
    display:inline-block;
    i {
      display: inline-block;
      background: ${(props) => props.theme.textLight};
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      color: ${(props) => props.theme.textLight}!important;
      
    }
  }
  .dropdown-menu
  {
    min-width: 14rem!important;
    padding:0px;
    z-index:9999;
    background: ${(props) => props.theme.backgroundDark}!important;
    border: 0px solid rgba(0, 0, 0, 0.15)!important;
    button {
    margin-right: 10px;
    text-align:left;
    background: ${(props) => props.theme.backgroundDark}!important;
    font-family: ${(props) => props.theme.headerFont};
    font-size: 600;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 0px!important;
    width:100%;
    border: 0px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.backgroundLight};
    padding: 3px 20px 3px 10px;
    color:${(props) => props.theme.textMenu}!important;
    &:hover {
      background: ${(props) => props.theme.backgroundDarker}!important;
    }
    display:inline-block;
    i {
      display: inline-block;
      background: ${(props) => props.theme.backgroundDark}!important;
      color:${(props) => props.theme.textMenu}!important;
      border-radius: 25px;
      padding: 0 3px;
      margin: 4px 0;
      margin-right: 10px;
    }
    span {
      display: inline-block;      
      color:${(props) => props.theme.textMenu}!important;
      
    }
  }
`;

export default ActionButtonStyle;
