import { compute, guid } from "../../../../utilities";

import { Observer } from "mobx-react";
import React from "react";
import computeId from "../../actions/computeId";
import { toJS } from "mobx";
import * as numeral from "numeral";

const ComputedTextBox = props => (
  <Observer>
    {() => (
      <div
        ref={() => {
          props.EntryDetails[props.Config.key] = compute(
            props.Config.operation,
            toJS(props.EntryDetails),
            props.Config.precision
          );
          return guid();
        }}
        className="form-group"
      >
        <label>{props.Config.label}</label>
        <span
          id={computeId(props.Config)}
          className="form-control"
          style={{
            display: "block",
            
            background: "#fff",
            border: "1px solid transparent"
          }}
        >
          
           {props.Config.format?numeral(compute(
            props.Config.operation,
            toJS(props.EntryDetails),
            props.Config.precision
          )).format(props.Config.format):compute(
            props.Config.operation,
            toJS(props.EntryDetails),
            props.Config.precision
          )}
        </span>
      </div>
    )}
  </Observer>
);

export default ComputedTextBox;
