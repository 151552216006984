import React from 'react';
import ActionBar from '../../Core/ActionBar';
import { Observer } from 'mobx-react';
import { observable,toJS } from 'mobx';

const Dialog = (props) => {
  //  const FormId = guid();

  const currentState = observable({
    isAdding: props.isAdding,
    isEditing: props.isEditing,
    EntryDetails: props.EntryDetails
  });


  const refreshData = (newData) => {
    return new Promise((resolve) => {
      if (props.reloadData) {
        props.reloadData(newData).then(data => {
          currentState.isAdding = false;
          currentState.isEditing = false;
          if (data) {
            currentState.currentData = data;
          }
          resolve();
        });
      }
    });

  }

  return (
    <Observer>{() => (

      <div className="dialogOverlay">
        <div className="dialog">
          <div className="dialog-header" style={{ height: '80px' }}>
            <a
              href="#/"
              className="close-dialog"
              onClick={e => {
                e.preventDefault();
                if (props.stack) {
                  var currentStack = toJS(props.stack);
                  if (currentStack[currentStack.length-1] && currentStack[currentStack.length-1].onClose != null && currentStack[currentStack.length-1].onClose != undefined) {
                    currentStack[currentStack.length-1].onClose();
                  }

                  props.stack.pop();
                }
                else {
                  if (props.onCloseDialog) {
                    props.onCloseDialog();
                  }
                }
              }}
            >
              <i style={{ color: '#fff' }} className="icon-cross" />
            </a>
            <div className="row">
              <div className="col-md-12">
                <div className="dialog-header-h">
                  <h2>{props.header}</h2>
                  <ActionBar
                    FormDefinition={props.FormDefinition}
                    AdditionalButtons={props.FormDefinition && props.FormDefinition.customActions ? props.FormDefinition.customActions : []}
                    nothingSelected={false}
                    refreshData={refreshData}
                    FormId={props.FormId}
                    refreshAfterSave={props.refreshAfterSave}
                    onStateChanged={props.onStateChanged}
                    stack={props.stack}
                    readOnly={props.ReadOnly}
                    Locked={props.Locked}
                    isAdding={currentState.isAdding}
                    isEditing={currentState.isEditing}
                    isDialog={true}
                    EntryDetails={currentState.EntryDetails}
                    ParentData={currentState.EntryDetails}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" />
            </div>
          </div>
          <div className="dialog-inner">
            {React.cloneElement(props.children, {
              FormId: props.FormId, EntryDetails: currentState.EntryDetails, ParentData: currentState.EntryDetails
            })}
          </div>
        </div>
      </div>
    )}</Observer>

  );

}

export default Dialog;